import { getLanguage, setLanguage } from "config/language";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { selectLan } from "redux/container/languageSlice";
import Select, { components } from "react-select";

export default function LandingNavBar() {
  const [scrolling, setScrolling] = useState("");
  const dispatch = useDispatch();
  const appLanguage = getLanguage();
  const ekkLanguage = useSelector((state) => state.lan.lan);
  var sSize = window.matchMedia("(max-width: 599px)");
  useEffect(() => {
    function onScroll() {
      let currentPosition = document.documentElement.scrollTop;
      if (currentPosition > 150) {
        setScrolling("isScrolled");
      } else {
        setScrolling("");
      }
    }

    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  const options = [
    {
      value: "bn",
      label: ekkLanguage.landing.bangla,
      image:
        "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-bangladesh.png",
    },
    {
      value: "en",
      label: ekkLanguage.landing.english,
      image:
        "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-united-states-minor-outlying-islands.png",
    },

    {
      value: "vn",
      label: ekkLanguage.landing.vietnamese,
      image:
        "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-viet-nam.png",
    },
    {
      value: "my",
      label: ekkLanguage.landing.malaysia,
      image:
        "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-malaysia.png",
    },
  ];
  const customStyles = {
    control: (provided) => ({
      ...provided,
      border: sSize.matches ? "1px solid gray" : "none",
      borderRadius: "10px",
      height: sSize.matches ? 30 : 50,
    }),
  };
  const selectedOption = options.find((el) => el.value === appLanguage);

  useEffect(() => {
    const handler = (e) => {
      e.preventDefault();
      console.log("we are being triggered :D");
    };
    window.addEventListener("beforeinstallprompt", handler);
    return () => window.removeEventListener("transitionend", handler);
  }, []);

  const languageSubmitHandler = (e) => {
    setLanguage(e.value);
    dispatch(selectLan());
  };

  const CustomSingleValue = ({ children, ...props }) => (
    <components.SingleValue {...props}>
      <div className="flex items-center w-[113px]">
        <img
          src={selectedOption.image}
          className="sm:h-[20px] sm:w-[30px] h-5 w-[33px] rounded-[2px]"
          alt="flag"
          style={{ marginRight: "10px" }}
        />
        {children}
      </div>
    </components.SingleValue>
  );

  const CustomOption = ({ innerProps, label, data }) => (
    <div
      className="flex items-center py-1 pl-[10px] cursor-pointer"
      {...innerProps}
    >
      <img
        src={data.image}
        className="sm:h-[20px] sm:w-[30px] h-5 w-[33px] rounded-[2px]"
        alt="flag"
        style={{ marginRight: "10px" }}
      />
      {label}
    </div>
  );

  return (
    <>
      <div>
        <div
          className={`absolute z-50 w-full ${
            scrolling ? "bg-white sticky top-0 left-0 right-0" : ""
          } lg:pt-2 pt-0`}
        >
          <div className={`container hidden lg:flex`}>
            <div>
              <Link to="/">
                <img
                  src="/assets/images/ekkbaz_brand_full_logo.png"
                  alt="logo"
                  className="w-[100px] mr-[80px] sm:w-[145px]"
                />
              </Link>
            </div>
            <div className="flex items-center justify-end w-full">
              <ul className="flex items-center justify-end">
                <li className="flex items-center">
                  <Select
                    styles={customStyles}
                    options={options}
                    isSearchable={false}
                    defaultValue={selectedOption}
                    onChange={(e) => languageSubmitHandler(e)}
                    components={{
                      IndicatorSeparator: () => null,
                      Option: CustomOption,
                      SingleValue: CustomSingleValue,
                    }}
                  />
                </li>
              </ul>
            </div>
          </div>

          <div className={`container mt-3`}>
            <div
              className={`${
                scrolling ? "mt-0 py-2" : "mt-0"
              } justify-between flex lg:hidden`}
            >
              <div>
                <Link to="/">
                  <img
                    src="/assets/images/ekkbaz_brand_full_logo.png"
                    alt="logo"
                    className="sm:w-[145px] w-[100px] mr-[80px]"
                  />
                </Link>
              </div>
              <div className="flex items-center">
                <li className="flex items-center">
                  <Select
                    styles={customStyles}
                    options={options}
                    isSearchable={false}
                    defaultValue={selectedOption}
                    onChange={(e) => languageSubmitHandler(e)}
                    components={{
                      IndicatorSeparator: () => null,
                      Option: CustomOption,
                      SingleValue: CustomSingleValue,
                    }}
                  />
                </li>
              </div>
            </div>
            <div
              className={`w-full bg-white z-50 lg:hidden top-[130px] p-[20px] rounded-[5px] transition-all hidden`}
            >
              <div>
                <ul className="flex justify-end flex-col-reverse ">
                  <li className="flex items-center mb-3">
                    <Select
                      styles={customStyles}
                      options={options}
                      isSearchable={false}
                      defaultValue={selectedOption}
                      onChange={(e) => languageSubmitHandler(e)}
                      components={{
                        IndicatorSeparator: () => null,
                        Option: CustomOption,
                        SingleValue: CustomSingleValue,
                      }}
                    />
                  </li>
                </ul>
              </div>
              <div className="py-4"></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
