import { baseUrl } from "config/apiUrl";
import SuperFetch from "config/SuperFetch";

const Payment = {
	getAllPaymentTerms: () => {
		return SuperFetch(`${baseUrl.pay}/ekkbaz/all_terms/?order_type=Primary`);
	},
	sellerPaymentTerms: () => {
		return SuperFetch(`${baseUrl.pay}/ekkbaz/seller_terms/`);
	},
	createPaymentTerms: (data) => {
		return SuperFetch(`${baseUrl.pay}/ekkbaz/seller_terms/create/`, {
			method: "POST",
			data: data,
		});
	},
	updatePaymentTerms: (data) => {
		return SuperFetch(`${baseUrl.pay}/ekkbaz/seller_terms/update/`, {
			method: "PUT",
			data: data,
		});
	},
	getReceiverBankInfo: (bank) => {
		return SuperFetch(`${baseUrl.pay}/ekkbaz/ekkpay_bank/?bank_name=${bank}`);
	},
	getSenderBankInfo: (bank) => {
		return SuperFetch(`${baseUrl.pay}/ekkbaz/seller_payment_gateways/`);
	},
	sellerPaySubmit: (data) => {
		return SuperFetch(`${baseUrl.pay}/ekkbaz/seller_pay/`, {
			method: "POST",
			data: data,
		});
	},
	getBankList: (id, page = 1, limit = 100) => {
		return SuperFetch(`${baseUrl.pay}/ekkbaz/banks/`);
	},
	getBankListSearch: (query, page = 1, limit = 100) => {
		return SuperFetch(`${baseUrl.pay}/ekkbaz/banks/?q=${query}`);
	},
	createBankAccount: (data) => {
		return SuperFetch(`${baseUrl.pay}/ekkbaz/seller_payment_gateways/`, {
			method: "POST",
			data: data,
		});
	},
	getBrandBankList: () => {
		return SuperFetch(`${baseUrl.pay}/ekkbaz/seller_payment_gateways/`);
	},

	defaultPayment: (id) => {
		return SuperFetch(
			`${baseUrl.pay}/ekkbaz/distributor_payment_gateway/?related_business_id=${id}`
		);
	},
	postDefaultPayment: (data) => {
		return SuperFetch(`${baseUrl.pay}/ekkbaz/distributor_payment_gateway/`, {
			method: "POST",
			data: data,
		});
	},
	putDefaultPayment: (id, data) => {
		return SuperFetch(
			`${baseUrl.pay}/ekkbaz/distributor_payment_gateway/${id}/`,
			{
				method: "PUT",
				data: data,
			}
		);
	},
	cardLoading: (data) => {
		return SuperFetch(`${baseUrl.pay}/create_setup_intent/`, {
			method: "POST",
			data: data,
		});
	},
	getCards: (id) => {
		return SuperFetch(`${baseUrl.pay}/get_customer_card/?seller_id=${id}`);
	},
	setCard: (data) => {
		return SuperFetch(`${baseUrl.pay}/add_card/`, {
			method: "PUT",
			data: data,
		});
	},
	setDefaultCard: (data) => {
		return SuperFetch(`${baseUrl.pay}/add_default_card/`, {
			method: "POST",
			data: data,
		});
	},
	setRemoveCard: (data) => {
		return SuperFetch(`${baseUrl.pay}/remove_card/`, {
			method: "POST",
			data: data,
		});
	},

	subscriptionPlan: () => {
		return SuperFetch(`${baseUrl.pay}/ekkbaz/subscription_plan/`);
	},
	getSelectedSubscriptionPlan: (id) => {
		return SuperFetch(`${baseUrl.pay}/ekkbaz/subscription_details/${id}/`);
	},
	makePayment: (data) => {
		return SuperFetch(`${baseUrl.pay}/create_payment/`, {
			method: "POST",
			data: data,
		});
	},
	getCoupon: (code) => {
		return SuperFetch(`${baseUrl.pay}/coupon_details/${code}`);
	},
	getTransactionHistory: (id) => {
		return SuperFetch(`${baseUrl.pay}/ekkbaz/subscription_payment/${id}`);
	},
};

export default Payment;
