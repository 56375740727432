import { digitChanger } from "helpers/helper";
import React from "react";
import { useNavigate } from "react-router-dom";

export default function DistributorCustomerCard({ item, ekkLanguage }) {
  const navigate = useNavigate();

  return (
    <div
      className="rounded py-1 w-full"
      // onClick={() =>
      // 	navigate(`/customer/${item.id}/${item.related_business_id}`)
      // }
    >
      {item?.images?.length ? (
        <div className="h-24 flex justify-center items-center overflow-hidden bg-white rounded-[10px]">
          <img className="" src={item?.images[0]} alt="logo"></img>
        </div>
      ) : (
        <div className="h-24 flex justify-center items-center overflow-hidden bg-white rounded-[10px]">
          <img className="" src="/assets/images/shops.png" alt="logo"></img>
        </div>
      )}
      <div className="flex flex-col justify-center px-1">
        <h4 className="text-14 text-black-1000 font-bold py-[2px] pt-2">
          {item?.related_business}
        </h4>
        <div className="flex justify-between items-center">
          <p className="text-12 text-black-500 font-normal">
            {item?.related_business_address}
          </p>
        </div>
        <div className="flex items-center">
          {" "}
          <img
            className="h-[12px] w-[12px]"
            src="/assets/images/Icon/pin.png"
            alt=""
          />
          <p className="text-12 text-[#FF261A] ml-0.5">
            {digitChanger(Number(item?.distance).toFixed(2))}{" "}
            {ekkLanguage.customerList.km}
          </p>
        </div>
      </div>
    </div>
  );
}
