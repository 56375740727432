import React, { useEffect, useRef, useState } from "react";
import Select from "react-select";
import { COUNTRYINFO } from "data/country";
import { useDispatch, useSelector } from "react-redux";
import { parseNumber } from "libphonenumber-js";
import Auth from "services/Auth";
import { Link, useNavigate } from "react-router-dom";
import { setCreateUser } from "redux/container/userSlice";
import { selectLan } from "redux/container/languageSlice";

export default function MobileNumber({
	showBtn,
	countryCode,
	setCountryCode,
	country,
	setCountry,
	phoneNo,
	setPhoneNo,
	fetchCustomer,
	existingCustomer,
}) {
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	let newBusinessPhoneInfo = useSelector(
		(state) => state.businessSettings.newBusiness
	);

	var countryList = Object.values(COUNTRYINFO).map((val) => ({
		countryCode: val.code,
		country: val.name,
		value: val.code,
		label: (
			<div className='flex items-center'>
				<img
					src={val.flag}
					className='sm:h-[32px] sm:w-[50px] h-auto w-5 rounded-[5px]'
					alt='flag'></img>{" "}
				<p className='text-16 pl-2'>{val.code}</p>
			</div>
		),
	}));
	const handleSelectCountry = (e) => {
		setCountryCode(e.countryCode);
		setCountry(e.country);
	};
	// let clickBtnRef = useRef(null);
	const _handleKeyPress = (e) => {
		if (e.key === "Enter") {
			// clickBtnRef.click();
			fetchCustomer(country, phoneNo);
		}
	};
	let customStyles = {
		option: (provided, state) => ({
			...provided,
			zIndex: 10,
			paddingLeft: 30,
		}),
		singleValue: (provided, state) => ({
			...provided,
			borderWidth: 0,
			height: 50,
			borderRadius: 0,
			display: "flex",
			fontSize: 16,
		}),
		control: (provided, state) => ({
			...provided,
			color: "red",
			borderRadius: 0,
			borderWidth: 0,
			outLine: "none",
			height: 50,
			paddingLeft: 10,
			fontSize: 16,
			borderBottomLeftRadius: 5,
			borderTopLeftRadius: 5,
		}),
	};

	const errorMessage = () => {
		if (phoneNo) {
			if (country === "Bangladesh") {
				if (
					!(
						phoneNo.trim().length > 10 &&
						phoneNo.trim().length < 12 &&
						phoneNo[0] == 0
					)
				) {
					return ekkLanguage?.login.validationLabel;
				} else {
					return "";
				}
			} else {
				if (
					!Object.keys(parseNumber(countryCode + phoneNo)).length &&
					phoneNo.trim().length > 0
				) {
					return ekkLanguage?.login.validationLabel;
				} else {
					return "";
				}
			}
		}
	};
	const numberInputRef = useRef(null);
	useEffect(() => {
		const handleWheel = (event) => {
			if (
				numberInputRef.current &&
				numberInputRef.current === document.activeElement
			) {
				event.preventDefault();
			}
		};
		window.addEventListener("wheel", handleWheel, { passive: false });
		return () => {
			window.removeEventListener("wheel", handleWheel);
		};
	}, []);
	return (
		<>
			<div className='pt-[20px]'>
				<label
					className={`text-16 md:text-16 font-bold text-262626-1000 sm:pb-3 pb-[10px] block`}>
					{ekkLanguage?.landing.phoneNumber}
				</label>
				{newBusinessPhoneInfo?.newBusinessUser ? (
					<div
						className='mb-4 bg-white rounded-[5px] h-[50px] flex items-center pl-4'
						style={{ boxShadow: "0px 5px 20px rgb(0,0,0,.05)" }}>
						<h6 className='text-success text-18 font-bold'>
							{newBusinessPhoneInfo.countryCode +
								newBusinessPhoneInfo?.phoneNo}
						</h6>
					</div>
				) : (
					<>
						<div
							className='flex z-10 relative'
							style={{ boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.05)" }}>
							<Select
								styles={customStyles}
								className='basic-single w-[200px]'
								placeholder='+88'
								name='color'
								options={countryList}
								onChange={(e) => handleSelectCountry(e)}
								components={{
									IndicatorSeparator: () => null,
								}}
								style={{ fontSize: 14 }}
								defaultValue={{
									countryCode: "+88",
									country: "Bangladesh",
									value: "+88",
									label: (
										<div className='flex items-center'>
											<img
												src='https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-bangladesh.png'
												className='sm:h-[32px] sm:w-[50px] h-auto w-5 rounded-[5px]'
												alt='flag'></img>{" "}
											<p className='text-18 pl-2'>+88</p>
										</div>
									),
								}}
							/>

							<input
								ref={numberInputRef}
								type='number'
								onKeyPress={_handleKeyPress}
								onChange={(event) => setPhoneNo(event.target.value)}
								placeholder={ekkLanguage?.customerList.customerNoHolder}
								id={"ekk-input"}
								style={{
									paddingLeft: 10,
									height: 50,
									flex: "flex: 1 0 auto",
									borderRadius: ".25rem",
									borderTopLeftRadius: 0,
									borderBottomLeftRadius: 0,
									borderWidth: 0,
									borderLeft: "1px solid rgba(0,0,0,.05)",
								}}
								className='appearance-none text-base w-full text-grey-darker border border-left-0  py-2 px-2 leading-tight focus:outline-none focus:bg-white bg-white'
							/>
						</div>
						<div className='pt-4'>
							<p className='text-red text-xs text-primary'>
								{errorMessage()}
							</p>
						</div>{" "}
					</>
				)}
				{existingCustomer && (
					<div>
						<p className='text-red text-14 text-primary text-right'>
							{existingCustomer}
						</p>
					</div>
				)}

				{showBtn && (
					<div className='text-center pt-[70px]'>
						<button
							onClick={() => {
								fetchCustomer(country, phoneNo);
							}}
							// propsRef={(input) => {
							// 	clickBtnRef = input;
							// }}
							style={{ background: "#00B224" }}
							className={`text-18 sm:text-18 font-semibold text-white h-[50px] sm:h-[50px] rounded-[5px] w-[156px]`}>
							{ekkLanguage?.customerList.nextBtn}
						</button>
					</div>
				)}
			</div>
		</>
	);
}
