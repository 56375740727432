import { IonCol } from "@ionic/react";
import Star from "assets/images/icon/svg/Star";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { digitChanger, numberWithCommas } from "helpers/helper";

export default function CustomerCard({
  ekkLanguage,
  link,
  customerName,
  imgSource,
  rating,
  customerId,
  customerAddress,
  distance,
}) {
  const navigate = useNavigate();

  return (
    <>
      <div
        className="rounded-md"
        onClick={() => navigate(`/${link}/${customerId}`)}
      >
        <div className="rounded-xl overflow-hidden bg-E6E6E6 p-3 mb-[5px] relative">
          <img src={imgSource} alt="logo" className="w-3/4 image-sizer" />
        </div>

        <div>
          {customerName && (
            <h5 className="font-bold text-12">{digitChanger(customerName)}</h5>
          )}
          {/* {customerAddress && (
						<h5 className='font-semibold text-12'>{customerAddress}</h5>
					)} */}

          {distance >= -1 ? (
            <div className="pt-[5px] flex justify-between">
              <span className="text-primary text-10 font-bold flex">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="none"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M1.75 5.15888C1.75 2.85895 3.67194 1 5.99672 1C8.32806 1 10.25 2.85895 10.25 5.15888C10.25 6.31785 9.8285 7.39381 9.13475 8.30579C8.3694 9.31176 7.42608 10.1882 6.36427 10.8762C6.12126 11.0352 5.90194 11.0472 5.63522 10.8762C4.56737 10.1882 3.62404 9.31176 2.86525 8.30579C2.17099 7.39381 1.75 6.31785 1.75 5.15888ZM4.59711 5.28838C4.59711 6.05885 5.22583 6.66483 5.99672 6.66483C6.76812 6.66483 7.40289 6.05885 7.40289 5.28838C7.40289 4.5239 6.76812 3.88842 5.99672 3.88842C5.22583 3.88842 4.59711 4.5239 4.59711 5.28838Z"
                    fill="#FF564C"
                  ></path>
                </svg>
                <span className="ml-[2px]">
                  {digitChanger(numberWithCommas(distance))}{" "}
                  {ekkLanguage.customerList.km}
                </span>
              </span>
            </div>
          ) : undefined}
        </div>
      </div>
    </>
  );
}
