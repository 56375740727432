import Calendar from "assets/images/icon/svg/Calendar";
import React, { useState } from "react";
import Card from "../Common/Card";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { IonButton, IonDatetime, IonPopover } from "@ionic/react";
import Add from "assets/images/icon/svg/Add";
import { useDispatch, useSelector } from "react-redux";
import { deliveryDate } from "redux/container/paymentMethodSlice";
import { useEffect } from "react";
import { useRef } from "react";

export default function DeliveryCard() {
	const dispatch = useDispatch();
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const datePickerRef = useRef();
	const datePickerClose = () => {
		datePickerRef.current?.dismiss();
	};
	const [selectedDalieveryDate, setSelectedDalieveryDate] = useState(
		moment(new Date()).add(1, "days")
	);
	let daliveryDate = useSelector((state) => state.payment?.deliveryDate);

	useEffect(() => {
		dispatch(deliveryDate(moment(selectedDalieveryDate).format()));
	}, [selectedDalieveryDate]);

	// console.log(moment().day("Friday"));
	return (
		<div className='px-2 pb-3 pt-1'>
			<Card title={ekkLanguage.createOrder.requestDeliveryDate}>
				<div
					className={`bg-F3F3F3 p-3 block rounded-[10px] mt-4 border ${
						daliveryDate ? "border-transparent" : "border-primary"
					} `}
					style={{
						boxShadow: "0px 3px 10px rgba(0, 0, 0, 0.15)",
					}}
					id='date-input'>
					<div className='flex justify-between items-center'>
						<div className='flex items-center'>
							<span>
								<Calendar
									color={daliveryDate ? "#222222" : "#F94B41"}
								/>
							</span>
							<p
								className={`text-12 ${
									daliveryDate ? "border-transparent" : "text-primary"
								} ml-2`}>
								{moment(selectedDalieveryDate).format("DD-MM-YYYY")}
							</p>
						</div>
						<div>
							<Add />
						</div>
					</div>
				</div>
				<IonPopover
					trigger='date-input'
					side='top'
					alignment='center'
					showBackdrop={true}
					ref={datePickerRef}>
					<ion-content>
						<IonDatetime
							min={moment().format("YYYY-MM-DD")}
							weekValues='f'
							presentation='date'
							onIonChange={(ev) => {
								setSelectedDalieveryDate(ev.detail.value);
								dispatch(
									deliveryDate(moment(selectedDalieveryDate).format())
								);
							}}
						/>
						<div className='text-center py-2'>
							<IonButton color='primary' onClick={datePickerClose}>
								Confirm
							</IonButton>
						</div>
					</ion-content>
				</IonPopover>
			</Card>
		</div>
	);
}
