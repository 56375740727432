import { IonSpinner } from "@ionic/react";
import EditBusinessProfile from "assets/images/icon/svg/EditBusinessProfile";
import { getUser, getUserBusiness, setUserBusiness } from "config/user";
import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useDispatch } from "react-redux";
import Business from "services/Business";
import User from "services/User";

export default function ProfileEdit({
	businessData,
	setBusinessData,
	name = "name",
	loading,
	setLoading,
}) {
	const [files, setFiles] = useState([]);
	const [value, setValue] = useState(null);
	const user = getUser();
	let dispatch = useDispatch();
	let business = getUserBusiness();

	useEffect(() => {
		return async () => {
			// Make sure to revoke the data uris to avoid memory leaks
			files.forEach((file) => URL.revokeObjectURL(file.preview));
		};
	}, [name, files]);

	useEffect(() => {
		let formData = new FormData();
		formData.append("key", files[0]);

		if (files.length !== 0) {
			setLoading(true);
			let response = User.toBucketImage(formData);
			response.then((res) => {
				if (res.status === 201) {
					setLoading(false);
					var reqbody = {
						images: [res.results[0]],
					};
					let resp = Business.updateUserBusiness(business?.id, reqbody);
					resp.then((r) => {
						if (r.status === 200) {
							// setUserBusiness(r?.results);
							setBusinessData(r?.results);
						}
					});
				}
				setValue(name, res.results[0]);
			});
		}
	}, [files, name, business?.id, setBusinessData]);

	const { getRootProps, getInputProps } = useDropzone({
		// accept: "image/jpeg, image/png, image/jpg",
		onDrop: (acceptedFiles) => {
			if (
				acceptedFiles[0].type === "image/jpeg" ||
				acceptedFiles[0].type === "image/png" ||
				acceptedFiles[0].type === "image/jpeg"
			) {
				setFiles(
					acceptedFiles.map((file) =>
						Object.assign(file, {
							preview: URL.createObjectURL(file),
						})
					)
				);
			}
		},
	});

	return (
		<>
			{businessData?.images?.length ? (
				<div
					style={{ width: "100%", height: 220 }}
					className='flex justify-between items-center bg-white relative overflow-hidden'>
					<div style={{ width: "100%" }} className='text-center bg-white'>
						{loading ? (
							<div className='flex items-center justify-center h-[156px] m-auto'>
								<IonSpinner name='crescent'></IonSpinner>
							</div>
						) : (
							<img
								src={businessData?.images[0]}
								alt='addImage'
								className='m-auto mb-2'
							/>
						)}
						<div {...getRootProps({ className: "dropzone" })}>
							<input {...getInputProps()} />
							<div className='absolute z-10 top-1 right-1'>
								<EditBusinessProfile />
							</div>
						</div>
					</div>
				</div>
			) : (
				<div
					style={{ width: "100%", height: 220 }}
					className='flex justify-between items-center bg-white'>
					<div style={{ width: "100%" }} className='text-center bg-white'>
						<div {...getRootProps({ className: "dropzone" })}>
							<input {...getInputProps()} />
							<img
								src='assets/images/add-image.png'
								alt='addImage'
								className='m-auto mb-2'
							/>
							<p className='text-16 font-normal text-black-500'>
								Upload Image
							</p>
						</div>
					</div>
				</div>
			)}
		</>
	);
}
