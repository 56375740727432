import Unverified from "assets/images/icon/svg/Unverified";
import VerifyCheck from "assets/images/icon/svg/VerifyCheck";
import React from "react";
import { useSelector } from "react-redux";
import { BUSINESS_TYPE } from "data/businessType";

export default function Banner({ businessData }) {
	const ekkLanguage = useSelector((state) => state.lan.lan);
	let image;

	if (
		businessData?.related_business_images &&
		businessData?.related_business_images.length
	) {
		image = `${businessData?.related_business_images[0]}`;
	}
	// else {
	// 	BUSINESS_TYPE.forEach((el) => {
	// 		if (el.title === businessData?.business_type) {
	// 			image = el?.image;
	// 		}
	// 	});
	// }
	return (
		<div className='relative bg-white'>
			{image ? (
				<img
					src={image}
					alt=''
					style={{ height: 250, objectFit: "contain", margin: "auto" }}
				/>
			) : (
				<img
					src='/assets/images/track.png'
					alt=''
					style={{ height: 250, objectFit: "contain", margin: "auto" }}
				/>
			)}

			{/* {businessData?.business_status === "Unverified" ? (
				<div
					className='absolute right-4 bottom-4 rounded-[5px] p-[3px] px-2'
					style={{ background: "#FFFFCC" }}
				>
					<p className='text-14 text-primary font-normal flex items-center'>
						<span className='inline-block pr-2'>
							<Unverified />
						</span>
						{ekkLanguage.businessProfile.unVerified}
					</p>
				</div>
			) : (
				<div
					className='absolute right-4 bottom-4 rounded-[5px] p-[3px] px-2'
					style={{ background: "#B3FFC2" }}
				>
					<p className='text-14 text-success font-normal flex items-center'>
						<span className='inline-block pr-2'>
							<VerifyCheck />
						</span>
						{ekkLanguage.businessProfile.verified}
					</p>
				</div>
			)} */}
		</div>
	);
}
