import { createSlice } from "@reduxjs/toolkit";

const customerSlice = createSlice({
  name: "customer",
  initialState: {
    data: true,
    location: {},
    productsTab: true,
  },
  reducers: {
    setCustomer: (state, action) => {
      state.data = action.payload;
    },
    setCustomerLocation: (state, action) => {
      state.location = action.payload;
    },
    setCustomerDetails: (state, action) => {
      state.productsTab = action.payload;
    },
  },
});

// Extract the action creators object and the reducer
export const { actions, reducer } = customerSlice;

export const setCustomer = (values) => async (dispatch, getState) => {
  dispatch(actions.setCustomer(values));
};
export const setCustomerDetails = (values) => async (dispatch, getState) => {
	dispatch(actions.setCustomerDetails(values));
  };
export const setCustomerLocation = (values) => async (dispatch, getState) => {
  dispatch(actions.setCustomerLocation(values));
};
// Export the reducer, either as a default or named export
export default reducer;
