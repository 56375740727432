import { IonApp, IonContent, IonPage } from "@ionic/react";
import BackButtonTitle from "components/mobile-components/Common/BackButtonTitle";
import DefaultPaymentSetting from "components/mobile-components/DefaultPaymentSetting";
import { getLanguage } from "config/language";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Payment from "services/Payment";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

export default function DefaultPaymentTermsPage() {
  const { brandBusinessId, relatedBusinessId, relationId } = useParams();
  const navigate = useNavigate();
  const ekkLanguage = useSelector((state) => state.lan.lan);
  const [paymentTerm, setPaymentTerm] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("");
  const [paymentTerms, setPaymentTerms] = useState([]);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [defaultPayment, setDefaultPayment] = useState({});
  const [loading, setLoading] = useState(true);
  const localLanguage = getLanguage();

  useEffect(() => {
    let getAllPaymentInfo = async function () {
      let paymentTermList = await Payment.getAllPaymentTerms();
      if (paymentTermList?.status === 200 || paymentTermList?.status === 201) {
        setPaymentTerms(paymentTermList.results);
        let paymentMethodList = await Payment.getBrandBankList();
        if (
          paymentMethodList?.status === 200 ||
          paymentMethodList?.status === 201
        ) {
          setPaymentMethods(paymentMethodList.results);
          setLoading(false);
        } else {
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
      let payment = await Payment.defaultPayment(relatedBusinessId);
      if (payment.status === 200 || payment.status === 201) {
        setDefaultPayment(payment?.results[0]);
        setPaymentTerm(payment?.results[0]?.payment_terms?.id);
        setPaymentMethod(payment?.results[0]?.seller_gateway?.id);
      }
    };
    getAllPaymentInfo();
  }, []);

  const handleSavePayment = async () => {
    console.log(defaultPayment?.id);
    const data = {
      payment_terms: Number(paymentTerm),
      seller_gateway: Number(paymentMethod),
      business_id: Number(brandBusinessId),
      related_business_id: Number(relatedBusinessId),
    };
    if (defaultPayment?.id) {
      let response = await Payment.putDefaultPayment(defaultPayment?.id, data);
      if (response.status === 200 || response.status === 201) {
        toast.success(ekkLanguage.defaultPayment.successMessage2);
        navigate(`/distributor-details/${relationId}`);
      }
    } else {
      let response = await Payment.postDefaultPayment(data);
      if (response.status === 200 || response.status === 201) {
        toast.success(ekkLanguage.defaultPayment.successMessage);
        navigate(`/distributor-details/${relationId}`);
      }
    }
  };

  return (
    <IonApp>
      <IonPage>
        <IonContent>
          <BackButtonTitle title={ekkLanguage.defaultPayment.pageTitle} />
          <DefaultPaymentSetting
            ekkLanguage={ekkLanguage}
            localLanguage={localLanguage}
            paymentTerms={paymentTerms}
            paymentMethods={paymentMethods}
            paymentTerm={paymentTerm}
            setPaymentTerm={setPaymentTerm}
            paymentMethod={paymentMethod}
            setPaymentMethod={setPaymentMethod}
            loading={loading}
            handleSavePayment={handleSavePayment}
          ></DefaultPaymentSetting>
        </IonContent>
      </IonPage>
    </IonApp>
  );
}
