import { createSlice } from "@reduxjs/toolkit";

export const loanSlice = createSlice({
	name: "loan",
	initialState: {
		paymentDetails: {},
	},
	reducers: {
		setPaymentDetails: (state, action) => {
			state.paymentDetails = action.payload;
		},
	},
});

export const { actions, reducer } = loanSlice;

export const setPaymentDetails = (values) => async (dispatch, getState) => {
	dispatch(actions.setPaymentDetails(values));
};

export default reducer;
