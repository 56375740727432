import {
  IonApp,
  IonContent,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonPage,
} from "@ionic/react";
import LeftSidebar from "components/global-components/Navigation/LeftSidebar";
import BackButtonTitle from "components/mobile-components/Common/BackButtonTitle";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import NotificationList from "components/mobile-components/Notifications";
import Notification from "services/Notification";
import { getUserBusiness } from "config/user";
import {
  setCustomerUnreadCount,
  setNotification,
  setSupplierUnreadCount,
} from "redux/container/notificationSlice";
import { getLanguage } from "config/language";

export default function NotificationPage() {
  const [loading, setLoading] = useState(false);
  const [menuCollapse, setMenuCollapse] = useState(true);
  const [notificationList, setNotificationList] = useState([]);
  const [notificationType, setNotificationType] = useState("customer");
  const dispatch = useDispatch();
  const [pageCount, setPageCount] = useState(2);
  const id = getUserBusiness()?.id;
  const ekkLanguage = useSelector((state) => state.lan.lan);
  const localLanguage = getLanguage();
  const [hasMoreData, setHasMoreData] = useState(true);
  const [reachedEnd, setReachedEnd] = useState(false);

  const menuIconClick = () => {
    setMenuCollapse(!menuCollapse);
  };

  let getNotificationList = async (value) => {
    if (hasMoreData) {
      if (notificationType === "customer") {
        let response = await Notification.getNewCustomerNotificationList(
          pageCount,
          id,
          localLanguage === "bn" ? "BD" : "EN"
        );
        if (response.status === 200) {
          setPageCount((prev) => prev + 1);
          dispatch(
            setNotification([...notificationList, ...response?.results])
          );
          setNotificationList([...notificationList, ...response?.results]);
        } else {
          setHasMoreData(false);
        }
      } else {
        let response = await Notification.getSupplierNotificationList(
          pageCount,
          id,
          localLanguage === "bn" ? "BD" : "EN"
        );
        if (response.status === 200) {
          setPageCount((prev) => prev + 1);
          dispatch(
            setNotification([...notificationList, ...response?.results])
          );
          setNotificationList([...notificationList, ...response?.results]);
        } else {
          setHasMoreData(false);
        }
      }
    }
  };

  let getInitCustomerNotificationList = async () => {
    setLoading(true);
    setPageCount(2);
    let response = await Notification.getNewCustomerNotificationList(
      1,
      id,
      localLanguage === "bn" ? "BD" : "EN"
    );
    if (response.status === 200) {
      setLoading(false);
      dispatch(setNotification(response?.results));
      setNotificationList(response?.results);
    } else {
      setLoading(false);
    }
  };
  // let getInitSupplierNotificationList = async () => {
  //   setLoading(true);
  //   setPageCount(2);
  //   let response = await Notification.getSupplierNotificationList(1, id);
  //   if (response.status === 200) {
  //     setLoading(false);
  //     dispatch(setNotification(response?.results));
  //     setNotificationList(response?.results);
  //   } else {
  //     setLoading(false);
  //   }
  // };

  let getUnreadCount = async () => {
    let response1 = await Notification.getNewCustomerUnreadCount(
      id,
      localLanguage === "bn" ? "BD" : "EN"
    );
    if (response1.status === 200) {
      dispatch(setCustomerUnreadCount(response1?.unread_count));
    }
  };

  useEffect(() => {
    // getUnreadCount();
    getInitCustomerNotificationList();
    // if (notificationType === "customer") {
    //   getInitCustomerNotificationList();
    // }
    //  else {
    //   getInitSupplierNotificationList();
    // }
  }, [notificationType]);

  return (
    <IonApp>
      <IonPage>
        <IonContent scrollEvents={true}>
          <LeftSidebar
            isMobile={true}
            menuCollapse={menuCollapse}
            menuIconClick={menuIconClick}
          />
          <BackButtonTitle
            title={ekkLanguage.notification.pageTitle}
            // burgerMenu={true}
            menuCollapse={menuCollapse}
            menuIconClick={menuIconClick}
          />
          <section>
            <NotificationList
              localLanguage={localLanguage}
              from={
                notificationType === "customer"
                  ? "customerNotification"
                  : "supplierNotification"
              }
              notificationType={notificationType}
              setNotificationType={setNotificationType}
              loading={loading}
              setLoading={setLoading}
            />
          </section>
          <IonInfiniteScroll
            onIonInfinite={(ev) => {
              if (reachedEnd) {
                ev.target.complete();
                return;
              }
              getNotificationList();
              setTimeout(() => {
                ev.target.complete();
                if (!hasMoreData) {
                  setReachedEnd(true);
                }
              }, 500);
            }}
          >
            <IonInfiniteScrollContent
              loadingText={ekkLanguage.orderList.loader}
              loadingSpinner={hasMoreData ? "bubbles" : null}
            ></IonInfiniteScrollContent>
          </IonInfiniteScroll>
        </IonContent>
      </IonPage>
    </IonApp>
  );
}
