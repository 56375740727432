import { baseUrl } from "config/apiUrl";
import SuperFetch from "config/SuperFetch";

const Brand = {
	brandCreate: (query) => {
		return SuperFetch(`${baseUrl.bizNpdt}/seller/brand/create/`, {
			method: "POST",
			data: query,
		});
	},
	brandUpdate: (id, query) => {
		return SuperFetch(`${baseUrl.bizNpdt}/seller/brand/update/${id}/`, {
			method: "PUT",
			data: query,
		});
	},
	getAllBrandList: (page = 1, limit = 20) => {
		return SuperFetch(
			`${baseUrl.bizNpdt}/sellers/brands/all/?page=${page}&limit=${limit}`
		);
	},
	brandDelete: (id) => {
		return SuperFetch(`${baseUrl.bizNpdt}/seller/brand/delete/${id}/`, {
			method: "DELETE",
		});
	},
	getAllBrandListSearch: (tearm = "", page = 1, limit = 20) => {
		return SuperFetch(
			`${baseUrl.bizNpdt}/sellers/brands/search/?page=${page}&limit=${limit}&tearm=${tearm}`
		);
	},
	getMyBrandList: (page = 1, limit = 20) => {
		return SuperFetch(
			`${baseUrl.bizNpdt}/sellers/brands/own/?page=${page}&limit=${limit}`
		);
	},
	getMyBrandListSearch: (tearm = "", page = 1, limit = 20) => {
		return SuperFetch(
			`${baseUrl.bizNpdt}/sellers/brands/search_own/?page=${page}&limit=${limit}&tearm=${tearm}`
		);
	},
	brandDetails: (id) => {
		return SuperFetch(`${baseUrl.bizNpdt}/sellers/brand/${id}/`);
	},
	brandProducts: (id, page = 1, limit = 20) => {
		return SuperFetch(
			`${baseUrl.bizNpdt}/brands/${id}/products/?page=${page}&limit=${limit}`
		);
	},
	importBrand: (query) => {
		return SuperFetch(`${baseUrl.bizNpdt}/import_brand/`, {
			method: "POST",
			data: query,
		});
	},
};

export default Brand;
