import { IonButton, IonSpinner } from "@ionic/react";
import ArrayRight from "assets/images/icon/svg/ArrayRight";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import User from "services/User";
import Check from "assets/images/icon/svg/Check";
import ImageUploader from "./ImageUploader";
import Delete from "assets/images/icon/svg/Delete";
import { setKYC } from "redux/container/businessSettingsSlice";
import Business from "services/Business";
import { getUserBusiness, setUserBusiness } from "config/user";

export default function NIDVerification() {
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const bs = useSelector((state) => state.businessSettings);
	const navigate = useNavigate();
	const [files1, setFiles1] = useState(bs.data?.nid_front_img ?? "");
	const [files2, setFiles2] = useState(bs.data?.nid_back_img ?? "");
	const [loader, setLoader] = useState(false);
	let dispatch = useDispatch();
	const business = getUserBusiness();

	const nextHandler = async () => {
		if (!files1[0].preview && !files2[0].preview) {
			localStorage.setItem("auth", true);
			return navigate("/trade-license-verification");
		}

		setLoader(true);
		let frontPart = new FormData();
		let backPart = new FormData();
		frontPart.append("key", files1[0]);
		backPart.append("key", files2[0]);

		if ((files1.length !== 0) | (files2.length !== 0)) {
			let response = Array.isArray(files1)
				? await User.toBucketImage(frontPart)
				: false;
			let response2 = Array.isArray(files2)
				? await User.toBucketImage(backPart)
				: false;
			if ((response?.status === 201) | (response2?.status === 201)) {
				setLoader(false);
				dispatch(
					setKYC({
						...bs.data,
						nid_front_img: Array.isArray(files1)
							? response.results[0]
							: bs.data.nid_front_img,
						nid_back_img: Array.isArray(files2)
							? response2.results[0]
							: bs.data.nid_back_img,
					})
				);
				let query = {
					nid_front_img: Array.isArray(files1)
						? response.results[0]
						: bs.data.nid_front_img,
					nid_back_img: Array.isArray(files2)
						? response2.results[0]
						: bs.data.nid_back_img,
					business: bs.data.business,
				};

				const res = bs.data?.kycUpdate
					? await Business.updateKYC(bs.data?.kycId, {
							nid_front_img: Array.isArray(files1)
								? response.results[0]
								: bs.data.nid_front_img,
							nid_back_img: Array.isArray(files2)
								? response2.results[0]
								: bs.data.nid_back_img,
					  })
					: await Business.createKYC(query);
				if (res.status === 200) {
					dispatch(
						setKYC({
							kycUpdate: true,
							business_status: "Pending",
						})
					);
					setUserBusiness({
						...business,
						business_status: "Pending",
					});
					navigate("/trade-license-verification");
				} else if (res.status === 201) {
					dispatch(
						setKYC({
							kycId: res.results?.id,
							kycUpdate: true,
							business_status: "Pending",
						})
					);
					setUserBusiness({
						...business,
						business_status: "Pending",
					});
					navigate("/trade-license-verification");
				}
				localStorage.setItem("auth", true);
			} else {
				setLoader(false);
			}
		}
	};
	return (
		<div className='pt-14 verifyBusiness-content'>
			<div className='py-5 px-4'>
				<div className='pt-4 pb-[10px] flex items-center justify-between'>
					<h4 className='font-bold text-black text-16'>
						{ekkLanguage.kycVerification.nidFront}
					</h4>
					<span
						className='h-10 w-10 flex items-center justify-center bg-white rounded-[5px]'
						onClick={() => setFiles1("")}>
						<Delete />
					</span>
				</div>

				<ImageUploader
					files={files1}
					setFiles={setFiles1}
					bHeight={246}
					placeHolder={ekkLanguage.kycVerification.uploadNidFront}
				/>
			</div>
			<div className='pb-5 px-4'>
				<div className='pt-4 pb-[10px] flex items-center justify-between'>
					<h4 className='font-bold text-black text-16'>
						{" "}
						{ekkLanguage.kycVerification.nidBack}
					</h4>
					<span
						className='h-10 w-10 flex items-center justify-center bg-white rounded-[5px]'
						onClick={() => setFiles2("")}>
						<Delete />
					</span>
				</div>
				<ImageUploader
					files={files2}
					setFiles={setFiles2}
					bHeight={246}
					placeHolder={ekkLanguage.kycVerification.uploadNidBack}
				/>
			</div>
			<div className='pt-5 px-4'>
				{files1.length && files2.length ? (
					<div
						className='rounded-[5px] text-center py-4'
						style={{
							background: "#CCFFD6",
							boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.15)",
						}}>
						<p
							className='font-normal text-14 flex justify-center mb-[10px]'
							style={{ color: "#808080" }}>
							<Check></Check> {ekkLanguage.kycVerification.complete}
						</p>
						<p
							className='font-normal text-14'
							style={{ color: "#808080" }}>
							{ekkLanguage.kycVerification.pleaseCheck} "
							<span style={{ color: "#00B224" }}>
								{ekkLanguage.kycVerification.next}
							</span>
							" {ekkLanguage.kycVerification.button}
						</p>
					</div>
				) : (
					<div
						className='rounded-[5px]'
						style={{
							background: "#FFFFCC",
							boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.15)",
						}}>
						<p
							className='font-normal p-[14px] text-14'
							style={{ color: "#808080" }}>
							{ekkLanguage.kycVerification.upload}{" "}
							<b className='text-primary font-semibold'>
								“{ekkLanguage.kycVerification.nid}”
							</b>{" "}
							{ekkLanguage.kycVerification.copy}
						</p>
					</div>
				)}

				<div className='pt-24 text-center pb-10'>
					<IonButton
						disabled={files1 && files2 ? false : true}
						color={files1 && files2 ? "success" : "dark"}
						className='font-bold h-[50px] w-full'
						onClick={nextHandler}>
						{loader ? (
							<IonSpinner></IonSpinner>
						) : (
							<>
								{ekkLanguage.businessCreateName.btnLabel}
								<span className='pl-2'>
									<ArrayRight />
								</span>
							</>
						)}
					</IonButton>
				</div>
			</div>
		</div>
	);
}
