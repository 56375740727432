import { createSlice } from "@reduxjs/toolkit";

const driverSlice = createSlice({
	name: "driver",
	initialState: {
		driverData: {},
	},
	reducers: {
		setDriverData: (state, action) => {
			state.driverData = action.payload;
		},
	},
});

// Extract the action creators object and the reducer
export const { actions, reducer } = driverSlice;

export const setDriverData = (data) => async (dispatch, getState) => {
	dispatch(actions.setDriverData(data));
};

export const setEmptyDriverData = () => async (dispatch, getState) => {
	dispatch(actions.setDriverData(""));
};

// Export the reducer, either as a default or named export
export default reducer;
