import { useSelector } from "react-redux";
import SectionHeader from "./SectionHeader";
import SingleBenefits from "./SingleBenefits";

export default function Benefits() {
	const ekkLanguage = useSelector((state) => state.lan.lan);
	return (
		<div className='container m-auto pb-[40px] md:pb-[50px] lg:pb-[50px] bg-F2F3F7 lg:pt-[50px] pt-[80px]'>
			<SectionHeader title={ekkLanguage.landing.benefits} />
			<div className='flex flex-wrap'>
				<div className='xl:w-3/12 lg:w-3/12 md:w-6/12 w-6/12'>
					<SingleBenefits
						image='assets/images/growth.png'
						title={ekkLanguage.landing.benefitsOne}
						// description='Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been.'
					/>
				</div>
				<div className='xl:w-3/12 lg:w-3/12 md:w-6/12 w-6/12'>
					<SingleBenefits
						image='assets/images/megaphone.png'
						title={ekkLanguage.landing.benefitsTwo}
						// description='Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been.'
					/>
				</div>
				<div className='xl:w-3/12 lg:w-3/12 md:w-6/12 w-6/12'>
					<SingleBenefits
						image='assets/images/visibility.png'
						title={ekkLanguage.landing.benefitsThree}
						// description='Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been.'
					/>
				</div>
				<div className='xl:w-3/12 lg:w-3/12 md:w-6/12 w-6/12'>
					<SingleBenefits
						image='assets/images/smartphone.png'
						title={ekkLanguage.landing.benefitsFore}
						// description='Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been.'
					/>
				</div>
			</div>
		</div>
	);
}
