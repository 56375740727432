import { IonCheckbox } from "@ionic/react";
import { getLanguage } from "config/language";
import React, { memo } from "react";

function CustomCheckBox({ el, currentState, changeHandler, title }) {
  let langInfo = getLanguage();
  return (
    <div
      className={`w-full h-[50px] border rounded flex items-center  mb-[10px] relative ${
        !currentState &&
        "checkbox-overlay border-2 border-[#00000080] bg-[#F2F3F7]"
      } ${currentState && "border-2 border-success bg-white"}`}
    >
      <label htmlFor="foodCheckBox" className="flex items-center w-full">
        <IonCheckbox
          disabled={el?.is_default === true}
          id="foodCheckBox"
          checked={currentState}
          className="pl-[16px] w-full  font-bold custom-checkbox"
          color="success"
          onIonChange={() => changeHandler(el)}
          labelPlacement="end"
        >
          <span
            className={`flex absolute left-14 top-0 ${
              !currentState && "opacity-60"
            }`}
          >
            {/* <img className='mr-[10px] w-5' src={imgSource} alt='' /> */}
            <p className="text-14">
              {langInfo === "en" ? title : el?.own_lg_title}
            </p>
          </span>
        </IonCheckbox>
      </label>
    </div>
  );
}
export default memo(CustomCheckBox);
