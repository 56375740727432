import { IonSegment, IonSegmentButton } from "@ionic/react";
import React, { memo, useState } from "react";

// import { setCustomer } from "redux/container/customerSlice";

function CustomerFilter({
	setMyBrand,
	getAllBrandList,
	getMyBrandList,
	ekkLanguage,
	setSearch,
	setReachedEnd,
	setHasMoreData,
}) {
	const [brandType, setBrandType] = useState("allBrand");

	return (
		<IonSegment
			scrollable
			className='orderBtnWrapper'
			value={brandType}
			onIonChange={(e) => {
				setReachedEnd(false);
				setHasMoreData(true);
				setMyBrand(e.target.value === "customer" ? true : false);
				setSearch("");
			}}>
			<IonSegmentButton
				value='allBrand'
				className='paid relative'
				onClick={() => {
					setBrandType("allBrand");
					getAllBrandList();
				}}>
				<button className='text-[#00B224] flex items-center font-sans p-0 font-bold shadow-none text-14'>
					{ekkLanguage.driectSales.allCustomer}
				</button>
			</IonSegmentButton>
			<IonSegmentButton
				value='customer'
				className='cancel relative'
				onClick={() => {
					setBrandType("customer");
					getMyBrandList();
				}}>
				<button className='text-primary flex items-center font-sans p-0 font-bold shadow-none text-14'>
					{ekkLanguage.driectSales.ownCustomer}
				</button>
			</IonSegmentButton>
		</IonSegment>
	);
}
export default memo(CustomerFilter);
