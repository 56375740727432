import React from "react";
import { useSelector } from "react-redux";
import { IonInfiniteScroll, IonInfiniteScrollContent } from "@ionic/react";
import CustomerList from "./CustomerList";
import OwnCustomer from "./OwnCustomer";
import CustomerFilter from "./CustomerFilter";

export default function Customers({
	ownCustomer,
	setOwnCustomer,
	customerList,
	loading,
	reachedEnd,
	hasMoreData,
	setReachedEnd,
	getOrderList,
	orderStatus,
	getInitOrderList,
	getMyCustomers,
	setSearchQ,
	setHasMoreData,
}) {
	const ekkLanguage = useSelector((state) => state.lan.lan);
	return (
		<div>
			<section className='px-2'>
				<CustomerFilter
					setMyBrand={setOwnCustomer}
					getAllBrandList={getInitOrderList}
					getMyBrandList={getMyCustomers}
					ekkLanguage={ekkLanguage}
					setSearch={setSearchQ}
					setReachedEnd={setReachedEnd}
					setHasMoreData={setHasMoreData}></CustomerFilter>
				{ownCustomer ? (
					<OwnCustomer
						ekkLanguage={ekkLanguage}
						loading={loading}
						customerList={customerList}
					/>
				) : (
					<CustomerList
						ekkLanguage={ekkLanguage}
						loading={loading}
						customerList={customerList}
					/>
				)}
			</section>
			{/* <div>
				<IonInfiniteScroll
					onIonInfinite={(ev) => {
						if (reachedEnd) {
							ev.target.complete();
							return;
						}
						getOrderList(orderStatus);
						setTimeout(() => {
							ev.target.complete();
							if (!hasMoreData) {
								setReachedEnd(true);
							}
						}, 1000);
					}}>
					<IonInfiniteScrollContent
						loadingText={ekkLanguage.orderList.loader}
						loadingSpinner={
							hasMoreData ? "bubbles" : null
						}></IonInfiniteScrollContent>
				</IonInfiniteScroll>
			</div> */}
		</div>
	);
}
