import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setNewBusiness } from "redux/container/businessSettingsSlice";
import Business from "services/Business";
import CustomerForm from "./CustomerForm";
import MobileNumber from "./MobileNumber";
import OldCustomers from "./OldCustomers";

function NewCustomer({ to }) {
	const ekkLanguage = useSelector((state) => state.lan.lan);
	let newBusinessInfo = useSelector(
		(state) => state.businessSettings.newBusiness
	);
	const [existingCustomer, setExistingCustomer] = useState("");
	// const [newBusinessUser, setNewBusinessUser] = useState(false);
	const [showBtn, setShowBtn] = useState(
		newBusinessInfo?.phoneNo ? false : true
	);
	const [newCustomer, setNewCustomer] = useState(
		newBusinessInfo?.phoneNo ? true : false
	);
	const [oldCustomer, setOldCustomer] = useState(false);
	const [oldCustomerList, setOldCustomerList] = useState([]);
	const [cameraImage, setCameraImage] = useState(
		newBusinessInfo?.businessImage
	);
	const [countryCode, setCountryCode] = useState(
		newBusinessInfo?.countryCode ? newBusinessInfo.countryCode : "+88"
	);
	const [country, setCountry] = useState("Bangladesh");
	const [phoneNo, setPhoneNo] = useState(
		newBusinessInfo?.phoneNo ? newBusinessInfo.phoneNo : ""
	);
	const [customerName, setCustomerName] = useState(newBusinessInfo?.name ?? "");
	const [businessName, setBusinessName] = useState(
		newBusinessInfo?.businessName ?? ""
	);
	const [city, setCity] = useState(newBusinessInfo?.city ?? "");
	const [postalCode, setPostalCode] = useState(
		newBusinessInfo?.postalCode ?? ""
	);
	const [address, setAddress] = useState(newBusinessInfo?.address ?? "");
	const [loading, setLoading] = useState([]);
	const dispatch = useDispatch();

	async function fetchCustomer(country, phoneNo) {
		setLoading(true);
		try {
			if (country === "Bangladesh") {
				if (
					!(
						phoneNo.trim().length > 10 &&
						phoneNo.trim().length < 12 &&
						phoneNo[0] == 0
					)
				) {
					return;
				}
			}
			let phone_number = phoneNo.trim();
			const response = await Business.customerCheck(phone_number);
			let data = {
				phoneNo,
				country,
				countryCode,
			};
			// if (response) {
			// 	setNewCustomer(true);
			// 	setShowBtn(false);
			// }
			if (Object.keys(response?.distributor).length) {
				setExistingCustomer(ekkLanguage.newCustomer.alreayExist);
			} else if (
				!Object.keys(response?.distributor).length &&
				Object.keys(response?.business).length
			) {
				setLoading(false);
				setOldCustomerList(response.business);
				setShowBtn(false);
				setOldCustomer(true);
				setExistingCustomer("");
				dispatch(setNewBusiness({ ...data, newBusinessUser: false }));
				// setNewBusinessUser(false);
			} else {
				setLoading(false);
				setOldCustomer(false);
				setOldCustomerList([]);
				setShowBtn(false);
				// setNewBusinessUser(true);
				setNewCustomer(true);
				setExistingCustomer("");
				dispatch(setNewBusiness({ ...data, newBusinessUser: true }));
			}
		} catch (error) {
			console.log(error);
		}
	}

	let newCustomerForm = () => {
		setOldCustomer(false);
		setShowBtn(false);
		setNewCustomer(true);
	};

	return (
		<div className='px-3'>
			<MobileNumber
				showBtn={showBtn}
				countryCode={countryCode}
				setCountryCode={setCountryCode}
				country={country}
				setCountry={setCountry}
				phoneNo={phoneNo}
				setPhoneNo={setPhoneNo}
				fetchCustomer={fetchCustomer}
				// newBusinessUser={newBusinessUser}
				existingCustomer={existingCustomer}
			/>

			{oldCustomer && (
				<OldCustomers
					oldCustomerList={oldCustomerList}
					newCustomerForm={newCustomerForm}
					to={to}
				/>
			)}

			{newCustomer && (
				<CustomerForm
					cameraImage={cameraImage}
					setCameraImage={setCameraImage}
					address={address}
					setAddress={setAddress}
					customerName={customerName}
					setCustomerName={setCustomerName}
					businessName={businessName}
					setBusinessName={setBusinessName}
					city={city}
					setCity={setCity}
					postalCode={postalCode}
					setPostalCode={setPostalCode}
					to={to}
				/>
			)}
		</div>
	);
}

export default NewCustomer;
