import { digitChanger } from "helpers/helper";
import React from "react";
import { useSelector } from "react-redux";
import Card from "../Common/Card";

export default function CustomerInfo({ businessData }) {
	const ekkLanguage = useSelector((state) => state.lan.lan);
	return (
		<Card title={ekkLanguage.customerList.customerDetailsLabel}>
			<div className='px-2'>
				<table className='table-fixed w-full'>
					<tbody>
						<tr>
							<td className='text-000000 text-14 font-bold py-[7px]'>
								{ekkLanguage.customerList.customerNameLabel}
							</td>
							<td className='text-000080 text-14 font-medium py-[7px] text-right'>
								{businessData?.related_business}
							</td>
						</tr>
						<tr>
							<td className='text-000000 text-14 font-bold py-[7px]'>
								{ekkLanguage.businessProfile.mobileNo}
							</td>
							<td className='text-000080 text-14 font-medium py-[7px] text-right'>
								{businessData?.related_business_phone &&
									digitChanger(businessData?.related_business_phone)}
							</td>
						</tr>
						<tr>
							<td className='text-000000 text-14 font-bold py-[7px]'>
								{ekkLanguage.customerList.businessTypeLabel}
							</td>
							<td className='text-000080 text-14 font-medium py-[7px] text-right'>
								{businessData?.related_business_type}
							</td>
						</tr>
						<tr>
							<td className='text-000000 text-14 font-bold py-[7px]'>
								{ekkLanguage.customerList.distributorCode}
							</td>
							<td className='text-000080 text-14 font-medium py-[7px] text-right'>
								{businessData?.code
									? businessData?.code
									: ekkLanguage.customerList.notSet}
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</Card>
	);
}
