import { createSlice } from "@reduxjs/toolkit";

const productSlice = createSlice({
	name: "product",
	initialState: {
		data: {
			productName: "",
			description: "",
		},
		singleProduct: {},
		productPack: {},
		singleProductPack: {},
		productOffer: {},
		singleProductOffer: {},
		productList: [],
	},
	reducers: {
		setProduct: (state, action) => {
			state.data = action.payload;
		},
		emptyProduct: (state, action) => {
			state.data = {};
		},
		updateProduct: (state, action) => {
			state.singleProduct = action.payload;
		},
		emptyUpdateProduct: (state, action) => {
			state.singleProduct = {};
		},
		setProductPack: (state, action) => {
			state.productPack = action.payload;
		},
		emptyProductPack: (state, action) => {
			state.productPack = {};
		},
		updateProductPack: (state, action) => {
			state.singleProductPack = action.payload;
		},
		emptyUpdateProductPack: (state, action) => {
			state.singleProductPack = {};
		},
		setProductOffer: (state, action) => {
			state.productOffer = action.payload;
		},
		setProductList: (state, action) => {
			state.productList = action.payload;
		},
		emptyProductOffer: (state, action) => {
			state.productOffer = {};
		},
		updateProductOffer: (state, action) => {
			state.singleProductOffer = action.payload;
		},
		emptyUpdateProductOffer: (state, action) => {
			state.singleProductOffer = {};
		},
	},
});

// Extract the action creators object and the reducer
export const { actions, reducer } = productSlice;

//product
export const productCreate = (values) => async (dispatch, getState) => {
	dispatch(actions.setProduct(values));
};
export const setEmptyProduct = () => async (dispatch, getState) => {
	dispatch(actions.emptyProduct());
};
export const productUpdate = (values) => async (dispatch, getState) => {
	dispatch(actions.updateProduct(values));
};
export const setEmptyUpdate = () => async (dispatch, getState) => {
	dispatch(actions.emptyUpdateProduct());
};
export const setProductList = (values) => async (dispatch, getState) => {
	dispatch(actions.setProductList(values));
};

//pack
export const productPackCreate = (values) => async (dispatch, getState) => {
	dispatch(actions.setProductPack(values));
};
export const setEmptyProductPack = () => async (dispatch, getState) => {
	dispatch(actions.emptyProductPack());
};
export const productPackUpdate = (values) => async (dispatch, getState) => {
	dispatch(actions.updateProductPack(values));
};
export const setEmptyPackUpdate = () => async (dispatch, getState) => {
	dispatch(actions.emptyUpdateProductPack());
};

//offer
export const productOfferCreate = (values) => async (dispatch, getState) => {
	dispatch(actions.setProductOffer(values));
};
export const setEmptyProductOffer = () => async (dispatch, getState) => {
	dispatch(actions.emptyProductOffer());
};
export const productOfferUpdate = (values) => async (dispatch, getState) => {
	dispatch(actions.updateProductOffer(values));
};
export const setEmptyOfferUpdate = () => async (dispatch, getState) => {
	dispatch(actions.emptyUpdateProductOffer());
};

// Export the reducer, either as a default or named export
export default reducer;
