import React from "react";
import { useSelector } from "react-redux";

export default function CustomerNameTitle({ businessData }) {
	const ekkLanguage = useSelector((state) => state.lan.lan);
	return (
		<div>
			<h4 className='text-20 text-black-1000 font-bold px-1 mb-[20px]'>
				{businessData?.related_business}
			</h4>
		</div>
	);
}
