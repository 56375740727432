import { baseUrl } from "config/apiUrl";
import SuperFetch from "config/SuperFetch";
// https://dorder.ekkbaz.com/v4/orders/business/list?limit=10&page=1
const Orders = {
	getOrdersList: (page, limit=20, value = "") => {
		return SuperFetch(
			`${baseUrl.order}/v4/orders/business/list-seller?limit=${limit}&page=${page}&order_status=${value}`,
			{
				method: "GET",
			}
		);
	},
	orderSearchById: (id) => {
		return SuperFetch(`${baseUrl.order}/v5/orders/order_id/${id}`, {
			method: "GET",
		});
	},
	orderListBySorted: (value, page = 1, limit = 10) => {
		return SuperFetch(
			`${baseUrl.order}/v4/orders/business/list?limit=${limit}&page=${page}&order_status=${value}`,
			{
				method: "GET",
			}
		);
	},
	singleOrder: (id) => {
		return SuperFetch(`${baseUrl.order}/v5/orders/details/${id}`);
	},
	// https://api.ekkbaz.com/order/v4/orders/details/62bbe678576ccf3565557d1c
	createOrder: (data) => {
		return SuperFetch(`${baseUrl.order}/v5/orders/create`, {
			method: "POST",
			data: data,
		});
		// /v4/orders/create
	},
	// createOrder: (data) => {
	//   return SuperFetch(`http://localhost:3005/v4/orders/create`, {
	//     method: "POST",
	//     data: data,
	//   });
	//   // /v4/orders/create
	// },
	todayPurchaseSummery: (start, end) => {
		return SuperFetch(
			`${baseUrl.order}/v4/orders/my-business/total-order-details?date_start=${start}&date_end=${end}`
		);
	},
	// ${baseUrl.order}/v4/orders/custom/search?page=${page}&limit=${limit}&order_id=${id}

	searchOrder: (id) => {
		return SuperFetch(
			`${baseUrl.order}/ekkbaz/orders/order-list/search-eb-ebs?order_id=${id}`
		);
	},
	orderCancel: (id) => {
		return SuperFetch(`${baseUrl.order}/v4/orders/update/${id}`, {
			method: "PUT",
			data: { order_status: "Cancel" },
		});
	},
	orderDelivery: (id, query) => {
		return SuperFetch(`${baseUrl.order}/v4/orders/update/${id}`, {
			method: "PUT",
			data: query,
		});
	},
	orderAmountCollection: (id) => {
		return SuperFetch(
			`${baseUrl.order}/v5/orders/order-amount-collection/${id}`
		);
	},
	orderAnalytics: () => {
		return SuperFetch(
			`${baseUrl.order}/v5/orders/my-business/total-order-analytics`
		);
	},
	orderUpdate: (id, query) => {
		return SuperFetch(`${baseUrl.order}/v4/orders/update/${id}`, {
			method: "PUT",
			data: query,
		});
	},
	orderUpdateV5: (id, query) => {
		return SuperFetch(`${baseUrl.order}/v5/orders/order-update/${id}`, {
			method: "PUT",
			data: query,
		});
	},
	getLoanDetails: (orderId) => {
		return SuperFetch(`${baseUrl.loan}/loan/order/loan_details/${orderId}/`);
	},
	getTransactionType: (orderId) => {
		return SuperFetch(
			`${baseUrl.pay}/ekkbaz/seller_loan_payment/${orderId}/?trns_type=Seller Payment`
		);
	},
};
export default Orders;
