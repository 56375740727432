import React, { useState } from "react";
import Card from "../Common/Card";
import { useSelector, useDispatch } from "react-redux";
import { digitChanger, numberWithCommas, translator } from "helpers/helper";
import { shoppingListItem } from "helpers/shoppingCart";

export default function CostSummaryCard() {
	const productCart = useSelector((state) => state.cart);
	const creditfee = useSelector((state) => state.payment.creditFee);
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const productList = shoppingListItem();
	return (
		<div className='px-2 pt-5 pb-14'>
			<Card title={ekkLanguage.createOrder.costSummaryTitle}>
				<div className='px-2'>
					<table className='table-fixed w-full'>
						<tbody>
							<tr>
								<td className='text-000080 text-12 font-medium py-[7px]'>
									{ekkLanguage.createOrder.subtotal}
								</td>
								<td className='text-000080 text-12 font-medium py-[7px] text-right'>
									{productList[0]?.currency &&
										translator(productList[0]?.currency)}{" "}
									{digitChanger(
										numberWithCommas(productCart?.subtotal)
									)}
								</td>
							</tr>
							<tr>
								<td className='text-000080 text-12 font-medium py-[7px]'>
									{ekkLanguage.createOrder.tax}
								</td>
								<td className='text-000080 text-12 font-medium py-[7px] text-right'>
									{productList[0]?.currency &&
										translator(productList[0]?.currency)}{" "}
									{digitChanger(numberWithCommas(0.0))}
								</td>
							</tr>
							<tr>
								<td className='text-000080 text-12 font-medium py-[7px]'>
									{ekkLanguage.createOrder.discount}
								</td>
								<td className='text-000080 text-12 font-medium py-[7px] text-right'>
									{productList[0]?.currency &&
										translator(productList[0]?.currency)}{" "}
									{digitChanger(numberWithCommas(0))}
								</td>
							</tr>
							<tr>
								<td className='text-000080 text-12 font-medium py-[7px]'>
									{ekkLanguage.createOrder.deliveryFee}
								</td>
								<td className='text-000080 text-12 font-medium py-[7px] text-right'>
									{productList[0]?.currency &&
										translator(productList[0]?.currency)}{" "}
									{digitChanger(numberWithCommas(0))}
								</td>
							</tr>
							<tr>
								<td className='text-000080 text-12 font-medium py-[7px]'>
									{ekkLanguage.createOrder.creditFee}
								</td>
								<td className='text-000080 text-12 font-medium py-[7px] text-right'>
									{productList[0]?.currency &&
										translator(productList[0]?.currency)}{" "}
									{digitChanger(numberWithCommas(creditfee))}
								</td>
							</tr>
						</tbody>
						<tfoot className='border-t'>
							<tr>
								<td className='text-primary text-12 font-bold py-[7px] p-0'>
									{ekkLanguage.createOrder.total}
								</td>
								<td className='text-primary text-12 font-bold py-[7px] p-0 text-right'>
									{productList[0]?.currency &&
										translator(productList[0]?.currency)}{" "}
									{digitChanger(numberWithCommas(productCart?.total))}
								</td>
							</tr>
						</tfoot>
					</table>
				</div>
			</Card>
		</div>
	);
}
