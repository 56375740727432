import { digitChanger, monthChanger, numberWithCommas } from "helpers/helper";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import Card from "../Common/Card";
import { setPaymentDetails } from "redux/container/loanSlice";
import { useNavigate } from "react-router-dom";

export default function PaymentDetailsCard({ order, sellerPayment }) {
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const placedOrderItems = useSelector((state) => state.order?.deliveredItems);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	let paymentTermTextFn = () => {
		if (order?.payment_term?.duration == 0) {
			return "Immediate Payment";
		} else if (order?.payment_term?.duration == 1) {
			return `At Night of Delivery Day ${moment()
				.format(order?.delivery_date)
				.format("DD-MM-YYYY")}`;
		} else {
			return `Pay in ${order?.payment_term?.duration} days`;
		}
	};

	return (
		<div className='px-2 pb-5'>
			<Card title={ekkLanguage.orderDetails.paymentDetailsLabel}>
				<div>
					<table
						className='table-fixed w-full'
						style={{ borderBottom: "2px solid #F2F3F7" }}>
						<tbody className="before:content-[' '] before:block before:h-2 ">
							<tr>
								<td className='text-000080 text-12 font-medium py-[6px]'>
									{ekkLanguage.orderDetails.assignedCollector}
								</td>
								<td className='text-000080 text-12 font-medium py-[6px] text-right'>
									{order?.collectorName ??
										ekkLanguage.orderDetails.notAssigned}
								</td>
							</tr>
							<tr>
								<td className='text-000080 text-12 font-medium py-[6px]'>
									{ekkLanguage.orderDetails.paymentMethod}
								</td>
								<td className='text-000080 text-12 font-medium py-[6px] text-right'>
									{order?.payment_method}
								</td>
							</tr>
							<tr>
								<td className='text-000080 text-12 font-medium py-[6px]'>
									{ekkLanguage.orderDetails.paymentTerms}
								</td>
								<td className='text-000080 text-12 font-medium py-[6px] text-right'>
									<span>{order?.payment_term?.name}</span>
								</td>
							</tr>

							<tr>
								<td className='text-000080 text-12 font-medium py-[6px]'>
									{ekkLanguage.orderDetails.paymentDueDate}
								</td>
								<td className='text-000080 text-12 font-medium py-[6px] text-right'>
									<span>
										{digitChanger(
											moment(
												order?.payment_term?.paymentDueDate
											).format("DD-MM-YYYY")
										)}
									</span>
								</td>
							</tr>
							<tr>
								<td className='text-000080 text-12 font-medium py-[6px]'>
									{ekkLanguage.orderDetails.totalDue}
								</td>
								<td className='text-000080 text-12 font-medium py-[6px] text-right'>
									{/* {translator(order.currency)}{" "} */}
									{order?.country?.currency_symbol_native}
									{digitChanger(
										order?.order_status !== "Placed"
											? numberWithCommas(order?.total)
											: numberWithCommas(placedOrderItems?.total)
									)}
									{/* {digitChanger(numberWithCommas(order?.total))} */}
								</td>
							</tr>
							<tr>
								<td className='text-000080 text-12 font-medium py-[6px]'>
									{ekkLanguage.orderDetails.totalPaid}
								</td>
								<td className='text-000080 text-12 font-medium py-[6px] text-right'>
									{/* {translator(order.currency)}{" "} */}
									{order?.country?.currency_symbol_native}
									{digitChanger(numberWithCommas(order?.total_paid))}
								</td>
							</tr>
							<tr>
								<td className='text-000080 text-12 font-medium py-[6px]'>
									{ekkLanguage.orderDetails.remaining}
								</td>
								<td className='text-000080 text-12 font-medium py-[6px] text-right'>
									{/* {translator(order.currency)}{" "} */}
									{order?.country?.currency_symbol_native}
									{digitChanger(
										order?.order_status !== "Placed"
											? numberWithCommas(
													order?.total - order?.total_paid
											  )
											: numberWithCommas(
													placedOrderItems?.total -
														order?.total_paid
											  )
									)}
								</td>
							</tr>
						</tbody>
					</table>
					{order?.payment_method === "Bank Transfer" && (
						<div className='border-t-2'>
							<p className='text-12 text-black-300 font-bold pt-2'>
								{ekkLanguage.orderDetails.bankInfoLabel}
							</p>
							<table className='table-fixed w-full'>
								<tbody className="before:content-[' '] before:block before:h-2 ">
									<tr>
										<td className='text-000080 text-12 font-medium py-[6px]'>
											{ekkLanguage.bankDetails.referenceNumber}
										</td>
										<td className='text-000080 text-12 font-medium py-[6px] text-right'>
											<span className='text-12 text-black-1000 font-bold'>
												{digitChanger(order?.order_id)}
											</span>
										</td>
									</tr>
									<tr>
										<td className='text-000080 text-12 font-medium py-[6px]'>
											{ekkLanguage.bankDetails.bankName}
										</td>
										<td className='text-000080 text-12 font-medium py-[6px] text-right'>
											Bank Asia
										</td>
									</tr>

									<tr>
										<td className='text-000080 text-12 font-medium py-[6px]'>
											{ekkLanguage.bankDetails.accountName}
										</td>
										<td className='text-000080 text-12 font-medium py-[6px] text-right'>
											EkkBaz Bangladesh Pvt. Ltd
										</td>
									</tr>
									<tr>
										<td className='text-000080 text-12 font-medium py-[6px]'>
											{ekkLanguage.bankDetails.accountNumber}
										</td>
										<td className='text-000080 text-12 font-medium py-[6px] text-right'>
											044 3300 1328
										</td>
									</tr>
									<tr>
										<td className='text-000080 text-12 font-medium py-[6px]'>
											{ekkLanguage.bankDetails.routeNumber}
										</td>
										<td className='text-000080 text-12 font-medium py-[6px] text-right'>
											070 274 187
										</td>
									</tr>
									<tr>
										<td className='text-000080 text-12 font-medium py-[6px]'>
											{ekkLanguage.bankDetails.branch}
										</td>
										<td className='text-000080 text-12 font-medium py-[6px] text-right'>
											Mogbazar Branch
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					)}

					{sellerPayment?.length ? (
						<>
							<div>
								{sellerPayment?.map((el, i) => {
									return (
										<table className='table-fixed w-full'>
											<tbody className="before:content-[' '] before:block before:h-2 after:content-[' '] after:block after:h-2">
												<tr>
													<td className='text-black-1000 text-12 font-bold py-[6px]'>
														{
															ekkLanguage.orderDetails
																.transactionType
														}
													</td>
													<td className='text-black-1000 text-12 font-bold py-[6px] text-right'>
														{el?.trns_type}
													</td>
												</tr>
												<tr>
													<td className='text-000080 text-12 font-medium py-[6px]'>
														{ekkLanguage.orderDetails.gateway}
													</td>
													<td className='text-000080 text-12 font-medium py-[6px] text-right'>
														<span>
															{el?.buyer_gateway?.bank?.name}
														</span>
													</td>
												</tr>
												<tr>
													<td className='text-000080 text-12 font-medium py-[6px]'>
														{ekkLanguage.orderDetails.accountName}
													</td>
													<td className='text-000080 text-12 font-medium py-[6px] text-right'>
														{el?.buyer_gateway?.account_name}
													</td>
												</tr>
												<tr>
													<td className='text-000080 text-12 font-medium py-[6px]'>
														{
															ekkLanguage.orderDetails
																.accountNumber
														}
													</td>
													<td className='text-000080 text-12 font-medium py-[6px] text-right'>
														{el?.buyer_gateway?.account_no}
													</td>
												</tr>
												<tr>
													<td className='text-000080 text-12 font-medium py-[6px]'>
														{
															ekkLanguage.orderDetails
																.transactionNumber
														}
													</td>
													<td className='text-000080 text-12 font-medium py-[6px] text-right'>
														{el?.buyer_transaction_no}
													</td>
												</tr>
												<tr>
													<td className='text-000080 text-12 font-medium py-[6px]'>
														{ekkLanguage.orderDetails.date}
													</td>
													<td className='text-000080 text-12 font-medium py-[6px] text-right'>
														{digitChanger(
															monthChanger(
																moment(el?.payment_date).format(
																	"DD MMM, YYYY"
																)
															)
														)}
													</td>
												</tr>
												<tr>
													<td className='text-000080 text-12 font-medium py-[6px]'>
														{ekkLanguage.orderDetails.amount}
													</td>
													<td className='text-000080 text-12 font-medium py-[6px] text-right'>
														{
															order?.country
																?.currency_symbol_native
														}
														{digitChanger(
															Number(el?.amount).toFixed(2)
														)}
													</td>
												</tr>

												<tr>
													<td className='text-000080 text-12 font-medium py-[6px]'>
														{ekkLanguage.orderDetails.receipt}
													</td>
													<td className='text-000080 text-12 font-medium py-[6px] text-right'>
														{el?.image ? (
															<img
																onClick={() => {
																	let details = {
																		...el,
																	};
																	dispatch(
																		setPaymentDetails(details)
																	);
																	navigate(
																		"/fs-payment-reciept"
																	);
																}}
																src={el?.image}
																alt='reciept'
																className='rounded-[10px] block h-[100px] w-[100px] m-auto lg:ml-[167px] ml-[50px]'
																style={{
																	background: "#E6E6E6",
																}}
															/>
														) : (
															ekkLanguage.orderDetails
																.receiptNotProvided
														)}
													</td>
												</tr>
											</tbody>
										</table>
									);
								})}
							</div>
						</>
					) : (
						""
					)}
				</div>
			</Card>
		</div>
	);
}
