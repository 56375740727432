import React, { useEffect, useState } from "react";
import Banner from "./Banner";
import BackButtonTitle from "../Common/BackButtonTitle";
import { useDispatch, useSelector } from "react-redux";
import CustomerInfo from "./CustomerInfo";
import AddressInfo from "./AddressInfo";
import CustomerNameTitle from "./CustomerNameTitle";
import { IonLoading } from "@ionic/react";
import Button from "./Button";
import SingleAMountBox from "../CustomerList/SingleAMountBox";

export default function CustomerDetail({ businessData, loading, customer  }) {
	const [isOpen, setIsOpen] = useState(false);
	const [cancelLoader, setCancelLoader] = useState(false);
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const requested = useSelector((state) => state.customer.data);
	const dispatch = useDispatch();

	const onOpenModal = () => setIsOpen(true);
	const onCloseModal = () => setIsOpen(false);

	const acceptCustomer = async () => {
		onCloseModal();
		// setCancelLoader(true);
		// let response = await Brand.brandDelete(brandInfo.id);
		// if (response.success) {
		// 	setCancelLoader(false);
		// 	dispatch(setBrandDetails(false));
		// 	onCloseModal();
		// 	navigate("/brand-list");
		// } else {
		// 	setCancelLoader(false);
		// }
	};

	return (
		<>
			<BackButtonTitle
				title={ekkLanguage.customerList.detailsPageTitle}
				from='customerDetails'
			/>

			{loading ? (
				<IonLoading
					isOpen={loading}
					message={ekkLanguage.orderList.loader}
				/>
			) : (
				<div className='pt-14'>
					<Banner businessData={businessData} />
					<div className='px-2 py-4'>
						<CustomerNameTitle
							businessData={businessData}></CustomerNameTitle>
						<AddressInfo businessData={businessData}></AddressInfo>
						<div className='mt-[20px]'>
							<CustomerInfo businessData={businessData}></CustomerInfo>
						</div>
						<div className='pt-4 pb-1'>
							<div className='grid grid-cols-2 gap-4'>
								<SingleAMountBox
									name={ekkLanguage.dashboard.totalOrder}
									value={customer?.total_orders}
									withComma={false}
								/>
								<SingleAMountBox
									name={ekkLanguage.dashboard.totalAmount}
									value={customer?.total_amount}
									withComma={true}
								/>
								<SingleAMountBox
									name={ekkLanguage.dashboard.totalPaid}
									value={customer?.total_paid}
									withComma={true}
								/>
								<SingleAMountBox
									name={ekkLanguage.dashboard.totalDue}
									value={customer?.due}
									withComma={true}
								/>
							</div>
						</div>
						{/* {!requested && (
							<Button
								onOpenModal={onOpenModal}
								isOpen={isOpen}
								onCloseModal={onCloseModal}
								cancelLoader={cancelLoader}
								acceptCustomer={acceptCustomer}
							></Button>
						)} */}
					</div>
				</div>
			)}
		</>
	);
}
