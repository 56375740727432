import { IonSelect, IonSelectOption } from "@ionic/react";
import React, { useState } from "react";
import Select from "react-select";

let customStyle = {
	control: (baseStyles, state) => ({
		...baseStyles,
		borderColor: "rgba(0,0,0,0.5)",
		background: "#F8F8F8",
		height: "50px",
		borderRadius: "5px",
	}),
};
export default function SelectWithIcon({
	type,
	title,
	selectItem,
	setSelectItem,
	placeholder,
	icon,
	errorText,
	required,
	options,
}) {
	return (
		<div className='pb-5'>
			<div className='flex'>
				{icon && <img src={icon} alt='icon' className='mr-[10px] w-5 h-5' />}
				{title && <h4 className='pb-3 font-bold text-14'>{title}</h4>}
			</div>

			<div>
				<Select
					value={selectItem}
					onChange={(e) => setSelectItem(e)}
					styles={customStyle}
					options={options}
					placeholder={placeholder}
				/>
			</div>
		</div>
	);
}
