import {
	IonApp,
	IonContent,
	IonLoading,
	IonPage,
	useIonViewDidEnter,
	useIonViewWillEnter,
} from "@ionic/react";
import BackButtonTitle from "components/mobile-components/Common/BackButtonTitle";
import CostSummeryCard from "components/mobile-components/OrderDetails/CostSummeryCard";
import DeliveryDetailsCard from "components/mobile-components/OrderDetails/DeliveryDetailsCard";
import OrderItemCard from "components/mobile-components/OrderDetails/OrderItemCard";
import OrderMetaBar from "components/mobile-components/OrderDetails/OrderMetaBar";
import OrderOnwerState from "components/mobile-components/OrderDetails/OrderOnwerState";
import PaymentDetailsCard from "components/mobile-components/OrderDetails/PaymetDetailsCard";
import TimeLine from "components/mobile-components/OrderDetails/TimeLine";
import TimeLineTextBox from "components/mobile-components/OrderDetails/TimeLineTextBox";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	fetchOderCollectionAmount,
	fetchOrderDetailsByOrderId,
	fetchOrderSingle,
} from "redux/container/orderSlice";
import { useParams } from "react-router-dom";
import BottomSheet from "components/mobile-components/OrderDetails/BottomSheet";
import PaymentSettlementCard from "components/mobile-components/OrderDetails/PaymentSettlement";
import Orders from "services/Orders";
import SelectTimeDate from "components/mobile-components/OrderDetails/SelectTimeDate";

export default function OrderDetailsPage() {
	let { id } = useParams();
	const dispatch = useDispatch();
	const response = useSelector((state) => state.order);
	let [collection, setCollection] = useState([]);
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const [loanDetails, setLoanDetails] = useState({});
	const [lanPaymentList, setLoanPaymentList] = useState({});

	useIonViewDidEnter(() => {
		if (response.from === "orderList") {
			dispatch(fetchOrderSingle(id));
		} else {
			dispatch(fetchOrderDetailsByOrderId(id));
		}
	});
	useIonViewWillEnter(() => {
		if (response.from === "orderList") {
			dispatch(fetchOrderSingle(id));
		} else {
			dispatch(fetchOrderDetailsByOrderId(id));
		}
	});
	const fetchOderCollectionAmount = async () => {
		if (response.from === "orderList") {
			let response = await Orders.orderAmountCollection(id);
			if (response?.status === 200) {
				setCollection(response.data);
			}
		} else {
			let res = await Orders.orderAmountCollection(
				response?.singleOrder?._id
			);
			if (res?.status === 200) {
				setCollection(res.data);
			}
		}
	};
	let getOrderDetails = async () => {
		if (response.from === "orderList") {
			let res = await dispatch(fetchOrderSingle(id));
			fetchOderCollectionAmount(id);

			if (res.status === 200) {
				getLoanDetails(res.data.order_id);
			}
		} else {
			 await dispatch(fetchOrderDetailsByOrderId(id));
			if (response?.singleOrder?.order_id === id) {
				fetchOderCollectionAmount(response?.singleOrder?._id);
			}
			getLoanDetails(id);
		}
	};
	useEffect(() => {
		getOrderDetails();
	}, [response?.singleOrder?.order_id, id]);

	const getLoanDetails = async (orderId) => {
		let res = await Orders.getLoanDetails(orderId);
		if (res?.status === 200) {
			setLoanDetails(res.result);
			let loanResponse = await Orders.getTransactionType(
				res?.result?.loanTrackingId
			);
			if (loanResponse.status === 200) {
				setLoanPaymentList(loanResponse.results);
			}
		} else {
		}
	};

	return (
		<IonApp>
			<IonPage>
				<IonContent>
					<BackButtonTitle
						title={
							response?.singleOrder?.created_by_business?.business_name
						}
						from={
							response.singleOrder?.order_status === "Placed"
								? ""
								: `orderDetailsPage`
						}
					/>
					{response.status === "loading" ? (
						<IonLoading
							isOpen={true}
							message={ekkLanguage.orderList.loader}
						/>
					) : (
						<>
							<OrderMetaBar order={response.singleOrder} />
							<section className='px-2 pt-24'>
								<OrderOnwerState
									order={response.singleOrder}
									loanDetails={loanDetails}
								/>
								<DeliveryDetailsCard order={response.singleOrder} />
								<OrderItemCard order={response.singleOrder} />
								<CostSummeryCard order={response.singleOrder} />
								<PaymentDetailsCard
									order={response.singleOrder}
									sellerPayment={lanPaymentList}
								/>
								<PaymentSettlementCard collection={collection} />
								{(response.singleOrder?.order_status === "Placed") |
								(response.singleOrder?.order_status === "Accepted") ? (
									<SelectTimeDate
										order={response.singleOrder}
										accepted={
											response.singleOrder?.order_status ===
											"Accepted"
										}
									/>
								) : (
									""
								)}

								<TimeLine order={response.singleOrder} />
								<BottomSheet order={response.singleOrder} />
							</section>
						</>
					)}

					{/* <TimeLineTextBox order={response.singleOrder} /> */}
				</IonContent>
			</IonPage>
		</IonApp>
	);
}
