import { createSlice } from "@reduxjs/toolkit";

const distributorSlice = createSlice({
  name: "distributor",
  initialState: {
    relationDetails: {},
  },
  reducers: {
    setRelationDetails: (state, action) => {
      state.relationDetails = action.payload;
    },
  },
});

// Extract the action creators object and the reducer
export const { actions, reducer } = distributorSlice;

export const setRelationDetails = (values) => async (dispatch, getState) => {
  dispatch(actions.setRelationDetails(values));
};

// Export the reducer, either as a default or named export
export default reducer;
