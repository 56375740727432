import { IonApp, IonContent, IonLoading, IonPage } from "@ionic/react";
import LeftSidebar from "components/global-components/Navigation/LeftSidebar";
import BackButtonTitle from "components/mobile-components/Common/BackButtonTitle";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Search from "components/mobile-components/Common/Search";
import Customer from "services/Customer";
import withLocation from "components/HOC/withLocation";
import InvitedDistributorList from "components/mobile-components/CustomerList/InvitedDistributorList";
import { getUserBusiness } from "config/user";

function InvitedDistributorListPage() {
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const [menuCollapse, setMenuCollapse] = useState(true);
	const [loading, setLoading] = useState(true);
	const [searchQ, setSearchQ] = useState("");
	const [customerData, setCustomerData] = useState([]);
	const menuIconClick = () => {
		setMenuCollapse(!menuCollapse);
	};
	let business = getUserBusiness();

	const getDistributorList = async () => {
		if (searchQ) {
			setCustomerData([]);
			setLoading(true);
			let response = await Customer.getInvitedDistributorSearch(
				business?.id,
				searchQ
			);
			if (response.status === 200) {
				setCustomerData(response.results);
				setLoading(false);
			} else {
				setLoading(false);
			}
		} else {
			setCustomerData([]);
			setLoading(true);
			let response = await Customer.getInvitedDistributorList(business?.id);
			if (response.status === 200) {
				setCustomerData(response.results);
				setLoading(false);
			} else {
				setLoading(false);
			}
		}
	};

	let singleDistributorSearch = async () => {
		let response = await Customer.getInvitedDistributorSearch(
			business?.id,
			searchQ
		);
		if (response.status === 200) {
			setCustomerData(response.results);
		}
	};

	useEffect(() => {
		if (searchQ === "") {
			getDistributorList();
		}
	}, [searchQ]);

	return (
		<IonApp style={{ "--ion-background-color": "white" }}>
			<IonPage>
				<IonContent>
					<LeftSidebar
						isMobile={true}
						menuCollapse={menuCollapse}
						menuIconClick={menuIconClick}
					/>
					<BackButtonTitle
						title={ekkLanguage.invitedDistributor.pageTitle}
						// burgerMenu={true}
						// menuCollapse={menuCollapse}
						// menuIconClick={menuIconClick}
					/>
					<section className='pt-14'>
						<div className='pt-5 px-4'>
							<Search
								search={searchQ}
								setSearch={setSearchQ}
								ekkLanguage={ekkLanguage}
								placeholder={
									ekkLanguage.invitedDistributor.searchPlaceHolder
								}
								singleSearch={singleDistributorSearch}
								filter={false}
							/>
							{loading ? (
								<IonLoading
									isOpen={true}
									message={ekkLanguage.orderList?.loader}></IonLoading>
							) : (
								<InvitedDistributorList
									loading={loading}
									customerData={customerData}
									link={"distributor-details"}
									ekkLanguage={ekkLanguage}
									getDistributorList={getDistributorList}
									identity='customer'></InvitedDistributorList>
							)}
						</div>
					</section>
				</IonContent>
			</IonPage>
		</IonApp>
	);
}

export default withLocation(InvitedDistributorListPage);
