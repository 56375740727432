import {
	IonButton,
	IonCol,
	IonGrid,
	IonInput,
	IonItem,
	IonRow,
} from "@ionic/react";
import City from "assets/images/icon/svg/City";
import Envelope from "assets/images/icon/svg/Envelope";
import Glove from "assets/images/icon/svg/Glove";
import LocationPin from "assets/images/icon/svg/LocationPin";
import OfficeCall from "assets/images/icon/svg/OfficeCall";
import PhoneCall from "assets/images/icon/svg/PhoneCall";
import PostalCode from "assets/images/icon/svg/PostalCode";
import Register from "assets/images/icon/svg/Register";
import Tax from "assets/images/icon/svg/Tax";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ProfileEdit from "./ProfileEdit";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import Business from "services/Business";
import { getUserBusiness, setUserBusiness } from "config/user";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

export default function BusinessEdit() {
	const ekkLanguage = useSelector((state) => state.lan.lan);
	let [businessData, setBusinessData] = useState({});
	let business = getUserBusiness();
	let [loading, setLoading] = useState(false);
	const validationSchema = Yup.object().shape({
		// address_line: Yup.string(),
	});
	const navigate = useNavigate();
	const {
		register,
		handleSubmit,
		reset,
		setValue,
		formState: { errors },
	} = useForm({
		resolver: yupResolver(validationSchema),
	});

	//get business data
	let getBusiness = async () => {
		let response = await Business.getBusiness(business?.id);
		if (response.status === 200) {
			setBusinessData(response.results);
			setValue("address_line", response.results?.address_line);
			setValue("city", response.results?.city);
			setValue("postal_code", response.results?.postal_code);
			setValue("registration_number", response.results?.registration_number);
			setValue("tax_vat_number", response.results?.tax_vat_number);
			setValue("phone_number", response.results?.phone_number);
			setValue("office_number", response.results?.meta_data?.office_number);
			setValue("email", response.results?.email);
			setValue("website", response.results?.meta_data?.website);
		}
	};
	useEffect(() => {
		getBusiness();
	}, []);

	const onSubmit = async (data) => {
		let response = await Business.updateUserBusiness(business?.id, data);
		if (response.status == 200) {
			toast.success("Business updated successfully");
			// setBusinessData(response.results);
			// setUserBusiness(response.results);
			navigate("/profile");
		} else {
			toast.error("Business updated failed");
		}
	};

	return (
		<div className='profile-edit bg-gray pt-14'>
			<ProfileEdit
				loading={loading}
				setLoading={setLoading}
				businessData={businessData}
				setBusinessData={setBusinessData}
			/>
			<div>
				<div className='px-2 relative pt-14'>
					<form
						onSubmit={handleSubmit(onSubmit)}
						className='profile-edit-form'>
						<IonGrid>
							<IonRow>
								<IonCol size='12'>
									<div className='flex mb-7'>
										<span>
											<LocationPin color='#222222' />
										</span>
										<div className='ml-[10px] w-full'>
											<h5 className='text-dark-1000 text-16 font-semibold'>
												{ekkLanguage.businessEdit.address}
											</h5>
											<IonItem>
												<IonInput
													value={businessData?.address_line}
													type='text'
													placeholder={
														ekkLanguage.businessEdit
															.addressPlaceholder
													}
													{...register("address_line")}
													clearInput></IonInput>
											</IonItem>
											{/* <p className="text-right">{errors.address?.message}</p> */}
										</div>
									</div>
								</IonCol>
								<IonCol size='6'>
									<div className='flex mb-7'>
										<span>
											<City />
										</span>
										<div className='ml-[10px] w-full'>
											<h5 className='text-dark-1000 text-16 font-semibold'>
												{ekkLanguage.businessEdit.city}
											</h5>
											<IonItem>
												<IonInput
													type='text'
													value={businessData?.city}
													placeholder={
														ekkLanguage.businessEdit
															.cityPlaceholder
													}
													{...register("city")}
													clearInput></IonInput>
											</IonItem>
										</div>
									</div>
								</IonCol>
								<IonCol size='6'>
									<div className='flex mb-7'>
										<span>
											<PostalCode />
										</span>
										<div className='ml-[10px] w-full'>
											<h5 className='text-dark-1000 text-16 font-semibold'>
												{ekkLanguage.businessEdit.postalCode}
											</h5>
											<IonItem>
												<IonInput
													type='text'
													value={businessData?.postal_code}
													placeholder={
														ekkLanguage.businessEdit
															.postalCodePlaceholder
													}
													{...register("postal_code")}
													clearInput></IonInput>
											</IonItem>
										</div>
									</div>
								</IonCol>
								<IonCol size='12'>
									<div className='flex mb-7'>
										<span>
											<Register />
										</span>
										<div className='ml-[10px] w-full'>
											<h5 className='text-dark-1000 text-16 font-semibold'>
												{ekkLanguage.businessEdit.resigter}
											</h5>
											<IonItem>
												<IonInput
													type='text'
													value={businessData?.registration_number}
													placeholder={
														ekkLanguage.businessEdit
															.resigterPlaceholder
													}
													{...register("registration_number")}
													clearInput></IonInput>
											</IonItem>
										</div>
									</div>
								</IonCol>
								<IonCol size='12'>
									<div className='flex mb-7'>
										<span>
											<Tax />
										</span>
										<div className='ml-[10px] w-full'>
											<h5 className='text-dark-1000 text-16 font-semibold'>
												{ekkLanguage.businessEdit.tex}
											</h5>
											<IonItem>
												<IonInput
													type='text'
													value={businessData?.tax_vat_number}
													placeholder={
														ekkLanguage.businessEdit
															.texPlaceholder
													}
													{...register("tax_vat_number")}
													clearInput></IonInput>
											</IonItem>
										</div>
									</div>
								</IonCol>
								<IonCol size='12'>
									<div className='flex mb-7'>
										<span>
											<PhoneCall color='#222222' />
										</span>
										<div className='ml-[10px] w-full'>
											<h5 className='text-dark-1000 text-16 font-semibold'>
												{ekkLanguage.businessEdit.mobile}
											</h5>
											<IonItem>
												<IonInput
													type='number'
													value={businessData?.phone_number}
													placeholder={
														ekkLanguage.businessEdit
															.mobilePlaceholder
													}
													readonly
													{...register("phone_number")}
													clearInput></IonInput>
											</IonItem>
										</div>
									</div>
								</IonCol>
								<IonCol size='12'>
									<div className='flex mb-7'>
										<span>
											<OfficeCall />
										</span>
										<div className='ml-[10px] w-full'>
											<h5 className='text-dark-1000 text-16 font-semibold'>
												{ekkLanguage.businessEdit.office}
											</h5>
											<IonItem>
												<IonInput
													type='number'
													value={businessData?.office_number}
													placeholder={
														ekkLanguage.businessEdit
															.officePlaceholder
													}
													{...register("office_number")}
													clearInput></IonInput>
											</IonItem>
										</div>
									</div>
								</IonCol>
								<IonCol size='12'>
									<div className='flex mb-7'>
										<span>
											<Envelope />
										</span>
										<div className='ml-[10px] w-full'>
											<h5 className='text-dark-1000 text-16 font-semibold'>
												{ekkLanguage.businessEdit.email}
											</h5>
											<IonItem>
												<IonInput
													type='email'
													value={businessData?.email}
													placeholder={
														ekkLanguage.businessEdit
															.emailPlaceholder
													}
													{...register("email")}
													clearInput></IonInput>
											</IonItem>
										</div>
									</div>
								</IonCol>
								<IonCol size='12'>
									<div className='flex mb-7'>
										<span>
											<Glove />
										</span>
										<div className='ml-[10px] w-full'>
											<h5 className='text-dark-1000 text-16 font-semibold'>
												{ekkLanguage.businessEdit.website}
											</h5>
											<IonItem>
												<IonInput
													type='website'
													value={businessData?.website}
													placeholder={
														ekkLanguage.businessEdit
															.websitePlaceholder
													}
													{...register("website")}
													clearInput></IonInput>
											</IonItem>
										</div>
									</div>
								</IonCol>
							</IonRow>
							<div>
								<IonRow>
									<IonCol size='12'>
										<div className='text-center'>
											<IonButton
												type='submit'
												// disabled={permissionToChange()}
												className='bg-primary rounded-[7px] font-extrabold text-12 h-10'>
												{ekkLanguage.contact.btnLabel}
											</IonButton>
										</div>
									</IonCol>
								</IonRow>
							</div>
						</IonGrid>
					</form>
				</div>
			</div>
		</div>
	);
}
