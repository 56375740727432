import {
  IonApp,
  IonContent,
  IonGrid,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonPage,
} from "@ionic/react";
import LeftSidebar from "components/global-components/Navigation/LeftSidebar";
import BackButtonTitle from "components/mobile-components/Common/BackButtonTitle";
import SectionTitle from "components/mobile-components/Common/SectionTitle";
import CustomerDetail from "components/mobile-components/CustomerDetails";
import CustomerDetailsBottomSheet from "components/mobile-components/CustomerDetails/CustomerDetailsBottomSheet";
import CustomerDetailsFilter from "components/mobile-components/CustomerDetails/CustomerDetailsFilter";
import DistributorCustomerList from "components/mobile-components/CustomerDetails/DistributorCustomerList";
import Products from "components/mobile-components/CustomerDetails/Products";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { setRelationDetails } from "redux/container/distributorSlice";
import Customer from "services/Customer";

export default function CustomerDetailsPage() {
  const { id } = useParams();
  const ekkLanguage = useSelector((state) => state.lan.lan);
  let locationData = useSelector(
    (state) => state.user.locationData.coordinates
  );
  let [businessData, setBusinessData] = useState({});
  const [product, setProduct] = useState([]);
  const [loading, setLoading] = useState(true);
  const [menuCollapse, setMenuCollapse] = useState(true);
  const [pageCount, setPageCount] = useState(2);
  const [tabType, setTabType] = useState("productList");
  const [customerList, setCustomerList] = useState([]);
  const [customerLoading, setCustomerLoading] = useState(false);
  const [productLoading, setProductLoading] = useState(false);
  const [distebutor360, setDistebutor360] = useState([]);
  const [hasMoreData, setHasMoreData] = useState(true);
  const [reachedEnd, setReachedEnd] = useState(false);
  const dispatch = useDispatch();
  const menuIconClick = () => {
    setMenuCollapse(!menuCollapse);
  };
  let getDistributorDetails = async () => {
    // * distributor list
    let response = await Customer.getDistributorDetails(id);
    if (response.status === 200) {
      setBusinessData(response.results);
      dispatch(setRelationDetails(response.results));
      // * 360
      let resp = await Customer.getDistebutor360(
        response?.results?.initiator_business_id
      );
      if (resp.status === 200) {
        setDistebutor360(resp.data);
      }
      // * Products
      if (tabType === "productList") {
        let res = await Customer.getDistributorCustomPriceProduct(
          response?.results?.related_business_id
        );
        if (res.status === 200) {
          setProduct(res.results);
          setLoading(false);
		  if (res.results.length < 30) {
			setHasMoreData(false);
			setReachedEnd(true);
		  }
        } else {
          setLoading(false);
        }
        // * Customer
      } else {
        let res = await Customer.getDistributorCustomerList(
          locationData[0],
          locationData[1],
          1,
          businessData?.related_business_id
        );
        if (res.status === 200) {
          setCustomerList(res.results);
          setLoading(false);
		  if (res.results.length < 30) {
			setHasMoreData(false);
			setReachedEnd(true);
		  }

        } else {
          setLoading(false);
        }
      }
    }
  };
  // ! Distributor Custom Product List
  const getCustomProductList = async () => {
    setReachedEnd(false);
    setHasMoreData(true);
    setProductLoading(true);
    setProduct([]);
    setPageCount(2);
    if (businessData?.related_business_id) {
      let res = await Customer.getDistributorCustomPriceProduct(
        businessData?.related_business_id,
        1
      );
      if (res.status === 200) {
        setProduct(res.results);
        setProductLoading(false);
        if (res.results.length < 30) {
          setHasMoreData(false);
          setReachedEnd(true);
        }
      } else {
        setProductLoading(false);
      }
    }
  };

  // ! Distributor Customer List
  const getDistributorCustomerList = async () => {
    setReachedEnd(false);
    setHasMoreData(true);
    setCustomerLoading(true);
    setCustomerList([]);
    setPageCount(2);
    if (businessData?.related_business_id) {
      let res = await Customer.getDistributorCustomerList(
        locationData[0],
        locationData[1],
        1,
        businessData?.related_business_id
      );
      if (res.status === 200) {
        setCustomerList(res.results);
        setCustomerLoading(false);
        if (res.results.length < 30) {
          setHasMoreData(false);
		  setReachedEnd(true);
        }
      } else {
        setCustomerLoading(false);
      }
    }
  };

  useEffect(() => {
    setLoading(true);
    let timeDelay = setTimeout(() => {
      getDistributorDetails();
    }, 500);
    return () => clearTimeout(timeDelay);
  }, []);

  let settings = {
    nameShow: false,
    titleShow: true,
    skuShow: false,
    priceShow: true,
    oldPriceShow: false,
    distShow: false,
    profitShow: true,
    sellPriceShow: false,
    supplierNameShow: false,
    link: "custom-product-details",
  };

  const loadMoreData = async () => {
    if (tabType === "productList") {
      let res = await Customer.getDistributorCustomPriceProduct(
        businessData?.related_business_id,
        pageCount
      );
      if (res.status === 200) {
        setProduct(res.results);
        setPageCount(pageCount + 1);
      } else {
        setHasMoreData(false);
      }
    } else {
      let res = await Customer.getDistributorCustomerList(
        locationData[0],
        locationData[1],
        pageCount,
        businessData?.related_business_id
      );
      if (res.status === 200) {
        setCustomerList(res.results);
        setPageCount(pageCount + 1);
      } else {
        setHasMoreData(false);
      }
    }
  };

  return (
    <IonApp>
      <IonPage>
        <IonContent>
          <LeftSidebar
            isMobile={true}
            menuCollapse={menuCollapse}
            menuIconClick={menuIconClick}
          />
          <BackButtonTitle
            title={ekkLanguage.businessProfile.pageTitle}
            burgerMenu={true}
            menuCollapse={menuCollapse}
            menuIconClick={menuIconClick}
            from="businessProfile"
          />
          <section>
            <CustomerDetail
              loading={loading}
              businessData={businessData}
              customer={distebutor360}
            />
          </section>
          <div
            className={`px-4 ${
              productLoading | customerLoading && "h-[100vh]"
            }`}
          >
            <CustomerDetailsFilter
              tabType={tabType}
              setTabType={setTabType}
              ekkLanguage={ekkLanguage}
              getCustomProductList={getCustomProductList}
              getDistributorCustomerList={getDistributorCustomerList}
            />
            {tabType === "productList" ? (
              <Products
                recommendData={product}
                settings={settings}
                loading={productLoading}
              />
            ) : (
              <DistributorCustomerList
                ekkLanguage={ekkLanguage}
                customerList={customerList}
                loading={customerLoading}
              />
            )}
          </div>
          <CustomerDetailsBottomSheet
            id={id}
            customerDetails={businessData}
            getDistributorDetails={getDistributorDetails}
          />
          <IonInfiniteScroll
            onIonInfinite={(ev) => {
              if (reachedEnd) {
                ev.target.complete();
                return;
              }
              loadMoreData();
              setTimeout(() => {
                ev.target.complete();
                if (!hasMoreData) {
                  setReachedEnd(true);
                }
              }, 500);
            }}
          >
            <IonInfiniteScrollContent
              loadingText={ekkLanguage.orderList.loader}
              loadingSpinner={hasMoreData ? "bubbles" : null}
            ></IonInfiniteScrollContent>
          </IonInfiniteScroll>
        </IonContent>
      </IonPage>
    </IonApp>
  );
}
