import { IonButton, IonInput, IonItem, IonLabel, IonModal } from "@ionic/react";
import CancelPopUp from "assets/images/icon/svg/CancelPopUp";
import moment from "moment";
import React, { useRef, useState } from "react";
import Sheet from "react-modal-sheet";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import { useNavigate } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import { fetchOrderSingle } from "redux/container/orderSlice";
import { setBottomSheet } from "redux/container/userSlice";
import Orders from "services/Orders";
import Card from "../Common/Card";
import { getUser } from "config/user";
import ReactModal from "react-modal";

export default function BottomSheet({ order }) {
	const ref = React.useRef();
	const isOpenBottomSheet = useSelector((state) => state.user.bottomSheet);
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const dispatch = useDispatch();
	const [isOpen, setIsOpen] = useState(false);
	const [isOpenDelivery, setIsOpenDelivery] = useState(false);
	const [isOpenDiscount, setIsOpenDiscount] = useState(false);
	const [isInstantDelivery, setIsInstantDelivery] = useState(false);
	const [loader, setLoader] = useState(false);
	const [deliveryFee, setDeliveryFee] = useState("");
	const [discount, setDiscount] = useState("");
	const [discountValidation, setDiscountValidation] = useState("");
	const navigate = useNavigate();
	let timeOut = false;

	let userInfo = getUser();

	const deliveryRef = useRef(null);
	const [fsAmount, setFsAmount] = useState(false);
	const [fsWarning, setFsWarning] = useState(
		ekkLanguage.orderDetails.cancelConformationAsk
	);

	const deliveryModalClose = () => {
		deliveryRef.current?.dismiss();
	};

	const onOpenModalDelivery = () => {
		dispatch(setBottomSheet(false));
		setIsOpenDelivery(true);
	};
	const onCloseModalDelivery = () => setIsOpenDelivery(false);

	const onOpenModalDiscount = () => {
		dispatch(setBottomSheet(false));
		setIsOpenDiscount(true);
	};
	const onCloseModalDiscount = () => setIsOpenDiscount(false);

	const onOpenModal = () => {
		setIsOpen(true);
	};

	const onCloseModal = () => {
		setIsOpen(false);
	};
	let startDayTime = moment().utc().startOf("day").toString();
	let dayEndTime = moment().utc().endOf("day").toString();
	let orderCreateTime = moment(order?.createdAt);

	if (
		Date.parse(dayEndTime) > Date.parse(orderCreateTime) &&
		Date.parse(startDayTime) < Date.parse(orderCreateTime)
	) {
		timeOut = true;
		// setTimeout(true);
	} else {
		// setTimeout(false);
	}
	let orderCancelHandler = async () => {
		let res = await Orders.orderCancel(order?._id);
		if (res.status === 200) {
			setIsOpen(false);
			dispatch(setBottomSheet(false));
			dispatch(fetchOrderSingle(order?._id));
		} else {
			setFsWarning(res.message);
			setFsAmount(true);
		}
	};

	const deliveryFeeSubmit = async () => {
		if (deliveryFee) {
			setLoader(true);
			let deliveryQuery = {
				order_status: "delivery_fee",
				delivery_amount: deliveryFee,
			};
			let resp = await Orders.orderUpdate(order?._id, deliveryQuery);
			if (resp.status === 200) {
				setLoader(false);
				setIsOpenDelivery(false);
				onCloseModalDelivery();
				setDeliveryFee("");
				dispatch(setBottomSheet(false));
				dispatch(fetchOrderSingle(order?._id));
			} else {
				setLoader(false);
			}
		}
	};

	const discountSubmit = async () => {
		if (discount) {
			if (order?.total > Number(discount)) {
				setLoader(true);
				let discountQuery = {
					order_status: "discount_amount",
					discount_amount: discount,
				};
				let resp = await Orders.orderUpdate(order?._id, discountQuery);
				if (resp.status === 200) {
					setLoader(false);
					setIsOpenDiscount(false);
					onCloseModalDiscount();
					setDiscount("");
					dispatch(setBottomSheet(false));
					dispatch(fetchOrderSingle(order?._id));
				} else {
					setLoader(false);
				}
			} else {
				setDiscountValidation(
					"Discount amount is higher than total amount"
				);
			}
		}
	};

	let orderDeliveryHandler = async () => {
		setLoader(true);
		let data = {
			order_status: "Delivered",
			deliveredBy: userInfo?.full_name,
		};
		let res = await Orders.orderDelivery(order?._id, data);
		if (res.status === 200) {
			setLoader(false);
			setIsOpen(false);
			dispatch(setBottomSheet(false));
			dispatch(fetchOrderSingle(order?._id));
		} else {
			setLoader(false);
		}
	};
	const customStyles = {
		content: {
			top: "50%",
			left: "50%",
			right: "auto",
			bottom: "auto",
			marginRight: "-50%",
			transform: "translate(-50%, -50%)",
			padding: "0px",
			width: "300px",
			transition: ".4s all ease",
			borderRadius: 12,
		},
		overlay: {
			position: "fixed",
			top: 0,
			left: 0,
			right: 0,
			bottom: 0,
			transition: ".4s all ease",
			backgroundColor: "rgba(0,0,0,.5)",
		},
	};
	return (
		<div>
			<Sheet
				className='bottom-sheet'
				initialSnap={1}
				ref={ref}
				isOpen={isOpenBottomSheet}
				snapPoints={[420, 420, 100, 0]}
				onClose={() => dispatch(setBottomSheet(false))}>
				<Sheet.Container>
					<Sheet.Header />
					<Sheet.Content>
						{(order?.order_status === "Placed") |
						(order?.order_status === "Scheduled For Delivery") ? (
							<>
								<IonItem
									button
									onClick={() => {
										dispatch(setBottomSheet(false));
										navigate(`/employes-list/driver/${order?._id}`);
									}}
									lines='none'>
									<span className='py-[5px]'>
										<img
											className='h-10 w-10'
											src='/assets/images/driving.png'
											alt='driver'
										/>
									</span>
									<p className='text-14 font-normal text-black-1000 pl-2'>
										{ekkLanguage.orderDetails.assignDriver}
									</p>
								</IonItem>
								<IonItem
									button
									onClick={() => {
										dispatch(setBottomSheet(false));
										navigate(`/employes-list/collector/${order?._id}`);
									}}
									lines='none'>
									<span className='py-[5px]'>
										<img
											className='h-10 w-10'
											src='/assets/images/save-money.png'
											alt='driver'
										/>
									</span>
									<p className='text-14 font-normal text-black-1000 pl-2'>
										{ekkLanguage.orderDetails.assignCollector}
									</p>
								</IonItem>
							</>
						) : (
							""
						)}
						{order?.order_status === "Scheduled For Delivery" && (
							<IonItem
								button
								onClick={() => {
									dispatch(setBottomSheet(false));
									setIsInstantDelivery(true);
								}}
								lines='none'>
								<span className='py-[5px]'>
									<img
										className='h-10 w-10'
										src='/assets/images/deliveryFee.png'
										alt='driver'
									/>
								</span>
								<p className='text-14 font-normal text-black-1000 pl-2'>
									{ekkLanguage.orderDetails.confirmDelivery}
								</p>
							</IonItem>
						)}
						{(order?.order_status === "Placed") |
						(order?.order_status === "Accepted") |
						(order?.order_status === "Scheduled For Delivery") ? (
							<>
								<IonItem
									button
									onClick={onOpenModalDelivery}
									lines='none'>
									<span className='py-[5px]'>
										<img
											className='h-10 w-10'
											src='/assets/images/moneyTrack.png'
											alt='driver'
										/>
									</span>
									<p className='text-14 font-normal text-black-1000 pl-2'>
										{ekkLanguage.orderDetails.addDeliveryFee}
									</p>
								</IonItem>
								<IonItem
									button
									onClick={onOpenModalDiscount}
									lines='none'>
									<span className='py-[5px]'>
										<img
											className='h-10 w-10'
											src='/assets/images/discount.png'
											alt='driver'
										/>
									</span>
									<p className='text-14 font-normal text-black-1000 pl-2'>
										{ekkLanguage.orderDetails.addDiscount}
									</p>
								</IonItem>
							</>
						) : (
							""
						)}
						{(order?.order_status === "Partially_Delivered") |
						(order?.order_status === "Delivered") ? (
							<IonItem
								button
								onClick={() => {
									dispatch(setBottomSheet(false));
									navigate(`/employes-list/collector/${order?._id}`);
								}}
								lines='none'>
								<span className='py-[5px]'>
									<img
										className='h-10 w-10'
										src='/assets/images/save-money.png'
										alt='driver'
									/>
								</span>
								<p className='text-14 font-normal text-black-1000 pl-2'>
									{ekkLanguage.orderDetails.assignCollector}
								</p>
							</IonItem>
						) : (
							""
						)}

						<IonItem
							button
							onClick={() => {
								dispatch(setBottomSheet(false));
								navigate(`/invoice/${order?._id}`);
							}}
							lines='none'>
							<span className='py-[5px]'>
								<img
									className='h-10 w-10'
									src='/assets/images/invoice.png'
									alt='driver'
								/>
							</span>
							<p className='text-14 font-normal text-black-1000 pl-2'>
								{ekkLanguage.orderDetails.downloadInvoices}
							</p>
						</IonItem>
						<IonItem
							button
							onClick={() => {
								dispatch(setBottomSheet(false));
								navigate(`/receipt/${order?._id}`);
							}}
							lines='none'>
							<span className='py-[5px]'>
								<img
									className='h-10 w-10'
									src='/assets/images/bill.png'
									alt='driver'
								/>
							</span>
							<p className='text-14 font-normal text-black-1000 pl-2'>
								{ekkLanguage.orderDetails.receiptDownloads}
							</p>
						</IonItem>
						{order?.order_status === "Placed" && (
							<IonItem button onClick={() => onOpenModal()} lines='none'>
								<span className='py-[5px]'>
									<img
										className='h-10 w-10'
										src='/assets/images/delete.png'
										alt='driver'
									/>
								</span>
								<p className='text-14 font-normal text-black-1000 pl-2'>
									{ekkLanguage.orderDetails.cancelOrder}
								</p>
							</IonItem>
						)}
					</Sheet.Content>
				</Sheet.Container>

				<Sheet.Backdrop onClick={() => dispatch(setBottomSheet(false))} />
			</Sheet>
			<Modal
				open={isOpen}
				onClose={onCloseModal}
				classNames='bottom-sheet-modal'>
				{timeOut ? (
					<div className='p-5'>
						<span className='mb-2 inline-block mx-2'>
							<CancelPopUp />
						</span>
						<h6 className='text-16 font-bold pb-[10px]'>{fsWarning}</h6>

						<div className='flex'>
							{!fsAmount && (
								<>
									<button
										className='border-2 font-bold h-10 rounded-[7px] w-1/2 mr-2 text-black-1000 border-black-200 border-solid'
										onClick={onCloseModal}>
										{ekkLanguage.orderDetails.noBtn}
									</button>
									<button
										color='primary'
										className='font-bold h-10 rounded-[7px] w-1/2 ml-2 border-2 bg-primary text-white'
										onClick={orderCancelHandler}>
										{ekkLanguage.orderDetails.yesBtn}
									</button>
								</>
							)}
							{fsAmount && (
								<button
									className='border-2 font-bold h-10 rounded-[7px] w-1/2 mr-2 text-black-1000 border-black-200 border-solid'
									onClick={onCloseModal}>
									{ekkLanguage.orderDetails.close}
								</button>
							)}
						</div>
					</div>
				) : (
					<div className='p-5'>
						<p>{ekkLanguage.orderDetails.cancelConformationParagraph}</p>
					</div>
				)}
			</Modal>
			<Modal
				className='paymentTrmsModal'
				showBackdrop={true}
				open={isOpenDelivery}>
				<div>
					<Card
						title={ekkLanguage.createOrder.deliveryFee}
						onHide={true}
						closeModal={onCloseModalDelivery}>
						<div>
							<div className='pt-5 pb-5'>
								<IonLabel>
									{ekkLanguage.createOrder.deliveryFee}
								</IonLabel>
								<IonItem style={{ "--background": "white" }}>
									<IonInput
										type='number'
										value={deliveryFee}
										onIonChange={(e) =>
											setDeliveryFee(e.target.value)
										}
										placeholder={
											ekkLanguage.orderDetails.feePlaceHolder
										}></IonInput>
								</IonItem>
							</div>

							<div className='text-center pt-0'>
								<IonButton
									color='primary'
									onClick={!loader && deliveryFeeSubmit}>
									{loader ? (
										<ClipLoader
											sizeUnit={"px"}
											size={20}
											color={"white"}
											loading={true}
										/>
									) : (
										ekkLanguage.createOrder.btnLabel
									)}
								</IonButton>
							</div>
						</div>
					</Card>
				</div>
			</Modal>
			<Modal
				className='paymentTrmsModal'
				showBackdrop={true}
				open={isOpenDiscount}>
				<div>
					<Card
						title={ekkLanguage.createOrder.discount}
						onHide={true}
						closeModal={onCloseModalDiscount}>
						<div>
							<div className='pt-5 pb-5'>
								<IonLabel>{ekkLanguage.createOrder.discount}</IonLabel>
								<IonItem style={{ "--background": "white" }}>
									<IonInput
										type='number'
										value={discount}
										onIonChange={(e) => setDiscount(e.target.value)}
										placeholder={
											ekkLanguage.orderDetails.discountPlaceHolder
										}></IonInput>
								</IonItem>
								<p className='text-primary text-14'>
									{discountValidation}
								</p>
							</div>

							<div className='text-center pt-0'>
								<IonButton
									color='primary'
									onClick={!loader && discountSubmit}>
									{loader ? (
										<ClipLoader
											sizeUnit={"px"}
											size={20}
											color={"white"}
											loading={true}
										/>
									) : (
										ekkLanguage.createOrder.btnLabel
									)}
								</IonButton>
							</div>
						</div>
					</Card>
				</div>
			</Modal>
			<ReactModal
				style={customStyles}
				closeTimeoutMS={200}
				isOpen={isInstantDelivery}
				onRequestClose={() => setIsInstantDelivery(false)}
				classNames='bottom-sheet-modal paymentTrmsModal'>
				<div className='p-5'>
					<span
						className='mb-[10px] inline-flex mx-2 h-[50px] w-[50px] rounded-full items-center justify-center'
						style={{ background: "rgb(0, 178, 36, .15" }}>
						<img src='/assets/images/deliveryman.png' alt='icon' />
					</span>
					<h6 className='text-16 font-bold pb-[30px]'>
						{ekkLanguage.orderDetails?.instantDelivery}
					</h6>

					<div className='flex justify-end'>
						<button
							color='primary'
							className={`${
								loader && "select-none cursor-no-drop"
							} font-bold h-10 rounded-[7px] w-1/2 mr-2 border-2 bg-success text-white flex justify-center items-center`}
							onClick={!loader && orderDeliveryHandler}>
							{loader ? (
								<ClipLoader
									sizeUnit={"px"}
									size={20}
									color={"white"}
									loading={true}
								/>
							) : (
								ekkLanguage.orderDetails.yesBtn
							)}
						</button>
						<button
							className='border-2 font-bold h-10 rounded-[7px] w-1/2 ml-2 bg-primary text-white'
							onClick={() => setIsInstantDelivery(false)}>
							{ekkLanguage.orderDetails.noBtn}
						</button>
					</div>
				</div>
			</ReactModal>
		</div>
	);
}
