import { IonSegment, IonSegmentButton } from "@ionic/react";
import React, { memo } from "react";
import { useDispatch } from "react-redux";
import { setCustomerDetails } from "redux/container/customerSlice";

function CustomerDetailsFilter({
  ekkLanguage,
  getCustomProductList,
  getDistributorCustomerList,
  tabType,
  setTabType,
}) {
  const dispatch = useDispatch();
  return (
    <IonSegment
      scrollable
      className="orderBtnWrapper"
      value={tabType}
      onIonChange={(e) => {
        dispatch(
          setCustomerDetails(e.target.value === "productList" ? true : false)
        );
      }}
    >
      <IonSegmentButton
        value="productList"
        className="paid relative"
        onClick={() => {
          setTabType("productList");
          // dispatch(setCustomer(true));
          getCustomProductList();
        }}
      >
        <button
          className={`${
            tabType === "productList" ? "text-[#00B224]" : "text-[#00000054]"
          } font-sans p-0 font-bold shadow-none text-14`}
        >
          {ekkLanguage.customerList.productsSectionTitle}
        </button>
      </IonSegmentButton>
      <IonSegmentButton
        value="customerList"
        className=" cancel relative"
        onClick={() => {
          setTabType("customerList");
          // dispatch(setCustomer(false));
          getDistributorCustomerList();
        }}
      >
        <button
          className={`${
            tabType === "customerList" ? "text-primary" : "text-[#00000054]"
          } font-sans p-0 font-bold shadow-none text-14 flex`}
        >
          {ekkLanguage.customerList.distributorCustomerList}
        </button>
      </IonSegmentButton>
    </IonSegment>
  );
}
export default memo(CustomerDetailsFilter);
