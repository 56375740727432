import {
  IonApp,
  IonContent,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonLoading,
  IonPage,
} from "@ionic/react";
import LeftSidebar from "components/global-components/Navigation/LeftSidebar";
import BackButtonTitle from "components/mobile-components/Common/BackButtonTitle";
import OrderStatus from "components/mobile-components/OrderList/OrderStatus";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { fetchOrder } from "redux/container/orderSlice";
import { useDispatch } from "react-redux";
import CustomerList from "components/mobile-components/CustomerList/CustomerList";
import CustomerFilter from "components/mobile-components/CustomerList/CustomerFilter";
import Search from "components/mobile-components/Common/Search";
import CustomerBottomSheet from "components/mobile-components/CustomerList/CustomerBottomSheet";
import Customer from "services/Customer";
import withLocation from "components/HOC/withLocation";
import OrderInvoiceSearch from "components/mobile-components/OrderList/OrderInvoiceSearch";

function CustomerListPage() {
  const ekkLanguage = useSelector((state) => state.lan.lan);
  const filterType = useSelector((state) => state.customer.data);
  const [menuCollapse, setMenuCollapse] = useState(true);
  const [customerType, setCustomerType] = useState("distributorList");
  const [loading, setLoading] = useState(true);
  const [searchQ, setSearchQ] = useState("");
  const [customerData, setCustomerData] = useState([]);
  const [pageCount, setPageCount] = useState(2);
  const [hasMoreData, setHasMoreData] = useState(true);
  const [reachedEnd, setReachedEnd] = useState(false);
  const [orderStatus, setOrderStatus] = useState([]);
  const menuIconClick = () => {
    setMenuCollapse(!menuCollapse);
  };

  let locationData = useSelector(
    (state) => state.user.locationData.coordinates
  );

  const dispatch = useDispatch();
  let orderListPage = useSelector((state) => state.order.page);

  // Seller Code
  const getAcceptedCustomerList = async () => {
    setReachedEnd(false);
    setHasMoreData(true);
    setPageCount(2);
    if (searchQ) {
      setCustomerData([]);
      setLoading(true);
      let response = await Customer.getDistributorListSearch(
        locationData[0],
        locationData[1],
        pageCount,
        searchQ
      );
      if (response.status === 200) {
        setCustomerData(response.results);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } else {
      setCustomerData([]);
      setLoading(true);
      let response = await Customer.getDistributorList(
        locationData[0],
        locationData[1]
      );
      if (response.status === 200) {
        setCustomerData(response.results);
        setLoading(false);
      } else {
        setLoading(false);
      }
    }
  };

  let lodeMoreData = async () => {
    if (hasMoreData) {
      if (searchQ) {
        const response = await Customer.getDistributorListSearch(
          locationData[0],
          locationData[1],
          pageCount,
          searchQ
        );
        if (response.status === 200) {
          setPageCount((prev) => prev + 1);
          setCustomerData([...customerData, ...response.results]);
        } else {
          setHasMoreData(false);
        }
      } else {
        const response = await Customer.getDistributorList(
          locationData[0],
          locationData[1],
          pageCount
        );
        if (response.status === 200) {
          setPageCount((prev) => prev + 1);
          setCustomerData([...customerData, ...response.results]);
        } else {
          setHasMoreData(false);
        }
      }
    }
  };

  let singleDistributorSearch = async () => {
    let response = await Customer.getDistributorListSearch(
      locationData[0],
      locationData[1],
      1,
      searchQ
    );
    if (response.status === 200) {
      setCustomerData(response.results);
    }
    // }
  };

  useEffect(() => {
    if (searchQ === "") {
      getAcceptedCustomerList();
    }
  }, [searchQ]);

  let getInitOrderList = async () => {
    setLoading(true);
    let response = await dispatch(fetchOrder(""));
    if (response.status === 200) {
      setLoading(false);
    } else {
      setLoading(false);
    }
  };
  let getOrderList = async (value) => {
    if (hasMoreData) {
      let response = await dispatch(fetchOrder(value, orderListPage));
      if (response.status === 200) {
        if (!response.data.length) {
          setHasMoreData(false);
        }
      }
    }
  };

  return (
    <IonApp style={{ "--ion-background-color": "white" }}>
      <IonPage>
        <IonContent>
          <LeftSidebar
            isMobile={true}
            menuCollapse={menuCollapse}
            menuIconClick={menuIconClick}
          />
          <BackButtonTitle
            title={ekkLanguage.customerList.pageTitle}
            burgerMenu={true}
            menuCollapse={menuCollapse}
            menuIconClick={menuIconClick}
            from="customerListPage"
          />
          <section className="pt-14">
            {customerType === "distributorList" ? (
              <div className="px-4 my-2">
                <Search
                  search={searchQ}
                  setSearch={setSearchQ}
                  ekkLanguage={ekkLanguage}
                  placeholder={ekkLanguage.customerList.searchPlaceHolder}
                  singleSearch={singleDistributorSearch}
                  filter={false}
                  // filterHandler={filterHandler}
                  // selectedFilter={selectedFilter}
                  // setTempSearchParams={setTempSearchParams}
                  setReachedEnd={setReachedEnd}
                  setHasMoreData={setHasMoreData}
                />
              </div>
            ) : (
              <div>
                <OrderInvoiceSearch
                  setLoading={setLoading}
                  ekkLanguage={ekkLanguage}
                  setReachedEnd={setReachedEnd}
                  setHasMoreData={setHasMoreData}
                />
              </div>
            )}
            <div className=" px-4">
              <CustomerFilter
                getAcceptedCustomerList={getAcceptedCustomerList}
                getRequestedCustomerList={getInitOrderList}
                ekkLanguage={ekkLanguage}
                customerType={customerType}
                setCustomerType={setCustomerType}
              />
            </div>
            {loading ? (
              <IonLoading
                isOpen={true}
                message={ekkLanguage.orderList?.loader}
              ></IonLoading>
            ) : customerType === "distributorList" ? (
              <>
                <CustomerList
                  loading={loading}
                  customerData={customerData}
                  link={"distributor-details"}
                  ekkLanguage={ekkLanguage}
                  identity="customer"
                ></CustomerList>
              </>
            ) : (
              <OrderStatus
                setOrderStatus={setOrderStatus}
                loading={loading}
                setLoading={setLoading}
                setReachedEnd={setReachedEnd}
                setHasMoreData={setHasMoreData}
              />
            )}
          </section>

          <IonInfiniteScroll
            onIonInfinite={(ev) => {
              if (reachedEnd) {
                ev.target.complete();
                return;
              }
              if (customerType === "distributorList") {
                lodeMoreData();
              } else {
                getOrderList(orderStatus);
              }

              setTimeout(() => {
                ev.target.complete();
                if (!hasMoreData) {
                  setReachedEnd(true);
                }
              }, 500);
            }}
          >
            <IonInfiniteScrollContent
              loadingText={ekkLanguage.orderList.loader}
              loadingSpinner={hasMoreData ? "bubbles" : null}
            ></IonInfiniteScrollContent>
          </IonInfiniteScroll>
          <CustomerBottomSheet toRedirect="my-distributor" />
        </IonContent>
      </IonPage>
    </IonApp>
  );
}

export default withLocation(CustomerListPage);
