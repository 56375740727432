import {
	IonCol,
	IonGrid,
	IonInfiniteScroll,
	IonInfiniteScrollContent,
	IonRow,
} from "@ionic/react";
import React from "react";
import { useSelector } from "react-redux";
import SectionTitle from "../Common/SectionTitle";
import SingleRecommendProduct from "./SingleRecommendProduct";

export default function RecommendProduct({
	recommendData,
	title,
	settinges,
	lodeMoreData,
	searchNext,
}) {
	// loadingText='Loading more products...'
	const ekkLanguage = useSelector((state) => state.lan.lan);
	return (
		<div className='related-product-card px-2 pb-7 pt-2'>
			<IonGrid className='bg-white rounded-lg'>
				<IonInfiniteScroll threshold='100px'>
					<IonInfiniteScrollContent loadingSpinner={false}>
						<IonRow>
							<IonCol size='12'>
								<SectionTitle title={title} />
							</IonCol>

							{recommendData?.length ? (
								recommendData.map((el, i) => {
									return (
										<IonCol
											size='6'
											sizeSm='4'
											className='mb-6'
											key={i}>
											<SingleRecommendProduct
												id={el.id ? el.id : el._id}
												name={el.brand_name}
												title={el.title}
												supplierName={el.business_name}
												qty={el.product_count}
												img={el.images}
												tag={el.tag}
												offer={el.offer}
												offerAmount={el.offerAmount}
												nearby={el.nearby}
												price={el.base_price}
												oldPrice={el.oldPrice}
												newPrice={el.newPrice}
												sellPrice={el.sellPrice}
												offerPrice={el.offer_price}
												profit={el.profit}
												nameShow={settinges.nameShow}
												supplierNameShow={
													settinges.supplierNameShow
												}
												skuShow={settinges.skuShow}
												priceShow={settinges.priceShow}
												oldPriceShow={settinges.oldPriceShow}
												distShow={settinges.distShow}
												profitShow={
													el.profit || el.consumer_price
														? settinges.profitShow
														: false
												}
												sellPriceShow={settinges.sellPriceShow}
												titleShow={settinges.titleShow}
												link={settinges.link}
												type={el.type}
												currency={el.currency}
											/>
										</IonCol>
									);
								})
							) : (
								<>
									<IonCol size='2'></IonCol>
									<IonCol size='8'>
										<div
											className='text-center flex items-center justify-center'
											style={{ height: "calc(100vh - 420px)" }}>
											<div>
												<img
													className='m-auto mb-[10px] mt-5'
													src='/assets/images/not-found-file.png'
													alt='emptyCard'
												/>
												<p className='text-16 font-normal text-222222 mb-[35px]'>
													{ekkLanguage.orderList.notFound}
												</p>
											</div>
										</div>
									</IonCol>
								</>
							)}
						</IonRow>
					</IonInfiniteScrollContent>
				</IonInfiniteScroll>
			</IonGrid>
		</div>
	);
}
