import { IonCol, IonGrid, IonLoading, IonRow } from "@ionic/react";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import CustomerCard from "./CustomerCard";

export default function CustomerList({
	customerData,
	loading,
	ekkLanguage,
	link,
}) {
	return (
		<>
			{loading ? (
				<IonLoading
					isOpen={true}
					message={ekkLanguage.orderList?.loader}></IonLoading>
			) : (
				<div className='w-full flex items-center justify-center'>
					<IonGrid classNae='w-full'>
						<IonRow className='w-full'>
							{customerData.length > 0 ? (
								customerData.map((customer, i) => {
									let image;
									if (customer?.related_business_images?.length > 0) {
										image = customer?.related_business_images;
									} else {
										image = "/assets/images/track.png";
									}
									return (
										<IonCol
											size='6'
											sizeSm='6'
											className='mb-6'
											key={i}>
											<CustomerCard
												key={customer?.id}
												customerId={customer?.id}
												customerName={customer?.related_business}
												imgSource={image}
												customerAddress={
													customer?.related_business_address
												}
												rating={
													customer?.products_count
														? customer?.products_count
														: 5
												}
												link={link}
												ekkLanguage={ekkLanguage}
												distance={customer.distance}></CustomerCard>
										</IonCol>
									);
								})
							) : (
								<>
									<IonCol size='2'></IonCol>
									<IonCol size='8'>
										<div
											className='text-center flex items-center justify-center'
											style={{ height: "calc(100vh - 420px)" }}>
											<div>
												<img
													className='m-auto mb-[10px] mt-5'
													src='/assets/images/not-found-file.png'
													alt='emptyCard'
												/>
												<p className='text-16 font-normal text-222222 mb-[35px]'>
													{ekkLanguage.orderList.notFound}
												</p>
											</div>
										</div>
									</IonCol>
								</>
							)}
						</IonRow>
					</IonGrid>
				</div>
			)}
		</>
	);
}
