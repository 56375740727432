import Chart from "assets/images/icon/svg/Chart";
import Download2 from "assets/images/icon/svg/Download2";
import Employees from "assets/images/icon/svg/Employees";
import CheckBusiness from "components/CheckBusiness";
import { getUser, getUserBusiness } from "config/user";
import DeviceDetector from "device-detector-js";
import { digitChanger, hasActive } from "helpers/helper";
import { useEffect, useState } from "react";
import Modal from "react-modal";
import {
	Menu,
	MenuItem,
	ProSidebar,
	SidebarContent,
	SidebarFooter,
	SidebarHeader,
} from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { setPwaDownloadData } from "redux/container/userSlice";
import Scrollbar from "smooth-scrollbar";
import "./styles/_SidebarComponent.scss";
import Product from "assets/images/icon/svg/Product";
import Business from "services/Business";
import { setKYC } from "redux/container/businessSettingsSlice";
import Settings from "assets/images/icon/svg/Settings";
import Customers from "assets/images/icon/svg/Customers";
import OrderListIcon from "assets/images/icon/svg/OrderListIcon";

const LeftSidebar = ({
	isMobile,
	menuCollapse,
	menuIconClick,
	businessDetails,
}) => {
	const { pathname } = useLocation();
	let dispatch = useDispatch();
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const kyc = useSelector((state) => state.businessSettings.data);
	const pwaObject = useSelector((state) => state.user);
	const [isOpen, setIsOpen] = useState(false);
	let user = getUser();
	let userBusiness = getUserBusiness();
	const userData = useSelector((state) => state.user.data);
	const navigate = useNavigate();

	useEffect(() => {
		Modal.setAppElement("body");
		Scrollbar.init(document.querySelector("#sidebar-scrollbar"));
	});
	// eslint-disable-next-line react-hooks/exhaustive-deps
	const handler = (e) => {
		e.preventDefault();
		dispatch(setPwaDownloadData(e));
	};
	useEffect(() => {
		window.addEventListener("beforeinstallprompt", handler);
		return () => window.removeEventListener("beforeinstallprompt", handler);
	}, [pathname, handler]);

	const onClick = () => {
		if (!pwaObject.pwaDownloadData) {
			return;
		}
		pwaObject.pwaDownloadData.prompt();
		setIsOpen(false);
	};
	const onCloseModal = () => {
		setIsOpen(false);
	};
	const customStyles = {
		content: {
			top: "50%",
			left: "50%",
			right: "auto",
			bottom: "auto",
			marginRight: "-50%",
			transform: "translate(-50%, -50%)",
			padding: "0px",
			width: "300px",
			transition: ".4s all ease",
			borderRadius: 12,
		},
		overlay: {
			position: "fixed",
			top: 0,
			left: 0,
			right: 0,
			bottom: 0,
			transition: ".4s all ease",
			backgroundColor: "rgba(0,0,0,.5)",
		},
	};
	const deviceDetector = new DeviceDetector();
	const userAgent = navigator.userAgent;
	const device = deviceDetector.parse(userAgent);

	useEffect(() => {
		if (kyc?.business_status === "Verified") {
			if (userBusiness.role !== "Admin") {
				navigate("/select-business", { replace: true });
			}
		} else if (kyc?.business_status === "Pending") {
			dispatch(
				setKYC({
					kycUpdate: true,
				})
			);
			navigate("/business-success", { replace: true });
		} else if (kyc?.business_status === "Unverified") {
			dispatch(
				setKYC({
					kycUpdate: false,
				})
			);
			navigate("/business-success", { replace: true });
		}
	}, []);
	// if (response.business_info.role === "Admin") {
	// 	navigate("/dashboard", { replace: true });
	// } else {
	// 	toast.error("Your not admin to login to application");
	// }
	const [businessDetailsLocally, setBusinessDetailsLocally] = useState({});
	const getBusinessDetails = async function () {
		let response = await Business.getBusinessDetails(userBusiness?.id);
		if (response.status === 200) {
			setBusinessDetailsLocally(response.results);
		}
	};
	useEffect(() => {
		getBusinessDetails();
	}, [businessDetails]);

	return (
		<>
			<CheckBusiness />
			<div
				onClick={menuIconClick}
				className={`back-drop w-full h-full bg-black-1000 z-[100]  opacity-40 transition-opacity ${
					menuCollapse
						? "opacity-0 invisible absolute"
						: "opacity-40 visible fixed"
				}`}></div>
			<div
				className={`sidebar ${isMobile ? "mobiel-sidebar" : ""}`}
				style={
					menuCollapse
						? {
								width: 0,
								zIndex: 1000000,
						  }
						: {
								width: "300px",
								zIndex: 1000000,
						  }
				}>
				<div id='sidebar-scrollbar'>
					<span
						className='absolute top-2 right-2 bg-primary text-white p-1 rounded-[5px]'
						style={{ zIndex: 1111, lineHeight: 1 }}
						onClick={menuIconClick}>
						<i className='material-icons'>close</i>
					</span>
					<nav className='sidebar-menu'>
						<ProSidebar collapsed={menuCollapse} className='relative'>
							{!menuCollapse && (
								<SidebarHeader>
									<Link to='/profile'>
										<div
											className='logo-box flex items-center pt-[30px] px-5 pb-5'
											// style={{
											// 	background:
											// 		"url(assets/images/sidebarBg.png)",
											// 	position: "relative",
											// }}
										>
											<div className='user-img mr-[10px] '>
												{userData && userData?.profile_image ? (
													<img
														src={userData?.profile_image}
														alt='avatar'
														className='h-[80px] w-[80px] rounded-full'
													/>
												) : (
													<img
														src={user?.profile_image}
														alt='user_avater'
														className='h-[80px] w-[80px] rounded-full'
													/>
												)}
											</div>
											<div className='user-info'>
												<p className='text-16 font-semibold text-222222 pb-[5px]'>
													{user?.full_name}
												</p>
												<p className='text-10 pb-[5px]'>
													{digitChanger(user?.PhoneNumber)}
												</p>
												<p className='text-10 pb-[5px]'>
													{userBusiness?.business_name}
												</p>
												<span className='text-12'>
													{userBusiness?.business_type?.title}
													{" - "}
													<span className='text-success'>
														{userBusiness?.business_status}
													</span>
													{" - "}
													{userBusiness?.role}
												</span>
											</div>{" "}
										</div>
									</Link>
								</SidebarHeader>
							)}
							<SidebarContent>
								<Menu iconShape='square'>
									{/* <MenuItem
										icon={<i className='material-icons'>home</i>}
										className={hasActive("/home", pathname)}>
										{ekkLanguage.letNav.homeMenuLabel}
										<NavLink to={`/home`} />
									</MenuItem> */}
									<MenuItem
										className={hasActive("/dashboard", pathname)}
										icon={<Chart />}>
										{ekkLanguage.letNav.dashboardProfileMenuLabel}
										<NavLink to={`/dashboard`} />
									</MenuItem>
									{/* <MenuItem
										className={hasActive("/order-list", pathname)}
										icon={<OrderListIcon />}>
										{ekkLanguage.letNav.orderMenuLabel}
										<NavLink to={`/order-list`} />
									</MenuItem> */}

									<MenuItem
										className={hasActive("/product-list", pathname)}
										icon={<Product />}>
										{ekkLanguage.letNav.product}
										<NavLink to={`/product-list`} />
									</MenuItem>
									<MenuItem
										className={hasActive(
											"/distributor-list",
											pathname
										)}
										icon={<Customers />}>
										{ekkLanguage.letNav.myCustomersMenuLabel}
										<NavLink to={`/distributor-list`} />
									</MenuItem>
									{/* {businessDetailsLocally?.business_direct_order && (
										<MenuItem
											className={hasActive(
												"/direct-sells",
												pathname
											)}
											icon={
												<svg
													xmlns='http://www.w3.org/2000/svg'
													width='18'
													height='18'
													viewBox='0 0 18 18'
													fill='none'>
													<path
														d='M17.0302 10.3448L15.5947 8.90925C15.1702 8.484 14.6047 8.25 14.0032 8.25H9.74996V6.75H14.625C15.6585 6.75 16.5 5.9085 16.5 4.875V3.375C16.5 2.3415 15.6585 1.5 14.625 1.5H9.74546L9.74996 0.7545C9.75221 0.33975 9.41846 0.00225 9.00371 0H8.99996C8.58746 0 8.25221 0.333 8.24996 0.7455L8.24546 1.5H3.99596C3.39521 1.5 2.82971 1.734 2.40446 2.15925L0.968961 3.59475C0.675711 3.888 0.675711 4.362 0.968961 4.65525L2.40446 6.09075C2.82896 6.516 3.39446 6.75 3.99596 6.75H8.24921V8.25H3.37421C2.34071 8.25 1.49921 9.0915 1.49921 10.125V11.625C1.49921 12.6585 2.34071 13.5 3.37421 13.5H8.24246L8.24921 17.2515C8.24921 17.6655 8.58596 18 8.99921 18H9.00071C9.41471 18 9.74996 17.6625 9.74921 17.2485L9.74246 13.5H14.0032C14.604 13.5 15.1695 13.266 15.5947 12.8407L17.0302 11.4052C17.3235 11.112 17.3235 10.638 17.0302 10.3448ZM3.46571 5.03025L2.56046 4.125L3.46571 3.21975C3.60746 3.078 3.79571 3 3.99671 3H14.625C14.832 3 15 3.168 15 3.375V4.875C15 5.082 14.832 5.25 14.625 5.25H3.99671C3.79646 5.25 3.60746 5.172 3.46571 5.03025ZM14.5342 11.7802C14.3925 11.922 14.2042 12 14.0032 12H3.37496C3.16796 12 2.99996 11.832 2.99996 11.625V10.125C2.99996 9.918 3.16796 9.75 3.37496 9.75H14.0032C14.2035 9.75 14.3925 9.828 14.5342 9.96975L15.4395 10.875L14.5342 11.7802Z'
														fill='black'
													/>
												</svg>
											}>
											{ekkLanguage.businessProfile.directSells}
											<NavLink to={`/direct-sells`} />
										</MenuItem>
									)} */}

									<MenuItem
										className={hasActive("/employees-list", pathname)}
										icon={<Employees />}>
										{ekkLanguage.letNav.myEmployeesMenuLabel}
										<NavLink to={`/employees-list`} />
									</MenuItem>

									{/* <MenuItem
										className={hasActive(
											"/business-profile-settings",
											pathname
										)}
										icon={<Settings />}>
										{ekkLanguage.letNav.businessProfileMenuLabel}
										<NavLink to={`/business-profile-settings`} />
									</MenuItem> */}
								</Menu>
							</SidebarContent>
							{/* <SidebarFooter>
								{pwaObject.pwaDownloadData !== undefined ? (
									<div
										className={`text-center cursor-pointer p-4  flex justify-center items-center`}
										onClick={() => onClick()}>
										<Download2 color='#F94B41' />
										<p
											className={`text-primary text-14 font-semibold pl-3 pt-1`}>
											Download EkkBaz App
										</p>
									</div>
								) : (
									<p
										className={`text-primary text-14 text-center font-semibold pl-3 pt-3 py-3`}>
										Installed{" "}
										{device?.device === "desktop"
											? device?.device?.brand
											: device?.device?.model}
									</p>
								)}
							</SidebarFooter> */}
						</ProSidebar>
					</nav>
				</div>
			</div>
		</>
	);
};

export default LeftSidebar;
