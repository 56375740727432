import { Button } from "components/global-components/common/button";
import { Input } from "components/global-components/common/Input";
import React, { useState } from "react";
import Box from "./Box";
// import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker-cssmodules.css";
import { toast } from "react-toastify";
import Auth from "services/Auth";
import { updateToken } from "config/SuperFetch";
import { setLocalUser } from "config/user";
import { loginFromSignUp } from "redux/container/authSlice";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setLanguage } from "config/language";
import { selectLan } from "redux/container/languageSlice";

export default function Registration({
	loading,
	phoneNo,
	country,
	countryCode,
}) {
	const [name, setName] = useState("");
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState();
	const [error, setError] = useState("");
	const [nameError, setNameError] = useState("");
	const [lengthError, setLengthError] = useState("");
	const history = useNavigate();
	let dispatch = useDispatch();
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const [passwordType, setPasswordType] = useState(false);
	const [confirmPasswordType, setConfirmPasswordType] = useState(false);
	const [message, setMessage] = useState("");

	const handlePassword = (passwordValue) => {
		const strengthChecks = {
			length: 0,
			hasUpperCase: false,
			hasLowerCase: false,
			hasDigit: false,
			// hasSpecialChar: false,
		};

		strengthChecks.length = passwordValue.length >= 8 ? true : false;
		strengthChecks.hasUpperCase = /[A-Z]+/.test(passwordValue);
		strengthChecks.hasLowerCase = /[a-z]+/.test(passwordValue);
		strengthChecks.hasDigit = /[0-9]+/.test(passwordValue);
		// strengthChecks.hasSpecialChar = /[^A-Za-z0-9]+/.test(passwordValue);
		let verifiedList = Object.values(strengthChecks).filter((value) => value);
		let strengthCheck = () => {
			if (verifiedList.length == 4) {
				return ekkLanguage.registration.strong;
			} else if (verifiedList.length >= 2) {
				return ekkLanguage.registration.medium;
			} else if (verifiedList.length >= 1) {
				return ekkLanguage.registration.weak;
			}
		};
		// let strength =
		// 	verifiedList.length == 3
		// 		? "Strong"
		// 		: verifiedList.length >= 4
		// 		? "Medium"
		// 		: "Weak";
		// console.log(verifiedList.length / 5);
		setPassword(passwordValue);
		// setProgress(`${verifiedList.length * 100}`);
		setMessage(strengthCheck());
	};

	const getActiveColor = (type) => {
		if (type === ekkLanguage.registration.strong) return "#8BC926";
		if (type === ekkLanguage.registration.medium) return "#FEBD01";
		return "#F94B41";
	};

	let profileCreateHandler = async () => {
		if (name.length > 3) {
			if (password.length > 3 && confirmPassword.length > 3) {
				if (!(password && confirmPassword !== password)) {
					let req_body = {
						phone_number: (countryCode + phoneNo).trim(),
						full_name: name,
						email: email,
						password: password,
						country: country,
						user_type: "Business User",
					};
					try {
						const response = await Auth.createNewUser(req_body);
						if (response.status === 200) {
							updateToken(response);
							setLocalUser(response?.data);
							dispatch(loginFromSignUp(response));
							history("/");
							toast.success(ekkLanguage.registration.successMessage);
							localStorage.setItem("auth", true);
						} else {
						}
					} catch (error) {
						console.log(error);
						toast.error(ekkLanguage.registration.errorMessage);
					}
				} else {
					setError(ekkLanguage.registration.passNotMatch);
				}
			} else {
				setLengthError(ekkLanguage.registration.passLengthValidLabel);
			}
		} else {
			setNameError(ekkLanguage.registration.validationLabel);
		}
	};

	return (
		<Box>
			<form
				onSubmit={(e) => {
					e.preventDefault();
					profileCreateHandler();
				}}>
				<Input
					onChange={(event) => setName(event.target.value)}
					type='text'
					label={ekkLanguage.registration.nameLabel}
					placeholder={ekkLanguage.registration.namePlaceholder}
					required
				/>
				{nameError && (
					<span className='text-primary mt-1'>{nameError}</span>
				)}
				<Input
					onChange={(event) => setEmail(event.target.value)}
					type='email'
					label={ekkLanguage.registration.emailLabel}
					placeholder={ekkLanguage.registration.emailPlaceholder}
				/>
				<Input
					onChange={(event) => {
						handlePassword(event.target.value);
						setPassword(event.target.value);
					}}
					type={passwordType ? "text" : "password"}
					label={ekkLanguage.registration.passwordLabel}
					placeholder={ekkLanguage.registration.passwordPlaceholder}
					required
					eyeActive={true}
					setEyeIcon={setPasswordType}
					eyeIcon={passwordType}
				/>
				<div>
					{/* {password.length !== 0 ? (
						<ProgressBar
							completed={progress}
							labelSize='0px'
							height='5px'
							bgColor={getActiveColor(message)}></ProgressBar>
					) : null} */}
					{password.length > 3 ? (
						<p
							className='message mt-[-13px]'
							style={{ color: getActiveColor(message) }}>
							{ekkLanguage.registration.passwordLength} {message}
						</p>
					) : null}
				</div>
				{lengthError && (
					<span className='text-primary mt-1'>{lengthError}</span>
				)}
				<Input
					onChange={(event) => setConfirmPassword(event.target.value)}
					type={confirmPasswordType ? "text" : "password"}
					label={ekkLanguage.registration.confirmPassLabel}
					placeholder={ekkLanguage.registration.confirmPassPlaceholder}
					required
					eyeActive={true}
					setEyeIcon={setConfirmPasswordType}
					eyeIcon={confirmPasswordType}
				/>
				{error && <span className='text-primary mt-1'>{error}</span>}

				<div className='flex justify-center py-4'>
					<Button
						title={ekkLanguage.registration.btnLabel}
						loading={loading}
						// onClick={profileCreateHandler}
						propsRef={(input) => input}
						type='submit'
					/>
				</div>
			</form>
		</Box>
	);
}
