import React, { useState } from "react";
import BannerContent from "./BannerContent";
import BannerSlider from "./BannerSlider";
import "./style.scss";
import { authBox_Block } from "Constants";

export default function BannerArea() {
	const [blockIndex, setBlockIndex] = useState(
		authBox_Block.PHONE_NUMBER_BLOCK
	);
	return (
		<div className='relative banner-area-wrapper'>
			<div className='pb-110 sm:pb-[100px] container m-auto  bg-F2F3F7'>
				<div className='flex md:space-x-0 lg:space-x-10 relative z-10'>
					<div className='w-5/12 hidden lg:block'>
						<BannerSlider />
					</div>
					<div
						className='lg:w-7/12 w-full banner-content lg:pb-[0] lg:h-auto '
						style={
							blockIndex === 5
								? { height: "calc(100vh + 250px)" }
								: { height: "100vh" }
						}>
						<BannerContent
							blockIndex={blockIndex}
							setBlockIndex={setBlockIndex}
						/>
					</div>
				</div>
			</div>
		</div>
	);
}
