import { createSlice } from "@reduxjs/toolkit";
import Orders from "services/Orders";
export const STATUSES = Object.freeze({
  IDLE: "idle",
  ERROR: "error",
  LOADING: "loading",
});
export const orderSlice = createSlice({
  name: "order",
  initialState: {
    data: [],
    singleOrder: {},
    status: STATUSES.IDLE,
    page: 1,
    limit: 15,
    deliveredItems: {},
    from: "orderList"
  },
  reducers: {
    initOrder(state, action) {
      state.data = action.payload;
      state.page = 2;
    },
    setOrder(state, action) {
      state.data = action.payload;
      state.page = state.page + 1;
    },

    singleOrder(state, action) {
      state.singleOrder = action.payload;
    },
    setStatus(state, action) {
      state.status = action.payload;
    },
    setFrom(state, action) {
      state.from = action.payload;
    },
    singleOrderUpdate(state, action) {
      state.singleOrder = action.payload;
    },
    setDeliveredItems: (state, action) => {
      state.deliveredItems = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setOrder, setStatus, setFrom, initOrder } = orderSlice.actions;
export const { actions, reducer } = orderSlice;
export default reducer;

//data fetch function
export function fetchOrder(value, page) {
  return async function fetchProductThunk(dispatch, getState) {
    dispatch(setStatus(STATUSES.LOADING));

    try {
      const res = await Orders.getOrdersList(
        page ?? 1,
        getState().order.limit,
        value
      );
      page
        ? dispatch(setOrder([...getState().order.data, ...res.data]))
        : dispatch(initOrder(res.data));

      dispatch(setStatus(STATUSES.IDLE));
      return { ...res };
    } catch (err) {
      console.log(err);
      dispatch(setStatus(STATUSES.ERROR));
    }
  };
}
//data fetch function
export function fetchOrderListBySorted(page, limit) {
  return async function fetchProductThunk(dispatch, getState) {
    dispatch(setStatus(STATUSES.LOADING));
    try {
      const res = await Orders.orderListBySorted(page, limit);
      //   const data = await res.json();
      dispatch(setOrder(res.data));
      dispatch(setStatus(STATUSES.IDLE));
      return { ...res };
    } catch (err) {
      console.log(err);
      dispatch(setStatus(STATUSES.ERROR));
    }
  };
}
//data fetch function
export function fetchOrderSingle(orderId) {
  return async function fetchProductThunk(dispatch, getState) {
    dispatch(setStatus(STATUSES.LOADING));
    try {
      const res = await Orders.singleOrder(orderId);
      if (res.status === 200) {
        dispatch(actions.singleOrder(res.data));
        dispatch(setStatus(STATUSES.IDLE));
        let orderItems = {
          productItems: res.data.order_items.map(
            (el) => el && { ...el, originalQuantity: el.quantity }
          ),
          subTotal: res.data.sub_total,
          taxTotal: res.data.tax_total,
          total: res.data.total,
          totalItems: res.data.total_items,
          totalPaid: res.data.total_paid,
          payment_term: res.data.payment_term,
          discount: res.data.discount ?? 0,
          creditFee: res.data?.payment_term?.creditFee ?? 0,
          deliveryFee: res.data.deliveryFee ?? 0,
        };
        dispatch(actions.setDeliveredItems(orderItems));

        return res;
      }
    } catch (err) {
      console.log(err);
      dispatch(setStatus(STATUSES.ERROR));
    }
  };
}
export function fetchOrderDetailsByOrderId(orderId) {
  return async function fetchProductThunk(dispatch, getState) {
    dispatch(setStatus(STATUSES.LOADING));
    try {
      const res = await Orders.orderSearchById(orderId);
      if (res.status === 200) {
        dispatch(actions.singleOrder(res.data));
        dispatch(setStatus(STATUSES.IDLE));
        let orderItems = {
          productItems: res.data.order_items.map(
            (el) => el && { ...el, originalQuantity: el.quantity }
          ),
          subTotal: res.data.sub_total,
          taxTotal: res.data.tax_total,
          total: res.data.total,
          totalItems: res.data.total_items,
          totalPaid: res.data.total_paid,
          payment_term: res.data.payment_term,
          discount: res.data.discount ?? 0,
          creditFee: res.data?.payment_term?.creditFee ?? 0,
          deliveryFee: res.data.deliveryFee ?? 0,
        };
        dispatch(actions.setDeliveredItems(orderItems));

        return res;
      }
    } catch (err) {
      console.log(err);
      dispatch(setStatus(STATUSES.ERROR));
    }
  };
}
export const searchOrder = (id) => async (dispatch, getState) => {
  dispatch(setStatus(STATUSES.LOADING));
  try {
    const response = await Orders.searchOrder(id);
    if (response?.status === 200) {
      dispatch(setOrder(response.data));
      dispatch(setStatus(STATUSES.IDLE));
    } else {
      dispatch(setOrder(response.data));
    }
    return response;
  } catch (error) {
    dispatch(setStatus(STATUSES.ERROR));
    return error.errorData;
  }
};

export const fetchOderCollectionAmount = (id) => async (dispatch, getState) => {
  try {
    let response = await Orders.orderAmountCollection(id);

    if (response?.status === 200) {
      dispatch(
        actions.singleOrderUpdate({
          ...getState().order?.singleOrder,
          collection: response.data,
        })
      );
      // dispatch(setOrder(response.data));
    }
    return response;
  } catch (error) {
    return error.errorData;
  }
};

//delivery options functionality
export const deliverItemDecrease = (id) => async (dispatch, getState) => {
  let deliveredItems = { ...getState().order.deliveredItems };
  let products = deliveredItems.productItems.map((el) =>
    el.catalog._id === id ? { ...el, quantity: el.quantity - 1 } : el
  );
  let price = deliveredItems.productItems.find(
    (el) => el.catalog._id === id
  ).unit_price;

  let productTax = deliveredItems.productItems.find(
    (el) => el.catalog._id === id
  ).catalog.tax;

  let productTotalTaxPerQty = (productTax / 100) * price;
  let productTotalTax = deliveredItems.taxTotal - productTotalTaxPerQty;

  let creditAmount;
  let paymentTermsDay = deliveredItems.payment_term
    ? deliveredItems.payment_term.duration
    : 0;
  if (paymentTermsDay === 0) {
    creditAmount = 0;
  } else if (paymentTermsDay === 1) {
    creditAmount = 0.001 * (deliveredItems.subTotal - price);
  } else if (paymentTermsDay === 3) {
    creditAmount = 0.005 * (deliveredItems.subTotal - price);
  } else if (paymentTermsDay === 7) {
    creditAmount = 0.01 * (deliveredItems.subTotal - price);
  }

  let updateDeliverProduct = {
    ...deliveredItems,
    productItems: products,
    subTotal: deliveredItems.subTotal - price,
    total:
      deliveredItems.subTotal +
      deliveredItems.deliveryFee -
      price +
      creditAmount +
      productTotalTax,
    creditFee: creditAmount,
    taxTotal: deliveredItems.taxTotal - productTotalTaxPerQty,
  };
  dispatch(actions.setDeliveredItems(updateDeliverProduct));
};

//delivery options functionality
export const deliverItemIncrease = (id) => async (dispatch, getState) => {
  let deliveredItems = getState().order.deliveredItems;
  let products = deliveredItems.productItems.map((el) =>
    el.catalog._id === id ? { ...el, quantity: el.quantity + 1 } : el
  );
  let price = deliveredItems.productItems.find(
    (el) => el.catalog._id === id
  ).unit_price;

  let productTax = deliveredItems.productItems.find(
    (el) => el.catalog._id === id
  ).catalog.tax;

  let productTotalTaxPerQty = (productTax / 100) * price;
  let productTotalTax = deliveredItems.taxTotal + productTotalTaxPerQty;

  let creditAmount;
  let paymentTermsDay = deliveredItems.payment_term
    ? deliveredItems.payment_term.duration
    : 0;
  if (paymentTermsDay === 0) {
    creditAmount = 0;
  } else if (paymentTermsDay === 1) {
    creditAmount = 0.001 * (deliveredItems.subTotal + price);
  } else if (paymentTermsDay === 3) {
    creditAmount = 0.005 * (deliveredItems.subTotal + price);
  } else if (paymentTermsDay === 7) {
    creditAmount = 0.01 * (deliveredItems.subTotal + price);
  }

  let updateDeliverProduct = {
    ...deliveredItems,
    productItems: products,
    subTotal: deliveredItems.subTotal + price,
    total:
      deliveredItems.subTotal +
      deliveredItems.deliveryFee +
      price +
      creditAmount +
      productTotalTax,
    creditFee: creditAmount,
    taxTotal: deliveredItems.taxTotal + productTotalTaxPerQty,
  };
  dispatch(actions.setDeliveredItems(updateDeliverProduct));
};

export const productRemove = (id) => async (dispatch, getState) => {
  let deliveredItems = getState().order.deliveredItems;
  let products = deliveredItems.productItems.filter(
    (el) => el.catalog._id !== id
  );
  let product = deliveredItems.productItems.find((el) => el.catalog._id === id);
  let price = product.unit_price * product.quantity;

  let creditAmount;
  let paymentTermsDay = deliveredItems.payment_term
    ? deliveredItems.payment_term.duration
    : 0;
  if (paymentTermsDay === 0) {
    creditAmount = 0;
  } else if (paymentTermsDay === 1) {
    creditAmount = 0.001 * (deliveredItems.subTotal - price);
  } else if (paymentTermsDay === 3) {
    creditAmount = 0.005 * (deliveredItems.subTotal - price);
  } else if (paymentTermsDay === 7) {
    creditAmount = 0.01 * (deliveredItems.subTotal - price);
  }

  let productTax = deliveredItems.productItems.find(
    (el) => el.catalog._id === id
  ).catalog.tax;

  let productTotalTaxPerQty = (productTax / 100) * price;
  let productTotalTax = deliveredItems.taxTotal + productTotalTaxPerQty;

  let updateDeliverProduct = {
    ...deliveredItems,
    productItems: products,
    subTotal: deliveredItems.subTotal - price,
    total:
      deliveredItems.subTotal +
      deliveredItems.deliveryFee -
      price +
      creditAmount +
      productTotalTax,
    creditFee: creditAmount,
    taxTotal: deliveredItems.taxTotal + productTotalTaxPerQty,
    totalItems: deliveredItems.totalItems - 1,
  };
  dispatch(actions.setDeliveredItems(updateDeliverProduct));
};
