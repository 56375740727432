import { useSelector } from "react-redux";
import SwiperCore, { Autoplay, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import SectionHeader from "./SectionHeader";
import "./style.scss";

export default function Brands() {
	const ekkLanguage = useSelector((state) => state.lan.lan);
	SwiperCore.use([Autoplay]);
	let slideData = [
		{
			image: "assets/images/brand1.png",
			name: "Fresh",
		},
		{
			image: "assets/images/brand2.png",
			name: "Ifad",
		},
		{
			image: "assets/images/brand3.png",
			name: "Dekko",
		},
		{
			image: "assets/images/brand4.png",
			name: "Shena",
		},
		{
			image: "assets/images/brand5.png",
			name: "Haque",
		},
		// {
		// 	image: "assets/images/brand5.png",
		// 	name: "Haque",
		// },
		// {
		// 	image: "assets/images/brand6.png",
		// 	name: "Haque",
		// },
		// {
		// 	image: "assets/images/brand7.png",
		// 	name: "Haque",
		// },
		// {
		// 	image: "assets/images/brand8.png",
		// 	name: "Haque",
		// },
		// {
		// 	image: "assets/images/brand9.png",
		// 	name: "Haque",
		// },
		// {
		// 	image: "assets/images/brand10.png",
		// 	name: "Haque",
		// },
		// {
		// 	image: "assets/images/brand11.png",
		// 	name: "Haque",
		// },
		// {
		// 	image: "assets/images/brand12.png",
		// 	name: "Haque",
		// },
		{
			image: "assets/images/brand13.jpg",
			name: "Haque",
		},
		{
			image: "assets/images/brand14.jpg",
			name: "Haque",
		},
		{
			image: "assets/images/brand16.jpg",
			name: "Haque",
		},
		{
			image: "assets/images/brand17.jpg",
			name: "Haque",
		},
		{
			image: "assets/images/brand18.png",
			name: "Haque",
		},
		{
			image: "assets/images/brand19.png",
			name: "Haque",
		},
		{
			image: "assets/images/brand20.png",
			name: "Haque",
		},
		{
			image: "assets/images/brand21.png",
			name: "Haque",
		},
		{
			image: "assets/images/brand22.png",
			name: "Haque",
		},
		{
			image: "assets/images/brand23.png",
			name: "Haque",
		},
	];
	return (
		<>
			<div className='container m-auto pb-[70px] sm:pb-[100px bg-F2F3F7 md:pt-[180px] sm:pt-[180px] lg:pt-[0] pt-[230px]'>
				<SectionHeader title={ekkLanguage.landing.brandHeader} />
				<div className='blog-wrapper'>
					<Swiper
						modules={[Pagination]}
						pagination={{ clickable: true }}
						autoplay={{ delay: 2500, disableOnInteraction: false }}
						breakpoints={{
							280: {
								slidesPerView: 2,
								spaceBetween: 20,
							},
							320: {
								slidesPerView: 3,
								spaceBetween: 20,
							},
							599: {
								slidesPerView: 4,
								spaceBetween: 25,
							},
							768: {
								slidesPerView: 5,
								spaceBetween: 40,
							},
						}}>
						{slideData.map((el, i) => {
							return (
								<SwiperSlide key={i}>
									<div className='pb-10'>
										<img
											src={el.image}
											alt='brand'
											className='m-auto'
										/>
									</div>
								</SwiperSlide>
							);
						})}
					</Swiper>
				</div>
			</div>
		</>
	);
}
