import { IonCheckbox, IonCol, IonLoading } from "@ionic/react";
import Tik from "assets/images/icon/Tik";
import RightArrow from "assets/images/icon/svg/RightArrow";

import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { setBankData } from "redux/container/addBankSlice";
import { setDriverData } from "redux/container/driverSlice";
import Business from "services/Business";
import Search from "../Common/Search";

export default function BankList({
	banks,
	empLoading,
	isForSelect,
	banksSearch,
	searchQ,
	setSearchQ,
	reachedEnd,
	setReachedEnd,
	hasMoreData,
	setHasMoreData,
}) {
	let { id, category } = useParams();
	const [checked, setChecked] = useState();
	const [selectedId, setSelectedId] = useState();
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const summaryType = useSelector((state) => state.productSummery.summaryType);
	const driver = useSelector((state) => state.driver.driverData);
	const bankData = useSelector((state) => state.bankSettings.bankData);
	const [assignLoading, setAssignLoading] = useState(false);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const setBank = async (bankId, bankName) => {
		setAssignLoading(false);
		let queryBuilder;
		if (category === "driver") {
			queryBuilder = {
				order_status: "assign_driver",
				driverId: bankId,
				driverName: bankName,
			};
		} else {
			queryBuilder = {
				order_status: "assign_order",
				collectorId: bankId,
				collectorName: bankName,
			};
		}

		let response = await Business.assignEmployeeToOrder(id, queryBuilder);
		if (response.status === 200) {
			setChecked(true);
			setAssignLoading(false);
			navigate(`/order-details/${id}/seller`, { replace: true });
			toast.success(`${category} assigned successfully`);
		} else {
			setAssignLoading(false);
		}
	};
	const handleNavigate = (id, name, bankId) => {
		const data = {
			name: name,
			id: id,
			bankId: bankId,
		};
		dispatch(setBankData(data));
		navigate("/add-bank-account", { replace: true });
	};
	return (
		<div className='pt-16 bg-F2F3F7 h-screen'>
			<IonLoading isOpen={assignLoading} />
			<div className='py-2 px-4'>
				<Search
					placeholder={ekkLanguage.addBankSettings.searchBank}
					search={searchQ}
					setSearch={setSearchQ}
					singleSearch={banksSearch}
					setReachedEnd={setReachedEnd}
					setHasMoreData={setHasMoreData}
				/>
			</div>
			{Array.isArray(banks) && banks.length ? (
				banks?.map((bank, i) => {
					return (
						<div
							className={`border-b-2 ${
								checked && bank.id === selectedId && "bg-F2F3F7"
							}`}
							onClick={() => {
								// setChecked(!checked);
								isForSelect
									? setSelectedId(bank.id)
									: handleNavigate(bank.user_id, bank.name, bank.id);
								isForSelect && setBank(bank.user_id, bank?.name);
							}}
							key={i}>
							<div className='flex justify-between items-center'>
								<div className='flex items-center'>
									{checked && bank.id === selectedId ? (
										<IonCheckbox
											color='success'
											className='rounede bank-checked'
											checked={checked && bank.id === selectedId}
										/>
									) : (
										<div className='flex w-9 h-9 m-4 justify-center items-center'>
											<h3 className='text-24 font-bold text-white'>
												<img src={bank?.image} />
											</h3>
										</div>
									)}

									<div className=''>
										<h6
											className={`font-semibold text-16 ${
												checked &&
												bank.id === selectedId &&
												"text-delivered"
											}`}>
											{bank?.name}
										</h6>
									</div>
								</div>
								{!isForSelect && (
									<div className='flex mr-6'>
										{banks.find(() => bank.id === bankData.bankId) ? (
											<Tik />
										) : (
											<></>
										)}
									</div>
								)}
							</div>
						</div>
					);
				})
			) : (
				<>
					<IonCol size='2'></IonCol>
					<IonCol size='8'>
						<div
							className='text-center flex items-center justify-center'
							style={{ height: "calc(100vh - 420px)" }}>
							<div>
								<img
									className='m-auto mb-[10px] mt-5'
									src='/assets/images/not-found-file.png'
									alt='emptyCard'
								/>
								<p className='text-16 font-normal text-222222 mb-[35px]'>
									{ekkLanguage.orderList.notFound}
								</p>
							</div>
						</div>
					</IonCol>
				</>
			)}
		</div>
	);
}
