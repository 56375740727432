import Login from "pages/Auth/Login";
import Landing from "pages/Landing/_index";
import BlogPostPage from "pages/Landing/BlogPostPage";
import SingleBlogPage from "pages/Landing/SingleBlogPage";
import BrandDetailsPage from "pages/MobilePage/BrandDetailsPage";
import BusinessLocationPage from "pages/MobilePage/BusinessLocationPage";
import BusinessLocationPickerPage from "pages/MobilePage/BusinessLocationPicker";
import BusinessNamePage from "pages/MobilePage/BusinessNamePage";
import BusinessProfilePage from "pages/MobilePage/BusinessProfilePage";
import BusinessTypePage from "pages/MobilePage/BusinessTypePage";
import ChangeLanguagePage from "pages/MobilePage/ChangeLanguagePage";
import ChangePasswordPage from "pages/MobilePage/ChangePasswordPage";
import ChangePasswordSuccessPage from "pages/MobilePage/ChangePasswordSuccessPage";
import CongratulationPage from "pages/MobilePage/CongratulationPage";
import ContactPage from "pages/MobilePage/ContactPage";
import CreateOrder from "pages/MobilePage/CreateOrder";
import EditBusinessPage from "pages/MobilePage/EditBusinessPage";
import EmployeesDetailsPage from "pages/MobilePage/EmployeesDetailsPage";
import EmployeesListPage from "pages/MobilePage/MyEmployeesListPage";
import Error from "pages/MobilePage/Error";
import Invoice from "pages/MobilePage/Invoice";
import LocationPage from "pages/MobilePage/LocationPage";
import Map from "pages/MobilePage/Map";
import MySupplierPage from "pages/MobilePage/MySupplierPage";
import MySuppliersDetailsPage from "pages/MobilePage/MySuppliersDetailsPage";
import OrderDetailsPage from "pages/MobilePage/OrderDetailsPage";
import OrderList from "pages/MobilePage/OrderList";
import PrivacyPolicy from "pages/MobilePage/PrivacyPolicy";
import ProductDetailsPage from "pages/MobilePage/ProductDetailsPage";
import ProfilePage from "pages/MobilePage/ProfilePage";
import ProfileVarifyOtpPage from "pages/MobilePage/ProfileVarifyOtpPage";
import ProfileVarifyPage from "pages/MobilePage/ProfileVarifyPage";
import ReceiptPage from "pages/MobilePage/ReceiptPage";
import RefundPolicy from "pages/MobilePage/RefundPolicy";
import SearchPage from "pages/MobilePage/SearchPage";
import SeeMoreProductPage from "pages/MobilePage/SeeMoreProductPage";
import SelectBusiness from "pages/MobilePage/SelectBusiness";
import SelectProductPage from "pages/MobilePage/SelectProductPage";
import SuppliersDetailsPage from "pages/MobilePage/SuppliersDetailsPage";
import TermsCondition from "pages/MobilePage/TermsCondition";
import VBNidPage from "pages/MobilePage/VBNid";
import VBPropertyPage from "pages/MobilePage/VBPropertyPage";
import VBRentalPage from "pages/MobilePage/VBRentalPage";
import VBTradLicensePage from "pages/MobilePage/VBTradLicensePage";
import { Route, Routes } from "react-router-dom";
import MyEmployeesListPage from "pages/MobilePage/MyEmployeesListPage";
import CreateEmployeePage from "pages/MobilePage/CreateEmployeePage";
import UpdateEmployeePage from "pages/MobilePage/UpdateEmployeesPage";
import ProductListPage from "pages/MobilePage/ProductListPage";
import BrandListPage from "pages/MobilePage/BrandListPage";
import CreateBrandPage from "pages/MobilePage/CreateBrandPage";
import CreateBrandSuccessPage from "pages/MobilePage/CreateBrandSuccessPage";
import CreateProductPage from "pages/MobilePage/CreateProductPage";
import UpdateBrandPage from "pages/MobilePage/UpdateBrandPage";
import UpdateBrandSuccessPage from "pages/MobilePage/UpdateBrandSuccessPage";
import MyBrandListPage from "pages/MobilePage/MyBrandListPage";
import DepartmentListPage from "pages/MobilePage/DepartmentListPage";
import UpdateProductPage from "pages/MobilePage/UpdateProductPage";
import CreatePackPage from "pages/MobilePage/CreatePackPage";
import CreateOfferPage from "pages/MobilePage/CreateOfferPage";
import PickLocationPage from "pages/MobilePage/PickLocationPage";
import CategoriesPage from "pages/MobilePage/CategoriesPage";
import SubCategoriesPage from "pages/MobilePage/SubCategoriesPage";
import UpdateOfferPage from "pages/MobilePage/UpdateOfferPage";
import PrivateOutlet from "./PrivateOutlet";
import CheckBusiness from "components/CheckBusiness";
import NIDVerificationPage from "pages/MobilePage/NIDVerificationPage";
import TradeLicensePage from "pages/MobilePage/TradeLicensePage";
import PropertyTypePage from "pages/MobilePage/PropertyTypePage";
import RentalInfoPage from "pages/MobilePage/RentalInfoPage";
import BusinessSuccess from "components/mobile-components/VerifyBusiness/BusinessSuccess";
import AllBrandListPage from "pages/MobilePage/AllBrandListPage";
import DashboardPage from "pages/MobilePage/DashboardPage";
import DashboardDetailsPage from "pages/MobilePage/DashboardDetailsPage";
import CustomerListPage from "pages/MobilePage/CustomerListPage";
import CustomerDetailsPage from "pages/MobilePage/CustomerDetailsPage";
import CreateNewCustomerPage from "pages/MobilePage/CreateNewCustomerPage";
import NotificationPage from "pages/MobilePage/NotificationPage";
import PaymentSettingsPage from "pages/MobilePage/PaymentSettingsPage";
import OrderDeliveryAmountSummaryPage from "pages/MobilePage/OrderDeliveryAmountSummaryPage";
import OrderDriverDeliverySummaryPage from "pages/MobilePage/OrderDriverDeliverySummaryPage";
import OrderDriverProductSummaryPage from "pages/MobilePage/OrderDriverProductSummaryPage";
import OrderDeliveryAmountDrilldownPage from "pages/MobilePage/OrderDeliveryAmountDrilldownPage";
import OrderDriverProductDrilldownPage from "pages/MobilePage/OrderDriverProductDrilldownPage";
import MyDriversListPage from "pages/MobilePage/MyDriverListPage";
import BuyerSummaryPage from "pages/MobilePage/BuyerSummaryPage";
import DelayInPaymentPage from "pages/MobilePage/DelayInPaymentPage";
import DelayInPaymentDrilldownPage from "pages/MobilePage/DelayInPaymentDrilldownPage";
import AutoLogoutPage from "pages/MobilePage/AutoLogoutPage";
import BuyerSummeryDrilldownPage from "pages/MobilePage/BuyerSummeryDrilldownPage";
import OrderProductSummaryDetailsPage from "pages/MobilePage/OrderProductSummaryDetailsPage";
import OrderProductSummaryPage from "pages/MobilePage/OrderProductSummaryPage";
import ProductCustomPricePage from "pages/MobilePage/ProductCustomPricePage";
import CustomPriceProductDetailsPage from "pages/MobilePage/CustomPriceProductDetailsPage";
import OtpSubmitPage from "pages/MobilePage/OtpSubmitPage";
import AddBankAccountPage from "pages/MobilePage/AddBankAccountPage";
import BankListPage from "pages/MobilePage/BankListPage";
import DefaultPaymentTermsPage from "pages/MobilePage/DefaultPaymentTermsPage";
import AddedBankListPage from "pages/MobilePage/AddedBankListPage";
import BlackListedPage from "pages/MobilePage/BlackListedPage";
import PaymentRecieptImagePage from "pages/MobilePage/PaymentRecieptImagePage";
import DirectSellsPage from "pages/MobilePage/DirectSellsPage";
import IndustryTypePage from "pages/MobilePage/IndustryTypePage";
import InvitedDistributorListPage from "pages/MobilePage/InvitedDistributorListPage";
import PhoneNumberCheck from "pages/LandingPageV2/PhoneNumberCheck";
import PasswordV2 from "pages/LandingPageV2/PasswordV2";
import RegistrationV2 from "pages/LandingPageV2/RegistrationV2";
import TokenV2 from "pages/LandingPageV2/TokenV2";
import ChangePasswordV2 from "pages/LandingPageV2/ChangePasswordV2";
import PaymentMethodSubscriptionPage from "pages/MobilePage/PaymentMethodSubscriptionPage";
import PaymentDetailsSubscriptionPage from "pages/MobilePage/PaymentDetailsSubscriptionPage";
import CardAddPage from "pages/MobilePage/CardAddPage";
import PaymentSuccessPage from "pages/MobilePage/PaymentSuccessPage";
import CardAddOnRegiPage from "pages/MobilePage/CardAddOnRegiPage";
import UpgradePlanPage from "pages/MobilePage/UpgradePlanPage";

function Mobile() {
	return (
		<Routes>
			<Route path='/*' element={<PrivateOutlet />}>
				<Route path='dashboard' element={<DashboardPage />} />
				<Route path='order-list' element={<OrderList />} />
				<Route
					path='product-details/:type/:id'
					element={<ProductDetailsPage />}
				/>
				<Route
					path='custom-product-details/:type/:id/:relatedBusinessId'
					element={<CustomPriceProductDetailsPage />}
				/>
				<Route
					path='create-distributor/:to'
					element={<CreateNewCustomerPage />}
				/>
				<Route path='product-list' element={<ProductListPage />} />
				<Route path='distributor-list' element={<CustomerListPage />} />
				<Route
					path='invited-distributor'
					element={<InvitedDistributorListPage />}
				/>

				<Route path='direct-sells' element={<DirectSellsPage />} />
				<Route path='create-order' element={<CreateOrder />} />
				<Route path='location' element={<Map />} />
				<Route path='success-order/:id' element={<CongratulationPage />} />
				<Route
					path='distributor-details/:id'
					element={<CustomerDetailsPage />}
				/>
				<Route
					path='select-product/:type'
					element={<SelectProductPage />}
				/>
				<Route path='order-details/:id' element={<OrderDetailsPage />} />
				<Route path='brand-details/:id' element={<BrandDetailsPage />} />
				<Route
					path='supplier-details/:id'
					element={<SuppliersDetailsPage />}
				/>
				<Route
					path='mysupplier-details/:id'
					element={<MySuppliersDetailsPage />}
				/>
				<Route path='business-name' element={<BusinessNamePage />} />
				<Route path='industry-type' element={<IndustryTypePage />} />
				<Route path='select-business' element={<SelectBusiness />} />
				<Route path='business-type' element={<BusinessTypePage />} />
				<Route path='nid-verification' element={<NIDVerificationPage />} />
				<Route
					path='trade-license-verification'
					element={<TradeLicensePage />}
				/>
				<Route path='property-type' element={<PropertyTypePage />} />
				<Route path='rental-info' element={<RentalInfoPage />} />
				<Route path='business-success' element={<BusinessSuccess />} />
				<Route
					path='business-location'
					element={<BusinessLocationPage />}
				/>
				<Route path='profile' element={<ProfilePage />} />
				<Route path='contact' element={<ContactPage />} />
				<Route path='change-password' element={<ChangePasswordPage />} />
				<Route
					path='changed-password-success'
					element={<ChangePasswordSuccessPage />}
				/>
				<Route path='products/:category' element={<SeeMoreProductPage />} />
				<Route path='otp-send' element={<ProfileVarifyPage />} />
				<Route path='otp-submit' element={<ProfileVarifyOtpPage />} />
				<Route path='change-language' element={<ChangeLanguagePage />} />
				<Route path='location-enabled' element={<LocationPage />} />
				<Route path='search-page/:tarm' element={<SearchPage />} />
				<Route path='mysupplier' element={<MySupplierPage />} />
				<Route
					path='business-profile-settings'
					element={<BusinessProfilePage />}
				/>
				<Route path='pick-location/:id' element={<PickLocationPage />} />
				<Route path='business-edit' element={<EditBusinessPage />} />
				<Route path='vbtrade-license' element={<VBTradLicensePage />} />
				<Route path='vbnid' element={<VBNidPage />} />
				<Route path='vbproperty' element={<VBPropertyPage />} />
				<Route path='vbrental-document' element={<VBRentalPage />} />
				<Route path='invoice/:id' element={<Invoice />} />
				<Route path='receipt/:id' element={<ReceiptPage />} />
				<Route
					path='search-location'
					element={<BusinessLocationPickerPage />}
				/>
				<Route
					path='employes-list/:category/:id'
					element={<EmployeesListPage />}
				/>
				<Route path='employees-list' element={<MyEmployeesListPage />} />
				<Route
					path='employee-details/:id'
					element={<EmployeesDetailsPage />}
				/>
				<Route path='create-employee' element={<CreateEmployeePage />} />
				<Route
					path='update-employee/:id'
					element={<UpdateEmployeePage />}
				/>
				<Route path='brand-list' element={<BrandListPage />} />
				<Route
					path='product-list-custom-price'
					element={<ProductCustomPricePage />}
				/>
				<Route path='my-brand' element={<MyBrandListPage />} />
				<Route path='all-brands' element={<AllBrandListPage />} />
				<Route path='categories' element={<CategoriesPage />} />
				<Route path='sub-categories' element={<SubCategoriesPage />} />
				<Route path='create-brand' element={<CreateBrandPage />} />
				<Route path='update-brand/:id' element={<UpdateBrandPage />} />
				<Route path='create-product' element={<CreateProductPage />} />
				<Route path='create-pack' element={<CreatePackPage />} />
				<Route path='create-offer' element={<CreateOfferPage />} />
				<Route path='update-product/:id' element={<UpdateProductPage />} />
				<Route path='update-offer/:id' element={<UpdateOfferPage />} />
				<Route
					path='brand-create-success'
					element={<CreateBrandSuccessPage />}
				/>
				<Route
					path='payment-terms-settings'
					element={<PaymentSettingsPage />}
				/>
				<Route
					path='brand-update-success/:id'
					element={<UpdateBrandSuccessPage />}
				/>
				<Route path='department-list' element={<DepartmentListPage />} />
				<Route path='notifications' element={<NotificationPage />} />
				<Route
					path='dashboard-details/:date/:category/:cateList'
					element={<DashboardDetailsPage />}
				/>
				<Route
					path='order-product-summary'
					element={<OrderProductSummaryPage />}
				/>
				<Route
					path='order-product-summary-details/:product'
					element={<OrderProductSummaryDetailsPage />}
				/>
				<Route
					path='order-driver-product-summary'
					element={<OrderDriverProductSummaryPage />}
				/>
				<Route
					path='order-driver-product-summary/:details'
					element={<OrderDriverProductDrilldownPage />}
				/>
				<Route
					path='order-driver-delivery-summary'
					element={<OrderDriverDeliverySummaryPage />}
				/>
				<Route
					path='order-delivery-amount-summary'
					element={<OrderDeliveryAmountSummaryPage />}
				/>
				<Route
					path='order-delivery-amount-summary/:details'
					element={<OrderDeliveryAmountDrilldownPage />}
				/>
				<Route path='buyer-summary' element={<BuyerSummaryPage />} />
				<Route
					path='buyer-summary/:details'
					element={<BuyerSummeryDrilldownPage />}
				/>
				<Route path='delay-in-payment' element={<DelayInPaymentPage />} />
				<Route
					path='delay-in-payment/:details'
					element={<DelayInPaymentDrilldownPage />}
				/>
				<Route path='add-bank-account' element={<AddBankAccountPage />} />
				<Route path='bank-list' element={<BankListPage />} />
				<Route path='added-bank-list' element={<AddedBankListPage />} />
				<Route
					path='driver-list'
					element={<MyDriversListPage></MyDriversListPage>}
				/>
				<Route path='business-delete-otp' element={<OtpSubmitPage />} />
				<Route
					path='distributor-default-payment-term/:brandBusinessId/:relatedBusinessId/:relationId'
					element={<DefaultPaymentTermsPage />}
				/>
				<Route
					path='fs-payment-reciept'
					element={<PaymentRecieptImagePage />}
				/>
				<Route path='select-upgrade-plan' element={<UpgradePlanPage />} />
				<Route
					path='select-payment-method-for-subscription'
					element={<PaymentMethodSubscriptionPage />}
				/>
				<Route
					path='select-payment-method-for-subscription/:methodType'
					element={<PaymentDetailsSubscriptionPage />}
				/>
				<Route path='payment-success' element={<PaymentSuccessPage />} />
				<Route path='card-add' element={<CardAddPage />} />
				<Route path='card-add/:from' element={<CardAddPage />} />
				<Route
					path='card-add-on-registration'
					element={<CardAddOnRegiPage />}
				/>
				<Route path='*' element={<Error />} />
			</Route>

			<Route path='/login' element={<Login />} />
			<Route path='/' element={<PhoneNumberCheck />} />
			<Route path='/password' element={<PasswordV2 />} />
			<Route path='/registration' element={<RegistrationV2 />} />
			<Route path='/submit-token' element={<TokenV2 />} />
			<Route path='/change-password' element={<ChangePasswordV2 />} />
			<Route path='/business-check' element={<CheckBusiness />} />
			<Route path='/post/en/:id' element={<SingleBlogPage />} />
			<Route path='/post/en' element={<BlogPostPage />} />
			<Route path='/refund-policy' element={<RefundPolicy />} />
			<Route path='/privacy-policy' element={<PrivacyPolicy />} />
			<Route path='/terms-conditions' element={<TermsCondition />} />
			<Route path='/auto-log-out' element={<AutoLogoutPage />} />
			<Route path='/black-listed' element={<BlackListedPage />} />
		</Routes>
	);
}

export default Mobile;
