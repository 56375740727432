import {
	IonApp,
	IonContent,
	IonInfiniteScroll,
	IonInfiniteScrollContent,
	IonPage,
} from "@ionic/react";
import BackButtonTitle from "components/mobile-components/Common/BackButtonTitle";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import Product from "services/Product";
import ProductCustomPrice from "components/mobile-components/ProductCustomPrice";
import { setProductList } from "redux/container/productSlice";

export default function ProductCustomPricePage() {
	const productList = useSelector((state) => state.product.productList);
	const [menuCollapse, setMenuCollapse] = useState(true);
	const [pageCount, setPageCount] = useState(2);
	const [searchQ, setSearchQ] = useState("");
	const [filteredSelections, setFilteredSelections] = useState("all");
	const [tempSearchParams, setTempSearchParams] = useState("");
	const dispatch = useDispatch();
	const [selectedBrand, setSelectedBrand] = useState("");
	const [loader, setLoader] = useState(false);
	const [hasMoreData, setHasMoreData] = useState(true);
	const [reachedEnd, setReachedEnd] = useState(false);

	const getProductList = async () => {
		setReachedEnd(false);
		setHasMoreData(true);
		setLoader(true);
		setPageCount(2);
		if (searchQ) {
			let response = await Product.singlePublishProductSearch(
				searchQ,
				filteredSelections,
				selectedBrand
			);

			if (response.status === 200) {
				setLoader(false);
				dispatch(setProductList(response.results));
			} else {
				setLoader(false);
				dispatch(setProductList([]));
			}
		} else {
			let response = await Product.publishProductList(
				filteredSelections,
				selectedBrand
			);
			if (response.status === 200) {
				setLoader(false);
				dispatch(setProductList(response.results));
			} else {
				setLoader(false);
				dispatch(setProductList([]));
			}
		}
	};
	const getProductListWithBrand = async (bId) => {
		setReachedEnd(false);
		setHasMoreData(true);
		setLoader(true);
		setPageCount(2);
		if (searchQ) {
			let response = await Product.singlePublishProductSearch(
				searchQ,
				filteredSelections,
				bId
			);

			if (response.status === 200) {
				setLoader(false);
				dispatch(setProductList(response.results));
			} else {
				setLoader(false);
				dispatch(setProductList([]));
			}
		} else {
			let response = await Product.publishProductList(
				filteredSelections,
				bId
			);
			if (response.status === 200) {
				setLoader(false);
				dispatch(setProductList(response.results));
			} else {
				setLoader(false);
				dispatch(setProductList([]));
			}
		}
	};
	const getOfferList = async () => {
		setReachedEnd(false);
		setHasMoreData(true);
		setLoader(true);
		setPageCount(2);
		if (searchQ) {
			let response = await await Product.singleOfferSearch(searchQ);
			if (response.status === 200) {
				setLoader(false);
				dispatch(setProductList(response.results));
			}
		} else {
			let response = await Product.getOfferList();
			if (response.status === 200) {
				setLoader(false);
				dispatch(setProductList(response.results));
			}
		}
	};

	useEffect(() => {
		if (searchQ === "") {
			if (filteredSelections === "offer") {
				getOfferList();
			} else {
				getProductList();
			}
		}
	}, [searchQ]);

	useEffect(() => {
		if (filteredSelections === "offer") {
			getOfferList();
		} else {
			getProductList();
		}
	}, [filteredSelections]);

	let singleProductSearch = async () => {
		setReachedEnd(false);
		setHasMoreData(true);
		setTempSearchParams(searchQ);
		setPageCount(2);
		setLoader(true);
		let response =
			filteredSelections !== "offer"
				? await Product.singlePublishProductSearch(
						searchQ,
						filteredSelections,
						selectedBrand
				  )
				: await Product.singleOfferSearch(searchQ);
		if (response.status === 200) {
			setLoader(false);
			dispatch(setProductList(response.results));
		} else {
			setLoader(false);
			dispatch(setProductList([]));
		}
	};

	let lodeMoreData = async () => {
		if (hasMoreData) {
			if (searchQ) {
				const response =
					filteredSelections !== "offer"
						? await Product.singlePublishProductSearch(
								searchQ,
								filteredSelections,
								selectedBrand,
								pageCount
						  )
						: await Product.singleOfferSearch(searchQ, pageCount);
				if (response.status === 200) {
					setPageCount((prev) => prev + 1);
					dispatch(setProductList([...productList, ...response.results]));
				} else {
					setHasMoreData(false);
				}
			} else {
				const response =
					filteredSelections !== "offer"
						? await Product.publishProductList(
								filteredSelections,
								selectedBrand,
								pageCount
						  )
						: await Product.getOfferList(pageCount);
				if (response.status === 200) {
					setPageCount((prev) => prev + 1);
					dispatch(setProductList([...productList, ...response.results]));
				} else {
					setHasMoreData(false);
				}
			}
		}
	};

	const menuIconClick = () => {
		setMenuCollapse(!menuCollapse);
	};
	const ekkLanguage = useSelector((state) => state.lan.lan);

	let filterHandler = () => {
		setSelectedBrand("");
		getProductListWithBrand("");
	};

	return (
		<IonApp>
			<IonPage>
				<IonContent>
					<BackButtonTitle
						title={ekkLanguage.productList.pageTitle}
						menuCollapse={menuCollapse}
						menuIconClick={menuIconClick}
						// from='productList'
					/>

					<section className='pt-14 px-4'>
						<ProductCustomPrice
							searchQ={searchQ}
							setSearchQ={setSearchQ}
							ekkLanguage={ekkLanguage}
							productList={productList}
							loader={loader}
							singleProductSearch={singleProductSearch}
							setFilteredSelections={setFilteredSelections}
							filterHandler={filterHandler}
							selectedFilter={selectedBrand}
							tempSearchParams={tempSearchParams}
							setTempSearchParams={setTempSearchParams}
							setReachedEnd={setReachedEnd}
							setHasMoreData={setHasMoreData}
						/>
					</section>

					<IonInfiniteScroll
						onIonInfinite={(ev) => {
							if (reachedEnd) {
								ev.target.complete();
								return;
							}
							lodeMoreData();
							setTimeout(() => {
								ev.target.complete();
								if (!hasMoreData) {
									setReachedEnd(true);
								}
							}, 500);
						}}>
						<IonInfiniteScrollContent
							loadingText={ekkLanguage.orderList.loader}
							loadingSpinner={
								hasMoreData ? "bubbles" : null
							}></IonInfiniteScrollContent>
					</IonInfiniteScroll>
				</IonContent>
			</IonPage>
		</IonApp>
	);
}
