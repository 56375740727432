import { baseUrl } from "config/apiUrl";
import SuperFetch from "../config/SuperFetch";

const Business = {
	getBusiness: (id) => {
		return SuperFetch(`${baseUrl.bizNpdt}/ekkbaz/business_details/${id}/`);
	},
	customerCheck: (phoneNo) => {
		return SuperFetch(
			`${baseUrl.bizNpdt}/brand/distributor_check/${phoneNo}/`
		);
	},
	getRelationList: () => {
		return SuperFetch(`${baseUrl.bizNpdt}/brand/relation_types/`);
	},
	getCustomers: (sellerId, long, lati, page = 1, limit = 30) => {
		return SuperFetch(
			`${baseUrl.bizNpdt}/ekkbaz/customers/?longitude=${long}&latitude=${lati}&page=${page}&limit=${limit}&seller_id=${sellerId}`
		);
	},
	getOwnCustomer: (sellerId, long, lati, page = 1, limit = 30) => {
		return SuperFetch(
			`${baseUrl.bizNpdt}/ekkbaz/business_customer/${sellerId}/?longitude=${long}&latitude=${lati}&page=${page}&limit=${limit}`
		);
	},
	getOwnCustomerSearch: (
		sellerId,
		long,
		lati,
		page = 1,
		limit = 30,
		query
	) => {
		return SuperFetch(
			`${baseUrl.bizNpdt}/ekkbaz/business_customer/${sellerId}/?longitude=${long}&latitude=${lati}&page=${page}&limit=${limit}&q=${query}`
		);
	},
	getAllCustomer: (sellerId, long, lati, page = 1, limit = 30) => {
		return SuperFetch(
			`${baseUrl.bizNpdt}/ekkbaz/business_customer_all/${sellerId}/?longitude=${long}&latitude=${lati}&page=${page}&limit=${limit}`
		);
	},
	getAllCustomerSearch: (
		sellerId,
		long,
		lati,
		page = 1,
		limit = 30,
		query
	) => {
		return SuperFetch(
			`${baseUrl.bizNpdt}/ekkbaz/business_customer_all/${sellerId}/?longitude=${long}&latitude=${lati}&page=${page}&limit=${limit}&q=${query}`
		);
	},
	// getBranchList: (page = 1, limit = "50") => {
	// 	return SuperFetch(
	// 		`${baseUrl.business}/v4/businesses/branches?page=${page}&limit=${limit}`
	// 	);
	// },
	createNewBusiness: (query) => {
		return SuperFetch(`${baseUrl.bizNpdt}/brand/distributor_create/`, {
			method: "POST",
			data: query,
		});
	},
	getBranchList: (page = 1, limit = "50") => {
		return SuperFetch(
			`${baseUrl.business}/v4/businesses/branches?page=${page}&limit=${limit}`
		);
	},
	getPaymentData: (id) => {
		return SuperFetch(
			`${baseUrl.business}/v4/businesses/bank-info/details/${id}`
		);
	},
	updateUserBusiness: (id, query) => {
		return SuperFetch(`${baseUrl.bizNpdt}/ekkbaz/business_update/${id}/`, {
			method: "PUT",
			data: query,
		});
	},
	getEmployesList: (id, page = 1, limit = 100) => {
		return SuperFetch(
			`${baseUrl.bizNpdt}/ekkbaz/business/employees/${id}/?page=${page}&limit=${limit}`
		);
	},
	getEmployeeSearch: (query, businessId, page = 1, limit = 100) => {
		return SuperFetch(
			`${baseUrl.bizNpdt}/ekkbaz/business/employee/search/${businessId}/?page=${page}&limit=${limit}&q=${query}`
		);
	},

	assignEmployeeToOrder: (id, query) => {
		return SuperFetch(`${baseUrl.order}/v4/orders/update/${id}`, {
			method: "PUT",
			data: query,
		});
	},
	getEmployees: (id) => {
		return SuperFetch(
			`${baseUrl.bizNpdt}/ekkbaz/business/employee/details/?emp_id=${id}`
		);
	},
	createBusiness: (query) => {
		return SuperFetch(
			`${baseUrl.business}/v4/business-employees/business/self`,
			{
				method: "POST",
				data: query,
			}
		);
	},
	updateEmployee: (id, query) => {
		return SuperFetch(
			`${baseUrl.bizNpdt}/ekkbaz/business/employee/update/${id}/`,
			{
				method: "PUT",
				data: query,
			}
		);
	},
	deleteEmployee: (id) => {
		return SuperFetch(
			`${baseUrl.bizNpdt}/ekkbaz/business/employee/delete/${id}/`,
			{
				method: "DELETE",
			}
		);
	},
	// updateBusinessLocation: (id, query) => {
	// 	return SuperFetch(`${baseUrl.bizNpdt}/seller/business/edit/${id}/`, {
	// 		method: "PUT",
	// 		data: query,
	// 	});
	// },
	updateBusinessLocation: (id, query) => {
		return SuperFetch(`${baseUrl.bizNpdt}/ekkbaz/business_update/${id}/`, {
			method: "PUT",
			data: query,
		});
	},
	getBusinessType: (page = 1, limit = 100) => {
		return SuperFetch(
			`${baseUrl.bizNpdt}/ekkbaz/business_types/?page=${page}&limit=${limit}`
		);
	},
	createUserBusiness: (query) => {
		return SuperFetch(`${baseUrl.bizNpdt}/ekkbaz/business/create/`, {
			method: "POST",
			data: query,
		});
	},
	checkBusiness: (id) => {
		return SuperFetch(`${baseUrl.bizNpdt}/ekkbaz/business_check/${id}/`);
	},
	getEmployeePosition: (page = 1, limit = 20) => {
		return SuperFetch(
			`${baseUrl.bizNpdt}/ekkbaz/employee_roles/?page=${page}&limit=${limit}`
		);
	},
	createEmployee: (query) => {
		return SuperFetch(`${baseUrl.bizNpdt}/ekkbaz/business/employee/create/`, {
			method: "POST",
			data: query,
		});
	},
	getKYC: (id) => {
		return SuperFetch(`${baseUrl.bizNpdt}/ekkbaz/kyc/details/${id}/`);
	},
	createKYC: (query) => {
		return SuperFetch(`${baseUrl.bizNpdt}/ekkbaz/kyc/create/`, {
			method: "POST",
			data: query,
		});
	},
	updateKYC: (id, query) => {
		return SuperFetch(`${baseUrl.bizNpdt}/ekkbaz/kyc/update/${id}/`, {
			method: "PUT",
			data: query,
		});
	},
	businessDeleteOtp: () => {
		return SuperFetch(`${baseUrl.bizNpdt}/ekkbaz/business_delete_otp_send/`);
	},
	businessDelete: (query) => {
		return SuperFetch(`${baseUrl.bizNpdt}/ekkbaz/delete_business/`, {
			method: "POST",
			data: query,
		});
	},
	getBankList: () => {
		return SuperFetch(`${baseUrl.pay}/ekkbaz/seller_payment_gateways/`);
	},
	getBusinessDetails: (id) => {
		return SuperFetch(`${baseUrl.bizNpdt}/ekkadmin/business/details/${id}`);
	},
	getBusinessDirectSells: (id, query) => {
		return SuperFetch(
			`${baseUrl.bizNpdt}/brand/neraby_seller_enable/${id}/`,
			{
				method: "PUT",
				data: query,
			}
		);
	},
	getIndustryList: () => {
		return SuperFetch(`${baseUrl.bizNpdt}/ekkbaz/primary_industry/`);
	},
};
export default Business;
