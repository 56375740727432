import {
	IonApp,
	IonContent,
	IonInfiniteScroll,
	IonInfiniteScrollContent,
	IonPage,
} from "@ionic/react";
import BrandDetails from "components/mobile-components/BrandDetails/BrandDetails";
import BrandDetailsBottomSheet from "components/mobile-components/BrandDetails/BrandDetailsBottomSheet";
import BackButtonTitle from "components/mobile-components/Common/BackButtonTitle";
import Banner from "components/mobile-components/ProductDetails/Banner";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Brand from "services/Brand";

export default function BrandDetailsPage() {
	const ekkLanguage = useSelector((state) => state.lan.lan);
	let { id } = useParams();
	const [brandProducts, setBrandProducts] = useState([]);
	const [brandInfo, setBrandInfo] = useState([]);
	const [pageCount, setPageCount] = useState(2);

	useEffect(() => {
		const getBrandInfo = async () => {
			let response = await Brand.brandDetails(id);
			if (response.status === 200) {
				setBrandInfo(response.results);
			}
		};
		const getBrandProducts = async () => {
			let response = await Brand.brandProducts(id);
			if (response.status === 200) {
				setBrandProducts(response.results);
			}
		};
		getBrandInfo();
		getBrandProducts();
	}, [id]);
	let settings = {
		nameShow: false,
		titleShow: true,
		skuShow: false,
		priceShow: true,
		oldPriceShow: false,
		distShow: false,
		profitShow: true,
		sellPriceShow: false,
		supplierNameShow: false,
		link: "product-details",
	};
	let lodeMoreData = async () => {
		const response = await Brand.brandProducts(id, pageCount);
		if (response.status === 200) {
			setPageCount((prev) => prev + 1);
			setBrandProducts([...brandProducts, ...response.results]);
		}
	};
	let slideData = brandInfo?.images;

	return (
		<IonApp>
			<IonPage>
				<IonContent>
					<BackButtonTitle
						title={ekkLanguage.brandDetails.pageTitle}
						from='brandDetails'
					/>
					<div className='pt-14'>
						<Banner brandInfo={brandInfo} slideData={slideData} />
						<BrandDetails
							brandInfo={brandInfo}
							product={brandProducts}
							settings={settings}
						/>
					</div>
					<div>
						<IonInfiniteScroll
							onIonInfinite={(ev) => {
								lodeMoreData();
								setTimeout(() => ev.target.complete(), 1000);
							}}>
							<IonInfiniteScrollContent
								className='pb-24'
								loadingText={ekkLanguage.orderList.loader}
								loadingSpinner='bubbles'></IonInfiniteScrollContent>
						</IonInfiniteScroll>
					</div>
					<BrandDetailsBottomSheet brandInfo={brandInfo} />
				</IonContent>
			</IonPage>
		</IonApp>
	);
}
