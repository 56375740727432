import { IonButton, IonInput, IonItem, IonLabel, IonModal } from "@ionic/react";
import CancelPopUp from "assets/images/icon/svg/CancelPopUp";
import moment from "moment";
import React, { useRef, useState } from "react";
import Sheet from "react-modal-sheet";
import { useDispatch, useSelector } from "react-redux";
import Modal from "react-modal";
import "react-responsive-modal/styles.css";
import { useNavigate } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import { fetchOrderSingle } from "redux/container/orderSlice";
import { setCustomerProductDetails } from "redux/container/userSlice";
import Orders from "services/Orders";
import Card from "../Common/Card";
import TextInputWithIcon from "../Common/TextInputWithIcon";
import { toast } from "react-toastify";
import Cross from "assets/images/icon/svg/Cross";
import Customer from "services/Customer";

const customModalStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: "0px",
    width: "300px",
    transition: ".4s all ease",
    borderRadius: 12,
  },
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    transition: ".4s all ease",
    backgroundColor: "rgba(0,0,0,.5)",
  },
};

export default function BottomSheet({ id, item, setProduct }) {
  const ref = React.useRef();
  const relationDetails = useSelector(
    (state) => state.distributor.relationDetails
  );
  const [sellingPriceEmpty, setSellingPriceEmpty] = useState("");
  const [sellingPrice, setSellingPrice] = useState(item?.procure_price);
  const isOpenBottomSheet = useSelector(
    (state) => state.user.customerProductDetails
  );
  const ekkLanguage = useSelector((state) => state.lan.lan);
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenDiscount, setIsOpenDiscount] = useState(false);
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();

  const onOpenModalDiscount = () => {
    setIsOpenDiscount(true);
  };
  const onCloseModalDiscount = () => setIsOpenDiscount(false);

  const onOpenModal = () => {
    setIsOpen(true);
  };

  const onCloseModal = () => {
    setIsOpen(false);
  };

  let productDeleteHandler = async () => {
    let res = await Customer.deleteCustomePriceProduct(
      id,
      relationDetails?.related_business_id
    );
    if (res.status === 200) {
      setIsOpen(false);
      navigate(`/distributor-details/${relationDetails?.id}`);
    }
  };

  const productUpdateHandler = async () => {
    setLoader(true);
    if (sellingPrice > 0) {
      const data = {
        catalog: item?.cat_id,
        initiator_business: relationDetails?.initiator_business_id,
        related_business: relationDetails?.related_business_id,
        price: Number(sellingPrice),
      };
      const response = Customer.updateCustomPrice(id, data);
      if (response.status === 200) {
      }
      setProduct({
        ...item,
        procure_price: sellingPrice,
      });

      toast.success("Price updated successfully!");
      setLoader(false);
      onCloseModalDiscount();
    } else {
      setSellingPriceEmpty(ekkLanguage.customerList.priceEmptyError);
    }
  };
  return (
    <div>
      <Sheet
        className="bottom-sheet"
        initialSnap={1}
        ref={ref}
        isOpen={isOpenBottomSheet}
        snapPoints={[160, 160, 100, 0]}
        onClose={() => dispatch(setCustomerProductDetails(false))}
      >
        <Sheet.Container>
          <Sheet.Header />
          <Sheet.Content>
            <IonItem
              button
              onClick={() => {
                dispatch(setCustomerProductDetails(false));
                onOpenModalDiscount();
              }}
              lines="none"
            >
              <span className="py-[5px]">
                <img
                  className="h-10 w-10"
                  src="/assets/images/edit_price.png"
                  alt="driver"
                />
              </span>
              <p className="text-14 font-normal text-black-1000 pl-2">
                {ekkLanguage.customerList.updatePriceTitle}
              </p>
            </IonItem>
            <IonItem
              button
              onClick={() => {
                dispatch(setCustomerProductDetails(false));
                onOpenModal();
              }}
              lines="none"
            >
              <span className="py-[5px]">
                <img
                  className="h-10 w-10"
                  src="/assets/images/Icon/delete.png"
                  alt="driver"
                />
              </span>
              <p className="text-14 font-normal text-black-1000 pl-2">
                {ekkLanguage.customerList.deletePriceTitle}
              </p>
            </IonItem>
          </Sheet.Content>
        </Sheet.Container>

        <Sheet.Backdrop
          onClick={() => dispatch(setCustomerProductDetails(false))}
        />
      </Sheet>
      {/* Product Update Handle  */}
      <Modal
        style={customModalStyles}
        closeTimeoutMS={200}
        isOpen={isOpenDiscount}
        onRequestClose={onCloseModalDiscount}
        ariaHideApp={false}
        classNames="bottom-sheet-modal"
      >
        <div className="p-5">
          <div className="border-b-2 mb-4 flex  justify-between">
            <h4 className="text-14 text-222222 font-bold pb-[10px]">
              {ekkLanguage.customerList.modalTitle}
            </h4>
            <span onClick={onCloseModalDiscount} className="cursor-pointer">
              <Cross />
            </span>
          </div>
          <h6 className="text-16 font-bold pb-[10px]">{item?.title}</h6>
          <TextInputWithIcon
            type="number"
            title={ekkLanguage.createProduct.sellingPrice}
            inputText={sellingPrice}
            setInputText={setSellingPrice}
            placeholder={ekkLanguage.customerList.inputPlaceholder}
            icon="/assets/images/selling.png"
            errorText={sellingPriceEmpty}
            required={true}
          />

          <div className="flex">
            <button
              className="border-2 font-bold h-10 rounded-[7px] w-1/2 mr-2 text-black-1000 border-black-200 border-solid"
              onClick={onCloseModalDiscount}
            >
              {ekkLanguage.customerList.cancelBtn}
            </button>
            <button
              onClick={productUpdateHandler}
              color="primary"
              className={`${
                loader && "select-none cursor-no-drop"
              } font-bold h-10 rounded-[7px] w-1/2 ml-2 border-2 bg-success text-white flex justify-center items-center`}
            >
              {loader ? (
                <ClipLoader
                  sizeUnit={"px"}
                  size={20}
                  color={"white"}
                  loading={true}
                />
              ) : (
                ekkLanguage.customerList.submit
              )}
            </button>
          </div>
        </div>
      </Modal>
      {/* delete Product  */}
      <Modal
        style={customModalStyles}
        closeTimeoutMS={200}
        isOpen={isOpen}
        onRequestClose={onCloseModal}
        ariaHideApp={false}
        classNames="bottom-sheet-modal"
      >
        <div className="p-5">
          <span className="mb-2 inline-block mx-2">
            <CancelPopUp />
          </span>
          <h6 className="text-16 font-bold pb-[10px]">
            {ekkLanguage.customerList.description}
          </h6>

          <div className="flex">
            <button
              className="border-2 font-bold h-10 rounded-[7px] w-1/2 mr-2 text-black-1000 border-black-200 border-solid"
              onClick={onCloseModal}
            >
              {ekkLanguage.orderDetails.noBtn}
            </button>
            <button
              color="primary"
              className="font-bold h-10 rounded-[7px] w-1/2 ml-2 border-2 bg-primary text-white"
              onClick={productDeleteHandler}
            >
              {ekkLanguage.orderDetails.yesBtn}
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
}
