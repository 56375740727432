import CancelPopUp from "assets/images/icon/svg/CancelPopUp";
import React from "react";
import { useSelector } from "react-redux";
import Modal from "react-modal";
import { ClipLoader } from "react-spinners";
const customModalStyles = {
	content: {
		top: "50%",
		left: "50%",
		right: "auto",
		bottom: "auto",
		marginRight: "-50%",
		transform: "translate(-50%, -50%)",
		padding: "0px",
		width: "300px",
		transition: ".4s all ease",
		borderRadius: 12,
	},
	overlay: {
		position: "fixed",
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		transition: ".4s all ease",
		backgroundColor: "rgba(0,0,0,.5)",
	},
};
export default function Button({
	onOpenModal,
	isOpen,
	onCloseModal,
	cancelLoader,
	acceptCustomer,
}) {
	const ekkLanguage = useSelector((state) => state.lan.lan);
	

	return (
		<>
			<button
				onClick={onOpenModal}
				color='primary'
				className={`font-bold h-[50px] rounded-[5px] w-full mt-5 bg-success text-white flex justify-center items-center`}
			>
				{ekkLanguage.customerList.accept}
			</button>{" "}
			<Modal
				style={customModalStyles}
				closeTimeoutMS={200}
				isOpen={isOpen}
				onRequestClose={onCloseModal}
				classNames='bottom-sheet-modal'
			>
				<div className='p-5'>
					<span className='mb-2 inline-block mx-2'>
						<img
							width={42}
							height={42}
							src='/assets/images/Icon/tik.png'
							alt=''
						/>
					</span>
					<h6 className='text-16 font-bold pb-[10px]'>
						{ekkLanguage.customerList.acceptConfirmation}
					</h6>

					<div className='flex'>
						<button
							className='border-2 font-bold h-10 rounded-[7px] w-1/2 mr-2 text-black-1000 border-black-200 border-solid'
							onClick={onCloseModal}
						>
							{ekkLanguage.orderDetails.noBtn}
						</button>
						<button
							color='primary'
							className='font-bold h-10 rounded-[7px] w-1/2 ml-2 border-2 bg-success text-white'
							onClick={!cancelLoader && acceptCustomer}
						>
							{cancelLoader ? (
								<ClipLoader
									sizeUnit={"px"}
									size={20}
									color={"white"}
									loading={true}
								/>
							) : (
								ekkLanguage.orderDetails.yesBtn
							)}
						</button>
					</div>
				</div>
			</Modal>
		</>
	);
}
