import { IonCol, IonGrid, IonRow } from "@ionic/react";
import React from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "moment-timezone";
import {
	addStatusClass,
	digitChanger,
	monthChanger,
	numberWithCommas,
	translator,
} from "helpers/helper";
import moment from "moment-timezone";
import Bag from "assets/images/icon/svg/Bag";
import EditPen from "assets/images/icon/svg/EditPen";
import Car from "assets/images/icon/svg/Car";
import Shop from "assets/images/icon/svg/Shop";
import { setFrom } from "redux/container/orderSlice";

export default function MyOrderStatusCard({ buyer }) {
	const response = useSelector((state) => state.order);
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const dispatch = useDispatch();

	return (
		<>
			<IonGrid>
				<IonRow>
					{response?.data?.length ? (
						response?.data.map((order, index) => {
							let orderStatus = addStatusClass(order?.order_status);
							return (
								<IonCol
									onClick={() => dispatch(setFrom("orderList"))}
									size='12'
									key={index}>
									<Link
										to={
											buyer === "buyer"
												? `/purchase-order-details/${order._id}/buyer`
												: `/order-details/${order._id}/${buyer}`
										}>
										<div
											className='mb-2 rounded-[10px] overflow-hidden'
											style={{
												boxShadow: `0px 5px 15px rgba(0, 0, 0, 0.05)`,
											}}>
											<div
												className={`bg-${orderStatus} py-[7px]  px-4`}>
												<ul className='flex items-center justify-between'>
													<li>
														<h6 className='font-bold text-12 text-white'>
															{digitChanger(order?.order_id)}
														</h6>
													</li>
													<li>
														<h6 className='font-bold text-12 text-white'>
															{digitChanger(
																monthChanger(
																	moment(
																		order?.createdAt
																	).format("DD MMM, YYYY")
																)
															)}
														</h6>
													</li>
													<li>
														<h6 className='font-bold text-12 text-white'>
															{
																order?.country
																	?.currency_symbol_native
															}
															{digitChanger(
																numberWithCommas(order?.total)
															)}
														</h6>
													</li>
												</ul>
											</div>
											<div className='px-4 bg-white py-[7px]'>
												<ul className='flex'>
													<li className='w-1/2'>
														<p className='text-black-500 text-10 font-semibold py-[3px] flex'>
															{/* {
																	ekkLanguage.orderList
																		.orderItem
																} */}
															{/* <img
																src='assets/images/icon/orderItem.png'
																alt=''
																className='w-[14px] h-[14px] mr-1'
															/> */}
															<Bag />
															{" - "}
															{digitChanger(order.total_items)}
														</p>

														<p className='text-black-500 text-10 font-semibold py-[3px] flex'>
															{/* <img
																src='/assets/images/icon/createdBy.png'
																alt=''
																className='w-[14px] h-[14px] mr-1'
															/> */}
															<EditPen />
															{" - "}
															{
																order?.created_by_user
																	?.DisplayName
															}
															{order?.order_origin ===
																"EkkHero Sales" && (
																<img
																	src='assets/images/hero-icon.png'
																	className='h-[14px] w-[14px] ml-2'
																	alt='hero'
																/>
															)}
														</p>
													</li>
													<li>
														<p className='text-black-500 text-10 font-semibold py-[3px] flex'>
															{/* <img
																src='assets/images/icon/to.png'
																alt=''
																className='w-[14px] h-[14px] mr-1'
															/> */}
															<Car />
															{" - "}
															{order?.seller?.business_name}
														</p>
														<p className='text-black-500 text-10 font-semibold py-[3px] flex'>
															{/* <img
																src='assets/images/icon/for.png'
																alt=''
																className='w-[14px] h-[14px] mr-1'
															/> */}
															<Shop />
															{" - "}
															{order?.buyer?.business_name}
														</p>
													</li>
												</ul>
											</div>
										</div>
									</Link>
								</IonCol>
							);
						})
					) : (
						<>
							<IonCol size='2'></IonCol>
							<IonCol size='8'>
								<div
									className='text-center flex items-center justify-center'
									style={{ height: "calc(100vh - 420px)" }}>
									<div>
										<img
											className='m-auto mb-[10px] mt-5'
											src='/assets/images/not-found-file.png'
											alt='emptyCard'
										/>
										<p className='text-16 font-normal text-222222 mb-[35px]'>
											{ekkLanguage.orderList.notFound}
										</p>
									</div>
								</div>
							</IonCol>
						</>
					)}
				</IonRow>
			</IonGrid>
		</>
	);
}
