import { IonLabel } from "@ionic/react";
import React from "react";

export default function CustomSelect({
  options,
  setOption,
  mainLabel,
  extraLabel,
  labelImage,
  star,
  option,
  selectPlaceholder,
  type,
  disable,
  selectPlaceholderWarning,
  localLanguage,
}) {
  return (
    <>
      <IonLabel className="flex items-center mb-[11px]  mt-5">
        {labelImage && (
          <img className="ml-1 mr-[10px] h-6 w-6" src={labelImage} alt=""></img>
        )}
        <span className="text-16 font-bold">{mainLabel}</span>
        {star && <span className="text-primary mx-1">*</span>}
        {extraLabel && (
          <p className="ml-1 text-12 text-[#00000080]">({extraLabel})</p>
        )}
      </IonLabel>

      <select
        disabled={disable}
        onChange={(e) => {
          setOption(e.target.value);
        }}
        className="w-full h-[50px] pl-[10px] bg-white rounded-md text-gray-700 leading-tight focus:outline-none focus:shadow-outline sm:w-full"
        style={{
          backgroundImage: 'url("/assets/images/ekkhero/Icons/dropdown.png")',
          backgroundRepeat: "no-repeat",
          backgroundPosition: "right 10px center",
        }}
        value={option}
      >
        <option className="text-14" value="">
          {!disable ? selectPlaceholder : selectPlaceholderWarning}
        </option>
        {type === "defaultPaymentTerm" &&
          options.map((item) => (
            <option className="text-14" key={item.id} value={item.id}>
              {localLanguage === "bn" ? item.own_lg_title : item.title}
            </option>
          ))}
          {type === "defaultPaymentMethod" &&
          options.map((item) => (
            <option className="text-14" key={item.id} value={item.id}>
              {localLanguage === "bn" ? item?.bank?.name : item?.bank?.name}
            </option>
          ))}
      </select>
    </>
  );
}
