import { IonCol, IonSpinner } from "@ionic/react";
import React from "react";
import DistributorCustomerCard from "./DistributorCustomerCard";

export default function DistributorCustomerList({
  ekkLanguage,
  customerList,
  loading,
}) {
  return (
    <div className="mt-[10px]">
      <div className="w-full">
        {loading && (
          <>
            <IonCol size="2"></IonCol>
            <IonCol size="8">
              <div
                className="text-center flex items-center justify-center"
                style={{ height: "calc(100vh - 600px)" }}
              >
                <IonSpinner color="danger"></IonSpinner>
              </div>
            </IonCol>
          </>
        )}
      </div>
      {customerList.length > 0 ? (
        <div className="grid grid-cols-2 gap-4">
          {customerList.map((el, i) => {
            return (
              <DistributorCustomerCard
                item={el}
                key={i}
                ekkLanguage={ekkLanguage}
              />
            );
          })}
        </div>
      ) : (
        <>
          {!loading && (
            <>
              <IonCol size="2"></IonCol>
              <IonCol size="6">
                <div
                  className="text-center flex items-center justify-center"
                  style={{ height: "calc(100vh - 600px)" }}
                >
                  <div>
                    <img
                      className="m-auto mb-[10px] mt-5"
                      src="/assets/images/not-found-file.png"
                      alt="emptyCard"
                    />
                    <p className="text-16 font-normal text-222222 mb-[35px]">
                      {ekkLanguage.orderList.notFound}
                    </p>
                  </div>
                </div>
              </IonCol>
            </>
          )}
        </>
      )}
    </div>
  );
}
