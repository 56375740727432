import { IonApp, IonButton, IonContent, IonPage } from "@ionic/react";
import { digitChanger, numberWithCommas, translator } from "helpers/helper";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { fetchOrderSingle } from "redux/container/orderSlice";
import {
	Document,
	Page,
	Text,
	View,
	StyleSheet,
	PDFViewer,
	PDFDownloadLink,
	Font,
	Image,
} from "@react-pdf/renderer";
import {
	Table,
	TableHeader,
	TableCell,
	TableBody,
	DataTableCell,
} from "@david.kucsai/react-pdf-table";

import banglaFont from "./../../assets/font/BenSenHandwriting.ttf";
import iconLogo from "assets/images/ekkbaz_icon.png";
import LeftArrow from "assets/images/icon/svg/LeftArrow";

export default function Invoice() {
	let { id } = useParams();
	const dispatch = useDispatch();
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const response = useSelector((state) => state.order?.singleOrder);
	const navigate = useNavigate();
	console.log(response?.country?.currency_symbol_native);

	Font.register({
		family: "BenSen",
		fontStyle: "normal",
		fontWeight: "normal",
		fonts: [
			{
				src: banglaFont,
			},
		],
	});
	// Create styles
	const styles = StyleSheet.create({
		page: {
			flexDirection: "row",
			backgroundColor: "#fff",
			border: "none",
		},
		section: {
			margin: 20,
			padding: 10,
			flexGrow: 1,
		},
		text: {
			fontFamily: "BenSen",
			fontWeight: 400,
			fontSize: 14,
		},
	});

	useEffect(() => {
		dispatch(fetchOrderSingle(id));
	}, []);
	const parseProductData = (data) => {
		if (data) {
			return data
				.map((el) => {
					return {
						title: el?.catalog?.product?.title,
						quantity: digitChanger(el?.quantity),
						price: `${
							el?.catalog?.country?.currency_symbol_native
						} ${digitChanger(el?.unit_price)}`,
						total: `${
							el?.catalog.country?.currency_symbol_native
						}  ${digitChanger(el?.quantity * el?.unit_price)}`,
					};
				})
				.flat();
		}
	};
	let productData = parseProductData(response?.order_items);

	const MyDocument = () => (
		<Document>
			<Page size='A4' style={styles.page}>
				<View style={styles.section}>
					<View
						style={{
							marginBottom: 30,
							display: "flex",
							flexDirection: "row",
							justifyContent: "space-between",
						}}>
						<View>
							<Text
								style={{
									fontFamily: "BenSen",
									color: "rgb(249 75 65)",
									fontWeight: 700,
									fontSize: 30,
								}}>
								{ekkLanguage.invoice.title}
							</Text>
							<Text style={styles.text}>
								{ekkLanguage.invoice.receipt} #
								{digitChanger(response?.order_id)}
							</Text>
							<Text style={styles.text}>
								{digitChanger(
									moment(response?.createdAt).format("DD/MM/YYYY")
								)}
							</Text>
						</View>
						<View>
							<Image src={iconLogo} style={{ width: 40 }}></Image>
						</View>
					</View>

					<View
						style={{
							display: "flex",
							flexDirection: "row",
							justifyContent: "space-between",
							marginBottom: 20,
							paddingBottom: 15,
							borderBottom: "1px solid rgb(226 232 240)",
						}}>
						<View>
							<Text
								style={{
									...styles.text,
									color: "rgba(0,0,0, .5)",
									marginBottom: 5,
								}}>
								{ekkLanguage.invoice.buyerDetails}
							</Text>
							<Text
								style={{
									fontFamily: "BenSen",
									fontWeight: 500,
									fontSize: 16,
									color: "rgb(249 75 65)",
								}}>
								{response?.buyer?.business_name}
							</Text>
							<Text style={styles.text}>
								{response?.buyer?.address_line}
							</Text>
							<Text style={styles.text}>{response?.buyer?.email}</Text>
							<Text style={styles.text}>
								{response?.buyer?.phone_number}
							</Text>
						</View>
						<View
							style={{
								textAlign: "right",
								width: "50%",
							}}>
							<Text
								style={{
									...styles.text,
									color: "rgba(0,0,0, .5)",
									marginBottom: 5,
								}}>
								{ekkLanguage.invoice.sellerDetails}
							</Text>
							<Text
								style={{
									fontFamily: "BenSen",
									fontWeight: 500,
									fontSize: 16,
									color: "rgb(249 75 65)",
								}}>
								{response?.seller?.business_name}
							</Text>
							<Text style={styles.text}>
								{response?.seller?.address_line}
							</Text>
							<Text style={styles.text}>{response?.seller?.email}</Text>
							<Text style={styles.text}>
								{response?.seller?.phone_number}
							</Text>
						</View>
					</View>

					<View style={{ marginBottom: 25 }}>
						<Table data={productData} style={{ borderColor: "red" }}>
							<TableHeader
								includeLeftBorder={false}
								includeRightBorder={false}
								includeTopBorder={false}
								style={{ borderColor: "red" }}>
								<TableCell
									weighting={0.55}
									style={{
										...styles.text,
										textAlign: "center",
										fontWeight: 700,
										color: "#000",
										border: "none",
									}}>
									{ekkLanguage.invoice.description}
								</TableCell>
								<TableCell
									weighting={0.15}
									style={{
										...styles.text,
										textAlign: "center",
										fontWeight: 700,
										color: "#000",
										border: "none",
									}}>
									{ekkLanguage.invoice.qty}
								</TableCell>
								<TableCell
									weighting={0.15}
									style={{
										...styles.text,
										textAlign: "center",
										fontWeight: 700,
										color: "#000",
										border: "none",
									}}>
									{ekkLanguage.invoice.price}
								</TableCell>
								<TableCell
									weighting={0.15}
									style={{
										...styles.text,
										textAlign: "center",
										fontWeight: 700,
										color: "#000",
									}}>
									{ekkLanguage.invoice.subtotal}
								</TableCell>
							</TableHeader>
							<TableBody
								includeLeftBorder={false}
								includeRightBorder={false}
								includeTopBorder={false}
								style={{ borderColor: "red" }}>
								<DataTableCell
									weighting={0.55}
									style={{
										...styles.text,
										paddingLeft: 15,
										border: "none",
										paddingBottom: 3,
										paddingTop: 3,
									}}
									getContent={(r) => r.title}
								/>
								<DataTableCell
									weighting={0.15}
									style={{
										...styles.text,
										textAlign: "center",
										border: "none",
										paddingBottom: 3,
										paddingTop: 3,
									}}
									getContent={(r) => r.quantity}
								/>
								<DataTableCell
									weighting={0.15}
									style={{
										...styles.text,
										textAlign: "center",
										border: "none",
										paddingBottom: 3,
										paddingTop: 3,
									}}
									getContent={(r) => r.price}
								/>
								<DataTableCell
									weighting={0.15}
									style={{
										...styles.text,
										textAlign: "center",
										border: "none",
										paddingBottom: 3,
										paddingTop: 3,
									}}
									getContent={(r) => r.total}
								/>
							</TableBody>
						</Table>
					</View>

					<View
						style={{
							display: "flex",
							flexDirection: "row",
							justifyContent: "space-between",
						}}>
						<View>
							<Text
								style={{
									...styles.text,
									color: "rgba(0,0,0, .5)",
									marginBottom: 5,
								}}>
								{ekkLanguage.orderDetails.paymentDetailsLabel}
							</Text>
							<Text style={styles.text}>
								{ekkLanguage.orderDetails.paymentMethod} :{" "}
								{response?.payment_method}
							</Text>
							<Text style={styles.text}>
								{ekkLanguage.orderDetails.paymentTerms} :{" "}
								{response?.payment_term?.name}
							</Text>
							<Text style={styles.text}>
								{ekkLanguage.orderDetails.paymentDueDate} :{" "}
								{digitChanger(
									moment(
										response?.payment_term?.paymentDueDate
									).format("DD-MM-YYYY")
								)}
							</Text>
							<Text style={styles.text}>
								{ekkLanguage.orderDetails.totalDue} :{" "}
								{translator(response?.country?.currency_symbol_native)}{" "}
								{digitChanger(numberWithCommas(response?.total))}
							</Text>
							<Text style={styles.text}>
								{ekkLanguage.orderDetails.totalPaid} :{" "}
								{translator(response?.country?.currency_symbol_native)}{" "}
								{digitChanger(numberWithCommas(response?.total_paid))}
							</Text>
							<Text style={styles.text}>
								{ekkLanguage.orderDetails.remaining} :{" "}
								{translator(response?.country?.currency_symbol_native)}{" "}
								{digitChanger(
									numberWithCommas(
										response?.total - response?.total_paid
									)
								)}
							</Text>
						</View>
						<View
							style={{
								textAlign: "right",
								width: "50%",
							}}>
							<Text
								style={{
									...styles.text,
									color: "rgba(0,0,0, .5)",
									marginBottom: 5,
								}}>
								{ekkLanguage.orderDetails.castItemLabel}
							</Text>
							<Text style={styles.text}>
								{ekkLanguage.orderDetails.subtotal} :
								{response?.country?.currency_symbol_native}{" "}
								{digitChanger(numberWithCommas(response?.sub_total))}
							</Text>
							<Text style={styles.text}>
								{ekkLanguage.orderDetails.tax} :{" "}
								{response?.country?.currency_symbol_native}{" "}
								{digitChanger(numberWithCommas(response?.tax_total))}
							</Text>
							<Text style={styles.text}>
								{ekkLanguage.orderDetails.discount} :{" "}
								{response?.country?.currency_symbol_native}{" "}
								{digitChanger(numberWithCommas(0.0))}
							</Text>
							<Text style={styles.text}>
								{ekkLanguage.orderDetails.deliveryFee} :
								{response?.country?.currency_symbol_native}{" "}
								{digitChanger(numberWithCommas(0.0))}
							</Text>
							<Text style={styles.text}>
								{ekkLanguage.orderDetails.creditFee} :{" "}
								{response?.country?.currency_symbol_native}{" "}
								{digitChanger(
									numberWithCommas(response?.payment_term?.creditFee)
								)}
							</Text>
							<Text
								style={{
									...styles.text,
									color: "rgb(249 75 65)",
									fontSize: 16,
									fontWeight: 700,
								}}>
								{ekkLanguage.orderDetails.total} :{" "}
								{response?.country?.currency_symbol_native}{" "}
								{digitChanger(numberWithCommas(response?.total))}
							</Text>
						</View>
					</View>
				</View>
			</Page>
		</Document>
	);

	return (
		<IonApp>
			<IonPage className='business-name' id='ok'>
				<IonContent>
					<div className='bg-white invoice-page'>
						<div className='intro-y flex flex-col sm:flex-row justify-end items-center mt-8  px-4'>
							<div className='w-full  sm:mt-0 flex justify-between items-center'>
								<div
									className='short-nav left-4 top-7 bg-E6E6E6 rounded-xl z-10 '
									style={{
										height: 40,
										width: 40,
										boxShadow: "0 5px 10px rgba(0,0,0,.1)",
									}}
									onClick={() => navigate(-1)}>
									<div className=' h-full flex items-center justify-center'>
										<LeftArrow />
									</div>
								</div>
								<PDFDownloadLink
									document={<MyDocument />}
									fileName='invoice.pdf'
									style={{
										textDecoration: "none",
										padding: "10px",
									}}>
									{({ blob, url, loading, error }) =>
										loading ? (
											"Loading document..."
										) : (
											<IonButton
												className='bg-primary rounded-[7px] font-extrabold text-12 h-10'
												// onClick={downloadFileDocument}
											>
												PDF Download
											</IonButton>
										)
									}
								</PDFDownloadLink>
							</div>
						</div>
						{/* BEGIN: Invoice */}
						{/* <PDFViewer>
						<MyDocument />
					</PDFViewer> */}
						<div className='intro-y box overflow-hidden'>
							<div className='border-b border-slate-200/60 dark:border-darkmode-400 text-center sm:text-left'>
								<div className='px-5 py-6 sm:px-14 pt-14'>
									<div>
										<div className='text-primary font-bold text-3xl '>
											{ekkLanguage.invoice.title}
										</div>
										<div className='mt-2'>
											{ekkLanguage.invoice.receipt}{" "}
											<span className='font-medium'>
												#{digitChanger(response?.order_id)}
											</span>
										</div>
										<div className='mt-1'>
											{digitChanger(
												moment(response?.createdAt).format(
													"DD/MM/YYYY"
												)
											)}
										</div>
									</div>
								</div>
								<div className='flex flex-col lg:flex-row px-5 sm:px-14 pt-4 pb-4'>
									<div>
										<div className='text-base text-slate-500'>
											{ekkLanguage.invoice.buyerDetails}
										</div>
										<div className='text-lg font-medium text-primary mt-2'>
											{response?.buyer?.business_name}
										</div>
										<div className='mt-1'>
											{response?.buyer?.address_line}
										</div>
										<div className='mt-1'>
											{response?.buyer?.email}
										</div>
										<div className='mt-1'>
											{response?.buyer?.phone_number}
										</div>
									</div>
									<div className='lg:text-right mt-10 lg:mt-0 lg:ml-auto'>
										<div className='text-base text-slate-500'>
											{ekkLanguage.invoice.sellerDetails}
										</div>
										<div className='text-lg font-medium text-primary mt-2'>
											{response?.seller?.business_name}
										</div>
										<div className='mt-1'>
											{response?.seller?.address_line}
										</div>
										<div className='mt-1'>
											{response?.seller?.email}
										</div>
										<div className='mt-1'>
											{response?.seller?.phone_number}
										</div>
									</div>
								</div>
							</div>
							<div className='sm:px-14 py-6 pb-10 px-14'>
								<div className='overflow-x-auto'>
									<table className='table'>
										<thead>
											<tr>
												<th className='border-b-2 dark:border-darkmode-400 whitespace-nowrap'>
													{ekkLanguage.invoice.description}
												</th>
												<th className='border-b-2 dark:border-darkmode-400 text-right whitespace-nowrap px-1'>
													{ekkLanguage.invoice.qty}
												</th>
												<th className='border-b-2 dark:border-darkmode-400 text-right whitespace-nowrap px-1'>
													{ekkLanguage.invoice.price}
												</th>
												<th className='border-b-2 dark:border-darkmode-400 text-right whitespace-nowrap px-1'>
													{ekkLanguage.invoice.subtotal}
												</th>
											</tr>
										</thead>
										<tbody>
											{response?.order_items?.map((el, i) => {
												return (
													<tr key={i}>
														<td className='border-b dark:border-darkmode-400'>
															<div className='font-medium whitespace-nowrap'>
																{el?.catalog?.product?.title}
															</div>
														</td>
														<td className='text-right border-b dark:border-darkmode-400 w-32'>
															{digitChanger(el?.quantity)}
														</td>
														<td className='text-right border-b dark:border-darkmode-400 w-32'>
															{
																el?.catalog?.country
																	?.currency_symbol_native
															}
															{digitChanger(el?.unit_price)}
														</td>
														<td className='text-right border-b dark:border-darkmode-400 w-32 font-medium'>
															{
																el?.catalog?.country
																	?.currency_symbol_native
															}
															{digitChanger(
																el?.quantity * el?.unit_price
															)}
														</td>
													</tr>
												);
											})}
										</tbody>
									</table>
								</div>
							</div>
							<div className='px-5 sm:px-14 pb-6 sm:pb-20 flex flex-col-reverse sm:flex-row'>
								<div className='text-center sm:text-left mt-10 sm:mt-0'>
									<div className='text-base text-slate-500'>
										{ekkLanguage.orderDetails.paymentDetailsLabel}
									</div>
									<div className='mt-1'>
										{ekkLanguage.orderDetails.paymentMethod} :{" "}
										{response?.payment_method}
									</div>
									<div className='mt-1'>
										{ekkLanguage.orderDetails.paymentTerms} :{" "}
										{response?.payment_term?.name}
									</div>
									<div className='mt-1'>
										{ekkLanguage.orderDetails.paymentDueDate} :{" "}
										{digitChanger(
											moment(
												response?.payment_term?.paymentDueDate
											).format("DD-MM-YYYY")
										)}
									</div>
									<div className='mt-1'>
										{ekkLanguage.orderDetails.totalDue} :{" "}
										{response?.country?.currency_symbol_native}{" "}
										{digitChanger(numberWithCommas(response?.total))}
									</div>
									<div className='mt-1'>
										{ekkLanguage.orderDetails.totalPaid} :{" "}
										{response?.country?.currency_symbol_native}{" "}
										{digitChanger(
											numberWithCommas(response?.total_paid)
										)}
									</div>
									<div className='mt-1'>
										{ekkLanguage.orderDetails.remaining} :{" "}
										{response?.country?.currency_symbol_native}{" "}
										{digitChanger(
											numberWithCommas(
												response?.total - response?.total_paid
											)
										)}
									</div>
								</div>
								<div className='text-center sm:text-right sm:ml-auto'>
									<div className='text-base text-slate-500'>
										{ekkLanguage.orderDetails.castItemLabel}
									</div>
									<div className='mt-1'>
										{ekkLanguage.orderDetails.subtotal} :
										{response?.country?.currency_symbol_native}{" "}
										{digitChanger(
											numberWithCommas(response?.sub_total)
										)}
									</div>
									<div className='mt-1'>
										{ekkLanguage.orderDetails.tax} :{" "}
										{response?.country?.currency_symbol_native}{" "}
										{digitChanger(
											numberWithCommas(response?.tax_total)
										)}
									</div>
									<div className='mt-1'>
										{ekkLanguage.orderDetails.discount} :{" "}
										{response?.country?.currency_symbol_native}{" "}
										{digitChanger(numberWithCommas(0.0))}
									</div>
									<div className='mt-1'>
										{ekkLanguage.orderDetails.deliveryFee} :
										{response?.country?.currency_symbol_native}{" "}
										{digitChanger(numberWithCommas(0.0))}
									</div>
									<div className='mt-1'>
										{ekkLanguage.orderDetails.creditFee} :{" "}
										{response?.country?.currency_symbol_native}{" "}
										{digitChanger(
											numberWithCommas(
												response?.payment_term?.creditFee
											)
										)}
									</div>
									<div className='mt-1 text-primary font-bold text-18'>
										{ekkLanguage.orderDetails.total} :{" "}
										{response?.country?.currency_symbol_native}{" "}
										{digitChanger(numberWithCommas(response?.total))}
									</div>
								</div>
							</div>
						</div>

						{/* END: Invoice */}
					</div>
				</IonContent>
			</IonPage>
		</IonApp>
	);
}
