import { digitChanger } from "helpers/helper";
import React from "react";
import { useSelector } from "react-redux";
import Card from "../Common/Card";

export default function AddressInfo({ businessData }) {
	const ekkLanguage = useSelector((state) => state.lan.lan);
	return (
		<Card title={ekkLanguage.customerList.addressLabel}>
			<div className='px-2'>
				<p className='text-000000 text-14 font-semibold py-[7px]'>
					{businessData?.related_business_address}
				</p>
				<table className='table-fixed w-full'>
					<tbody>
						<tr>
							<td className='text-000000 text-14 font-bold py-[7px]'>
								{ekkLanguage.customerList.postalCodeLabel}
							</td>
							<td className='text-000080 text-14 font-medium py-[7px] text-right'>
								{businessData?.related_business_postal_code}
							</td>
						</tr>
						<tr>
							<td className='text-000000 text-14 font-bold py-[7px]'>
								{ekkLanguage.customerList.cityLabel}
							</td>
							<td className='text-000080 text-14 font-medium py-[7px] text-right'>
								{businessData?.related_business_city}
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</Card>
	);
}
