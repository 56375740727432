import { getLanguage } from "config/language";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PhoneNoCheck from "./PhoneNoCheck";
import { Transition, animated } from "react-spring/renderprops";
import { authBox_Block } from "Constants";
import Password from "./Password";
import { setWarning } from "redux/container/userSlice";
import Cancel from "assets/images/icon/svg/Cancel";
import parse from "html-react-parser";
import Token from "./Token";
import ChangePassword from "./ChangePassword";
import Registration from "./Registration";
import VerificationBox from "./VerificationBox";

export default function BannerContent({ blockIndex, setBlockIndex }) {
	const [loading, setLoading] = useState(false);
	const [countryCode, setCountryCode] = useState("+88");
	const [country, setCountry] = useState("Bangladesh");
	const [phoneNo, setPhoneNo] = useState("");
	const ekkLanguage = useSelector((state) => state.lan);
	var sSize = window.matchMedia("(max-width: 599px)");

	const [token, setToken] = useState("");
	const [password, setPassword] = useState("");
	const warning = useSelector((state) => state.user.warning);
	const [isForgotPassword, setIsForgotPassword] = useState(false);
	const startLoading = () => setLoading(true);
	const stopLoading = () => setLoading(false);
	const dispatch = useDispatch();
	const [isOpen, setIsOpen] = useState(warning);
	const onCloseModal = () => {
		dispatch(setWarning(false));
		setIsOpen(false);
	};
	let userInfo = useSelector((state) => state.user);
	const [verifyNumber, setVerifyNumber] = useState(
		userInfo?.createUserData.verify
	);

	return (
		<div className='sm:pt-[185px] pt-[120px]'>
			<h1
				className={`  ${
					getLanguage() === "en"
						? "lg:text-32 text-22 md:text-32"
						: "lg:text-32 text-20 md:text-32"
				} font-bold lg:text-262626-1000 pr-[0px] pb-4 text-white text-center pt-10`}>
				{ekkLanguage?.lan.landing.header1}
			</h1>
			<h1
				className={`  ${
					getLanguage() === "en"
						? "lg:text-32 text-18 md:text-32"
						: "lg:text-32 text-20 md:text-32"
				} font-extrabold lg:text-262626-1000 lg:text-right text-white text-center flex flex-wrap justify-center`}>
				{ekkLanguage?.lan.landing.header2}{" "}
			</h1>

			{/* {isOpen && (
				<div className='flex justify-end'>
					<div
						className='p-5 mt-10 relative rounded-[15px]'
						style={{
							background: "#FEF7EA",
							maxWidth: 527,
							border: "3px solid #EFE0BE",
						}}>
						<span
							className='mb-2 inline-block mx-2 absolute right-5 top-5 cursor-pointer'
							onClick={onCloseModal}>
							<Cancel />
						</span>

						<div className='flex items-center'>
							<img
								src='assets/images/warning.png'
								alt='warning'
								className='w-12 h-12 mr-5'
							/>
							<div>
								<p
									className='text-16 font-bold pb-[10px]'
									style={{ color: "#262626" }}>
									{ekkLanguage?.lan.home?.warningHeading}
								</p>
								<p className='text-14 font-medium  text-black-500'>
									{ekkLanguage?.lan.home?.warning &&
										parse(ekkLanguage?.lan.home?.warning)}
								</p>
							</div>
						</div>
					</div>
				</div>
			)} */}

			<div className='pt-20 relative'>
				<Transition
					native
					reset
					unique
					items={blockIndex}
					from={{
						opacity: 0,
						transform: "translate3d(30%,0,0)",
					}}
					enter={{ opacity: 1, transform: "translate3d(0%,0,0)" }}
					leave={{
						opacity: 0,
						transform: "translate3d(-30%,0,0)",
					}}>
					{(index) => {
						if (index === authBox_Block.PHONE_NUMBER_BLOCK) {
							return (style) => (
								<animated.div
									className='absolute w-full'
									style={{ ...style }}>
									<PhoneNoCheck
										countryCode={countryCode}
										setCountryCode={setCountryCode}
										country={country}
										setCountry={setCountry}
										phoneNo={phoneNo}
										setPhoneNo={setPhoneNo}
										sSize={sSize}
										setBlockIndex={setBlockIndex}
									/>
								</animated.div>
							);
						}
						if (index === authBox_Block.PASSWORD_BLOCK) {
							return (style) => (
								<animated.div
									className='absolute w-full'
									style={{ ...style }}>
									<div>
										<Password
											setPassword={setPassword}
											loading={loading}
											setIsForgotPassword={setIsForgotPassword}
											password={password}
											phoneNo={phoneNo}
											country={country}
											countryCode={countryCode}
											startLoading={startLoading}
											stopLoading={stopLoading}
											setBlockIndex={setBlockIndex}
											sSize={sSize}
										/>
									</div>
								</animated.div>
							);
						}
						if (index === authBox_Block.TOKEN) {
							return (style) => (
								<animated.div
									className='absolute w-full'
									style={{ ...style }}>
									<div>
										<Token
											loading={loading}
											setToken={setToken}
											phoneNo={phoneNo}
											country={country}
											countryCode={countryCode}
											startLoading={startLoading}
											stopLoading={stopLoading}
											setBlockIndex={setBlockIndex}
											token={token}
											isForgotPassword={isForgotPassword}
											sSize={sSize}
										/>
									</div>
								</animated.div>
							);
						}

						if (index === authBox_Block.CHANGE_PASS_BLOCK) {
							return (style) => (
								<animated.div
									className='absolute w-full'
									style={{ ...style }}>
									<div>
										<ChangePassword
											loading={loading}
											phoneNo={phoneNo}
											country={country}
											countryCode={countryCode}
											startLoading={startLoading}
											stopLoading={stopLoading}
											setBlockIndex={setBlockIndex}
											setIsForgotPassword={setIsForgotPassword}
											sSize={sSize}
										/>
									</div>
								</animated.div>
							);
						}
						if (index === authBox_Block.REGISTRATION_BLOCK) {
							return (style) => (
								<animated.div className=' w-full' style={{ ...style }}>
									<div>
										<VerificationBox
											loading={loading}
											phoneNo={phoneNo}
											country={country}
											countryCode={countryCode}
											setBlockIndex={setBlockIndex}
											setVerifyNumber={setVerifyNumber}
											sSize={sSize}
										/>
										{/* {!verifyNumber ? (
											<Registration
												loading={loading}
												phoneNo={phoneNo}
												country={country}
												countryCode={countryCode}
												sSize={sSize}
												setBlockIndex={setBlockIndex}
											/>
										) : (
											<VerificationBox
												loading={loading}
												phoneNo={phoneNo}
												country={country}
												countryCode={countryCode}
												setBlockIndex={setBlockIndex}
												setVerifyNumber={setVerifyNumber}
												sSize={sSize}
											/>
										)} */}
									</div>
								</animated.div>
							);
						}
					}}
				</Transition>
			</div>
		</div>
	);
}
