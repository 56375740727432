import { IonCol, IonGrid, IonRow } from "@ionic/react";
import React, { useEffect, useState } from "react";
import Card from "../Common/Card";
import Minus from "assets/images/icon/svg/Minus";
import Plus from "assets/images/icon/svg/Plus";
import { useSelector, useDispatch } from "react-redux";
import {
	getCartList,
	subtotalAmount,
	totalAmount,
} from "redux/container/shoppingCartSlice";
import {
	cartMinus,
	cartPlus,
	cartTotalAmount,
	removeCartProduct,
	setQuantity,
	shoppingCart,
	shoppingListItem,
} from "helpers/shoppingCart";
import Cross from "assets/images/icon/svg/Cross";
import { creditFee } from "redux/container/paymentMethodSlice";
import { digitChanger } from "helpers/helper";
import ProductQuantityInput from "./ProductQuantityInput";
import SingleProductForCard from "./SingleProductForCard";
import { useCallback } from "react";
export default function ProductCard() {
	const [qty, setQty] = useState(1);
	const dispatch = useDispatch();
	const productCart = useSelector((state) => state.cart);
	const [productList, setProductList] = useState(shoppingListItem());
	const paymentTermsDay = useSelector((state) => state.payment.paymentTerm);
	const ekkLanguage = useSelector((state) => state.lan.lan);

	useEffect(() => {
		dispatch(subtotalAmount(cartTotalAmount()));
		dispatch(totalAmount(cartTotalAmount()));
	}, []);

	useEffect(() => {
		dispatch(getCartList());
	});

	let creditPercent;
	if (paymentTermsDay == 0) {
		creditPercent = 0;
	} else if (paymentTermsDay == 1) {
		creditPercent = 0.001;
	} else if (paymentTermsDay == 3) {
		creditPercent = 0.005;
	} else if (paymentTermsDay == 7) {
		creditPercent = 0.01;
	}

	// let totalWithCreitFee = cartTotalAmount() + creditFee;

	const minusCartItem = (item) => {
		if (item.minimum_order_quantity < item.quantity) {
			cartMinus(item);
			setProductList(shoppingListItem());
			dispatch(
				totalAmount(cartTotalAmount() * creditPercent + cartTotalAmount())
			);
			dispatch(subtotalAmount(cartTotalAmount()));
			dispatch(creditFee(cartTotalAmount() * creditPercent));
		}
	};

	const plusCartItem = (item) => {
		cartPlus(item);
		setProductList(shoppingListItem());
		dispatch(
			totalAmount(cartTotalAmount() * creditPercent + cartTotalAmount())
		);
		dispatch(subtotalAmount(cartTotalAmount()));
		dispatch(creditFee(cartTotalAmount() * creditPercent));
	};

	return (
		<div className='px-2 pt-2 pb-5'>
			<Card title={ekkLanguage.createOrder.productItemTitle}>
				<div>
					<IonRow>
						{productList.map((item) => {
							let image;
							if (item?.images[0]) {
								image = item?.images[0];
							} else {
								if (item.type === "variant") {
									image = "/assets/images/varient_default.png";
								} else if (item.type === "pack") {
									image = "/assets/images/pack_default.png";
								} else if (item.type === "offer") {
									image = "/assets/images/offer_default.png";
								}
							}
							return (
								<SingleProductForCard
									image={image}
									item={item}
									minusCartItem={minusCartItem}
									plusCartItem={plusCartItem}
									setProductList={setProductList}
								/>
							);
						})}
					</IonRow>
				</div>
			</Card>
		</div>
	);
}
