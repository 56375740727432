import { IonButton, IonCol, IonGrid, IonLoading, IonRow } from "@ionic/react";
import ArrowDown from "assets/images/icon/svg/ArrowDown";
import ArrowUp from "assets/images/icon/svg/ArrowUp";
import React, { forwardRef, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import Calendar from "assets/images/icon/svg/Calendar";
import DashBoardService from "services/Dashboard";
import {
	dateTimeFormatter,
	digitChanger,
	numberWithCommas,
	weekChanger,
} from "helpers/helper";
import { useDispatch, useSelector } from "react-redux";
import { getUserBusiness } from "config/user";
import { getSelectedDate } from "redux/container/dashboardSlice";
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import DashBoard from "services/Dashboard";
import Add from "assets/images/icon/svg/Add";
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip);

export default function Dashboard() {
	const [analytics, setAnalytics] = useState({});
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const dateInfo = useSelector((state) => state.dashboard.dateInfo);
	const navigate = useNavigate();
	const [selectedDate, setSelectedDate] = useState(
		dateInfo ? JSON.stringify(dateInfo) : moment(new Date())
	);
	const [startDate, setStartDate] = useState(moment().startOf("day")._d);
	const [endDate, setEndDate] = useState(null);
	const [selectLevel, setSelectLevel] = useState(
		dateInfo?.level ? dateInfo.level : "today"
	);
	const [dayCalender, setDayCalender] = useState(
		dateInfo?.level === "selectDay" ? true : false
	);
	const [rangeCalender, setRangeCalender] = useState(
		dateInfo?.level === "custom" ? true : false
	);
	const [loader, setLoader] = useState(true);
	const userBusiness = getUserBusiness();
	const dispatch = useDispatch();

	const getAnalytics = async (start, end) => {
		setLoader(true);
		let response = await DashBoardService.getAnalytics(start, end);
		if (response.status === 200) {
			setLoader(false);
			setAnalytics(response?.data);
		} else {
			setLoader(false);
		}
	};

	useEffect(() => {
		let interval = setTimeout(() => {
			let start = dateInfo?.start
				? dateInfo?.start
				: moment().startOf("day").toString();
			let end = dateInfo?.end
				? dateInfo?.end
				: moment().endOf("day").toString();
			let dateTime = { start, end, level: selectLevel };
			dispatch(getSelectedDate(dateTime));
			let stringDateTime = JSON.stringify(dateTime);
			setSelectedDate(stringDateTime);
			getAnalytics(start, end);

			const startDay = moment().startOf("week").day("Sunday");
			const endDay = moment().endOf("week").day("Saturday");

			setCustomerDataPeriod({
				startDate: moment(startDay).format("DD-MM-YYYY"),
				endDate: moment(endDay).format("DD-MM-YYYY"),
			});
			getCustomerData(startDay, endDay);
		}, 700);
		return () => clearTimeout(interval);
	}, []);

	const options = [
		{ value: "today", label: ekkLanguage.dashboard.today },
		{ value: "yesterday", label: ekkLanguage.dashboard.yesterday },
		{ value: "week", label: ekkLanguage.dashboard.thisWeek },
		{ value: "month", label: ekkLanguage.dashboard.thisMonth },
		{ value: "year", label: ekkLanguage.dashboard.thisYear },
		{ value: "lastWeek", label: ekkLanguage.dashboard.lastWeek },
		{ value: "lastMonth", label: ekkLanguage.dashboard.lastMonth },
		{ value: "lastYear", label: ekkLanguage.dashboard.lastYear },
		{ value: "custom", label: ekkLanguage.dashboard.custom },
		{ value: "selectDay", label: ekkLanguage.dashboard.selectDay },
	];

	const OfferStartDate = forwardRef(({ value, onClick }, ref) => (
		<div
			className={`bg-white h-10 w-10 rounded-[5px] ml-3 flex items-center justify-center`}
			onClick={onClick}>
			<Calendar></Calendar>
		</div>
	));
	const selectDateChanger = (e) => {
		if (e.value === "custom") {
			setRangeCalender(true);
			setDayCalender(false);
			setSelectLevel(e.value ?? options[0]);
			let timeDate = dateTimeFormatter(e.value);
			let dateTime = {
				start: timeDate.start,
				end: timeDate.end,
				level: e.value,
			};
			let stringDateTime = JSON.stringify(dateTime);
			setSelectedDate(stringDateTime);
			dispatch(getSelectedDate(dateTime));
		} else if (e.value === "selectDay") {
			setDayCalender(true);
			setRangeCalender(false);
			setSelectLevel(e.value ?? options[0]);
			let timeDate = dateTimeFormatter(e.value);
			let dateTime = {
				start: timeDate.start,
				end: timeDate.end,
				level: e.value,
			};
			let stringDateTime = JSON.stringify(dateTime);
			setSelectedDate(stringDateTime);
			dispatch(getSelectedDate(dateTime));
		} else {
			setRangeCalender(false);
			setDayCalender(false);
			setSelectLevel(e.value ?? options[0]);
			let timeDate = dateTimeFormatter(e.value);
			let dateTime = {
				start: timeDate.start,
				end: timeDate.end,
				level: e.value,
			};
			dispatch(getSelectedDate(dateTime));
			let stringDateTime = JSON.stringify(dateTime);
			setSelectedDate(stringDateTime);
			// Get the analytics for the selected date range.
			getAnalytics(timeDate.start, timeDate.end);
		}
	};

	const onChange = (dates) => {
		const [start, endDate] = dates;
		const end = endDate
			? moment(endDate).endOf("day")._d
			: moment(start).endOf("day")._d;

		setStartDate(start);
		setEndDate(endDate);
		let dateTime = { start, end, level: selectLevel };
		dispatch(getSelectedDate(dateTime));
		let stringDateTime = JSON.stringify(dateTime);
		setSelectedDate(stringDateTime);
		getAnalytics(start, end);
	};
	const onDayChange = (dates) => {
		const end = moment(dates).endOf("day")._d;
		setStartDate(dates);
		setEndDate(endDate);
		let dateTime = { start: dates, end, level: selectLevel };
		dispatch(getSelectedDate(dateTime));
		let stringDateTime = JSON.stringify(dateTime);
		setSelectedDate(stringDateTime);
		getAnalytics(dates, end);
	};

	const onRangeStart = (dates) => {
		setStartDate(dates);
		setEndDate(null);
		let dateTime = {
			start: dates,
			end: null,
			level: selectLevel,
		};
		dispatch(getSelectedDate(dateTime));
		let stringDateTime = JSON.stringify(dateTime);
		setSelectedDate(stringDateTime);
	};
	const onRangeEnd = (dates) => {
		const end = moment(dates).endOf("day")._d;
		setEndDate(end);
		let dateTime = {
			start: JSON.parse(selectedDate).start,
			end,
			level: selectLevel,
		};
		dispatch(getSelectedDate(dateTime));
		let stringDateTime = JSON.stringify(dateTime);
		setSelectedDate(stringDateTime);
		getAnalytics(JSON.parse(selectedDate).start, end);
	};
	const CustomInput = forwardRef(({ value, onClick }, ref) => (
		<div
			ref={ref}
			className={`w-full h-10 border p-2.5 rounded relative bg-white ml-4
								${
									selectedDate
										? "border-transparent font-bold text-[#333333]"
										: "border-[#FF564C]"
								} custom-placeholder-style`}
			onClick={onClick}>
			<div className='flex'>
				<span className=''>
					{selectedDate ? (
						<Calendar color={"#333333"}></Calendar>
					) : (
						<Calendar color={"#FF564C"}></Calendar>
					)}
				</span>
				<span>
					{selectedDate ? (
						<h1 className='text-center pl-2.5 text-12 font-bold text-[#333333] pt-0.5 '>
							{moment(JSON.parse(selectedDate)?.start).format(
								"DD-MM-YYYY"
							)}
						</h1>
					) : (
						<h1 className='text-center pl-2.5 text-12 font-normal text-[#FF564C] pt-0.5 '>
							Select Date
						</h1>
					)}
				</span>
				<span className='absolute right-0 mr-2.5'>
					{selectedDate ? (
						<Add color={"#333333"}></Add>
					) : (
						<Add color={"#FF564C"}></Add>
					)}
				</span>
			</div>
		</div>
	));

	const FromDateInput = forwardRef(({ value, onClick }, ref) => (
		<div
			ref={ref}
			className={`w-full h-10 border p-2.5 rounded relative bg-white mt-4
								${
									JSON.parse(selectedDate)?.start
										? "border-[#333333] font-bold text-[#333333]"
										: "border-[#FF564C]"
								} custom-placeholder-style`}
			onClick={onClick}>
			<div className='flex'>
				<span className=''>
					{JSON.parse(selectedDate)?.start ? (
						<Calendar color={"#333333"}></Calendar>
					) : (
						<Calendar color={"#FF564C"}></Calendar>
					)}
				</span>
				<span>
					{JSON.parse(selectedDate)?.start ? (
						<h1 className='text-center pl-2.5 text-12 font-bold text-[#333333] pt-0.5 '>
							{moment(JSON.parse(selectedDate)?.start).format(
								"DD-MM-YYYY"
							)}
						</h1>
					) : (
						<h1 className='text-center pl-2.5 text-12 font-normal text-[#FF564C] pt-0.5 '>
							From Date
						</h1>
					)}
				</span>
				<span className='absolute right-0 mr-2.5'>
					{JSON.parse(selectedDate)?.start ? (
						<Add color={"#333333"}></Add>
					) : (
						<Add color={"#FF564C"}></Add>
					)}
				</span>
			</div>
		</div>
	));

	const ToDateInput = forwardRef(({ value, onClick }, ref) => (
		<div
			style={{ width: "calc(100%-12px" }}
			ref={ref}
			className={`w-full h-10 border p-2.5 rounded relative bg-white mt-4
								${
									JSON.parse(selectedDate)?.end
										? "border-[#333333] font-bold text-[#333333]"
										: "border-[#FF564C]"
								} custom-placeholder-style`}
			onClick={onClick}>
			<div className='flex'>
				<span className=''>
					{JSON.parse(selectedDate)?.end ? (
						<Calendar color={"#333333"}></Calendar>
					) : (
						<Calendar color={"#FF564C"}></Calendar>
					)}
				</span>
				<span>
					{JSON.parse(selectedDate)?.end ? (
						<h1 className='text-center pl-2.5 text-12 font-bold text-[#333333] pt-0.5 '>
							{moment(JSON.parse(selectedDate)?.end).format(
								"DD-MM-YYYY"
							)}
						</h1>
					) : (
						<h1 className='text-center pl-2.5 text-12 font-normal text-[#FF564C] pt-0.5 '>
							To Date
						</h1>
					)}
				</span>
				<span className='absolute right-0 mr-2.5'>
					{JSON.parse(selectedDate)?.end ? (
						<Add color={"#333333"}></Add>
					) : (
						<Add color={"#FF564C"}></Add>
					)}
				</span>
			</div>
		</div>
	));

	let getDetails = (name, listName) => {
		return navigate(`/dashboard-details/${selectedDate}/${name}/${listName}`);
	};

	const [customerData, setCustomerData] = useState([]);

	const [customerDataPeriod, setCustomerDataPeriod] = useState({
		startDate: "",
		endDate: "",
	});

	const getCustomerData = async (start, end) => {
		let response = await DashBoard.getCustomerOrder(start, end);
		if (response.status === 200) {
			setCustomerData(response.data);
		}
	};

	const [customerWeekCount, setCustomerWeekCount] = useState(1);

	const getWeekRange = (weeksAgo) => {
		moment.updateLocale("en", {
			week: {
				dow: 0,
			},
		});
		const endDay = moment()
			.subtract(weeksAgo * 7, "days")
			.endOf("week");
		const startDay = moment(endDay).subtract(6, "days");
		const formattedStartDay = startDay.format("YYYY-MM-DD");
		const formattedEndDay = endDay.format("YYYY-MM-DD");
		return { startDay: formattedStartDay, endDay: formattedEndDay };
	};

	const previousWeekHandler = () => {
		setCustomerWeekCount(customerWeekCount + 1);
		let start = getWeekRange(customerWeekCount).startDay; // Use customerWeekCount directly
		let end = getWeekRange(customerWeekCount).endDay;
		setCustomerDataPeriod({
			startDate: moment(start).format("DD-MM-YYYY"),
			endDate: moment(end).format("DD-MM-YYYY"),
		});
		getCustomerData(start, end);
	};

	const nextWeekHandler = () => {
		if (customerWeekCount > 1) {
			setCustomerWeekCount(customerWeekCount - 1);
			let start = getWeekRange(customerWeekCount - 2).startDay; // Adjust index
			let end = getWeekRange(customerWeekCount - 2).endDay;
			setCustomerDataPeriod({
				startDate: moment(start).format("DD-MM-YYYY"),
				endDate: moment(end).format("DD-MM-YYYY"),
			});
			getCustomerData(start, end);
		}
	};

	const customersData = {
		labels: customerData?.map((item) => weekChanger(item?.day)),
		datasets: [
			{
				data: customerData?.map((item) => item.amount),
				backgroundColor: "#00B224",
			},
		],
	};

	return (
		<div>
			<IonLoading
				isOpen={loader}
				message={ekkLanguage.createOrder.loader}
				spinner='circles'
			/>
			<div className='pb-5 chart-wrapper'>
				<div className='px-2 pb-5 flex justify-between items-center pt-5'>
					<h4 className='font-bold text-20 text-black-1000'>
						{ekkLanguage.customerOrder.customerOrderAmount}
					</h4>
				</div>
				<div className='flex justify-between py-3 items-center'>
					<button
						className='font-bold h-9 rounded-[5px] ml-2 border-2 bg-success text-white px-2 text-12'
						onClick={() => previousWeekHandler()}>
						{ekkLanguage.dashboard.perviousWeek}
					</button>
					<h4 className='font-bold'>
						<span className='font-semibold text-12'>
							{digitChanger(customerDataPeriod?.startDate)} -{" "}
							{digitChanger(customerDataPeriod?.endDate)}
						</span>
					</h4>
					<button
						className={`font-bold h-9 rounded-[5px] ml-2 border-2 ${
							customerWeekCount === 1 ? "bg-36A94D-333" : "bg-success"
						}  text-white px-2 text-12`}
						onClick={() => nextWeekHandler()}>
						{ekkLanguage.dashboard.nextWeek}
					</button>
				</div>

				<div
					className='flex justify-center'
					style={{ width: "100%", maxWidth: "768px" }}>
					<Bar data={customersData} />
				</div>
			</div>
			{/* <div className='px-2 py-5 flex justify-between items-center'>
				<h4 className='font-bold text-20 text-black-1000'>
					{ekkLanguage.dashboard.statusLable}
				</h4>
				<div className='flex items-center'>
					<Select
						defaultValue={options.filter(
							(el) => el.value === dateInfo.level
						)}
						options={options}
						onChange={selectDateChanger}
						styles={{
							control: (baseStyles, state) => ({
								...baseStyles,
								width: 120,
								height: 40,
								border: "none",
								outLine: "none",
								fontWeight: 600,
								fontSize: 12,
								borderRadius: 5,
							}),
						}}
						theme={(theme) => ({
							...theme,
							borderRadius: 5,
							colors: {
								...theme.colors,
								primary25: "rgb(204, 255, 214)",
								primary: "#00FF33",
							},
						})}
					/>
					<span>
						
						<DatePicker
							selected={startDate}
							onChange={onChange}
							startDate={startDate}
							endDate={endDate}
							selectsRange
							selectsDisabledDaysInRange
							withPortal
							customInput={<OfferStartDate />}
						/>
					</span>
				</div>
			</div> */}
			<div className='py-5'>
				<div className='px-2'>
					<div className={dayCalender ? "flex" : "block"}>
						<div className={dayCalender ? "w-[calc(50%-8px)]" : "w-full"}>
							<Select
								isSearchable={false}
								defaultValue={options.filter(
									(el) => el.value === dateInfo.level
								)}
								options={options}
								onChange={selectDateChanger}
								styles={{
									control: (baseStyles, state) => ({
										...baseStyles,
										// width: "100%",
										height: 40,
										border: "none",
										outLine: "none",
										fontWeight: 600,
										fontSize: 12,
										borderRadius: 5,
									}),
								}}
								theme={(theme) => ({
									...theme,
									borderRadius: 5,
									colors: {
										...theme.colors,
										primary25: "rgb(204, 255, 214)",
										primary: "#00FF33",
									},
								})}
							/>
						</div>
						{dayCalender && (
							<div className='w-[calc(50%-8px)]'>
								<DatePicker
									selected={startDate}
									onChange={onDayChange}
									startDate={startDate}
									endDate={endDate}
									selectsDisabledDaysInRange
									withPortal
									customInput={<CustomInput />}
								/>
							</div>
						)}
					</div>
					<div>
						{rangeCalender && (
							<div className='flex items-center'>
								<div className='mr-2 w-[calc(50%-8px)]'>
									<DatePicker
										selected={startDate}
										onChange={onRangeStart}
										startDate={startDate}
										endDate={startDate}
										selectsDisabledDaysInRange
										withPortal
										customInput={<FromDateInput />}
									/>
								</div>
								<div className='ml-2 w-[calc(50%-8px)]'>
									<DatePicker
										selected={endDate}
										onChange={onRangeEnd}
										startDate={endDate}
										endDate={endDate}
										selectsDisabledDaysInRange
										withPortal
										customInput={<ToDateInput />}
									/>
								</div>
							</div>
						)}
					</div>
				</div>
			</div>
			<IonGrid>
				<IonRow className='mb-2'>
					<IonCol size='6' className='pr-2 mb-[6px]'>
						<div
							className='p-[10px] bg-white rounded-[7px] cursor-pointer'
							style={{
								boxShadow: " 0px 3px 20px 0px rgba(0, 0, 0, 0.05)",
							}}
							onClick={() =>
								getDetails(ekkLanguage.dashboard.Order, "order")
							}>
							<h4 className='font-bold text-14 text-black-1000 pb-4'>
								{ekkLanguage.dashboard.Order}
							</h4>
							<h2 className='font-bold text-24 text-center text-black-1000 pb-5'>
								{digitChanger(analytics?.totalOrder ?? 0)}
							</h2>
							{/* <div className='flex justify-end items-center'>
								<span
									className='text-10 font-semibold text-success rounded-[21px] py-[3px] px-[4px] flex justify-center items-center'
									style={{ background: "#CCFFD6" }}>
									<ArrowUp color='#00991F' /> 0.5%
								</span>
								<Link
									className='text-12 underline'
									style={{ color: "#00ACE5" }}
									to=''
									// to='/dashboard-details/11-7-2023/order'
								>
									{ekkLanguage.dashboard.seeDetails}
								</Link>
							</div> */}
						</div>
					</IonCol>
					<IonCol size='6' className='pl-2 mb-[6px]'>
						<div
							className='p-[10px] bg-white rounded-[7px] cursor-pointer'
							style={{
								boxShadow: " 0px 3px 20px 0px rgba(0, 0, 0, 0.05)",
							}}
							onClick={() =>
								getDetails(ekkLanguage.dashboard.orderAmount, "order")
							}>
							<h4 className='font-bold text-14 text-black-1000 pb-4'>
								{ekkLanguage.dashboard.orderAmount}
							</h4>
							<h2 className='font-bold text-24 text-center text-black-1000 pb-5'>
								{digitChanger(
									numberWithCommas(analytics?.totalAmount ?? 0)
								)}
							</h2>
							{/* <div className='flex justify-end items-center'>
								<span
									className='text-10 font-semibold text-primary rounded-[21px] py-[3px] px-[4px] flex justify-center items-center'
									style={{ background: "#FFCFCC" }}>
									<ArrowDown color='#FF261A' /> 0.5%
								</span>
								<Link
									className='text-12 underline'
									style={{ color: "#00ACE5" }}
									to='#'>
									{ekkLanguage.dashboard.seeDetails}
								</Link>
							</div> */}
						</div>
					</IonCol>

					<IonCol size='6' className='pr-2 mb-[6px]'>
						<div
							className='p-[10px] bg-white rounded-[7px]'
							style={{
								boxShadow: " 0px 3px 20px 0px rgba(0, 0, 0, 0.05)",
							}}
							onClick={() =>
								getDetails(
									ekkLanguage.dashboard.orderAmount,
									"total_paid"
								)
							}>
							<h4 className='font-bold text-14 text-black-1000 pb-4'>
								{ekkLanguage.dashboard.collectedAmount}
							</h4>
							<h2 className='font-bold text-24 text-center text-black-1000 pb-5'>
								{digitChanger(
									numberWithCommas(analytics?.total_paid ?? 0)
								)}
							</h2>
							{/* <div className='flex justify-end items-center'>
								<span
									className='text-10 font-semibold text-success rounded-[21px] py-[3px] px-[4px] flex justify-center items-center'
									style={{ background: "#CCFFD6" }}>
									<ArrowUp color='#00991F' /> 0.5%
								</span>
								<Link
									className='text-12 underline'
									style={{ color: "#00ACE5" }}
									to='#'>
									{ekkLanguage.dashboard.seeDetails}
								</Link>
							</div> */}
						</div>
					</IonCol>
					<IonCol size='6' className='pl-2 mb-[6px]'>
						<div
							className='p-[10px] bg-white rounded-[7px]'
							style={{
								boxShadow: " 0px 3px 20px 0px rgba(0, 0, 0, 0.05)",
							}}
							onClick={() =>
								getDetails(
									ekkLanguage.dashboard.dueAmount,
									"calc_due_amount"
								)
							}>
							<h4 className='font-bold text-14 text-black-1000 pb-4'>
								{ekkLanguage.dashboard.dueAmount}
							</h4>
							<h2 className='font-bold text-24 text-center text-black-1000 pb-5'>
								{digitChanger(
									numberWithCommas(
										analytics?.totalAmountWithOutEx ?? 0
									)
								)}
							</h2>
							{/* <div className='flex justify-between items-center'>
								<span
									className='text-10 font-semibold text-primary rounded-[21px] py-[3px] px-[4px] flex justify-center items-center'
									style={{ background: "#FFCFCC" }}>
									<ArrowDown color='#FF261A' /> 0.5%
								</span>
								<Link
									className='text-12 underline'
									style={{ color: "#00ACE5" }}
									to='#'>
									{ekkLanguage.dashboard.seeDetails}
								</Link>
							</div> */}
						</div>
					</IonCol>

					<IonCol size='6' className='pr-2 mb-[6px]'>
						<div
							className='p-[10px] bg-white rounded-[7px]'
							style={{
								boxShadow: " 0px 3px 20px 0px rgba(0, 0, 0, 0.05)",
							}}
							onClick={() =>
								getDetails(ekkLanguage.dashboard.placed, "Placed")
							}>
							<h4 className='font-bold text-14 text-black-1000 pb-4'>
								{ekkLanguage.dashboard.placed}
							</h4>
							<h2 className='font-bold text-24 text-center text-black-1000 pb-5'>
								{digitChanger(analytics?.placed ?? 0)}
							</h2>
							{/* <div className='flex justify-end items-center'>
								<Link
									className='text-12 underline'
									style={{ color: "#00ACE5" }}
									to='#'>
									{ekkLanguage.dashboard.seeDetails}
								</Link>
							</div> */}
						</div>
					</IonCol>
					<IonCol size='6' className='pr-2 mb-[6px]'>
						<div
							className='p-[10px] bg-white rounded-[7px]'
							style={{
								boxShadow: " 0px 3px 20px 0px rgba(0, 0, 0, 0.05)",
							}}
							onClick={() =>
								getDetails(ekkLanguage.dashboard.accepted, "Accepted")
							}>
							<h4 className='font-bold text-14 text-black-1000 pb-4'>
								{ekkLanguage.dashboard.accepted}
							</h4>
							<h2 className='font-bold text-24 text-center text-black-1000 pb-5'>
								{digitChanger(analytics?.accepted ?? 0)}
							</h2>
							{/* <div className='flex justify-end items-center'>
								<Link
									className='text-12 underline'
									style={{ color: "#00ACE5" }}
									to='#'>
									{ekkLanguage.dashboard.seeDetails}
								</Link>
							</div> */}
						</div>
					</IonCol>
					<IonCol size='6' className='pr-2 mb-[6px]'>
						<div
							className='p-[10px] bg-white rounded-[7px]'
							style={{
								boxShadow: " 0px 3px 20px 0px rgba(0, 0, 0, 0.05)",
							}}
							onClick={() =>
								getDetails(
									ekkLanguage.dashboard.scDelivered,
									"Scheduled For Delivery"
								)
							}>
							<h4 className='font-bold text-14 text-black-1000 pb-4'>
								{ekkLanguage.dashboard.scDelivered}
							</h4>
							<h2 className='font-bold text-24 text-center text-black-1000 pb-5'>
								{digitChanger(analytics?.scheduled_for_delivery ?? 0)}
							</h2>
							{/* <div className='flex justify-end items-center'>
								<Link
									className='text-12 underline'
									style={{ color: "#00ACE5" }}
									to='#'>
									{ekkLanguage.dashboard.seeDetails}
								</Link>
							</div> */}
						</div>
					</IonCol>
					<IonCol size='6' className='pr-2 mb-[6px]'>
						<div
							className='p-[10px] bg-white rounded-[7px]'
							style={{
								boxShadow: " 0px 3px 20px 0px rgba(0, 0, 0, 0.05)",
							}}
							onClick={() =>
								getDetails(
									ekkLanguage.dashboard.deliveryFailed,
									"Delivery Failed"
								)
							}>
							<h4 className='font-bold text-14 text-black-1000 pb-4'>
								{ekkLanguage.dashboard.deliveryFailed}
							</h4>
							<h2 className='font-bold text-24 text-center text-black-1000 pb-5'>
								{digitChanger(analytics?.failed_delivery ?? 0)}
							</h2>
							{/* <div className='flex justify-end items-center'>
								<Link
									className='text-12 underline'
									style={{ color: "#00ACE5" }}
									to='#'>
									{ekkLanguage.dashboard.seeDetails}
								</Link>
							</div> */}
						</div>
					</IonCol>
					<IonCol size='6' className='pr-2 mb-[6px]'>
						<div
							className='p-[10px] bg-white rounded-[7px]'
							style={{
								boxShadow: " 0px 3px 20px 0px rgba(0, 0, 0, 0.05)",
							}}
							onClick={() =>
								getDetails(
									ekkLanguage.dashboard.partialDelivered,
									"Partially_Delivered"
								)
							}>
							<h4 className='font-bold text-14 text-black-1000 pb-4'>
								{ekkLanguage.dashboard.partialDelivered}
							</h4>
							<h2 className='font-bold text-24 text-center text-black-1000 pb-5'>
								{digitChanger(analytics?.partially_delivered ?? 0)}
							</h2>
							{/* <div className='flex justify-end items-center'>
								<Link
									className='text-12 underline'
									style={{ color: "#00ACE5" }}
									to='#'>
									{ekkLanguage.dashboard.seeDetails}
								</Link>
							</div> */}
						</div>
					</IonCol>
					<IonCol size='6' className='pr-2 mb-[6px]'>
						<div
							className='p-[10px] bg-white rounded-[7px]'
							style={{
								boxShadow: " 0px 3px 20px 0px rgba(0, 0, 0, 0.05)",
							}}
							onClick={() =>
								getDetails(ekkLanguage.dashboard.delivered, "Delivered")
							}>
							<h4 className='font-bold text-14 text-black-1000 pb-4'>
								{ekkLanguage.dashboard.delivered}
							</h4>
							<h2 className='font-bold text-24 text-center text-black-1000 pb-5'>
								{digitChanger(analytics?.delivered ?? 0)}
							</h2>
							{/* <div className='flex justify-end items-center'>
								<Link
									className='text-12 underline'
									style={{ color: "#00ACE5" }}
									to='#'>
									{ekkLanguage.dashboard.seeDetails}
								</Link>
							</div> */}
						</div>
					</IonCol>
					<IonCol size='6' className='pr-2 mb-[6px]'>
						<div
							className='p-[10px] bg-white rounded-[7px]'
							style={{
								boxShadow: " 0px 3px 20px 0px rgba(0, 0, 0, 0.05)",
							}}
							onClick={() =>
								getDetails(
									ekkLanguage.dashboard.partialPaid,
									"Partially_Paid"
								)
							}>
							<h4 className='font-bold text-14 text-black-1000 pb-4'>
								{ekkLanguage.dashboard.partialPaid}
							</h4>
							<h2 className='font-bold text-24 text-center text-black-1000 pb-5'>
								{digitChanger(analytics?.partially_paid ?? 0)}
							</h2>
							{/* <div className='flex justify-end items-center'>
								<Link
									className='text-12 underline'
									style={{ color: "#00ACE5" }}
									to='#'>
									{ekkLanguage.dashboard.seeDetails}
								</Link>
							</div> */}
						</div>
					</IonCol>
					<IonCol size='6' className='pr-2 mb-[6px]'>
						<div
							className='p-[10px] bg-white rounded-[7px]'
							style={{
								boxShadow: " 0px 3px 20px 0px rgba(0, 0, 0, 0.05)",
							}}
							onClick={() =>
								getDetails(ekkLanguage.dashboard.paid, "Paid")
							}>
							<h4 className='font-bold text-14 text-black-1000 pb-4'>
								{ekkLanguage.dashboard.paid}
							</h4>
							<h2 className='font-bold text-24 text-center text-black-1000 pb-5'>
								{digitChanger(analytics?.paid ?? 0)}
							</h2>
							{/* <div className='flex justify-end items-center'>
								<Link
									className='text-12 underline'
									style={{ color: "#00ACE5" }}
									to='#'>
									{ekkLanguage.dashboard.seeDetails}
								</Link>
							</div> */}
						</div>
					</IonCol>
					{/* <IonCol size='6' className='pr-2 mb-[6px]'></IonCol> */}
					{/* <IonCol size='6' className='pl-2'>
						<div
							className='p-[10px] bg-white rounded-[7px]'
							style={{
								boxShadow: " 0px 3px 20px 0px rgba(0, 0, 0, 0.05)",
							}}>
							<h4 className='font-bold text-14 text-black-1000 pb-4'>
								Pick Up Order
							</h4>
							<h2 className='font-bold text-24 text-center text-black-1000 pb-5'>
								{analytics?.placed}
							</h2>
							<div className='flex justify-end'>
								<Link
									className='text-12 underline'
									style={{ color: "#00ACE5" }}
									to='#'>
									See Details
								</Link>
							</div>
						</div>
					</IonCol> */}

					<IonCol size='6' className='pr-2 mb-[6px]'>
						<div
							className='p-[10px] bg-white rounded-[7px]'
							style={{
								boxShadow: " 0px 3px 20px 0px rgba(0, 0, 0, 0.05)",
							}}
							onClick={() =>
								getDetails(ekkLanguage.dashboard.directOrder, "direct")
							}>
							<div className='flex justify-between'>
								<h4 className='font-bold text-14 text-black-1000 pb-4'>
									{ekkLanguage.dashboard.directOrder}
								</h4>
								{/* <h6 className='text-12 text-black-333'>60%</h6> */}
							</div>

							<h2 className='font-bold text-24 text-center text-black-1000 pb-5'>
								{digitChanger(Number(analytics?.ekkBazCount ?? 0))}
							</h2>
							{/* <div className='flex justify-end items-center'>
								<span
									className='text-10 font-semibold text-success rounded-[21px] py-[3px] px-[4px] flex justify-center items-center'
									style={{ background: "#CCFFD6" }}>
									<ArrowUp color='#00991F' /> 0.5%
								</span>
								<Link
									className='text-12 underline'
									style={{ color: "#00ACE5" }}
									to='#'>
									{ekkLanguage.dashboard.seeDetails}
								</Link>
							</div> */}
						</div>
					</IonCol>
					<IonCol size='6' className='prl-2 mb-[6px]'>
						<div
							className='p-[10px] bg-white rounded-[7px]'
							style={{
								boxShadow: " 0px 3px 20px 0px rgba(0, 0, 0, 0.05)",
							}}
							onClick={() =>
								getDetails(
									ekkLanguage.dashboard.directOrderAmount,
									"direct"
								)
							}>
							<h4 className='font-bold text-14 text-black-1000 pb-4'>
								{ekkLanguage.dashboard.directOrderAmount}
							</h4>
							<h2 className='font-bold text-24 text-center text-black-1000 pb-5'>
								{digitChanger(
									numberWithCommas(analytics?.ekkBazOrderAmount ?? 0)
								)}
							</h2>
							{/* <div className='flex justify-end items-center'>
								<span
									className='text-10 font-semibold text-primary rounded-[21px] py-[3px] px-[4px] flex justify-center items-center'
									style={{ background: "#FFCFCC" }}>
									<ArrowDown color='#FF261A' /> 0.5%
								</span>
								<Link
									className='text-12 underline'
									style={{ color: "#00ACE5" }}
									to='#'>
									{ekkLanguage.dashboard.seeDetails}
								</Link>
							</div> */}
						</div>
					</IonCol>

					<IonCol size='6' className='pr-2 mb-[6px]'>
						<div
							className='p-[10px] bg-white rounded-[7px]'
							style={{
								boxShadow: " 0px 3px 20px 0px rgba(0, 0, 0, 0.05)",
							}}
							onClick={() =>
								getDetails(
									ekkLanguage.dashboard.ekkHeroOrder,
									"ekkhero"
								)
							}>
							<div className='flex justify-between '>
								<h4 className='font-bold text-14 text-black-1000 pb-4'>
									{ekkLanguage.dashboard.ekkHeroOrder}
								</h4>
								{/* <h6 className='text-12 text-black-333'>40%</h6> */}
							</div>

							<h2 className='font-bold text-24 text-center text-black-1000 pb-5'>
								{digitChanger(Number(analytics?.ekkHeroCount ?? 0))}
							</h2>
							{/* <div className='flex justify-end items-center'>
								<span
									className='text-10 font-semibold text-success rounded-[21px] py-[3px] px-[4px] flex justify-center items-center'
									style={{ background: "#CCFFD6" }}>
									<ArrowUp color='#00991F' /> 0.5%
								</span>
								<Link
									className='text-12 underline'
									style={{ color: "#00ACE5" }}
									to='#'>
									{ekkLanguage.dashboard.seeDetails}
								</Link>
							</div> */}
						</div>
					</IonCol>
					<IonCol size='6' className='pl-2 mb-[6px]'>
						<div
							className='p-[10px] bg-white rounded-[7px]'
							style={{
								boxShadow: " 0px 3px 20px 0px rgba(0, 0, 0, 0.05)",
							}}
							onClick={() =>
								getDetails(
									ekkLanguage.dashboard.ekkHeroOrderAmount,
									"ekkhero"
								)
							}>
							<h4 className='font-bold text-14 text-black-1000 pb-4'>
								{ekkLanguage.dashboard.ekkHeroOrderAmount}
							</h4>
							<h2 className='font-bold text-24 text-center text-black-1000 pb-5'>
								{digitChanger(
									numberWithCommas(analytics?.ekkHeroOrderAmount ?? 0)
								)}
							</h2>
							{/* <div className='flex justify-end items-center'>
								<span
									className='text-10 font-semibold text-primary rounded-[21px] py-[3px] px-[4px] flex justify-center items-center'
									style={{ background: "#FFCFCC" }}>
									<ArrowDown color='#FF261A' /> 0.5%
								</span>
								<Link
									className='text-12 underline'
									style={{ color: "#00ACE5" }}
									to='#'>
									{ekkLanguage.dashboard.seeDetails}
								</Link>
							</div> */}
						</div>
					</IonCol>

					<IonCol size='6' className='pr-2 mb-[6px]'>
						<div
							className='p-[10px] bg-white rounded-[7px]'
							style={{
								boxShadow: " 0px 3px 20px 0px rgba(0, 0, 0, 0.05)",
							}}
							onClick={() =>
								getDetails(ekkLanguage.dashboard.cancel, "Cancel")
							}>
							<h4 className='font-bold text-14 text-black-1000 pb-4'>
								{ekkLanguage.dashboard.cancel}
							</h4>
							<h2 className='font-bold text-24 text-center text-black-1000 pb-5'>
								{digitChanger(Number(analytics?.cancel ?? 0))}
							</h2>
							{/* <div className='flex justify-end items-center'>
								<span
									className='text-10 font-semibold text-success rounded-[21px] py-[3px] px-[4px] flex justify-center items-center'
									style={{ background: "#CCFFD6" }}>
									<ArrowUp color='#00991F' /> 0.5%
								</span>
								<Link
									className='text-12 underline'
									style={{ color: "#00ACE5" }}
									to='#'>
									{ekkLanguage.dashboard.seeDetails}
								</Link>
							</div> */}
						</div>
					</IonCol>
					<IonCol size='6' className='pl-2 mb-[6px]'>
						<div
							className='p-[10px] bg-white rounded-[7px]'
							style={{
								boxShadow: " 0px 3px 20px 0px rgba(0, 0, 0, 0.05)",
							}}
							onClick={() =>
								getDetails(
									ekkLanguage.dashboard.cancelOrderAmount,
									"Cancel"
								)
							}>
							<h4 className='font-bold text-14 text-black-1000 pb-4'>
								{ekkLanguage.dashboard.cancelOrderAmount}
							</h4>
							<h2 className='font-bold text-24 text-center text-black-1000 pb-5'>
								{digitChanger(
									numberWithCommas(analytics?.cancelAmount ?? 0)
								)}
							</h2>
							{/* <div className='flex justify-end items-center'>
								<span
									className='text-10 font-semibold text-success rounded-[21px] py-[3px] px-[4px] flex justify-center items-center'
									style={{ background: "#CCFFD6" }}>
									<ArrowUp color='#00991F' /> 0.5%
								</span>
								<Link
									className='text-12 underline'
									style={{ color: "#00ACE5" }}
									to='#'>
									{ekkLanguage.dashboard.seeDetails}
								</Link>
							</div> */}
						</div>
					</IonCol>
				</IonRow>
				{/* <IonRow className='mb-2 pb-5'>
					<IonCol size='6' className='pr-2'>
						<div
							className='p-[10px] bg-white rounded-[7px]'
							style={{
								boxShadow: " 0px 3px 20px 0px rgba(0, 0, 0, 0.05)",
							}}>
							<h4 className='font-bold text-14 text-black-1000 pb-4'>
								Return Product
							</h4>
							<h2 className='font-bold text-24 text-center text-black-1000 pb-5'>
								56
							</h2>
							<div className='flex justify-between items-center'>
								<span
									className='text-10 font-semibold text-primary rounded-[21px] py-[3px] px-[4px] flex justify-center items-center'
									style={{ background: "#FFCFCC" }}>
									<ArrowDown color='#FF261A' /> 0.5%
								</span>
								<Link
									className='text-12 underline'
									style={{ color: "#00ACE5" }}
									to='#'>
									See Details
								</Link>
							</div>
						</div>
					</IonCol>
					<IonCol size='6' className='pl-2'>
						<div
							className='p-[10px] bg-white rounded-[7px]'
							style={{
								boxShadow: " 0px 3px 20px 0px rgba(0, 0, 0, 0.05)",
							}}>
							<h4 className='font-bold text-14 text-black-1000 pb-4'>
								Return Product Am..
							</h4>
							<h2 className='font-bold text-24 text-center text-black-1000 pb-5'>
								355786.00
							</h2>
							<div className='flex justify-between items-center'>
								<span
									className='text-10 font-semibold text-success rounded-[21px] py-[3px] px-[4px] flex justify-center items-center'
									style={{ background: "#CCFFD6" }}>
									<ArrowUp color='#00991F' /> 0.5%
								</span>
								<Link
									className='text-12 underline'
									style={{ color: "#00ACE5" }}
									to='#'>
									See Details
								</Link>
							</div>
						</div>
					</IonCol>
				</IonRow> */}
			</IonGrid>
		</div>
	);
}
