import { IonLoading } from "@ionic/react";
import React from "react";
import { useSelector } from "react-redux";
import NotificationCard from "./NotificationCard";
// import NotificationTypeBtn from "./NotificationTypeBtn";
// import SupplierNotificationCard from "./SupplierNotificationCard";

export default function NotificationList({
  loading,
  setLoading,
  notificationType,
  setNotificationType,
  from,
  localLanguage
}) {
  const ekkLanguage = useSelector((state) => state.lan.lan);
  // const supplierUnreadCount = useSelector(
  //   (state) => state.notification.supplierUnreadCount
  // );
  // const customerUnreadCount = useSelector(
  //   (state) => state.notification.customerUnreadCount
  // );
  return (
    <div className="px-2 pt-14">
      {/* <NotificationTypeBtn
        setNotificationType={setNotificationType}
        notificationType={notificationType}
        supplierUnreadCount={supplierUnreadCount}
        customerUnreadCount={customerUnreadCount}
      /> */}
      {loading ? (
        <IonLoading
          isOpen={true}
          message={ekkLanguage.orderList?.loader}
        ></IonLoading>
      ) : (
        <NotificationCard localLanguage={localLanguage} from={from} />
      )}
    </div>
  );
}
