import { Button } from "components/global-components/common/button";
import { Input } from "components/global-components/common/Input";
import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import Auth from "services/Auth";
import { authBox_Block } from "Constants";
import { authOtpSend } from "helpers/helper";
import { useSelector } from "react-redux";
import { updateToken } from "config/SuperFetch";
import LeftArrow2 from "assets/images/icon/svg/LeftArrow2";

export default function Token({
	loading,
	setToken,
	phoneNo,
	country,
	countryCode,
	startLoading,
	stopLoading,
	token,
	setBlockIndex,
	isForgotPassword,
	sSize,
}) {
	let clickBtnRef = useRef(null);
	const [waiting, setWaiting] = useState(false);
	const [counter, setCounter] = useState(120);
	const ekkLanguage = useSelector((state) => state.lan.lan);
	let time = 0;
	var interval;

	const _handleKeyPress = (e) => {
		if (e.key === "Enter") {
			clickBtnRef.click();
		}
	};

	//this function handle token resend
	const resendTokenHandler = async (phoneNumber, country, countryCode) => {
		let getToken = authOtpSend(phoneNumber, country, countryCode);
		getToken
			.then((res) => {
				if (res.status === 200) {
					// setBlockIndex(authBox_Block.TOKEN);
					toast.success(ekkLanguage.token.tokenSuccessLabel);
					setWaiting(true);
					interval = setInterval(() => {
						time = time + 1;
						setCounter(counter - time);
						if (time === 120) {
							setWaiting(false);
							clearInterval(interval);
							setCounter(120);
						}
					}, 1000);
				} else {
					// setBlockIndex(authBox_Block.TOKEN);
					toast.success(ekkLanguage.token.tokenErrorLabel);
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const tokenSubmit = async (countryCode, country, phoneNumber) => {
		if (token) {
			let req_body = {
				country: country,
				phone_number: (countryCode + phoneNumber).trim(),
				otp_token: Number(token),
			};

			try {
				startLoading();
				let response = await Auth.userVerifyOtp(req_body);
				console.log(response);
				if (response.status === 200) {
					updateToken(response);
					if (response.success) {
						toast.success(ekkLanguage.token.tokenVarified);
						setBlockIndex(authBox_Block.CHANGE_PASS_BLOCK);
					} else {
						toast.error(ekkLanguage.token.tokenNotMatch);
					}
				}
				stopLoading();
			} catch (e) {
				stopLoading();
				toast.error(ekkLanguage.token.tokenNotMatch);
				console.log(e);
			}
		} else {
			toast.error(ekkLanguage.token.tokenNotWrite);
		}
	};
	useEffect(() => {
		setWaiting(true);
		let time = 0;
		let int;
		int = setInterval(() => {
			time = time + 1;
			setCounter(counter - time);
			if (time === 120) {
				setWaiting(false);
				clearInterval(int);
				setCounter(120);
			}
		}, 1000);
	}, []);
	return (
		<>
			<div>
				<label
					className={`text-14 md:text-20 text-262626-1000 sm:pb-3 pb-[10px] flex justify-between items-center`}>
					{ekkLanguage.token.tokenLabel}
					<span
						onClick={() => setBlockIndex(authBox_Block.PASSWORD_BLOCK)}
						className='text-xs text-red cursor-pointer flex items-center'>
						<span className='mr-1'>
							<LeftArrow2 />
						</span>
						{ekkLanguage?.password.backWord}
					</span>
				</label>
				<div className='pb-5'>
					<input
						onChange={(event) => {
							setToken(event.target.value);
						}}
						id={"ekk-input"}
						label={ekkLanguage.token.tokenLabel}
						placeholder={ekkLanguage.token.tokenPlaceHolder}
						onKeyPress={_handleKeyPress}
						className='appearance-none text-base w-full text-grey-darker border border-left-0  py-2 px-2 leading-tight focus:outline-none focus:bg-white bg-white'
						style={{
							paddingLeft: 30,
							height: sSize.matches ? 50 : 80,
							flex: "flex: 1 0 auto",
							borderRadius: ".25rem",
							borderTopLeftRadius: 0,
							borderBottomLeftRadius: 0,
							borderWidth: 0,
							borderLeft: "1px solid rgba(0,0,0,.05)",
						}}
					/>
				</div>

				<div>
					{!waiting ? (
						<span
							onClick={() =>
								resendTokenHandler(phoneNo, country, countryCode)
							}
							id='resend-token'
							className='text-sm underline text-red'>
							{ekkLanguage.token.tokenResend}
						</span>
					) : (
						<p className='text-dark-500 text-14 font-normal'>
							{ekkLanguage.token.timerLabel} {counter}{" "}
							{ekkLanguage.token.second}
						</p>
					)}
				</div>
			</div>

			<div className='flex justify-center py-4'>
				{/* <Button
					title={ekkLanguage.token.btnLabel}
					loading={loading}
					onClick={() => tokenSubmit(countryCode, country, phoneNo)}
					propsRef={(input) => {
						clickBtnRef = input;
					}}
				/> */}
				<button
					onClick={() => tokenSubmit(countryCode, country, phoneNo)}
					ref={clickBtnRef}
					style={{
						background:
							"linear-gradient(89.31deg, #00C0FF 0%, rgba(0, 178, 36, 0.5) 100%)",
					}}
					className={`text-20 sm:text-26 font-bold text-white w-full h-[50px] sm:h-[60px] rounded-[5px]`}>
					{ekkLanguage?.token.btnLabel}
				</button>
			</div>
		</>
	);
}
