import {
	IonApp,
	IonButton,
	IonContent,
	IonPage,
	IonSpinner,
} from "@ionic/react";
import BackButtonTitle from "components/mobile-components/Common/BackButtonTitle";
import { getUserBusiness } from "config/user";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import Payment from "services/Payment";
import Modal from "react-modal";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import Card from "components/mobile-components/Common/Card";
import CheckoutForm from "components/mobile-components/AddCard/CheckoutForm";
import { digitChanger } from "helpers/helper";
import { baseUrl } from "config/apiUrl";

export default function PaymentDetailsSubscriptionPage() {
	const navigate = useNavigate();
	const billing = useSelector((state) => state.subscription?.upgradePlan);
	const { methodType } = useParams();
	const [cardList, setCardList] = useState(null);
	const [cardLoading, setCardLoading] = useState(false);
	const business = getUserBusiness();
	const [clientSecret, setClientSecret] = useState("");
	const [customerId, setCustomerId] = useState("");
	const [newCardModel, setNewCardModel] = useState(false);
	const openCardModel = () => setNewCardModel(true);
	const closeCardModel = () => setNewCardModel(false);
	const [paymentLoader, setPaymentLoader] = useState(false);
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const [cardLoadingError, setCardLoadingError] = useState(null);
	const [couponCode, setCouponCode] = useState();
	const [couponCodeResult, setCouponCodeResult] = useState();
	const [couponText, setCouponText] = useState("");
	const [couponModel, setCouponModel] = useState(false);
	const openCouponModel = () => setCouponModel(true);
	const closeCouponModel = () => setCouponModel(false);
	const [couponInput, setCouponInput] = useState("");

	const getCardList = async () => {
		setCardLoading(true);
		let response = await Payment.getCards(business?.id);
		if (response) {
			let defaultCard = response.card?.find((el) => el.is_default);
			setCardList(defaultCard);
			setCardLoading(false);
		} else {
			setCardList(null);
			setCardLoading(false);
		}
	};

	useEffect(() => {
		getCardList();
	}, []);

	const customStyles = {
		content: {
			top: "50%",
			left: "50%",
			right: "auto",
			bottom: "auto",
			marginRight: "-50%",
			transform: "translate(-50%, -50%)",
			padding: "0px",
			width: "300px",
			transition: ".4s all ease",
			borderRadius: 12,
		},
		overlay: {
			position: "fixed",
			top: 0,
			left: 0,
			right: 0,
			bottom: 0,
			transition: ".4s all ease",
			backgroundColor: "rgba(0,0,0,.5)",
		},
	};
	let publicKey = baseUrl.stripPublicKey;

	const appearance = {
		theme: "stripe",
	};

	const stripePromise = loadStripe(publicKey);
	const getSecret = async () => {
		let query = {
			seller_id: business?.id,
			public_key: publicKey,
		};
		let response = await Payment.cardLoading(query);
		if (response.success) {
			setClientSecret(response?.clientSecret);
			setCustomerId(response?.customer_id);
		} else {
			setCardLoadingError(response?.message);
		}
	};
	useEffect(() => {
		getSecret();
	}, []);

	const options = {
		clientSecret,
		appearance,
	};
	const getCouponHandler = async () => {
		if (couponCode) {
			let response = await Payment.getCoupon(couponCode);
			if (response.status === 200) {
				if (response?.results?.valid) {
					setCouponCodeResult(response.results);
					openCouponModel();
					// setCouponCode("");
				} else {
					setCouponText(`Coupon Code expired`);
				}
			} else {
				setCouponText(`Coupon Code invalid`);
			}
		} else {
			setCouponInput("Enter a valid coupon code.");
		}
	};
	const getDiscountAmount = () => {
		return couponCodeResult?.amount_off || couponCodeResult?.percent_off
			? couponCodeResult?.amount_off
				? couponCodeResult?.amount_off
				: (couponCodeResult?.percent_off / 100) * billing?.amount
			: 0;
	};

	const paymentHandler = async () => {
		setPaymentLoader(true);
		let payload = {
			customer_id: cardList?.customer,
			payment_method_id: cardList?.id,
			seller_id: business?.id,
			stripe_plan_id: billing?.stripe_plan_id,
			plan_id: billing?.id,
			amount: billing?.amount - getDiscountAmount(),
			country: business?.country,
			discount_amount: getDiscountAmount(),
			coupon_id: couponCode ? couponCode : null,
		};
		let response = await Payment.makePayment(payload);
		if (response?.success) {
			setPaymentLoader(false);
			navigate("/payment-success");
		} else {
			setPaymentLoader(false);
		}
	};

	return (
		<IonApp>
			<IonPage>
				<IonContent>
					<BackButtonTitle title={ekkLanguage.paymentModule.checkOut} />
					<section className='pt-14 relative w-full h-full px-4'>
						<div className='pt-5'>
							<div className='flex justify-between items-center'>
								<h4 className='font-bold mb-4'>
									{ekkLanguage.paymentModule.paymentCard}
								</h4>
								{!cardList?.brand && (
									<IonButton color='success' onClick={openCardModel}>
										<span className='text-14 font-bold text-white'>
											{ekkLanguage?.addCard.addNewCard}
										</span>
									</IonButton>
								)}
							</div>

							{!cardLoading ? (
								<>
									{cardList?.brand && (
										<>
											<div className='border-2 py-2 px-3 rounded-[10px] border-success'>
												<div className='flex justify-between items-center'>
													{" "}
													<h4 className='font-bold uppercase text-success'>
														{cardList?.brand}
													</h4>
													<h4 className='font-bold'>
														********{cardList?.last4}
														<span className='block text-right mt-1 font-normal'>
															{cardList?.exp_month}/
															{cardList?.exp_year}
														</span>
													</h4>
												</div>
											</div>
											<Link
												to='/card-add'
												className='text-14 text-delivered block text-right mt-1'>
												{ekkLanguage.paymentModule.changeCard}
											</Link>
										</>
									)}
								</>
							) : (
								<div className='flex justify-center items-center py-5'>
									<IonSpinner
										name='crescent'
										className='mr-2'></IonSpinner>
								</div>
							)}
						</div>
						<div className='pt-5'>
							<h4 className='font-bold mb-4'>
								{" "}
								{ekkLanguage.paymentModule.selectPackage}
							</h4>
							<div
								className={`relative flex justify-between items-center border-2 rounded-[10px] border-success bg-white mb-5 p-2 cursor-pointer`}>
								<h6
									className={`font-bold text-18 ${"text-black-1000"} `}>
									{billing?.title}
								</h6>
								<div className='flex items-center'>
									<h6
										className={`font-bold text-24 ${"text-black-1000"}`}>
										{billing?.country?.currency_symbol_native}
										{billing?.amount}
									</h6>
									<p
										className={`font-medium text-12 ${"text-black-1000"}`}>
										({billing?.plan_type})
									</p>
								</div>
							</div>
						</div>

						<div>
							<h4 className='font-bold'>
								{" "}
								{ekkLanguage.paymentModule.couponDiscount}
							</h4>
							<div className='pt-4 pb-5 '>
								<div className='relative'>
									<input
										className={`bg-white w-full h-[50px] rounded-[7px] pl-5 search-input border common-search`}
										type='string'
										placeholder={
											ekkLanguage.paymentModule.enterCouponCode
										}
										style={{
											boxShadow: "1px 2px 8px rgba(0, 0, 0, 0.05) ",
										}}
										onChange={(e) => setCouponCode(e.target.value)}
										value={couponCode}></input>

									<button
										className='absolute right-[5px] top-[5px] bg-success w-[120px] rounded-[5px] text-white h-[calc(100%-10px)] font-bold'
										onClick={getCouponHandler}>
										{ekkLanguage.paymentModule.getDiscount}
									</button>
								</div>
								<p className='text-primary'>{couponInput}</p>
							</div>
						</div>
						<div>
							<h4 className='font-bold'>
								{ekkLanguage.paymentModule.orderSummary}
							</h4>
							<div className='py-5'>
								<table class='table-auto w-full'>
									<tbody>
										<tr className='text-center'>
											<td className='p-2'>
												{ekkLanguage.paymentModule.subtotal}
											</td>
											<td className='p-2'>
												{billing?.country?.currency_symbol_native}
												{digitChanger(billing?.amount)}
											</td>
										</tr>
										<tr className='text-center border-b border-black-1000'>
											<td className='p-2'>
												{" "}
												{ekkLanguage.paymentModule.discount}
											</td>
											<td className='p-2'>
												-{billing?.country?.currency_symbol_native}
												{digitChanger(getDiscountAmount())}
											</td>
										</tr>
									</tbody>
									<tfoot>
										<tr className='text-center'>
											<td className='font-bold p-2'>
												{ekkLanguage.paymentModule.total}
											</td>
											<td className='font-bold p-2'>
												{billing?.country?.currency_symbol_native}
												{digitChanger(
													billing?.amount - getDiscountAmount()
												)}
											</td>
										</tr>
									</tfoot>
								</table>
							</div>
						</div>
					</section>

					{/* Upgrade Button  */}
					<IonButton
						onClick={!paymentLoader && paymentHandler}
						disabled={paymentLoader || !cardList?.brand}
						type='submit'
						color='success'
						className='font-bold h-12 text-20 rounde-lg w-[95%] mt-12'>
						<span className='text-white flex justify-center items-center'>
							{paymentLoader && (
								<IonSpinner
									name='crescent'
									className='mr-2'></IonSpinner>
							)}
							{ekkLanguage.paymentModule.payNow}{" "}
							{billing?.country?.currency_symbol_native}
							{digitChanger(billing?.amount - getDiscountAmount())}
						</span>
					</IonButton>
				</IonContent>
			</IonPage>
			<Modal
				style={customStyles}
				closeTimeoutMS={200}
				isOpen={newCardModel}
				onRequestClose={closeCardModel}
				classNames='bottom-sheet-modal'
				showBackdrop={true}>
				<div>
					<Card
						title={"Add New Card"}
						onHide={true}
						closeModal={closeCardModel}>
						<div className='pt-4'>
							{clientSecret ? (
								<Elements stripe={stripePromise} options={options}>
									<CheckoutForm
										closeCardModel={closeCardModel}
										clientSecret={clientSecret}
										customerId={customerId}
										getCardList={getCardList}
									/>
								</Elements>
							) : (
								<p>{cardLoadingError}</p>
							)}
						</div>
					</Card>
				</div>
			</Modal>
			<Modal
				style={customStyles}
				closeTimeoutMS={200}
				isOpen={couponModel}
				onRequestClose={closeCouponModel}
				classNames='bottom-sheet-modal'
				showBackdrop={true}>
				<div>
					<Card
						title={"Coupon Details"}
						onHide={true}
						closeModal={closeCouponModel}>
						<div className='pt-4'>
							<p>
								{couponCodeResult?.amount_off
									? `You got successfully
								${billing?.country?.currency_symbol_native}${couponCodeResult?.amount_off} discount.`
									: `You got successfully ${couponCodeResult?.percent_off}% discount.`}
							</p>
						</div>
					</Card>
				</div>
			</Modal>
		</IonApp>
	);
}
