import React from "react";
import { useSelector } from "react-redux";
import SectionHeader from "./SectionHeader";

export default function Backers() {
	const ekkLanguage = useSelector((state) => state.lan.lan);
	return (
		<div className='container m-auto pb-[40px] md:pb-[120px] lg:pb-[160px] bg-F2F3F7'>
			<SectionHeader title={ekkLanguage.landing.backers} />
			<div className='flex flex-wrap justify-center items-center'>
				<div className='xl:w-[20%] lg:w-6/12 md:w-6/12 w-full'>
					<div className='mb-7 px-4'>
						<div className=' mb-[10px] flex justify-center items-center'>
							<img
								src='assets/images/keystone.png'
								alt='icon'
								className='object-contain'
							/>
						</div>
					</div>
				</div>
				<div className='xl:w-[20%] lg:w-6/12 md:w-6/12 w-full'>
					<div className=' mb-[10px] flex justify-center items-center'>
						<img
							src='assets/images/hatua.png'
							alt='icon'
							className='object-contain'
						/>
					</div>
				</div>
				<div className='xl:w-[20%] lg:w-6/12 md:w-6/12 w-full'>
					<div className=' mb-[10px] flex justify-center items-center'>
						<img
							src='assets/images/esing-logo.png'
							alt='icon'
							className='object-contain'
						/>
					</div>
				</div>
				<div className='xl:w-[20%] lg:w-6/12 md:w-6/12 w-full'>
					<div className=' mb-[10px] flex justify-center items-center'>
						<img
							src='assets/images/artesin.png'
							alt='icon'
							className='object-contain'
						/>
					</div>
				</div>
				<div className='xl:w-[20%] lg:w-6/12 md:w-6/12 w-full'>
					<div className=' mb-[10px] flex justify-center items-center'>
						<img
							src='assets/images/sosv.png'
							alt='icon'
							className='object-contain'
						/>
					</div>
				</div>
			</div>
		</div>
	);
}
