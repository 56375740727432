import { digitChanger, numberWithCommas } from "helpers/helper";
import React from "react";
import { useNavigate } from "react-router-dom";

export default function SingleCustomer({ item, from }) {
	const navigate = useNavigate();

	return (
		<div
			className='w-full bg-white rounded-[5px] px-[10px] mt-4 py-2'
			style={{ boxShadow: "0px 5px 15px rgba(0,0,0,0.05)" }}
			// onClick={() =>
			// 	navigate(`/customer/${item.id}/${item.related_business_id}`)
			// }
		>
			{/* {item?.images?.length ? (
				<div className='h-24 flex justify-center items-center overflow-hidden bg-white rounded-[10px]'>
					<img className='' src={item?.images[0]} alt='logo'></img>
				</div>
			) : (
				<div className='h-24 flex justify-center items-center overflow-hidden bg-white rounded-[10px]'>
					<img
						className=''
						src='/assets/images/shops.png'
						alt='logo'></img>
				</div>
			)} */}
			<div className='flex flex-col justify-center px-1'>
				<h4 className='text-14 text-black-1000 font-bold py-[2px]'>
					{item?.related_business}
				</h4>
				<div className='flex justify-between items-center'>
					{/* <p className='text-12 text-black-500 font-normal'>
						{item?.related_business_address}
					</p> */}
					<div className='flex justify-between items-center w-full'>
						{item?.distance && (
							<div className='flex items-center'>
								{" "}
								<img
									className='h-[10px] w-[10px]'
									src='/assets/images/Icon/pin.png'
									alt=''
								/>
								<p className='text-12 font-bold text-[#FF261A] ml-0.5'>
									{Number(item?.distance).toFixed(2)}km
								</p>
							</div>
						)}
						{item?.relationship_status === "Invited" && (
							<p className='text-12 text-success font-bold'>Invited</p>
						)}
					</div>
				</div>
				{/* <p className='font-bold text-14 text-primary pt-[3px]'>
					{item?.due_amount?.currency ?? ""}{" "}
					{digitChanger(numberWithCommas(item?.due_amount?.due))}
				</p> */}
			</div>
		</div>
	);
}
