import EyeClose from "assets/images/icon/svg/EyeClose";
import EyeOpen from "assets/images/icon/svg/EyeOpen";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Modal from "react-modal";
import CancelPopUp from "assets/images/icon/svg/CancelPopUp";
import { useDispatch, useSelector } from "react-redux";
import { digitChanger, numberWithCommas } from "helpers/helper";
import { IonCol } from "@ionic/react";
import { ClipLoader } from "react-spinners";
import TextInputWithIcon from "../Common/TextInputWithIcon";
import { setProductList } from "redux/container/productSlice";
import Cross from "assets/images/icon/svg/Cross";
import Customer from "services/Customer";
import { toast } from "react-toastify";

export default function SingleProduct({ item, productList }) {
	const relationDetails = useSelector(
		(state) => state.distributor.relationDetails
	);
	const [openPriceModal, setOpenPriceModal] = useState(false);
	const [sellingPriceEmpty, setSellingPriceEmpty] = useState("");
	const [sellingPrice, setSellingPrice] = useState(
		item?.procure_price > 0 && item?.procure_price
	);
	const dispatch = useDispatch();
	const [loader, setLoader] = useState(false);
	const onOpenModal = () => setOpenPriceModal(true);
	const onCloseModal = () => setOpenPriceModal(false);
	const customModalStyles = {
		content: {
			top: "50%",
			left: "50%",
			right: "auto",
			bottom: "auto",
			marginRight: "-50%",
			transform: "translate(-50%, -50%)",
			padding: "0px",
			width: "300px",
			transition: ".4s all ease",
			borderRadius: 12,
			// height: "calc(100% - 60px)",
		},
		overlay: {
			position: "fixed",
			top: 0,
			left: 0,
			right: 0,
			bottom: 0,
			transition: ".4s all ease",
			backgroundColor: "rgba(0,0,0,.5)",
		},
	};
	const ekkLanguage = useSelector((state) => state.lan.lan);

	const imageChecker = () => {
		if (item.images[0]) {
			return `${item.images[0]}`;
		} else {
			if (item.type === "variant") {
				return "/assets/images/varient_default.png";
			} else if (item.type === "pack") {
				return "/assets/images/pack_default.png";
			} else if (item.type === "offer") {
				return "/assets/images/offer_default.png";
			}
		}
	};
	return (
		<IonCol size='6' className='mb-4'>
			<div
				className='rounded-md'
				onClick={() => {
					onOpenModal();
				}}>
				<div className='rounded-xl overflow-hidden bg-E6E6E6 p-3 mb-1 relative'>
					<img
						src={imageChecker()}
						alt='product'
						className='w-full image-sizer'
					/>
				</div>

				<div>
					<h5 className='font-semibold text-16'>{item.title}</h5>
					<span className='font-bold text-12 text-primary'>
						{item?.country?.currency_symbol_native}{" "}
						{digitChanger(item?.procure_price)}
					</span>
				</div>
			</div>
			<Modal
				style={customModalStyles}
				closeTimeoutMS={200}
				isOpen={openPriceModal}
				onRequestClose={onCloseModal}
				ariaHideApp={false}
				classNames='bottom-sheet-modal'>
				<div className='p-5'>
					<div className='border-b-2 mb-4 flex  justify-between'>
						<h4 className='text-14 text-222222 font-bold pb-[10px]'>
							{ekkLanguage.customerList.modalTitle}
						</h4>
						<span onClick={onCloseModal} className='cursor-pointer'>
							<Cross />
						</span>
					</div>
					<h6 className='text-16 font-bold pb-[10px]'>{item?.title}</h6>
					<TextInputWithIcon
						type='number'
						title={ekkLanguage.createProduct.sellingPrice}
						inputText={sellingPrice}
						setInputText={setSellingPrice}
						placeholder={ekkLanguage.customerList.inputPlaceholder}
						icon='assets/images/selling.png'
						errorText={sellingPriceEmpty}
						required={true}
					/>

					<div className='flex'>
						<button
							className='border-2 font-bold h-10 rounded-[7px] w-1/2 mr-2 text-black-1000 border-black-200 border-solid'
							onClick={onCloseModal}>
							{ekkLanguage.customerList.cancelBtn}
						</button>
						<button
							onClick={async () => {
								if (sellingPrice > 0) {
									const data = {
										catalog: item?.cat_id,
										initiator_business:
											relationDetails?.initiator_business_id,
										related_business:
											relationDetails?.related_business_id,
										price: Number(sellingPrice),
									};
									const response = await Customer.customPricePost(
										data
									);
									if (response.status === 201) {
										const selectedProduct = productList.map(
											(element) =>
												element.id === item?.id
													? {
															...element,
															procure_price: sellingPrice,
													  }
													: element
										);
										dispatch(setProductList(selectedProduct));
										toast.success("Price set successfully!");
										onCloseModal();
									} else {
										setSellingPriceEmpty(
											ekkLanguage.customerList.alreadySetPriceError
										);
									}
								} else {
									setSellingPriceEmpty(
										ekkLanguage.customerList.priceEmptyError
									);
								}
							}}
							color='primary'
							className={`${
								loader && "select-none cursor-no-drop"
							} font-bold h-10 rounded-[7px] w-1/2 ml-2 border-2 bg-success text-white flex justify-center items-center`}>
							{loader ? (
								<ClipLoader
									sizeUnit={"px"}
									size={20}
									color={"white"}
									loading={true}
								/>
							) : (
								ekkLanguage.customerList.submit
							)}
						</button>
					</div>
				</div>
			</Modal>
		</IonCol>
	);
}
