import {
	IonApp,
	IonContent,
	IonInfiniteScroll,
	IonInfiniteScrollContent,
	IonPage,
} from "@ionic/react";
import LeftSidebar from "components/global-components/Navigation/LeftSidebar";
import BackButtonTitle from "components/mobile-components/Common/BackButtonTitle";
import OrderStatus from "components/mobile-components/OrderList/OrderStatus";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { fetchOrder } from "redux/container/orderSlice";
import { useDispatch } from "react-redux";

export default function OrderList() {
	const [menuCollapse, setMenuCollapse] = useState(true);
	const [productLastResponse, setProductLastResponse] = useState([]);
	const [orderStatus, setOrderStatus] = useState([]);
	const [loading, setLoading] = useState(false);
	const [hasMoreData, setHasMoreData] = useState(true);
	const [reachedEnd, setReachedEnd] = useState(false);

	const menuIconClick = () => {
		setMenuCollapse(!menuCollapse);
	};
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const dispatch = useDispatch();
	let orderListPage = useSelector((state) => state.order.page);

	let getInitOrderList = async () => {
		let response = await dispatch(fetchOrder(""));
		if (response.status === 200) {
			setLoading(false);
			setProductLastResponse(response.data);
		} else {
			setLoading(false);
		}
	};

	let getOrderList = async (value) => {
		if (hasMoreData) {
			let response = await dispatch(fetchOrder(value, orderListPage));
			if (response.status === 200) {
				setProductLastResponse(response.data);
				if (!response.data.length) {
					setHasMoreData(false);
				}
			}
		}
	};
	useEffect(() => {
		setLoading(true);
		let timeDelay = setTimeout(() => {
			getInitOrderList();
		}, 500);
		return () => clearTimeout(timeDelay);
	}, []);

	return (
		<IonApp>
			<IonPage>
				<IonContent>
					<LeftSidebar
						isMobile={true}
						menuCollapse={menuCollapse}
						menuIconClick={menuIconClick}
					/>
					<BackButtonTitle
						title={ekkLanguage.orderList.pageTitle}
						burgerMenu={true}
						menuCollapse={menuCollapse}
						menuIconClick={menuIconClick}
					/>
					<section>
						<OrderStatus
							setOrderStatus={setOrderStatus}
							loading={loading}
							setLoading={setLoading}
							setReachedEnd={setReachedEnd}
							setHasMoreData={setHasMoreData}
						/>
					</section>
					<IonInfiniteScroll
						onIonInfinite={(ev) => {
							if (reachedEnd) {
								ev.target.complete();
								return;
							}
							getOrderList(orderStatus);
							setTimeout(() => {
								ev.target.complete();
								if (!hasMoreData) {
									setReachedEnd(true);
								}
							}, 500);
						}}>
						<IonInfiniteScrollContent
							loadingText={ekkLanguage.orderList.loader}
							loadingSpinner={
								hasMoreData ? "bubbles" : null
							}></IonInfiniteScrollContent>
					</IonInfiniteScroll>
				</IonContent>
			</IonPage>
		</IonApp>
	);
}
