import React, { useEffect, useState } from "react";
import Sheet from "react-modal-sheet";
import { IonButton, IonItem, IonLabel } from "@ionic/react";
import { useDispatch, useSelector } from "react-redux";
import { setCustomerDetails } from "redux/container/userSlice";
import "react-responsive-modal/styles.css";
import { useNavigate } from "react-router-dom";
import { emptyBusiness } from "redux/container/businessSettingsSlice";
import Modal from "react-modal";
import CancelPopUp from "assets/images/icon/svg/CancelPopUp";
import { ClipLoader } from "react-spinners";
import Customer from "services/Customer";
import { toast } from "react-toastify";
import Card from "../Common/Card";

export default function CustomerDetailsBottomSheet({
	id,
	customerDetails,
	getDistributorDetails,
}) {
	const [isOpen, setIsOpen] = useState(false);
	const [loader, setLoader] = useState(false);
	const [codeLoader, setCodeLoader] = useState(false);
	const onOpenModal = () => setIsOpen(true);
	const onCloseModal = () => setIsOpen(false);
	const ref = React.useRef();
	const isOpenBottomSheet = useSelector((state) => state.user.customerDetails);
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [disCode, setDisCode] = useState("");
	const customStyles = {
		content: {
			top: "50%",
			left: "50%",
			right: "auto",
			bottom: "auto",
			marginRight: "-50%",
			transform: "translate(-50%, -50%)",
			padding: "0px",
			width: "300px",
			transition: ".4s all ease",
			borderRadius: 12,
		},
		overlay: {
			position: "fixed",
			top: 0,
			left: 0,
			right: 0,
			bottom: 0,
			transition: ".4s all ease",
			backgroundColor: "rgba(0,0,0,.5)",
		},
	};
	const handleDelete = async function () {
		setLoader(true);
		let response = await Customer.deleteDistributor(id);
		if (response.status === 200) {
			setLoader(false);
			navigate("/distributor-list");
			toast.success(ekkLanguage.customerList.successMsg);
		} else {
			setLoader(false);
		}
	};
	const [isOpenCodeModel, setIsOpenCodeModel] = useState(false);
	const onCloseCodeModal = () => setIsOpenCodeModel(false);

	const handleDistributorCode = async function () {
		setLoader(true);
		let response = await Customer.addDistributor(id, { code: disCode });
		if (response.status === 200) {
			setLoader(false);
			getDistributorDetails();
			toast.success(
				customerDetails?.code
					? ekkLanguage.customerList.editSuccessLabel
					: ekkLanguage.customerList.successLabel
			);
			onCloseCodeModal();
		} else {
			setLoader(false);
		}
	};

	useEffect(() => setDisCode(customerDetails?.code), [customerDetails?.code]);

	return (
		<div>
			<Sheet
				className='bottom-sheet'
				initialSnap={1}
				ref={ref}
				isOpen={isOpenBottomSheet}
				snapPoints={[300, 300, 160, 0]}
				onClose={() => dispatch(setCustomerDetails(false))}>
				<Sheet.Container>
					<Sheet.Header />
					<Sheet.Content>
						<IonItem
							button
							onClick={() => {
								dispatch(setCustomerDetails(false));
								setIsOpenCodeModel(true);
							}}
							lines='none'>
							<div className='flex items-center justify-center py-[5px]'>
								<span className='mr-[10px]'>
									<img
										src={
											customerDetails?.code
												? "/assets/images/edit2.png"
												: "/assets/images/addDis.png"
										}
										className='h-10 w-10'
										alt=''
									/>
								</span>
								<p className='text-16 font-bold text-black-1000 pl-2'>
									{customerDetails?.code
										? ekkLanguage.customerList.distributorCodeEdit
										: ekkLanguage.customerList.distributorCodeAdd}
								</p>
							</div>
						</IonItem>
						<IonItem
							button
							onClick={() => {
								dispatch(setCustomerDetails(false));
								navigate("/product-list-custom-price");
							}}
							lines='none'>
							<div className='flex items-center justify-center py-[5px]'>
								{" "}
								<span className='mr-[10px]'>
									<img
										src='/assets/images/customPrice.png'
										className='h-10 w-10'
										alt=''
									/>
								</span>
								<p className='text-16 font-bold text-black-1000 pl-2'>
									{ekkLanguage.customerList.addCustomPrice}
								</p>
							</div>
						</IonItem>
						<IonItem
							button
							onClick={() => {
								dispatch(setCustomerDetails(false));
								navigate(`/distributor-default-payment-term/${customerDetails?.initiator_business_id}/${customerDetails?.related_business_id}/${customerDetails?.id}`);
							}}
							lines='none'>
							<div className='flex items-center justify-center py-[5px]'>
								{" "}
								<span className='mr-[10px]'>
									<img
										src='/assets/images/default_payment_menu.png'
										className='h-10 w-10'
										alt=''
									/>
								</span>
								<p className='text-16 font-bold text-black-1000 pl-2'>
									{ekkLanguage.defaultPayment.menuTitle}
								</p>
							</div>
						</IonItem>
						<IonItem
							button
							onClick={() => {
								dispatch(setCustomerDetails(false));
								onOpenModal();
							}}
							lines='none'>
							<div className='flex items-center justify-center py-[5px]'>
								{" "}
								<span className='mr-[10px]'>
									<img
										src='/assets/images/delete.png'
										className='h-10 w-10'
										alt=''
									/>
								</span>
								<p className='text-16 font-bold text-black-1000 pl-2'>
									{ekkLanguage.customerList.deleteDistributor}
								</p>
							</div>
						</IonItem>
					</Sheet.Content>
					
				</Sheet.Container>

				<Sheet.Backdrop
					onClick={() => dispatch(setCustomerDetails(false))}
				/>
			</Sheet>

			{/* Delete Modal  */}
			<Modal
				style={customStyles}
				closeTimeoutMS={200}
				isOpen={isOpen}
				onRequestClose={onCloseModal}
				classNames='bottom-sheet-modal'>
				<div className='p-5'>
					<span className='mb-2 inline-block mx-2 bg-[#F94B4126] p-[10px] rounded-full'>
						<img
							src='/assets/images/delete.png'
							className='h-10 w-10'
							alt=''
						/>
					</span>
					<h6 className='text-16 font-bold pb-[30px]'>
						{ekkLanguage.customerList.warningMsg}
					</h6>

					<div className='flex'>
						<button
							color='primary'
							className={`${
								loader && "select-none cursor-no-drop"
							} font-bold h-10 rounded-[7px] w-1/2 mr-2 border-2 bg-primary text-white flex justify-center items-center`}
							onClick={!loader && handleDelete}>
							{loader ? (
								<ClipLoader
									sizeUnit={"px"}
									size={20}
									color={"white"}
									loading={true}
								/>
							) : (
								ekkLanguage.customerList.yes
							)}
						</button>
						<button
							className='border-2 font-bold h-10 rounded-[7px] w-1/2 ml-2 bg-success text-white'
							onClick={onCloseModal}>
							{ekkLanguage.customerList.no}
						</button>
					</div>
				</div>
			</Modal>

			{/* add distributor code */}
			<Modal
				style={customStyles}
				closeTimeoutMS={200}
				isOpen={isOpenCodeModel}
				onRequestClose={onCloseCodeModal}
				classNames='bottom-sheet-modal'
				showBackdrop={true}>
				<div>
					<Card
						title={ekkLanguage.customerList.distributorCode}
						onHide={true}
						closeModal={onCloseCodeModal}>
						<div>
							<div className='pt-5 pb-5'>
								<IonLabel>
									{ekkLanguage.customerList.writeDistributorCode}
								</IonLabel>
								<IonItem style={{ "--background": "white" }}>
									<input
										type='string'
										value={disCode}
										className='focus-visible:none outline-none'
										onChange={(e) => setDisCode(e.target.value)}
										placeholder={
											ekkLanguage.customerList.distributorCode
										}></input>
								</IonItem>
							</div>

							<div className='text-center pt-0'>
								<IonButton
									color='primary'
									onClick={handleDistributorCode}>
									{codeLoader ? (
										<ClipLoader
											sizeUnit={"px"}
											size={20}
											color={"white"}
											loading={true}
										/>
									) : (
										ekkLanguage.createOrder.btnLabel
									)}
								</IonButton>
							</div>
						</div>
					</Card>
				</div>
			</Modal>
		</div>
	);
}
