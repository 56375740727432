import { combineReducers } from "redux";
import auth from "redux/container/authSlice";
import businessSettingsSlice from "redux/container/businessSettingsSlice";
import user from "redux/container/userSlice";
import productSlice from "redux/container/productSlice";
import { counterSlice } from "redux/container/counter/counterSlice";
import orderSlice from "redux/container/orderSlice";
import paymentMethodSlice from "redux/container/paymentMethodSlice";
import shoppingCartSlice from "redux/container/shoppingCartSlice";
import languageSlice from "redux/container/languageSlice";
import customerSlice from "redux/container/customerSlice";
import dashboard from "redux/container/dashboardSlice";
import orderDetailsSlice from "redux/container/orderDetailsSlice";
import notificationSlice from "redux/container/notificationSlice";
import ProductSummerySlice from "redux/container/ProductSummerySlice";
import driverSlice from "redux/container/driverSlice";
import distributorSlice from "redux/container/distributorSlice";
import addBankSlice from "redux/container/addBankSlice";
import loanSlice from "redux/container/loanSlice";
import SubscriptionSlice from "redux/container/SubscriptionSlice";

const appReducer = combineReducers({
	auth,
	user,
	cart: shoppingCartSlice,
	counter: counterSlice,
	order: orderSlice,
	payment: paymentMethodSlice,
	lan: languageSlice,
	businessSettings: businessSettingsSlice,
	product: productSlice,
	customer: customerSlice,
	dashboard,
	orderDetails: orderDetailsSlice,
	productSummery: ProductSummerySlice,
	driver: driverSlice,
	notification: notificationSlice,
	distributor: distributorSlice,
	bankSettings: addBankSlice,
	loan: loanSlice,
	subscription: SubscriptionSlice,
});

const rootReducer = (state, action) => {
	if (action.type === "auth/logout") state = undefined;
	return appReducer(state, action);
};
export default rootReducer;
