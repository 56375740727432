import { IonCheckbox, IonCol, IonLoading } from "@ionic/react";
import RightArrow from "assets/images/icon/svg/RightArrow";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { setDriverData } from "redux/container/driverSlice";
import Business from "services/Business";

export default function DriverList({ employees, empLoading, isForSelect }) {
	let { id, category } = useParams();
	const [checked, setChecked] = useState();
	const [selectedId, setSelectedId] = useState();
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const summaryType = useSelector((state) => state.productSummery.summaryType);
	const driver = useSelector((state) => state.driver.driverData)
	const [assignLoading, setAssignLoading] = useState(false);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const setEmployees = async (employeeId, employeeName) => {
		setAssignLoading(false);
		let queryBuilder;
		if (category === "driver") {
			queryBuilder = {
				order_status: "assign_driver",
				driverId: employeeId,
				driverName: employeeName,
			};
		} else {
			queryBuilder = {
				order_status: "assign_order",
				collectorId: employeeId,
				collectorName: employeeName,
			};
		}

		let response = await Business.assignEmployeeToOrder(id, queryBuilder);
		if (response.status === 200) {
			setChecked(true);
			setAssignLoading(false);
			navigate(`/order-details/${id}`, { replace: true });
			toast.success(`${category} assigned successfully`);
		} else {
			setAssignLoading(false);
		}
	};
	const handleNavigateSummary = (id, name) => {
		localStorage.setItem("driverId", id)
		localStorage.setItem("driverName", name)
		const data = {
			name: name,
			id: id
		}
		dispatch(setDriverData(data))
		if (summaryType === "DriverDeliverySummary") {
			navigate("/order-driver-delivery-summary", { replace: true })
		}
		else if (summaryType === "DeliveryAmountSummary") {
			navigate("/order-delivery-amount-summary", { replace: true })
		}
		else {
			navigate("/order-driver-product-summary", { replace: true })
		}
	}

	// <span
	// 	className='absolute'
	// 	style={{ top: -1, right: -1 }}
	// >
	// 	{employees?.find(
	// 		(element) => element.id === driver.id
	// 	) && (
	// 			<IonCheckbox
	// 				color='success'
	// 				className='rounded select-product'
	// 				checked={true}
	// 			/>
	// 		)}
	// </span>
	return (
		<div className='px-4 pt-4'>

			<IonLoading isOpen={assignLoading} />
			{Array.isArray(employees) && employees.length ? (
				employees?.map((employee, i) => {
					return (
						<div
							className={`mb-4 px-4 py-[5px] ${checked && employee.id === selectedId && "bg-E6E6E6"
								}`}
							onClick={() => {
								// setChecked(!checked);
								isForSelect
									? setSelectedId(employee.id)
									: handleNavigateSummary(employee.user_id, employee.display_name)
								isForSelect &&
									setEmployees(
										employee.user_id,
										employee?.display_name
									);
							}}
							key={i}>
							<div className='flex justify-between items-center'>
								<div className='flex'>
									{checked && employee.id === selectedId ? (
										<IonCheckbox
											color='success'
											className='rounede employee-checked'
											checked={checked && employee.id === selectedId}
										/>
									) : (
										<div className='flex w-10 h-10 bg-black-500 rounded-full justify-center items-center'>
											<h3 className='text-24 font-bold text-white'>
												{employee?.display_name?.slice()[0]}
											</h3>
										</div>
									)}

									<div className='ml-4'>
										<h6
											className={`font-bold text-14 pb-[2px] ${checked &&
												employee.id === selectedId &&
												"text-delivered"
												}`}>
											{employee?.display_name}
										</h6>
										<p
											className={`font-normal text-10 text-black-500 ${checked &&
												employee.id === selectedId &&
												"text-delivered"
												}`}>
											{employee?.role?.title}
										</p>
									</div>
								</div>
								{!isForSelect && (
									<div className='flex'>
										{
											employees.find(() => employee.user_id === driver.id) ? (
												<IonCheckbox
													color='success'
													className='rounded'
													checked={true}
												/>
											) : <RightArrow />
										}

									</div>
								)}
							</div>
						</div>
					);
				})
			) : (
				<>
					<IonCol size='2'></IonCol>
					<IonCol size='8'>
						<div
							className='text-center flex items-center justify-center'
							style={{ height: "calc(100vh - 420px)" }}>
							<div>
								<img
									className='m-auto mb-[10px] mt-5'
									src='/assets/images/not-found-file.png'
									alt='emptyCard'
								/>
								<p className='text-16 font-normal text-222222 mb-[35px]'>
									{ekkLanguage.orderList.notFound}
								</p>
							</div>
						</div>
					</IonCol>
				</>
			)}
		</div>
	);
}
