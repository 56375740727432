import React from "react";

export default function Cancel() {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='20'
			height='20'
			viewBox='0 0 20 20'
			fill='none'>
			<path
				d='M19 0.999971C18.7187 0.718764 18.3372 0.560791 17.9395 0.560791C17.5417 0.560791 17.1603 0.718764 16.879 0.999971L9.99997 7.87897L3.12097 0.999971C2.83968 0.718764 2.45822 0.560791 2.06047 0.560791C1.66272 0.560791 1.28126 0.718764 0.999971 0.999971C0.718764 1.28126 0.560791 1.66272 0.560791 2.06047C0.560791 2.45822 0.718764 2.83968 0.999971 3.12097L7.87897 9.99997L0.999971 16.879C0.718764 17.1603 0.560791 17.5417 0.560791 17.9395C0.560791 18.3372 0.718764 18.7187 0.999971 19C1.28126 19.2812 1.66272 19.4392 2.06047 19.4392C2.45822 19.4392 2.83968 19.2812 3.12097 19L9.99997 12.121L16.879 19C17.1603 19.2812 17.5417 19.4392 17.9395 19.4392C18.3372 19.4392 18.7187 19.2812 19 19C19.2812 18.7187 19.4392 18.3372 19.4392 17.9395C19.4392 17.5417 19.2812 17.1603 19 16.879L12.121 9.99997L19 3.12097C19.2812 2.83968 19.4392 2.45822 19.4392 2.06047C19.4392 1.66272 19.2812 1.28126 19 0.999971Z'
				fill='black'
				fillOpacity='0.5'
			/>
		</svg>
	);
}
