export default function SingleBenefits({ image, title, description }) {
	return (
	  <div className="mb-7 px-4 flex lg:justify-start justify-center">
		<div className="flex flex-col lg:justify-start justify-center lg:items-start items-center">
		  <div
			className="w-[100px] h-[100px] mb-[10px] flex justify-center items-center bg-white rounded-full"
			style={{ boxShadow: "0px 10px 15px rgba(0, 0, 0, 0.07)" }}
		  >
			<img src={image} alt="icon" />
		  </div>
		  <h4 className="sm:text-24 text-16 font-bold text-262626-1000 mb-[10px]">
			{title}
		  </h4>
		  <p className="text-16 font-normal text-black-500">{description}</p>
		</div>
	  </div>
	);
  }