import { baseUrl } from "config/apiUrl";
import SuperFetch from "config/SuperFetch";

const Notification = {
  // * New RTC APIs v2.0
  getNewCustomerNotificationList: (page, id, language = "EN", limit = 20) => {
    return SuperFetch(
      `${baseUrl.rtc}/inapp/notification/brand-unread-notification-list/?user_type=seller&user_id=${id}&page=${page}&limit=${limit}&country=${language}`
    );
  },
  setNewSellerReadNotification: (notificationId) => {
    return SuperFetch(
      `${baseUrl.rtc}/inapp/notification/read/${notificationId}/?user_type=seller`,
      {
        method: "PUT",
      }
    );
  },
  getNewCustomerUnreadCount: (id, language = "EN") => {
    return SuperFetch(
      `${baseUrl.rtc}/inapp/notification/brand-unread-notification-count/?user_type=seller&user_id=${id}&country=${language}`
    );
  },
};

export default Notification;
