import { setLanguage } from "config/language";
import { updateToken } from "config/SuperFetch";
import { authBox_Block } from "Constants";
import React, { useState } from "react";
import { useEffect } from "react";
import { useCallback } from "react";
import { GoogleReCaptcha } from "react-google-recaptcha-v3";
import { useDispatch } from "react-redux";
import { selectLan } from "redux/container/languageSlice";
import Auth from "services/Auth";
import ReCAPTCHA from "react-google-recaptcha";
import { baseUrl } from "config/apiUrl";
import Registration from "./Registration";

export default function VerificationBox({
	phoneNo,
	country,
	countryCode,
	setBlockIndex,
	setVerifyNumber,
	loading,
	sSize,
}) {
	const [t, setT] = useState("");
	let dispatch = useDispatch();
	let [captaValue, setCaptaValue] = useState(null);
	const onVerify = useCallback(
		(token) => {
			setT(token);
		},
		[t != "" && false]
	);
	let tokenSnedToServer = async () => {
		if (t != "") {
			let req_body = {
				country,
				phone_number: (countryCode + phoneNo).trim(),
				captcha_key: t,
			};

			let response = await Auth.userCaptcha(req_body);
			if (response.status === 200) {
				if (response.data?.user?.Country === "Bangladesh") {
					setLanguage("bn");
					dispatch(selectLan());
				} else {
					setLanguage("en");
					dispatch(selectLan());
				}
				updateToken(response);
				setVerifyNumber(false);
				setBlockIndex(authBox_Block.REGISTRATION_BLOCK);
				// clearInterval(interval);
				// let interval = setInterval(() => {

				// }, 2000);
			}
		}
	};

	// useEffect(() => {
	// 	tokenSnedToServer();
	// }, [t]);

	async function onChange(value) {
		setCaptaValue(value);
		if (value) {
			let req_body = {
				country,
				phone_number: (countryCode + phoneNo).trim(),
				captcha_key: value,
			};
			let response = await Auth.userCaptcha(req_body);
			console.log(response);
			if (response.status === 200) {
				if (response.data?.user?.Country === "Bangladesh") {
					setLanguage("bn");
					dispatch(selectLan());
				} else {
					setLanguage("en");
					dispatch(selectLan());
				}
				updateToken(response);
				setVerifyNumber(false);
				setBlockIndex(authBox_Block.REGISTRATION_BLOCK);
				// clearInterval(interval);
				// let interval = setInterval(() => {

				// }, 2000);
			}

			// let interval = setInterval(() => {
			// 	setVerifyNumber(false);
			// 	setBlockIndex(authBox_Block.REGISTRATION_BLOCK);
			// 	clearInterval(interval);
			// }, 500);
		}
	}

	return (
		<>
			{captaValue ? (
				<>
					<Registration
						loading={loading}
						phoneNo={phoneNo}
						country={country}
						countryCode={countryCode}
						sSize={sSize}
						setBlockIndex={setBlockIndex}
					/>
				</>
			) : (
				<div className='flex items-center justify-center'>
					<div className='text-center'>
						<h5 className='text-24 font-semibold text-black-1000 mb-10'>
							Verification
						</h5>
						<p className='text-14 font-normal text-black-500 mb-10'>
							We are checking you! Are you human or robot?
						</p>
						<h6 className='text-16 font-bold text-black-1000 mb-6'>
							Please wait a moment.
						</h6>
						{/* <img src='/assets/images/robot.png' className='m-auto' alt='' /> */}
						<></>
						<GoogleReCaptcha
							onVerify={(token) => {
								onChange(token);
							}}
						/>
						{/* <ReCAPTCHA sitekey={baseUrl.sitekey} onChange={onChange} /> */}
					</div>
				</div>
			)}
		</>
	);
}
