import { baseUrl } from "config/apiUrl";
import SuperFetch from "config/SuperFetch";
const Customer = {
	getDistributorList: (long, lati, page = 1, limit = 20) => {
		return SuperFetch(
			`${baseUrl.bizNpdt}/brand/distributors/?relation_type=8&longitude=${long}&latitude=${lati}&page=${page}&limit=${limit}`,
			{
				method: "GET",
			}
		);
	},
	getDistributorListSearch: (long, lati, page = 1, value, limit = 20) => {
		console.log(long, lati, (page = 1), value);
		return SuperFetch(
			`${baseUrl.bizNpdt}/brand/distributors/?relation_type=8&longitude=${long}&latitude=${lati}&page=${page}&limit=${limit}&q=${value}`,
			{
				method: "GET",
			}
		);
	},
	getInvitedDistributorList: (id) => {
		return SuperFetch(`${baseUrl.bizNpdt}/brand/invited_distributor/${id}`, {
			method: "GET",
		});
	},
	getInvitedDistributorSearch: (id, q) => {
		return SuperFetch(
			`${baseUrl.bizNpdt}/brand/invited_distributor/${id}/?q=${q}`,
			{
				method: "GET",
			}
		);
	},
	approveInvitedDistributor: (id) => {
		return SuperFetch(`${baseUrl.bizNpdt}/brand/approve_distributor/${id}/`, {
			method: "PUT",
		});
	},
	getDistributorDetails: (id) => {
		return SuperFetch(`${baseUrl.bizNpdt}/brand/distributor/${id}/`, {
			method: "GET",
		});
	},
	deleteDistributor: (id) => {
		return SuperFetch(`${baseUrl.bizNpdt}/brand/distributor_delete/${id}/`, {
			method: "DELETE",
		});
	},
	getDistebutor360: (id) => {
		return SuperFetch(
			`${baseUrl.order}/ekkbaz/orders/brand/total-analytics-brand?sellerId=${id}`,
			{
				method: "GET",
			}
		);
	},
	getDistributorCustomPriceProduct: (id, page = 1, limit = 30) => {
		return SuperFetch(
			`${baseUrl.bizNpdt}/brand/distributor_price_set/?distributor=${id}&page=${page}&limit=${limit}`,
			{
				method: "GET",
			}
		);
	},
	customPricePost: (data) => {
		return SuperFetch(`${baseUrl.bizNpdt}/brand/distributor_price_set/`, {
			method: "POST",
			data: data,
		});
	},
	customPriceProductDetails: (id, relatedBusinessId) => {
		return SuperFetch(
			`${baseUrl.bizNpdt}/brand/distributor_price_set/${id}/?distributor=${relatedBusinessId}`,
			{
				method: "GET",
			}
		);
	},
	updateCustomPrice: (id, data) => {
		return SuperFetch(
			`${baseUrl.bizNpdt}/brand/distributor_price_set/${id}/`,
			{
				method: "PUT",
				data: data,
			}
		);
	},
	deleteCustomePriceProduct: (id, relatedBusinessId) => {
		return SuperFetch(
			`${baseUrl.bizNpdt}/brand/distributor_price_set/${id}/`,
			{
				method: "DELETE",
			}
		);
	},
	addDistributor: (id, query) => {
		return SuperFetch(`${baseUrl.bizNpdt}/brand/distributor/${id}/`, {
			method: "PUT",
			data: query,
		});
	},
	getDistributorCustomerList: (
		long,
		lati,
		page = 1,
		distributorId,
		limit = 30
	) => {
		return SuperFetch(
			`${baseUrl.bizNpdt}/ekkbaz/customers/?longitude=${long}&latitude=${lati}&page=${page}&limit=${limit}&seller_id=${distributorId}`
		);
	},
};
export default Customer;
