import React, { forwardRef, useState, useRef } from "react";
import Card from "../Common/Card";
import moment from "moment";
import Add from "assets/images/icon/svg/Add";
import { useDispatch, useSelector } from "react-redux";
import { deliveryDate } from "redux/container/paymentMethodSlice";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Calendar from "assets/images/icon/svg/Calendar";
import {
	IonButton,
	IonItem,
	IonLabel,
	IonRadio,
	IonRadioGroup,
} from "@ionic/react";
import Modal from "react-modal";
import Orders from "services/Orders";
import { fetchOrderSingle } from "redux/container/orderSlice";

export default function SelectTimeDate({ order, accepted }) {
	const dispatch = useDispatch();
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const [failedStatus, setFailureStatus] = useState("");
	const [isOpen, setIsOpen] = useState(false);
	const onCloseModal = () => setIsOpen(false);
	const onOpenModal = () => {
		setFsAmount(false);
		setFsWarning("");
		setIsOpen(true);
	};
	const [deliveryPosition, setDeliveryPosition] = useState("");
	const [deliveryTime, setDeliveryTime] = useState("");
	let [deliveryDate, setDeliveryDate] = useState("");
	let [dateTimeWarning, setDateTimeWarning] = useState("");
	const [fsAmount, setFsAmount] = useState(false);
	const [fsWarning, setFsWarning] = useState("");

	const orderItems = useSelector((state) => state.order.deliveredItems);
	let productItems = orderItems?.productItems.map(
		(el) =>
			el && {
				id: el._id,
				quantity: el.quantity,
				subtotal: el.quantity * el.unit_price,
			}
	);

	// let calculateTotalPrice = () => {
	// 	let sum = 0;
	// 	let result = {};
	// 	for (let i = 0; i < productItems?.length; i++) {
	// 		sum += productItems[i].subtotal;
	// 	}
	// 	let duration = order?.payment_term?.duration;
	// 	let creditPercent;
	// 	if (duration == 0) {
	// 		creditPercent = 0;
	// 	} else if (duration == 1) {
	// 		creditPercent = 0.001;
	// 	} else if (duration == 3) {
	// 		creditPercent = 0.005;
	// 	} else if (duration == 7) {
	// 		creditPercent = 0.01;
	// 	}
	// 	result.tax = order.tax_total;
	// 	result.subtotal = sum;
	// 	result.total = sum + order.tax_total + creditPercent * sum;
	// 	result.creditFee = creditPercent * sum;
	// 	return result;
	// };

	let dateChanger = (e) => {
		setDeliveryDate(e);
	};
	const customStyles = {
		content: {
			top: "50%",
			left: "50%",
			right: "auto",
			bottom: "auto",
			marginRight: "-50%",
			transform: "translate(-50%, -50%)",
			padding: "0px",
			width: "calc(100% - 50px)",
			maxWidth: "500px",
			maxHeight: "calc(100% - 40px)",
			height: "auto",
			transition: ".4s all ease",
			borderRadius: 12,
			backgroundColor: "#F2F3F7",
		},
		overlay: {
			position: "fixed",
			top: 0,
			left: 0,
			right: 0,
			bottom: 0,
			transition: ".4s all ease",
			backgroundColor: "rgba(0,0,0,.5)",
		},
	};

	const deliveryCancelHandler = async () => {
		let query = {
			order_status: "Cancel",
			delivery_msg: failedStatus,
			tax: orderItems?.taxTotal,
			subtotal: orderItems?.subTotal,
			creditFee: orderItems?.creditFee,
			total: orderItems?.total,
		};
		let response = await Orders.orderUpdateV5(order._id, query);
		if (response.status === 200) {
			onCloseModal();
			dispatch(fetchOrderSingle(order._id));
		} else {
			setFsWarning(response.message);
			setFsAmount(true);
		}
	};

	const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
		<div
			ref={ref}
			className={`bg-F3F3F3 p-3 block rounded-[10px]  border example-custom-input ${
				deliveryDate ? "border-transparent" : "border-primary"
			} `}
			onClick={onClick}
			style={{
				boxShadow: "0px 3px 10px rgba(0, 0, 0, 0.15)",
			}}>
			<div className='flex justify-between items-center'>
				<div className='flex items-center'>
					<span>
						<Calendar color={deliveryDate ? "#222222" : "#F94B41"} />
					</span>
					<p
						className={`text-12 ${
							deliveryDate ? "border-transparent" : "text-primary"
						} ml-2`}>
						{deliveryDate
							? moment(deliveryDate).format("DD-MM-YYYY")
							: "Select a date"}
					</p>
				</div>
				<div>
					<Add />
				</div>
			</div>
		</div>
	));

	const deliveryAcceptHandler = async () => {
		let query = {
			order_status: "Schedule_For_Delivery",
			scheduleDeliveryTime: deliveryTime,
			scheduleDeliveryDate: deliveryDate,
			tax: orderItems?.taxTotal,
			subtotal: orderItems?.subTotal,
			creditFee: orderItems?.creditFee,
			total: orderItems?.total,
		};
		if (deliveryTime && deliveryDate) {
			let response = await Orders.orderUpdateV5(order._id, query);
			if (response.status === 200) {
				dispatch(fetchOrderSingle(order._id));
			}
		} else {
			dispatch(fetchOrderSingle(order._id));
		}
	};
	const deliveryAccept = async () => {
		let acceptQuery = {
			order_status: "Accepted",
			order_items: productItems,
			tax: orderItems?.taxTotal,
			subtotal: orderItems?.subTotal,
			creditFee: orderItems?.creditFee,
			total: orderItems?.total,
		};

		let resp = await Orders.orderUpdate(order._id, acceptQuery);
		if (resp.status === 200) {
			dispatch(fetchOrderSingle(order._id));
		}
	};
	const deliveryScheduleHandler = async () => {
		if (!deliveryTime | !deliveryDate) {
			setDateTimeWarning(ekkLanguage.orderDetails.deliveryDateTimeWarning);
		}
		let query = {
			order_status: "Schedule_For_Delivery",
			scheduleDeliveryTime: deliveryTime,
			scheduleDeliveryDate: deliveryDate,
		};
		if (deliveryTime && deliveryDate) {
			let response = await Orders.orderUpdateV5(order._id, query);
			if (response.status === 200) {
				dispatch(fetchOrderSingle(order._id));
			}
		}
	};

	return (
		<>
			{!accepted ? (
				<div className='pt-5 px-2'>
					{productItems?.length > 0 && (
						<button
							className='border-2 font-bold h-[65px] rounded-[7px] w-full mr-2 text-white bg-success border-solid border-transparent mb-5'
							onClick={deliveryAccept}>
							{ekkLanguage.orderDetails.acceptOrder}
						</button>
					)}

					{accepted && !dateTimeWarning == false && (
						<p className='text-red text-xs text-primary'>
							{dateTimeWarning}
						</p>
					)}

					<button
						color='primary'
						className={`border-2 font-bold h-[65px] rounded-[7px] w-full flex justify-center border-solid items-center border-22222 text-black-333`}
						onClick={onOpenModal}>
						{ekkLanguage.orderDetails.cancelOrder}
					</button>
				</div>
			) : (
				<div>
					<div className='px-2 pb-5'>
						<Card title={ekkLanguage.orderDetails.selectTimeDate}>
							<div className='pb-3'>
								<h4 className='text-12 font-bold py-[10px] px-2'>
									{ekkLanguage.orderDetails.selectDate}
								</h4>
								<div className='px-2'>
									<DatePicker
										minDate={moment().toDate()}
										selected={
											deliveryDate
												? moment(deliveryDate)._d
												: moment(new Date())._d
										}
										onChange={(date) => dateChanger(date)}
										customInput={<ExampleCustomInput />}
										withPortal
									/>
									{deliveryTime && !deliveryDate && (
										<p className='text-red text-xs text-primary pt-4'>
											{ekkLanguage.orderDetails.deliveryTimeWarning}
										</p>
									)}
								</div>
							</div>
							<div>
								<h4 className='text-12 font-bold py-[10px] px-2'>
									{ekkLanguage.orderDetails.selectTime}
								</h4>
								<div className='px-2 selected-time'>
									<IonRadioGroup
										// value={payMethod}
										onIonChange={(e) => {
											setDeliveryTime(e.detail.value);
										}}>
										<IonItem
											style={{ "--background": "bg-white" }}
											lines='none'
											className='border rounded-[10px] mb-5 no-ripple relative'>
											<IonLabel className='absolute left-6 w-full border-0 font-sans text-14 text-black-500 font-medium'>
												<span className='ml-2'>
													9:00 am to 1:00 pm
												</span>
											</IonLabel>
											<IonRadio
												// slot='start'
												value='9:00 am to 1:00 pm'
												color='success'
											/>
										</IonItem>
										<IonItem
											style={{ "--background": "bg-white" }}
											lines='none'
											className='border rounded-[10px] mb-5 no-ripple relative'>
											<IonLabel className='absolute left-6 w-full border-0 font-sans text-14 text-black-500 font-medium'>
												<span className='ml-2'>
													2:00 pm to 6:00 pm
												</span>
											</IonLabel>
											<IonRadio
												// slot='start'
												value='2:00 pm to 6:00 pm'
												color='success'
											/>
										</IonItem>
										<IonItem
											style={{ "--background": "bg-white" }}
											lines='none'
											className='border rounded-[10px] mb-5 no-ripple relative'>
											<IonLabel className='absolute left-6 w-full border-0 font-sans text-14 text-black-500 font-medium'>
												<span className='ml-2'>
													7:00 pm to 11:00 pm
												</span>
											</IonLabel>
											<IonRadio
												// slot='start'
												value='7:00 pm to 11:00 pm'
												color='success'
											/>
										</IonItem>
									</IonRadioGroup>
									{deliveryDate && !deliveryTime && (
										<p className='text-red text-xs text-primary'>
											{ekkLanguage.orderDetails.deliveryDateWarning}
										</p>
									)}
								</div>
							</div>
						</Card>
					</div>
					<div className='pt-10 px-2'>
						{productItems?.length > 0 && (
							<button
								className='border-2 font-bold h-[65px] rounded-[7px] w-full mr-2 text-white bg-success border-solid border-transparent mb-5'
								onClick={deliveryScheduleHandler}>
								{ekkLanguage.orderDetails.scheduleDelivery}
							</button>
						)}

						{accepted && !dateTimeWarning == false && (
							<p className='text-red text-xs text-primary'>
								{dateTimeWarning}
							</p>
						)}
					</div>
				</div>
			)}

			<Modal
				style={customStyles}
				closeTimeoutMS={200}
				isOpen={isOpen}
				onRequestClose={onCloseModal}>
				<div>
					<Card
						title={ekkLanguage.orderDetails.selectCancelOrder}
						onHide={true}
						closeModal={onCloseModal}>
						<div>
							{!fsAmount ? (
								<>
									<div className='pt-5 cancel-order-reason'>
										<IonRadioGroup
											defaultValue={deliveryPosition}
											value={deliveryPosition}
											onIonChange={(e) => {
												setFailureStatus(e.detail.value);
												setDeliveryPosition(e.detail.value);
											}}>
											<IonItem
												lines='none'
												className='bg-white border rounded-[10px] mb-4 no-ripple'
												style={{ "--background": "bg-white" }}>
												<IonLabel className='border-0 font-sans text-14 text-black-500 font-medium'>
													{ekkLanguage.orderDetails.multipleTime}
												</IonLabel>
												<IonRadio
													slot='start'
													value={
														ekkLanguage.orderDetails.multipleTime
													}
													color='success'
												/>
											</IonItem>
											<IonItem
												lines='none'
												className='bg-white border rounded-[10px] mb-5 no-ripple'
												style={{ "--background": "bg-white" }}>
												<IonLabel className=' border-0 font-sans text-14 text-black-500 font-medium'>
													<div className='flex'>
														{
															ekkLanguage.orderDetails
																.productNotAvailable
														}
													</div>
												</IonLabel>
												<IonRadio
													slot='start'
													value={
														ekkLanguage.orderDetails
															.productNotAvailable
													}
													color='success'
												/>
											</IonItem>
											<IonItem
												lines='none'
												className='bg-white border rounded-[10px] mb-5 no-ripple'
												style={{ "--background": "bg-white" }}>
												<IonLabel className=' border-0 font-sans text-14 text-black-500 font-medium'>
													<div className='flex'>
														{
															ekkLanguage.orderDetails
																.cannotFindAddress
														}
													</div>
												</IonLabel>
												<IonRadio
													slot='start'
													value={
														ekkLanguage.orderDetails
															.cannotFindAddress
													}
													color='success'
												/>
											</IonItem>
											<IonItem
												lines='none'
												className='bg-white border rounded-[10px] mb-4 no-ripple'
												style={{ "--background": "bg-white" }}>
												<IonLabel className='border-0 font-sans text-14 text-black-500 font-medium'>
													{ekkLanguage.orderDetails.noShop}
												</IonLabel>
												<IonRadio
													slot='start'
													value={ekkLanguage.orderDetails.noShop}
													color='success'
												/>
											</IonItem>
											<IonItem
												lines='none'
												className='bg-white border rounded-[10px] mb-4 no-ripple'
												style={{ "--background": "bg-white" }}>
												<IonLabel className='border-0 font-sans text-14 text-black-500 font-medium'>
													{
														ekkLanguage.orderDetails
															.supplierNotGive
													}
												</IonLabel>
												<IonRadio
													slot='start'
													notEnoughProduct
													value={
														ekkLanguage.orderDetails
															.supplierNotGive
													}
													color='success'
												/>
											</IonItem>
											<IonItem
												lines='none'
												className='bg-white border rounded-[10px] mb-4 no-ripple'
												style={{ "--background": "bg-white" }}>
												<IonLabel className='border-0 font-sans text-14 text-black-500 font-medium'>
													{ekkLanguage.orderDetails.other}
												</IonLabel>
												<IonRadio
													slot='start'
													notEnoughProduct
													value={ekkLanguage.orderDetails.other}
													color='success'
												/>
											</IonItem>
										</IonRadioGroup>
									</div>
								</>
							) : (
								<h4 className='font-bold py-4 text-18'>{fsWarning}</h4>
							)}

							<div className='text-center pt-0 flex justify-end'>
								{!fsAmount && (
									<>
										<button
											className='border-2 font-bold h-[45px] rounded-[7px] w-full  text-white bg-success border-solid border-transparent mb-5 mr-2'
											onClick={onCloseModal}>
											{ekkLanguage.orderDetails.no}
										</button>
										<button
											color='primary'
											className={`border-2 font-bold h-[45px] rounded-[7px] w-full flex justify-center border-solid items-center border-transparent text-white bg-primary ml-2`}
											onClick={deliveryCancelHandler}>
											{ekkLanguage.orderDetails.yes}
										</button>
									</>
								)}
								{fsAmount && (
									<button
										className='border-2 font-bold h-[45px] rounded-[7px] w-full  text-white bg-success border-solid border-transparent mb-5 mr-2'
										onClick={onCloseModal}>
										{ekkLanguage.orderDetails.close}
									</button>
								)}
							</div>
						</div>
					</Card>
				</div>
			</Modal>
		</>
	);
}
