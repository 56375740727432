import { IonInput } from "@ionic/react";
import React, { memo, useEffect, useRef } from "react";

function OTPInput({ otp, ekkLanguage, setOtp }) {
	const numberInputRef = useRef(null);
	useEffect(() => {
		const handleWheel = (event) => {
			if (
				numberInputRef.current &&
				numberInputRef.current === document.activeElement
			) {
				event.preventDefault();
			}
		};
		window.addEventListener("wheel", handleWheel, { passive: false });
		return () => {
			window.removeEventListener("wheel", handleWheel);
		};
	}, []);

	return (
		<IonInput
			ref={numberInputRef}
			type='number'
			value={otp}
			placeholder={ekkLanguage.otpsubmit.otpPlaceholder}
			onIonChange={(e) => setOtp(e.detail.value)}
			clearInput></IonInput>
	);
}
export default memo(OTPInput);
