import React from "react";
import Sheet from "react-modal-sheet";
import Print from "assets/images/icon/svg/Print";
import { IonItem } from "@ionic/react";
import { useDispatch, useSelector } from "react-redux";
import { setCreateCustomerBS } from "redux/container/userSlice";
import "react-responsive-modal/styles.css";
import CreateCustomer from "assets/images/icon/svg/CreateCustomer";
import { useNavigate } from "react-router-dom";
import { emptyBusiness } from "redux/container/businessSettingsSlice";

export default function CustomerBottomSheet({ toRedirect }) {
	const ref = React.useRef();
	const isOpenBottomSheet = useSelector(
		(state) => state.user.createCustomerBS
	);
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const dispatch = useDispatch();
	const navigate = useNavigate();

	return (
		<div>
			<Sheet
				className='bottom-sheet'
				initialSnap={1}
				ref={ref}
				isOpen={isOpenBottomSheet}
				snapPoints={[180, 180, 100, 0]}
				onClose={() => dispatch(setCreateCustomerBS(false))}>
				<Sheet.Container>
					<Sheet.Header />
					<Sheet.Content>
						<IonItem
							button
							onClick={() => {
								navigate(`/create-distributor/${toRedirect}`);
								dispatch(emptyBusiness());
							}}
							lines='none'>
							<span className='py-[5px] mr-[10px]'>
								<img src='/assets/images/supplier-create.png' alt='' />
								{/* <CreateCustomer /> */}
							</span>
							<p className='text-16 font-bold text-black-1000 pl-2'>
								{ekkLanguage.customerList.createCustomer}
							</p>
						</IonItem>
						<IonItem
							button
							onClick={() => {
								setCreateCustomerBS(false);
								navigate(`/invited-distributor`);

								// dispatch(emptyBusiness());
							}}
							lines='none'>
							<span className='py-[5px] mr-[10px]'>
								<img
									className='w-10 h-10'
									src='/assets/images/invited-supplier.png'
									alt='icon'
								/>
								{/* <CreateCustomer /> */}
							</span>
							<p className='text-16 font-bold text-black-1000 pl-2'>
								{ekkLanguage.customerList.invitedDistributor}
							</p>
						</IonItem>
					</Sheet.Content>
				</Sheet.Container>

				<Sheet.Backdrop
					onClick={() => dispatch(setCreateCustomerBS(false))}
				/>
			</Sheet>
		</div>
	);
}
