import React, { useEffect, useRef } from "react";

export default function TextInputWithIcon({
	type,
	title,
	inputText,
	setInputText,
	placeholder,
	icon,
	errorText,
	required,
}) {
	const numberInputRef = useRef(null);
	useEffect(() => {
		const handleWheel = (event) => {
			if (
				numberInputRef.current &&
				numberInputRef.current === document.activeElement
			) {
				event.preventDefault();
			}
		};
		window.addEventListener("wheel", handleWheel, { passive: false });
		return () => {
			window.removeEventListener("wheel", handleWheel);
		};
	}, []);
	return (
		<div className='pb-5'>
			<div className='flex'>
				{icon && (
					<img src={icon} alt='icon' className='mr-[10px] w-5 h-5' />
				)}
				{title && <h4 className='pb-3 font-bold text-14'>{title}</h4>}
			</div>

			<input
				className='pl-4 h-[50px] rounded-[5px] bg-F8F8F8 placeholder-black-500 w-full border border-black-500'
				type={type}
				placeholder={placeholder}
				value={inputText}
				onChange={(e) => setInputText(e.target.value)}
				ref={numberInputRef}
			/>
			{required && errorText && (
				<div className='text-right mt-1'>
					<p className='text-12 text-primary'>{errorText}</p>
				</div>
			)}
		</div>
	);
}
