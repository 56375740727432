import {
	IonButton,
	IonCol,
	IonGrid,
	IonInput,
	IonItem,
	IonRow,
} from "@ionic/react";
import Envelope from "assets/images/icon/svg/Envelope";
import Message from "assets/images/icon/svg/Message";
import UserIcon from "assets/images/icon/svg/User";
import { getUser, setUser } from "config/user";
import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import User from "services/User";

export default function Contact() {
	const user = getUser();
	const userData = useSelector((state) => state.user.data);
	const ekkLanguage = useSelector((state) => state.lan.lan);

	const [name, setName] = useState(userData?.full_name);
	const [email, setEmail] = useState(userData.contact_and_basic_info?.email);
	const [nid, setNid] = useState(userData.contact_and_basic_info?.nid_number);
	const history = useNavigate();

	let contactUpdateHandler = async () => {
		if (email.includes("@") === false) {
			toast.warning(ekkLanguage.contact.currectEmail);
		}
		if ((email && email.includes("@")) || nid) {
			var reqbody = {
				id_number: nid,
				email: email,
				display_name: name,
			};
			try {
				let response = await User.basicInfoUpdate(user._id, reqbody);
				if (response.status === 200 || response.status === 201) {
					toast.success(ekkLanguage.contact.successLabel);
					setUser({ ...user, full_name: response.data.full_name });
					history("/profile");
				}
			} catch (e) {
				console.log(e);
			}
		} else {
		}
	};
	let permissionToChange = () => {
		if (email && nid && name) {
			return true;
		} else {
			return false;
		}
	};

	return (
		<div className='px-2 relative h-screen pt-24'>
			<IonGrid>
				<IonRow>
					<IonCol size='12'>
						<div className='flex mb-7'>
							<span>
								<UserIcon />
							</span>

							<div className='ml-[10px] w-full'>
								<h5 className='text-dark-1000 text-16 font-semibold'>
									{ekkLanguage.businessLocation.userName}*
								</h5>
								<IonItem>
									<IonInput
										type='email'
										value={name}
										placeholder={
											ekkLanguage.businessLocation
												.userNamePlaceholder
										}
										onIonInput={(e) => {
											setName(e.detail.value);
										}}
										clearInput></IonInput>
								</IonItem>
							</div>
						</div>
					</IonCol>
					<IonCol size='12'>
						<div className='flex mb-7'>
							<span>
								<Message />
							</span>
							<div className='ml-[10px] w-full'>
								<h5 className='text-dark-1000 text-16 font-semibold'>
									{ekkLanguage.contact.emailLabel}*
								</h5>
								<IonItem>
									<IonInput
										type='email'
										value={email}
										placeholder={ekkLanguage.contact.emailPlaceholder}
										onIonInput={(e) => {
											setEmail(e.detail.value);
										}}
										clearInput></IonInput>
								</IonItem>
							</div>
						</div>
					</IonCol>
					<IonCol size='12'>
						<div className='flex mb-7'>
							<span>
								<img src='assets/images/nid.png' alt='icon' />
							</span>
							<div className='ml-[10px] w-full'>
								<h5 className='text-dark-1000 text-16 font-semibold'>
									{ekkLanguage.contact.nidLabel}*
								</h5>
								<IonItem>
									<IonInput
										type='text'
										value={nid}
										placeholder={ekkLanguage.contact.nidPlaceholder}
										onIonInput={(e) => {
											setNid(e.detail.value);
										}}
										clearInput></IonInput>
								</IonItem>
							</div>
						</div>
					</IonCol>
				</IonRow>
				<div className='absolute bottom-3 w-full pr-7'>
					<IonRow>
						<IonCol size='12'>
							<div className='text-center'>
								<IonButton
									disabled={!name}
									className='bg-success rounded-[7px] w-full font-extrabold text-12 h-10'
									onClick={contactUpdateHandler}>
									{ekkLanguage.contact.btnLabel}
								</IonButton>
							</div>
						</IonCol>
					</IonRow>
				</div>
			</IonGrid>
		</div>
	);
}
