import { setLanguage } from "config/language";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, Navigate } from "react-router-dom";
import { selectors } from "redux/container/authSlice";
import BlogData from "services/Blog";
import Backers from "./Backers";
import BannerArea from "./BannerArea";
import Benefits from "./Benefits";
import Blog from "./Blog";
import Brands from "./Brands";
import Footer from "./Footer";
import Navigation from "./Navigation";
import Partners from "./Partners";
import { getUserBusiness } from "config/user";
import CheckBusiness from "components/CheckBusiness";
import FooterStyle2 from "./FooterStyle2";

export default function Landing() {
	let navigate = useNavigate();
	if (localStorage.getItem("lan") === null) {
		setLanguage("en");
	}
	const [scrolling, setScrolling] = useState(false);
	const [scrollTop, setScrollTop] = useState(0);
	const { loginStatus } = useSelector(selectors.getAuth);
	const [blogList, setBlogList] = useState([]);
	let lan = useSelector((state) => state);

	useEffect(() => {
		function onScroll() {
			// let currentPosition = window.pageYOffset;
			// document.documentElement.scrollTop; // or use document.documentElement.scrollTop;
			let currentPosition = document.documentElement.scrollTop; // or use document.documentElement.scrollTop;
			if (currentPosition > 150) {
				setScrolling(true);
			} else {
				setScrolling(false);
			}
			setScrollTop(currentPosition);
		}

		window.addEventListener("scroll", onScroll);
		return () => window.removeEventListener("scroll", onScroll);
	}, [scrollTop]);

	//   const getBlogList = async (ctx) => {
	//     let blogList = await BlogData.getBlogList(ctx);
	//     if (blogList.status === 200) {
	//       setBlogList(blogList.data);
	//     }
	//   };

	//   useEffect(() => {
	//     getBlogList();
	//   }, []);

	// useEffect(() => {
	// 	navigate(loginStatus ? "/dashboard" : "/");

	// }, [loginStatus, navigate]);
	if (loginStatus) {
		let business = getUserBusiness();

		if (business) {
			return <Navigate to='/dashboard' />;
		} else {
			return <CheckBusiness></CheckBusiness>;
		}
	}

	return (
		<section>
			<Navigation scrolling={scrolling ? "isScrolled" : ""} />
			<BannerArea />
			{/* <Brands /> */}
			<Benefits />
			{/* <Blog items={blogList} /> */}
			{/* <OurLocation /> */}
			{/* <Backers />
      <Partners /> */}
			<FooterStyle2 />
			{/* <Footer /> */}
		</section>
	);
}
