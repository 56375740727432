import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import "./style.scss";

export default function FooterStyle2() {
	const [promptInstall, setPromptInstall] = useState(null);
	const [supportsPWA, setSupportsPWA] = useState(false);
	const ekkLanguage = useSelector((state) => state.lan.lan);
	useEffect(() => {
		const handler = (e) => {
			e.preventDefault();
			// console.log("we are being triggered :D");
			setSupportsPWA(true);
			setPromptInstall(e);
		};
		window.addEventListener("beforeinstallprompt", handler);
		return () => window.removeEventListener("transitionend", handler);
	}, []);

	const onClickPWADownload = (evt) => {
		evt.preventDefault();
		if (!promptInstall) {
			return;
		}
		promptInstall.prompt();
	};
	return (
		<div className='lg:pt-[180px] pt-[0] mt-[300px] lg:mt-0 relative'>
			{/* Phone Image for Mobile  */}
			<img
				src='assets/images/phone.png'
				alt='phnforMobile'
				className='block lg:hidden w-full absolute top-[-150px] px-5'
			/>
			<div className='footer-area-wrapper'>
				<div className='container'>
					<div className='sm:flex relative'>
						<div className='lg:w-4/12 hidden lg:block'>
							<img
								src='assets/images/phone.png'
								alt='images'
								className='xl:top-[-160px] lg:top-[10px] lg:max-w-[280px] xl:max-w-[500px] xl:left-[-40px] lg:left-[0] absolute'
							/>
						</div>
						<div className='lg:w-8/12 sm:w-full md:w-full text-center lg:pt-0 pt-[180px]'>
							<h2 className='font-bold sm:text-32 md:text-46 lg:text-46 xl:text-54 text-[36px] text-white pt-[120px] mb-12'>
								{ekkLanguage.landing.downloadApp}
							</h2>
							<div className='sm:flex pb-[80px] sm:justify-between text-center'>
								<a className='mx-4 block mb-6' href='!#'>
									<img
										src='assets/images/pwa.png'
										alt='pwa'
										className='m-auto'
										onClick={onClickPWADownload}
									/>
								</a>

								<a
									className='mx-4 block mb-6'
									target='_blank'
									href='https://play.google.com/store/apps/details?id=com.ekkbaz.seller&hl=en&gl=US'
									rel='noreferrer'>
									<img
										src='assets/images/play-store.png'
										alt='play'
										className='m-auto'
									/>
								</a>
								<a
									className='mx-4  inline-block mb-0'
									target='_blank'
									href='https://apps.apple.com/us/app/ekkbaz-business/id1279257167'
									rel='noreferrer'>
									<img
										src='assets/images/app-store.png'
										alt='app'
										className='m-auto'
									/>
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div style={{ background: "#F2F3F7" }}>
				<p>
					<a
						href='#!'
						className='font-normal text-16 flex justify-center items-center py-[10px]'
						style={{ color: "#808080" }}>
						<img
							src='assets/images/copyright.png'
							alt='@'
							className='w-4 h-4 mr-1'
						/>{" "}
						2024 Enam Pte. Ltd.
					</a>
				</p>
			</div>
		</div>
	);
}
