import { Button } from "components/global-components/common/button";
import { Input } from "components/global-components/common/Input";
import React, { useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker-cssmodules.css";
import { toast } from "react-toastify";
import Auth from "services/Auth";
import { updateToken } from "config/SuperFetch";
import { setLocalUser } from "config/user";
import { loginFromSignUp } from "redux/container/authSlice";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setLanguage } from "config/language";
import { selectLan } from "redux/container/languageSlice";
// import ProgressBar from "@ramonak/react-progress-bar";
import LeftArrow2 from "assets/images/icon/svg/LeftArrow2";
import { authBox_Block } from "Constants";
import EyeOn from "assets/images/icon/svg/EyeOn";
import EyeOff from "assets/images/icon/svg/EyeOff";

export default function Registration({
	loading,
	phoneNo,
	country,
	countryCode,
	sSize,
	setBlockIndex,
}) {
	const [name, setName] = useState("");
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState();
	const [error, setError] = useState("");
	const [nameError, setNameError] = useState("");
	const [lengthError, setLengthError] = useState("");
	const history = useNavigate();
	let dispatch = useDispatch();
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const [passwordType, setPasswordType] = useState(false);
	const [confirmPasswordType, setConfirmPasswordType] = useState(false);
	const [message, setMessage] = useState("");
	// const [progress, setProgress] = useState("");

	const handlePassword = (passwordValue) => {
		const strengthChecks = {
			length: 0,
			hasUpperCase: false,
			hasLowerCase: false,
			hasDigit: false,
			// hasSpecialChar: false,
		};

		strengthChecks.length = passwordValue.length >= 8 ? true : false;
		strengthChecks.hasUpperCase = /[A-Z]+/.test(passwordValue);
		strengthChecks.hasLowerCase = /[a-z]+/.test(passwordValue);
		strengthChecks.hasDigit = /[0-9]+/.test(passwordValue);
		// strengthChecks.hasSpecialChar = /[^A-Za-z0-9]+/.test(passwordValue);
		let verifiedList = Object.values(strengthChecks).filter((value) => value);
		let strengthCheck = () => {
			if (verifiedList.length == 4) {
				return ekkLanguage.registration.strong;
			} else if (verifiedList.length >= 2) {
				return ekkLanguage.registration.medium;
			} else if (verifiedList.length >= 1) {
				return ekkLanguage.registration.weak;
			}
		};
		// let strength =
		// 	verifiedList.length == 3
		// 		? "Strong"
		// 		: verifiedList.length >= 4
		// 		? "Medium"
		// 		: "Weak";
		// console.log(verifiedList.length / 5);
		setPassword(passwordValue);
		// setProgress(`${verifiedList.length * 100}`);
		setMessage(strengthCheck());
	};

	const getActiveColor = (type) => {
		if (type === ekkLanguage.registration.strong) return "#8BC926";
		if (type === ekkLanguage.registration.medium) return "#FEBD01";
		return "#F94B41";
	};

	let profileCreateHandler = async () => {
		if (name.length > 3) {
			if (password.length > 3 && confirmPassword.length > 3) {
				if (!(password && confirmPassword !== password)) {
					let req_body = {
						phone_number: (countryCode + phoneNo).trim(),
						full_name: name,
						email: email,
						password: password,
						country: country,
						user_type: "Business User",
					};
					try {
						const response = await Auth.createNewUser(req_body);
						if (response.status === 200) {
							updateToken(response);
							setLocalUser(response?.data);
							dispatch(loginFromSignUp(response));
							history("/");
							// toast.success(ekkLanguage.registration.successMessage);
							localStorage.setItem("auth", true);
						} else {
						}
					} catch (error) {
						console.log(error);
						toast.error(ekkLanguage.registration.errorMessage);
					}
				} else {
					setError(ekkLanguage.registration.passNotMatch);
				}
			} else {
				setLengthError(ekkLanguage.registration.passLengthValidLabel);
			}
		} else {
			setNameError(ekkLanguage.registration.validationLabel);
		}
	};

	return (
		<div>
			<form
				onSubmit={(e) => {
					e.preventDefault();
					profileCreateHandler();
				}}>
				<div className='grid lg:grid-cols-2 grid-cols-1 gap-3'>
					<div className='pb-3'>
						<label
							className={`text-14 md:text-20 text-262626-1000 sm:pb-3 pb-[10px] flex justify-between items-center`}>
							{ekkLanguage.registration.nameLabel}

							<span
								onClick={() =>
									setBlockIndex(authBox_Block.PHONE_NUMBER_BLOCK)
								}
								className='text-xs text-red cursor-pointer flex items-center lg:hidden'>
								<span className='mr-1'>
									<LeftArrow2 />
								</span>
								{ekkLanguage?.password.backWord}
							</span>
						</label>
						<div className='relative'>
							<input
								onChange={(event) => setName(event.target.value)}
								type='text'
								label={ekkLanguage.registration.nameLabel}
								placeholder={ekkLanguage.registration.namePlaceholder}
								required
								id={"ekk-input"}
								style={{
									paddingLeft: 30,
									height: sSize.matches ? 45 : 80,
									flex: "flex: 1 0 auto",
									borderRadius: ".25rem",
									borderTopLeftRadius: 0,
									borderBottomLeftRadius: 0,
									borderWidth: 0,
									borderLeft: "1px solid rgba(0,0,0,.05)",
								}}
								className='appearance-none text-base w-full text-grey-darker border border-left-0  py-2 px-2 leading-tight focus:outline-none focus:bg-white bg-white'
							/>
						</div>
						{nameError && (
							<span className='text-primary absolute'>{nameError}</span>
						)}
					</div>
					<div className='pb-3'>
						<label
							className={`text-14 md:text-20 text-262626-1000 sm:pb-3 pb-[10px] flex justify-between items-center`}>
							{ekkLanguage.registration.emailLabel}
							<span
								onClick={() =>
									setBlockIndex(authBox_Block.PHONE_NUMBER_BLOCK)
								}
								className='text-xs text-red cursor-pointer items-center hidden lg:flex'>
								<span className='mr-1'>
									<LeftArrow2 />
								</span>
								{ekkLanguage?.password.backWord}
							</span>
						</label>
						<div className='relative'>
							<input
								onChange={(event) => setEmail(event.target.value)}
								type='email'
								label={ekkLanguage.registration.emailLabel}
								placeholder={ekkLanguage.registration.emailPlaceholder}
								id={"ekk-input"}
								style={{
									paddingLeft: 30,
									height: sSize.matches ? 45 : 80,
									flex: "flex: 1 0 auto",
									borderRadius: ".25rem",
									borderTopLeftRadius: 0,
									borderBottomLeftRadius: 0,
									borderWidth: 0,
									borderLeft: "1px solid rgba(0,0,0,.05)",
								}}
								className='appearance-none text-base w-full text-grey-darker border border-left-0  py-2 px-2 leading-tight focus:outline-none focus:bg-white bg-white'
							/>
						</div>
					</div>

					<div className='pb-3'>
						<label
							className={`text-14 md:text-20 text-262626-1000 sm:pb-3 pb-[10px] flex justify-between items-center`}>
							{ekkLanguage.registration.passwordLabel}
						</label>
						<div className='relative'>
							<input
								onChange={(event) => {
									handlePassword(event.target.value);
									setPassword(event.target.value);
								}}
								type={passwordType ? "text" : "password"}
								label={ekkLanguage.registration.passwordLabel}
								placeholder={
									ekkLanguage.registration.passwordPlaceholder
								}
								required
								id={"ekk-input"}
								style={{
									paddingLeft: 30,
									height: sSize.matches ? 45 : 80,
									flex: "flex: 1 0 auto",
									borderRadius: ".25rem",
									borderTopLeftRadius: 0,
									borderBottomLeftRadius: 0,
									borderWidth: 0,
									borderLeft: "1px solid rgba(0,0,0,.05)",
								}}
								className='appearance-none text-base w-full text-grey-darker border border-left-0  py-2 px-2 leading-tight focus:outline-none focus:bg-white bg-white'
							/>
							<span
								className='absolute right-3 top-[50%]'
								onClick={() => {
									setPasswordType(!passwordType);
								}}
								style={{ transform: "translateY(-50%)" }}>
								{" "}
								{passwordType ? <EyeOn /> : <EyeOff />}
							</span>
							<div>
								{/* {password.length !== 0 ? (
						<ProgressBar
							completed={progress}
							labelSize='0px'
							height='5px'
							bgColor={getActiveColor(message)}></ProgressBar>
					) : null} */}
								{password.length > 3 ? (
									<p
										className='message absolute'
										style={{ color: getActiveColor(message) }}>
										{ekkLanguage.registration.passwordLength}{" "}
										{message}
									</p>
								) : null}
							</div>
						</div>

						{lengthError && (
							<span className='text-primary absolute'>
								{lengthError}
							</span>
						)}
					</div>
					<div className='pb-3'>
						{" "}
						<label
							className={`text-14 md:text-20 text-262626-1000 sm:pb-3 pb-[10px] flex justify-between items-center`}>
							{ekkLanguage.registration.confirmPassLabel}
						</label>
						<div className='relative'>
							<input
								onChange={(event) =>
									setConfirmPassword(event.target.value)
								}
								type={confirmPasswordType ? "text" : "password"}
								label={ekkLanguage.registration.confirmPassLabel}
								placeholder={
									ekkLanguage.registration.confirmPassPlaceholder
								}
								required
								eyeActive={true}
								setEyeIcon={setConfirmPasswordType}
								eyeIcon={confirmPasswordType}
								id={"ekk-input"}
								style={{
									paddingLeft: 30,
									height: sSize.matches ? 45 : 80,
									flex: "flex: 1 0 auto",
									borderRadius: ".25rem",
									borderTopLeftRadius: 0,
									borderBottomLeftRadius: 0,
									borderWidth: 0,
									borderLeft: "1px solid rgba(0,0,0,.05)",
								}}
								className='appearance-none text-base w-full text-grey-darker border border-left-0  py-2 px-2 leading-tight focus:outline-none focus:bg-white bg-white'
							/>
							<span
								className='absolute right-3 top-[50%]'
								onClick={() => {
									setConfirmPasswordType(!confirmPasswordType);
								}}
								style={{ transform: "translateY(-50%)" }}>
								{" "}
								{confirmPasswordType ? <EyeOn /> : <EyeOff />}
							</span>
						</div>
						{error && (
							<span className='text-primary absolute'>{error}</span>
						)}
					</div>
				</div>

				<div className='flex justify-center py-8'>
					{/* <Button
						title={ekkLanguage.registration.btnLabel}
						loading={loading}
						// onClick={profileCreateHandler}
						propsRef={(input) => input}
						type='submit'
					/> */}

					<button
						onClick={profileCreateHandler}
						// ref={clickBtnRef}
						style={{
							background:
								"linear-gradient(89.31deg, #00C0FF 0%, rgba(0, 178, 36, 0.5) 100%)",
						}}
						className={`text-20 sm:text-26 font-bold text-white w-full h-[50px] sm:h-[60px] rounded-[5px]`}>
						{ekkLanguage.registration.btnLabel}
					</button>
				</div>
			</form>
		</div>
	);
}
