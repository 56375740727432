import { IonApp, IonContent, IonPage } from "@ionic/react";
import BankList from "components/mobile-components/Banks/BankList";
import BackButtonTitle from "components/mobile-components/Common/BackButtonTitle";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Payment from "services/Payment";

export default function BankListPage() {
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const [banks, setBanks] = useState([]);
	const [empLoading, setEmpLoading] = useState(false);
	const [searchQ, setSearchQ] = useState("");
	const [hasMoreData, setHasMoreData] = useState(true);
	const [reachedEnd, setReachedEnd] = useState(false);

	let driverIds = localStorage.getItem("_b");
	const id = JSON.parse(driverIds);
	let driverId = id.id;

	const banksList = async () => {
		setEmpLoading(true);
		let response = await Payment.getBankList(driverId);
		if (response.status === 200) {
			setBanks(response.results);
			setEmpLoading(false);
		} else {
			setEmpLoading(false);
		}
	};
	const banksSearch = async (q) => {
		setEmpLoading(true);
		let response = await Payment.getBankListSearch(q);
		if (response.status === 200) {
			setBanks(response.results);
			setEmpLoading(false);
		} else {
			setEmpLoading(false);
		}
	};
	useEffect(() => {
		banksList();
	}, [searchQ === ""]);

	return (
		<IonApp>
			<IonPage className='business-name'>
				<IonContent>
					<BackButtonTitle
						title={ekkLanguage.addBankSettings.selectBank}
					/>
					<BankList
						banks={banks}
						empLoading={empLoading}
						banksSearch={banksSearch}
						hasMoreData={hasMoreData}
						setHasMoreData={setHasMoreData}
						searchQ={searchQ}
						setSearchQ={setSearchQ}
						reachedEnd={reachedEnd}
						setReachedEnd={setReachedEnd}
					/>
				</IonContent>
			</IonPage>
		</IonApp>
	);
}
