import React from "react";
import Sheet from "react-modal-sheet";
import Print from "assets/images/icon/svg/Print";
import { IonItem } from "@ionic/react";
import { useDispatch, useSelector } from "react-redux";
import { setBusinessBottomSheet } from "redux/container/userSlice";
import BpEdit from "assets/images/icon/svg/BpEdit";
import BpPayment from "assets/images/icon/svg/BpPayment";
import BpBranch from "assets/images/icon/svg/BpBranch";
import BpVarified from "assets/images/icon/svg/BpVarified";
import { useNavigate } from "react-router-dom";

export default function BusinessBottomSheet({ businessData }) {
	const ref = React.useRef();
	let navigate = useNavigate();
	const isOpenBottomSheet = useSelector(
		(state) => state.user.businessBottomSheet
	);
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const dispatch = useDispatch();

	return (
		<div>
			<Sheet
				className='bottom-sheet'
				initialSnap={1}
				ref={ref}
				isOpen={isOpenBottomSheet}
				snapPoints={[260, 260, 100, 0]}
				onClose={() => dispatch(setBusinessBottomSheet(false))}>
				<Sheet.Container>
					<Sheet.Header />
					<Sheet.Content>
						<IonItem
							button
							onClick={() => {
								dispatch(setBusinessBottomSheet(false));
								navigate("/business-edit");
							}}
							lines='none'>
							<span className='py-[5px]'>
								<BpEdit />
							</span>
							<p className='text-14 font-normal text-black-1000 pl-2'>
								{ekkLanguage.businessProfile.editBusinessMenu}
							</p>
						</IonItem>

						{/* <IonItem button onClick={() => {}} lines='none'>
							<span className='py-[5px]'>
								<BpPayment />
							</span>
							<p className='text-14 font-normal text-black-1000 pl-2'>
								{ekkLanguage.businessProfile.addPayMenu}
							</p>
						</IonItem>
						<IonItem button onClick={() => {}} lines='none'>
							<span className='py-[5px]'>
								<BpBranch />
							</span>
							<p className='text-14 font-normal text-black-1000 pl-2'>
								{ekkLanguage.businessProfile.addMenu}
							</p>
						</IonItem>
*/}
						{/* <IonItem
							button
							onClick={() => {
								dispatch(setBusinessBottomSheet(false));
								navigate("/nid-verification");
							}}
							lines='none'>
							<span className='py-[5px]'>
								<BpVarified />
							</span>
							<p className='text-14 font-normal text-black-1000 pl-2'>
								{ekkLanguage.businessProfile.verifyBusinessMenu}
							</p>
						</IonItem> */}
						<IonItem
							button
							onClick={() => {
								dispatch(setBusinessBottomSheet(false));
								navigate(`/pick-location/${businessData.id}`);
							}}
							lines='none'>
							<span className='py-[5px]'>
								<BpVarified />
							</span>
							<p className='text-14 font-normal text-black-1000 pl-2'>
								{ekkLanguage.businessProfile.setLocation}
							</p>
						</IonItem>
						<IonItem
							button
							onClick={() => {
								dispatch(setBusinessBottomSheet(false));
								navigate("/payment-terms-settings");
							}}
							lines='none'>
							<span className='py-[5px]'>
								<img
									className='w-[40px]  h-[40px]'
									src='assets/images/money.png'
									alt='icon'></img>
								{/* <BpPayment></BpPayment> */}
							</span>
							<p className='text-14 font-normal text-black-1000 pl-2'>
								{ekkLanguage.businessProfile.paymentTermsSettings}
							</p>
						</IonItem>
					</Sheet.Content>
				</Sheet.Container>

				<Sheet.Backdrop
					onClick={() => dispatch(setBusinessBottomSheet(false))}
				/>
			</Sheet>
		</div>
	);
}
