import { IonButton, IonSpinner } from "@ionic/react";
import ArrayRight from "assets/images/icon/svg/ArrayRight";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import User from "services/User";
import Check from "assets/images/icon/svg/Check";
import ImageUploader from "./ImageUploader";
import Delete from "assets/images/icon/svg/Delete";
import { setKYC } from "redux/container/businessSettingsSlice";
import Business from "services/Business";
import { getUserBusiness, setUserBusiness } from "config/user";

export default function TradeLicense() {
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const bs = useSelector((state) => state.businessSettings);
	const navigate = useNavigate();
	const [files1, setFiles1] = useState(bs.data?.trade_img ?? "");
	const [loader, setLoader] = useState(false);
	const business = getUserBusiness();

	let dispatch = useDispatch();

	const nextHandler = async () => {
		if (!files1[0].preview) {
			return navigate("/card-add-on-registration");
		}
		setLoader(true);
		let frontPart = new FormData();
		frontPart.append("key", files1[0]);

		if (files1.length !== 0) {
			let response = await User.toBucketImage(frontPart);
			if (response.status === 201) {
				setLoader(false);
				dispatch(
					setKYC({
						trade_img: response.results[0],
					})
				);

				let query = {
					trade_img: response.results[0],
					business: business?.id,
				};

				const res = bs.data?.kycUpdate
					? await Business.updateKYC(bs.data?.kycId, {
							trade_img: response.results[0],
					  })
					: await Business.createKYC(query);
				if (res.status === 200) {
					dispatch(
						setKYC({
							kycUpdate: true,
							business_status: "Pending",
						})
					);
					setUserBusiness({
						...business,
						business_status: "Pending",
					});
					navigate("/card-add-on-registration");
				} else if (res.status === 201) {
					dispatch(
						setKYC({
							kycId: res.results?.id,
							kycUpdate: true,
							business_status: "Pending",
						})
					);
					setUserBusiness({
						...business,
						business_status: "Pending",
					});
					navigate("/card-add-on-registration");
				}
			} else {
				setLoader(false);
			}
		}
	};
	return (
		<div className='pt-14 verifyBusiness-content'>
			<div className='py-5 px-4'>
				<div className='pt-4 pb-[10px] flex items-center justify-between'>
					<h4 className='font-bold text-black text-16'>Trade License</h4>
					<span
						className='h-10 w-10 flex items-center justify-center bg-white rounded-[5px]'
						onClick={() => setFiles1("")}>
						<Delete />
					</span>
				</div>

				<ImageUploader
					files={files1}
					setFiles={setFiles1}
					bHeight={438}
					placeHolder='Upload NID “Trade License”'
				/>
			</div>

			<div className='pt-5 px-4'>
				{files1.length ? (
					<div
						className='rounded-[5px] text-center py-4'
						style={{
							background: "#CCFFD6",
							boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.15)",
						}}>
						<p
							className='font-normal text-14 flex justify-center mb-[10px]'
							style={{ color: "#808080" }}>
							<Check></Check> Upload Complete.
						</p>
						<p
							className='font-normal text-14'
							style={{ color: "#808080" }}>
							Please click "
							<span style={{ color: "#00B224" }}>NEXT</span>" button
						</p>
					</div>
				) : (
					<div
						className='rounded-[5px]'
						style={{
							background: "#FFFFCC",
							boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.15)",
						}}>
						<p
							className='font-normal p-[14px] text-14'
							style={{ color: "#808080" }}>
							Please, upload your{" "}
							<b className='text-primary font-semibold'>
								“Trade License”
							</b>{" "}
							copy.
						</p>
					</div>
				)}

				<div className='pt-24 text-center pb-10'>
					<IonButton
						disabled={files1 ? false : true}
						color={files1 ? "success" : "dark"}
						className='font-bold h-[50px] w-full'
						onClick={nextHandler}>
						{loader ? (
							<IonSpinner></IonSpinner>
						) : (
							<>
								<span className='text-white'>
									{ekkLanguage.businessCreateName.btnLabel}
								</span>
								<span className='pl-2'>
									<ArrayRight />
								</span>
							</>
						)}
					</IonButton>

					<IonButton
						color='white'
						className='font-bold h-[50px] w-full mt-[30px] border-primary border-2 text-primary rounded-[5px]'
						onClick={() => {
							navigate("/card-add-on-registration");
							//  navigate("/business-success");
						}}>
						{ekkLanguage.kycVerification.giveLater}
						<span className='pl-2'>
							<ArrayRight color='red' />
						</span>
					</IonButton>
				</div>
			</div>
		</div>
	);
}
