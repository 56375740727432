import { createSlice } from "@reduxjs/toolkit";
import { getLanguage } from "config/language";
import bangla from "i18n/bn";
import english from "i18n/en";
import malaysia from "i18n/my";
import vietnamese from "i18n/vn";

export const languageSlice = createSlice({
	name: "language",
	initialState: {
		lan:
			getLanguage() === "bn"
				? bangla
				: getLanguage() === "vn"
				? vietnamese
				: getLanguage() === "my"
				? malaysia
				: english,
	},
	reducers: {
		selectLan: (state) => {
			if (getLanguage() === "en") {
				state.lan = english;
			} else if (getLanguage() === "bn") {
				state.lan = bangla;
			} else if (getLanguage() === "vn") {
				state.lan = vietnamese;
			}else if (getLanguage() === "my") {
				state.lan = malaysia;
			}
		},
	},
});

// Action creators are generated for each case reducer function
export const { selectLan } = languageSlice.actions;
export const { actions, reducer } = languageSlice;
export default reducer;
