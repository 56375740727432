import { baseUrl } from "config/apiUrl";
import SuperFetch from "config/SuperFetch";

const Blog = {
	getBlogList: (page = 1, limit = 9, lan = "EN") => {
		return SuperFetch(
			`${baseUrl.social}/articles/filter/category?&page=${page}&limit=${limit}&context=Article&categories=Corporate&language=${lan}`
		);
	},
	singleBlog: (id) => {
		return SuperFetch(`${baseUrl.social}/articles/${id}`);
	},
};

export default Blog;
