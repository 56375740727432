import {
  IonApp,
  IonContent,
  IonPage,
  IonButton,
  IonSpinner,
} from "@ionic/react";
import React from "react";
import CustomSelect from "components/global-components/common/CustomSelect";

export default function DefaultPaymentSetting({
  ekkLanguage,
  paymentTerms,
  paymentMethods,
  paymentTerm,
  setPaymentTerm,
  paymentMethod,
  setPaymentMethod,
  loading,
  handleSavePayment,
  localLanguage,
}) {
  return (
    <IonApp>
      <IonPage>
        <IonContent>
          <div className="pt-20 px-4">
            {/* page headline  */}
            <h3 className="text-18 font-bold mb-5">
              {ekkLanguage.defaultPayment.pageHeadline}
            </h3>
            <h6 className="text-14 opacity-50 font-semibold mb-5">
              {ekkLanguage.defaultPayment.pageSubHeadline}
            </h6>
            {/* payment options */}
            <>
              {loading ? (
                <div className="flex items-center justify-center h-[156px] m-auto">
                  <IonSpinner name="crescent"></IonSpinner>
                </div>
              ) : (
                <>
                  {/* Payment Term  */}
                  <CustomSelect
                    labelImage="/assets/images/default_payment_term.png"
                    type="defaultPaymentTerm"
                    localLanguage={localLanguage}
                    options={paymentTerms}
                    setOption={setPaymentTerm}
                    option={paymentTerm}
                    mainLabel={ekkLanguage.defaultPayment.paymentTermLabel}
                    selectPlaceholder={ekkLanguage.defaultPayment.paymentTermPlaceHolder}
                  ></CustomSelect>
                  {/* Payment Method  */}
                  <CustomSelect
                    labelImage="/assets/images/default_payment_method.png"
                    type="defaultPaymentMethod"
                    localLanguage={localLanguage}
                    options={paymentMethods}
                    setOption={setPaymentMethod}
                    option={paymentMethod}
                    mainLabel={ekkLanguage.defaultPayment.paymentMethodLabel}
                    selectPlaceholder={ekkLanguage.defaultPayment.paymentMethodPlaceHolder}
                  ></CustomSelect>
                  {/* Save Button  */}
                  <IonButton
                    type="submit"
                    onClick={() => handleSavePayment()}
                    color="success"
                    className="font-bold h-12 text-20 rounded  w-full mt-60 mx-auto"
                  >
                    {ekkLanguage.paymentSettings.btnLabel}
                  </IonButton>
                </>
              )}
            </>
          </div>
        </IonContent>
      </IonPage>
    </IonApp>
  );
}
