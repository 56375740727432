import { IonItem } from "@ionic/react";

import React from "react";
import Sheet from "react-modal-sheet";
import { useDispatch, useSelector } from "react-redux";
import "react-responsive-modal/styles.css";
import { useNavigate } from "react-router-dom";
import { setDashboardBottomSheet } from "redux/container/userSlice";

export default function DashboardBottomSheet() {
	const ref = React.useRef();
	const isOpenBottomSheet = useSelector(
		(state) => state.user.dashboardBottomSheet
	);
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	console.log(isOpenBottomSheet);
	return (
		<div>
			<Sheet
				className='bottom-sheet'
				initialSnap={1}
				ref={ref}
				isOpen={isOpenBottomSheet}
				snapPoints={[380, 380, 100, 0]}
				onClose={() => dispatch(setDashboardBottomSheet(false))}>
				<Sheet.Container>
					<Sheet.Header />
					<Sheet.Content>
						<IonItem
							button
							onClick={() => navigate("/order-product-summary")}
							lines='none'>
							<span className='py-2'>
								<img
									className='h-10 w-10'
									src='assets/images/box.png'
									alt='user'
								/>
							</span>
							<p className='text-16 font-bold text-black-1000 pl-4'>
								{ekkLanguage.orderSummary.orderProductPageTitle}
							</p>
						</IonItem>
						<IonItem
							button
							onClick={() => navigate("/order-driver-product-summary")}
							lines='none'>
							<span className='py-2'>
								<img
									className='h-10 w-10'
									src='assets/images/deliveryman.png'
									alt='user'
								/>
							</span>
							<p className='text-16 font-bold text-black-1000 pl-4'>
								{ekkLanguage.orderSummary.driverProductPageTitle}
							</p>
						</IonItem>
						<IonItem
							button
							onClick={() => navigate("/order-driver-delivery-summary")}
							lines='none'>
							<span className='py-2'>
								<img
									className='h-10 w-10'
									src='assets/images/Loading.png'
									alt='user'
								/>
							</span>
							<p className='text-16 font-bold text-black-1000 pl-4'>
								{ekkLanguage.orderSummary.driverDeliveryPageTitle}
							</p>
						</IonItem>
						<IonItem
							button
							onClick={() => navigate("/order-delivery-amount-summary")}
							lines='none'>
							<span className='py-2'>
								<img
									className='h-10 w-10'
									src='assets/images/amount.png'
									alt='user'
								/>
							</span>
							<p className='text-16 font-bold text-black-1000 pl-4'>
								{ekkLanguage.orderSummary.deliveryAmountPageTitle}
							</p>
						</IonItem>

						<IonItem
							button
							onClick={() => navigate("/delay-in-payment")}
							lines='none'>
							<span className='py-2'>
								<img
									className='h-10 w-10'
									src='assets/images/time.png'
									alt='user'
								/>
							</span>
							<p className='text-16 font-bold text-black-1000 pl-4'>
								{ekkLanguage.orderSummary.delayInPayment}
							</p>
						</IonItem>
						<IonItem
							button
							onClick={() => navigate("/buyer-summary")}
							lines='none'>
							<span className='py-[5px]'>
								<img
									className='h-10 w-10'
									src='assets/images/summary.png'
									alt='user'
								/>
							</span>
							<p className='text-16 font-bold text-black-1000 pl-4'>
								{ekkLanguage.orderSummary.buyerSummary}
							</p>
						</IonItem>
					</Sheet.Content>
				</Sheet.Container>
				<Sheet.Backdrop
					onClick={() => dispatch(setDashboardBottomSheet(false))}
				/>
			</Sheet>
		</div>
	);
}
