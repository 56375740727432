import { IonCol, IonGrid, IonLoading, IonRow } from "@ionic/react";
import React, { useState } from "react";
import DistributorCard from "./DistributorCard";
import Modal from "react-modal";
import CancelPopUp from "assets/images/icon/svg/CancelPopUp";
import { ClipLoader } from "react-spinners";
import Customer from "services/Customer";
import { toast } from "react-toastify";

export default function InvitedDistributorList({
	customerData,
	loading,
	ekkLanguage,
	link,
	getDistributorList,
}) {
	const [isOpen, setIsOpen] = useState(false);
	const [loader, setLoader] = useState(false);
	const [selectedDistributor, setSelectedDistributor] = useState(null);
	const onOpenModal = () => {
		setIsOpen(true);
	};
	const onCloseModal = () => {
		setIsOpen(false);
	};
	const customStyles = {
		content: {
			top: "50%",
			left: "50%",
			right: "auto",
			bottom: "auto",
			marginRight: "-50%",
			transform: "translate(-50%, -50%)",
			padding: "0px",
			width: "300px",
			transition: ".4s all ease",
			borderRadius: 12,
		},
		overlay: {
			position: "fixed",
			top: 0,
			left: 0,
			right: 0,
			bottom: 0,
			transition: ".4s all ease",
			backgroundColor: "rgba(0,0,0,.5)",
		},
	};
	let invitedDistributor = async (id) => {
		setLoader(true);
		let response = await Customer.approveInvitedDistributor(id);
		if (response.success) {
			onCloseModal();
			setLoader(false);
			toast.success(ekkLanguage.invitedDistributor.inviteApprove);
			getDistributorList();
		} else {
			setLoader(false);
		}
	};
	return (
		<>
			{loading ? (
				<IonLoading
					isOpen={true}
					message={ekkLanguage.orderList?.loader}></IonLoading>
			) : (
				<div className='w-full flex items-center justify-center'>
					<IonGrid classNae='w-full'>
						<IonRow className='w-full'>
							{customerData.length > 0 ? (
								customerData.map((customer, i) => {
									let image;
									if (customer?.related_business_images?.length > 0) {
										image = customer?.related_business_images;
									} else {
										image = "/assets/images/track.png";
									}
									return (
										<IonCol
											size='6'
											sizeSm='6'
											className='mb-6'
											key={i}>
											<DistributorCard
												key={customer?.id}
												customerId={customer?.id}
												relationId={customer?.business_relation_id}
												customerName={customer?.business_name}
												imgSource={image}
												customerAddress={
													customer?.city + ", " + customer?.country
												}
												rating={
													customer?.products_count
														? customer?.products_count
														: 5
												}
												link={link}
												ekkLanguage={ekkLanguage}
												distance={customer.distance}
												onOpenModal={onOpenModal}
												setSelectedDistributor={
													setSelectedDistributor
												}
												relationshipStatus={
													customer?.relationship_status
												}></DistributorCard>
										</IonCol>
									);
								})
							) : (
								<div className='pt-10 flex w-full justify-center'>
									<IonCol size='2'></IonCol>
									<IonCol size='8'>
										<div
											className='text-center flex items-center justify-center'
											style={{ height: "calc(100vh - 420px)" }}>
											<div>
												<img
													className='m-auto mb-[10px] mt-5'
													src='/assets/images/not-found-file.png'
													alt='emptyCard'
												/>
												<p className='text-16 font-normal text-222222 mb-[35px]'>
													{ekkLanguage.orderList.notFound}
												</p>
											</div>
										</div>
									</IonCol>
								</div>
							)}
						</IonRow>
					</IonGrid>
					<Modal
						style={customStyles}
						closeTimeoutMS={200}
						isOpen={isOpen}
						onRequestClose={onCloseModal}
						classNames='bottom-sheet-modal'>
						<div className='p-5'>
							<span
								className='text-primary font-bold absolute right-3 top-3 h-5 w-5 cursor-pointer flex justify-center items-center'
								onClick={onCloseModal}>
								X
							</span>
							<span className='mb-2 inline-block mx-2'>
								<CancelPopUp />
							</span>
							<h6 className='text-16 font-bold pb-[30px]'>
								{ekkLanguage.invitedDistributor.invitedDistributor}
							</h6>

							<div className='flex justify-end'>
								<>
									<button
										className='border-2 font-bold h-10 rounded-[7px] w-1/2 mr-2 bg-primary text-white'
										onClick={onCloseModal}>
										{ekkLanguage.orderDetails.no}
									</button>
									<button
										color='primary'
										className={`${
											loader && "select-none cursor-no-drop"
										} font-bold h-10 rounded-[7px] w-1/2 mr-2 border-2 bg-success text-white flex justify-center items-center`}
										onClick={() =>
											!loader &&
											invitedDistributor(selectedDistributor)
										}>
										{loader ? (
											<ClipLoader
												sizeUnit={"px"}
												size={20}
												color={"white"}
												loading={true}
											/>
										) : (
											ekkLanguage.orderDetails.yes
										)}
									</button>
								</>
							</div>
						</div>
					</Modal>
				</div>
			)}
		</>
	);
}
