import { IonLoading } from "@ionic/react";
import React from "react";
import { useSelector } from "react-redux";
import OrderInvoiceSearch from "./OrderInvoiceSearch";
import OrderStatusBtn from "./OrderStatusBtn";
import OrderStatusCard from "./OrderStatusCard";

export default function OrderStatus({
	setOrderStatus,
	loading,
	setLoading,
	setReachedEnd,
	setHasMoreData,
}) {
	const ekkLanguage = useSelector((state) => state.lan.lan);

	return (
		<div className='px-2'>
			{/* <OrderInvoiceSearch
				setLoading={setLoading}
				ekkLanguage={ekkLanguage}
				setReachedEnd={setReachedEnd}
				setHasMoreData={setHasMoreData}
			/> */}
			<OrderStatusBtn
				setOrderStatus={setOrderStatus}
				setReachedEnd={setReachedEnd}
				setHasMoreData={setHasMoreData}
			/>
			{loading ? (
				<IonLoading
					isOpen={true}
					message={ekkLanguage.orderList?.loader}></IonLoading>
			) : (
				<OrderStatusCard />
			)}
		</div>
	);
}
