import React, { useEffect, useState } from "react";
import { Elements, useStripe } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "./CheckoutForm";
import Payment from "services/Payment";
import { getLocalUser, getUserBusiness } from "config/user";
import { IonButton } from "@ionic/react";
import Modal from "react-modal";
import Card from "../Common/Card";
import { useSelector } from "react-redux";
import { baseUrl } from "config/apiUrl";

export default function AddCard({ getCardList }) {
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const [newCardModel, setNewCardModel] = useState(false);
	const openCardModel = () => setNewCardModel(true);
	const closeCardModel = () => setNewCardModel(false);
	const [clientSecret, setClientSecret] = useState("");
	const business = getUserBusiness();
	const [customerId, setCustomerId] = useState("");
	const [cardLoadingError, setCardLoadingError] = useState(null);
	let publicKey = baseUrl.stripPublicKey;
	const localBusiness = getLocalUser();

	const appearance = {
		theme: "stripe",
	};

	const stripePromise = loadStripe(publicKey);
	const getSecret = async () => {
		let query = {
			seller_id: business?.id
				? business.id
				: localBusiness?.associated_businesses[0]?.business_id,
			public_key: publicKey,
		};
		let response = await Payment.cardLoading(query);
		if (response.success) {
			setClientSecret(response?.clientSecret);
			setCustomerId(response?.customer_id);
		} else {
			setCardLoadingError(response.message);
		}
	};
	useEffect(() => {
		getSecret();
	}, []);

	const options = {
		// mode: "payment",
		// amount: 1099,
		// currency: "usd",
		clientSecret,
		appearance,
	};

	const customStyles = {
		content: {
			top: "50%",
			left: "50%",
			right: "auto",
			bottom: "auto",
			marginRight: "-50%",
			transform: "translate(-50%, -50%)",
			padding: "0px",
			width: "300px",
			transition: ".4s all ease",
			borderRadius: 12,
		},
		overlay: {
			position: "fixed",
			top: 0,
			left: 0,
			right: 0,
			bottom: 0,
			transition: ".4s all ease",
			backgroundColor: "rgba(0,0,0,.5)",
		},
	};

	return (
		<div className=''>
			<div className='pt-5 flex justify-between items-center'>
				<h4 className='font-bold text-18'>
					{ekkLanguage?.addCard.addedList}
				</h4>
				<IonButton color='success' onClick={openCardModel}>
					<span className='text-14 font-bold text-white'>
						{ekkLanguage?.addCard.addNewCard}
					</span>
				</IonButton>
			</div>
			<Modal
				style={customStyles}
				closeTimeoutMS={200}
				isOpen={newCardModel}
				onRequestClose={closeCardModel}
				classNames='bottom-sheet-modal'
				showBackdrop={true}
				size='lg'>
				<div>
					<Card
						title={"Add New Card"}
						onHide={true}
						closeModal={closeCardModel}>
						<div className='pt-4'>
							{clientSecret ? (
								<Elements stripe={stripePromise} options={options}>
									<CheckoutForm
										closeCardModel={closeCardModel}
										clientSecret={clientSecret}
										customerId={customerId}
										getCardList={getCardList}
									/>
								</Elements>
							) : (
								<p>{cardLoadingError}</p>
							)}
						</div>
					</Card>
				</div>
			</Modal>
		</div>
	);
}
