import { IonSegment, IonSegmentButton } from "@ionic/react";
import React, { memo, useState } from "react";
import { useDispatch } from "react-redux";
import { setCustomer } from "redux/container/customerSlice";

function CustomerFilter({
	ekkLanguage,
	getAcceptedCustomerList,
	getRequestedCustomerList,customerType, setCustomerType
}) {
	const dispatch = useDispatch();
	return (
		<IonSegment
			scrollable
			className='orderBtnWrapper'
			value={customerType}
			onIonChange={(e) => {
				dispatch(
					setCustomer(e.target.value === "distributorList" ? true : false)
				);
			}}
		>
			<IonSegmentButton
				value='distributorList'
				className='paid relative'
				onClick={() => {
					setCustomerType("distributorList");
					// dispatch(setCustomer(true));
					getAcceptedCustomerList();
				}}
			>
				<button
					className={`${
						customerType === "distributorList"
							? "text-[#00B224]"
							: "text-[#00000054]"
					} font-sans p-0 font-bold shadow-none text-14`}
				>
					{ekkLanguage.customerList.acceptedCustomerLabel}
				</button>
			</IonSegmentButton>
			<IonSegmentButton
				value='orderList'
				className=' cancel relative'
				onClick={() => {
					setCustomerType("orderList");
					// dispatch(setCustomer(false));
					getRequestedCustomerList();
				}}
			>
				<button
					className={`${
						customerType === "orderList"
							? "text-primary"
							: "text-[#00000054]"
					} font-sans p-0 font-bold shadow-none text-14 flex`}
				>
					{/* {customerType === "orderList" ? (
						<img
							className='w-[19px] h-[19px] mr-1'
							src='/assets/images/Icon/requests.png'
							alt=''
						/>
					) : (
						<img
							className='w-[19px] h-[19px] mr-1'
							src='/assets/images/Icon/request-gray.png'
							alt=''
						/>
					)}{" "} */}
					{ekkLanguage.customerList.requestedCustomerLabel}
				</button>
			</IonSegmentButton>
		</IonSegment>
	);
}
export default memo(CustomerFilter);
