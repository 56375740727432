import { digitChanger, numberWithCommas } from "helpers/helper";
import React from "react";
import { useSelector } from "react-redux";
import Card from "../Common/Card";

export default function CostSummeryCard({ order }) {
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const placedOrder = useSelector((state) => state.order.deliveredItems);
	return (
		<div className='px-2 pb-5'>
			<Card title={ekkLanguage.orderDetails.castItemLabel}>
				<div>
					<>
						{order?.order_status !== "Placed" ? (
							<table className='table-fixed w-full'>
								<tbody className="before:content-[' '] before:block before:h-2 after:content-[' '] after:block after:h-2">
									<tr>
										<td className='text-000080 text-12 font-medium py-[6px]'>
											{ekkLanguage.orderDetails.subtotal}
										</td>
										<td className='text-000080 text-12 font-medium py-[6px] text-right'>
											{/* {translator(order.currency)}{" "} */}
											{order?.country?.currency_symbol_native}
											{digitChanger(
												numberWithCommas(order?.sub_total)
											)}
										</td>
									</tr>
									<tr>
										<td className='text-000080 text-12 font-medium py-[6px]'>
											{ekkLanguage.orderDetails.tax}
										</td>
										<td className='text-000080 text-12 font-medium py-[6px] text-right'>
											{/* {translator(order.currency)}{" "} */}
											{order?.country?.currency_symbol_native}
											{digitChanger(
												numberWithCommas(order?.tax_total)
											)}
										</td>
									</tr>
									<tr>
										<td className='text-000080 text-12 font-medium py-[6px]'>
											{ekkLanguage.orderDetails.discount}
										</td>
										<td className='text-000080 text-12 font-medium py-[6px] text-right'>
											{/* {translator(order?.currency)}{" "} */}
											{order?.country?.currency_symbol_native}
											{order?.discountAmount ? "-" : null}
											{digitChanger(
												numberWithCommas(
													order?.discountAmount ?? 0.0
												)
											)}
										</td>
									</tr>
									<tr>
										<td className='text-000080 text-12 font-medium py-[6px]'>
											{ekkLanguage.orderDetails.deliveryFee}
										</td>
										<td className='text-000080 text-12 font-medium py-[6px] text-right'>
											{/* {translator(order.currency)}{" "} */}
											{order?.country?.currency_symbol_native}
											{digitChanger(
												numberWithCommas(order?.deliveryFee ?? 0.0)
											)}
										</td>
									</tr>
									{/* <tr>
										<td className='text-000080 text-12 font-medium py-[6px]'>
											{ekkLanguage.orderDetails.creditFee}
										</td>
										<td className='text-000080 text-12 font-medium py-[6px] text-right'>
											{order?.country?.currency_symbol_native}
											{digitChanger(
												numberWithCommas(
													order?.payment_term?.creditFee
												)
											)}
										</td>
									</tr> */}
								</tbody>
								<tfoot className='border-t'>
									<tr>
										<td className='text-primary text-12 font-bold py-[6px]'>
											{ekkLanguage.orderDetails.total}
										</td>
										<td className='text-primary text-12 font-bold py-[6px] text-right'>
											{/* {translator(order.currency)}{" "} */}
											{order?.country?.currency_symbol_native}
											{digitChanger(numberWithCommas(order?.total))}
										</td>
									</tr>
								</tfoot>
							</table>
						) : (
							<table className='table-fixed w-full'>
								<tbody className="before:content-[' '] before:block before:h-2 after:content-[' '] after:block after:h-2">
									<tr>
										<td className='text-000080 text-12 font-medium py-[6px]'>
											{ekkLanguage.orderDetails.subtotal}
										</td>
										<td className='text-000080 text-12 font-medium py-[6px] text-right'>
											{/* {translator(order.currency)}{" "} */}
											{order?.country?.currency_symbol_native}
											{digitChanger(
												numberWithCommas(placedOrder?.subTotal)
											)}
										</td>
									</tr>
									<tr>
										<td className='text-000080 text-12 font-medium py-[6px]'>
											{ekkLanguage.orderDetails.tax}
										</td>
										<td className='text-000080 text-12 font-medium py-[6px] text-right'>
											{/* {translator(order.currency)}{" "} */}
											{order?.country?.currency_symbol_native}
											{digitChanger(
												numberWithCommas(placedOrder?.taxTotal)
											)}
										</td>
									</tr>
									<tr>
										<td className='text-000080 text-12 font-medium py-[6px]'>
											{ekkLanguage.orderDetails.discount}
										</td>
										<td className='text-000080 text-12 font-medium py-[6px] text-right'>
											{/* {translator(order?.currency)}{" "} */}
											{order?.country?.currency_symbol_native}
											{placedOrder?.discount ? "-" : null}
											{digitChanger(
												numberWithCommas(
													placedOrder?.discount ?? 0.0
												)
											)}
										</td>
									</tr>
									<tr>
										<td className='text-000080 text-12 font-medium py-[6px]'>
											{ekkLanguage.orderDetails.deliveryFee}
										</td>
										<td className='text-000080 text-12 font-medium py-[6px] text-right'>
											{/* {translator(order.currency)}{" "} */}
											{order?.country?.currency_symbol_native}
											{digitChanger(
												numberWithCommas(
													placedOrder?.deliveryFee ?? 0.0
												)
											)}
										</td>
									</tr>
									{/* <tr>
										<td className='text-000080 text-12 font-medium py-[6px]'>
											{ekkLanguage.orderDetails.creditFee}
										</td>
										<td className='text-000080 text-12 font-medium py-[6px] text-right'>
											{order?.country?.currency_symbol_native}
											{digitChanger(
												numberWithCommas(placedOrder?.creditFee)
											)}
										</td>
									</tr> */}
								</tbody>
								<tfoot className='border-t'>
									<tr>
										<td className='text-primary text-12 font-bold py-[6px]'>
											{ekkLanguage.orderDetails.total}
										</td>
										<td className='text-primary text-12 font-bold py-[6px] text-right'>
											{/* {translator(order.currency)}{" "} */}
											{order?.country?.currency_symbol_native}
											{digitChanger(
												numberWithCommas(placedOrder?.total)
											)}
										</td>
									</tr>
								</tfoot>
							</table>
						)}
					</>
				</div>
			</Card>
		</div>
	);
}
