import React from "react";

export default function ArrowDown({ color = "#FF261A" }) {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='16'
			height='16'
			viewBox='0 0 16 16'
			fill='none'>
			<g id='fi-rr-arrow-up' clipPath='url(#clip0_1394_313)'>
				<path
					id='Vector'
					d='M11.4683 7.93815C11.3582 7.93848 11.2527 7.98235 11.1749 8.06018C11.097 8.13802 11.0532 8.2435 11.0528 8.35357L11.0646 10.4808L5.04833 4.46448C4.97019 4.38634 4.86421 4.34244 4.7537 4.34244C4.64319 4.34244 4.53721 4.38634 4.45907 4.46448C4.38093 4.54262 4.33703 4.6486 4.33703 4.75911C4.33703 4.86961 4.38093 4.97559 4.45907 5.05374L10.4724 11.0671L8.33933 11.0671C8.28432 11.0669 8.22982 11.0775 8.17896 11.0985C8.1281 11.1194 8.08188 11.1502 8.04299 11.1891C8.0041 11.228 7.97329 11.2742 7.95234 11.3251C7.9314 11.376 7.92073 11.4305 7.92095 11.4855C7.92128 11.5955 7.96516 11.701 8.04299 11.7789C8.12083 11.8567 8.2263 11.9006 8.33638 11.9009L10.6433 11.9038C10.9746 11.9036 11.2922 11.772 11.5264 11.5377C11.7607 11.3035 11.8923 10.9859 11.8925 10.6546L11.8837 8.35357C11.8834 8.2435 11.8395 8.13802 11.7617 8.06018C11.6838 7.98235 11.5784 7.93848 11.4683 7.93815Z'
					fill={color}
				/>
			</g>
			<defs>
				<clipPath id='clip0_1394_313'>
					<rect
						width='10'
						height='10'
						fill='white'
						transform='translate(15.0664 8) rotate(135)'
					/>
				</clipPath>
			</defs>
		</svg>
	);
}
