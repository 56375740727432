import {
  IonCol,
  IonGrid,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonRow,
  IonSpinner,
} from "@ionic/react";
import SingleRecommendProduct from "components/mobile-components/Home/SingleRecommendProduct";
import React from "react";
import { useSelector } from "react-redux";

export default function Products({
  recommendData,
  title,
  settings,
  lodeMoreData,
  searchNext,
  loading,
}) {
  const ekkLanguage = useSelector((state) => state.lan.lan);

  return (
    <div className=" pt-2">
      {loading && (
        <>
          <IonCol size="2"></IonCol>
          <IonCol size="8">
            <div
              className="text-center flex items-center justify-center"
              style={{ height: "calc(100vh - 600px)" }}
            >
              <IonSpinner color="success"></IonSpinner>
            </div>
          </IonCol>
        </>
      )}
      <IonGrid>
        <IonRow>
          {recommendData?.length ? (
            recommendData?.map((el, i) => {
              return (
                <IonCol size="6" sizeSm="6" className="mb-6" key={i}>
                  <SingleRecommendProduct
                    id={el.id}
                    name={el.catalogs.brand_name}
                    title={el.catalogs.title}
                    supplierName={el.catalogs.business_name}
                    qty={el.catalogs.product_count}
                    img={el.catalogs.images}
                    tag={el.catalogs.tag}
                    offer={el.catalogs.offer}
                    offerAmount={el.catalogs.offerAmount}
                    nearby={el.catalogs.nearby}
                    price={el.price}
                    oldPrice={el.catalogs.oldPrice}
                    newPrice={el.catalogs.newPrice}
                    sellPrice={el.catalogs.sellPrice}
                    offerPrice={el.catalogs.offer_price}
                    profit={el.catalogs.distributor_profit}
                    nameShow={settings.nameShow}
                    supplierNameShow={settings.supplierNameShow}
                    skuShow={settings.skuShow}
                    priceShow={settings.priceShow}
                    oldPriceShow={settings.oldPriceShow}
                    distShow={settings.distShow}
                    profitShow={
                      el.catalogs.profit || el.catalogs.consumer_price
                        ? settings.profitShow
                        : false
                    }
                    sellPriceShow={settings.sellPriceShow}
                    titleShow={settings.titleShow}
                    link={settings.link}
                    type={el.catalogs.type}
                    currency={el.catalogs.country.currency_symbol_native}
                    from="customPriceDetails"
                    relatedBusinessId={el?.business?.id}
                  />
                </IonCol>
              );
            })
          ) : (
            <>
              {!loading && (
                <>
                  <IonCol size="2"></IonCol>
                  <IonCol size="8">
                    <div
                      className="flex items-center justify-center w-full"
                      style={{ height: "calc(100vh - 600px)" }}
                    >
                      <div>
                        <img
                          className="m-auto mb-[10px] mt-5"
                          src="/assets/images/not-found-file.png"
                          alt="emptyCard"
                        />
                        <p className="text-16 font-normal text-222222 mb-[35px]">
                          {ekkLanguage.orderList.notFound}
                        </p>
                      </div>
                    </div>
                  </IonCol>
                </>
              )}
            </>
          )}
        </IonRow>
      </IonGrid>
    </div>
  );
}
