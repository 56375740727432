import React from "react";

export default function OrderListIcon() {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='16'
			height='18'
			viewBox='0 0 16 18'
			fill='none'
		>
			<path
				d='M11.0977 12.5192H5.08105'
				stroke='black'
				strokeWidth='1.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M11.0977 9.02995H5.08105'
				stroke='black'
				strokeWidth='1.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M7.37689 5.54948H5.08105'
				stroke='black'
				strokeWidth='1.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M11.2576 1.29102C11.2576 1.29102 4.86014 1.29435 4.85014 1.29435C2.55014 1.30852 1.12598 2.82185 1.12598 5.13018V12.7935C1.12598 15.1135 2.56098 16.6327 4.88098 16.6327C4.88098 16.6327 11.2776 16.6302 11.2885 16.6302C13.5885 16.616 15.0135 15.1018 15.0135 12.7935V5.13018C15.0135 2.81018 13.5776 1.29102 11.2576 1.29102Z'
				stroke='black'
				strokeWidth='1.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	);
}
