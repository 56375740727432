const bangla = {
	login: {
		contryLabel: "দেশ",
		contryPlaceHolder: "",
		phoneLable: "ফোন নম্বর",
		phonePlaceHolder: "মোবাইল নম্বর লিখুন",
		validationLabel: "অনুগ্রহ করে বৈধ ফোন নম্বর দিন!",
		btnLabel: "পরবর্তী",
		tokenSuccessLabel: " সফলভাবে টোকেন পাঠানো হয়েছে",
		tokenSuccessFailed:
			"টোকেনটি পাঠানো যায় নি, অনুগ্রহ করে আবার চেষ্টা করুন।",
		langChange: "ভাষা পরিবর্তন করুন",
		termsAllow: "লগ-ইন করার সময় শর্তাবলী অনুমতি দিন!",
	},
	password: {
		passwordLabel: "পাসওয়াার্ড",
		passwordPlaceHolder: "পাসওয়ার্ড লিখুন",
		forgetPass: "পাসওয়ার্ড ভুলে গিয়েছেন?",
		btnLabel: "প্রবেশ করুন",
		otpSendLabel: "আপনার মোবাইলে কোড পাঠানো হয়েছে,",
		backWord: "পেছনে যান",
		passError: "ফোন নম্বরের সাথে পাসওয়ার্ড মিলছে না",
	},
	changePass: {
		passwordLabel: "নতুন পাসওয়ার্ড",
		passwordPlaceHolder: "*******************",
		confirmPassLabel: "পাসওয়ার্ড নিশ্চিত করুন",
		confirmPassPlaceHolder: "*******************",
		passNotMatchLabel: "পাসওয়ার্ড মেলে না",
		btnLabel: "পরবর্তী",
		successLabel: "আপনার পাসওয়ার্ড সফলভাবে পরিবর্তন হয়েছে",
		errorLabel: "আপনার পাসওয়ার্ড পরিবর্তন ব্যর্থ হয়েছে",
		lengthErrorLabel: "পাসওয়ার্ড কমপক্ষে ৪ অক্ষরের হতে হবে",
		notMatchLabel: "আপনার পাসওয়ার্ড এবং নিশ্চিত পাসওয়ার্ড মেলে না।",
	},
	token: {
		tokenLabel: "টোকেন",
		tokenPlaceHolder: "টোকেন লিখুন",
		tokenResend: "পুনরায় টোকেন পাঠাও",
		tokenSuccessLabel:
			" আপনার মোবাইলে কোড পাঠানো হয়েছে, পরবর্তী ধাপে যেতে এটি লিখুন",
		tokenErrorLabel: "টোকেনটি পাঠানো যায় নি, অনুগ্রহ করে আবার চেষ্টা করুন।",
		timerLabel: "অনুগ্রহ করে অপেক্ষা করুন ",
		second: "সেকেন্ড",
		btnLabel: "পরবর্তী",
		tokenNotMatch: "মেয়াদোত্তীর্ণ টোকেন",
		tokenVarified: "আপনার টোকেন যাচাই করা হয়েছে",
		tokenNotWrite: "আপনার টোকেন লিখুন",
	},
	registration: {
		nameLabel: "পূর্ণ নাম",
		namePlaceholder: "পূূর্ণ নাম লিখুন",
		emailLabel: "ইমেইল ",
		emailPlaceholder: "ইমেইল লিখুন",
		passwordLabel: "পাসওয়াার্ড",
		passwordPlaceholder: "পাসওয়ার্ড লিখুন",
		confirmPassLabel: "নিশ্চিত পাসওয়াার্ড",
		confirmPassPlaceholder: "নিশ্চিত পাসওয়ার্ড লিখুন",
		validationLabel: "ইনপুট ক্ষেত্র ৪ অক্ষরের হতে হবে",
		passNotMatch: "আপনার পাসওয়ার্ড এবং নিশ্চিত পাসওয়ার্ড মেলে না।",
		btnLabel: "পরবর্তী",
		successMessage: "প্রোফাইল সফলভাবে তৈরি করা হয়েছে",
		errorMessage: "বৈধ ইনপুট চেক করুন",
		passLengthValidLabel: "পাসওয়ার্ড কমপক্ষে ৪ অক্ষরের হতে হবে",
		passwordLength: "আপনার পাসওয়ার্ডটি",
		strong: "শক্তিশালী",
		medium: "মাধ্যম",
		weak: "দুর্বল",
		gender: "লিঙ্গ",
		male: "পুরুষ",
		female: "নারী",
		others: "অন্যান্য",
		dobLabel: "জন্ম তারিখ",
		day: "দিন",
		month: "মাস",
		year: "বছর",
	},
	letNav: {
		homeMenuLabel: "হোম",
		orderMenuLabel: "অর্ডার তালিকা",
		mySupplierMenuLabel: "আমার সরবরাহকারী",
		businessProfileMenuLabel: "ব্যবসায়িক প্রোফাইল সেটিংস",
		phoneCall: "কল টু অর্ডার",
		myEmployeesMenuLabel: "কর্মী তালিকা",
		myCustomersMenuLabel: "ডিস্ট্রিবিউটর তালিকা",
		product: "পণ্য তালিকা",
		dashboardProfileMenuLabel: "ড্যাশবোর্ড",
	},
	home: {
		showMoreLabel: "আরো দেখুন",
		brandLabel: "ব্র্যান্ড",
		offerLabel: "অফার পণ্য",
		mySuppliersLabel: "আমার সরবরাহকারী",
		nearSuppliersLabel: "কাছাকাছি সরবরাহকারী",
		recommendentLabel: "শুধু আপনার জন্য",
		searchLabel: "পণ্য সার্চ করুন",
		todayPurchaseLabel: "আজকের কেনাকাটা",
		totalOrderLabel: "ধরণের পণ্য কিনেছেন",
		totalOrder: "টি অর্ডারে",
		skusLabel: "এসকেইউ",
		tillNow: "এখন পর্যন্ত",
		orderDue: "অর্ডার বাকি",
		brandProductPageTitle: "ব্র্যান্ডের সকল পণ্য",
		offerProductPageTitle: "মূল্যছাড় সকল পণ্য",
		offerProductNotFound: "দুঃখিত, আপনার জন্য কোন অফার পাওয়া যায়নি.",
		mySupplierNotFound:
			"আপনার কোনো সরবরাহকারী নেই। এখানে আপনার সরবরাহকারীদের যোগ করতে পণ্য কিনুন।",
		account: "অ্যাকাউন্ট",
		orderNumber: "অর্ডার নম্বর",
		amount: "এমাউন্ট",
		warning: `দয়া করে নোট করুন, লেনদেনগুলি ১লা মার্চ ২০২৪ প্রভাবিত হতে পারে, কারণ সিস্টেম আপগ্রেড করা হচ্ছে৷`,
		warningHeading: "সতর্কতা",
	},
	profile: {
		pageTitle: "প্রোফাইল এবং সেটিংস",
		contactMenuLable: "যোগাযোগ এবং মৌলিক তথ্য",
		passwordMenuLable: "পাসওয়ার্ড পরিবর্তন করুন",
		logoutMenuLable: "লগআউট",
		changeLanguageMenuLable: "ভাষা পরিবর্তন করুন",
		kycMenuLable: "KYC তথ্য",
		subscriptionPlan: "সাবস্ক্রিপশন প্ল্যান",
	},
	contact: {
		pageTitle: "ব্যবহারকারীর তথ্য",
		emailLabel: "ইমেইল",
		emailPlaceholder: "ইমেইল লিখুন",
		nidLabel: "এনআইডি",
		nidPlaceholder: "এনআইডি লিখুন",
		btnLabel: "সেভ করুন",
		successLabel: "আপনার প্রোফাইল সফলভাবে আপডেট করা হয়েছে.",
		currectEmail: "অনুগ্রহ করে সঠিক ইমেল ঠিকানা লিখুন",
	},
	otpsend: {
		pageTitle: "নম্বর যাচাই করুন",
		btnLabel: "পাঠান",
		message:
			"খুদেবার্তা এর মাধ্যমে আপনার মোবাইলে প্রমাণীকরণ কোড পাঠাতে ট্যাপ করুন।",
		successLabel: "আপনার ফোনে আপনার টোকেন পাঠানো হয়েছে",
		errorLabel: "আপনার টোকেন এখনো তৈরি হয়নি অনুগ্রহ করে আবার চেষ্টা করুন",
	},
	otpsubmit: {
		pageTitle: "নম্বর যাচাই করুন",
		btnLabel: "জমা দিন",
		title: "যাচাইকরণ কোড",
		verifiLabel: "যাচাই করুন এবং চালিয়ে যান",
		paragraph: "আমরা আপনার মোবাইল নম্বরে যাচাইকরণ কোড পাঠিয়েছি",
		otpLabel: "ওটিপি",
		otpPlaceholder: "আপনার ওটিপি কোড লিখুন",
		receiveYetLabel: "কোড পাননি?",
		againRequestLabel: "আবার অনুরোধ করুন",
		timerLabel: "অনুগ্রহ করে অপেক্ষা করুন ",
		second: "সেকেন্ড",
		successLabel: "আপনার ফোনে আপনার টোকেন পাঠানো হয়েছে",
		errorLabel: "আপনার টোকেন এখনো তৈরি হয়নি অনুগ্রহ করে আবার চেষ্টা করুন",
		tokenVarifiedMessage: "আপনার টোকেন যাচাই করা হয়েছে",
		validationLabel: "আপনার ওটিপি কোড জমা দিন",
	},
	changePassword: {
		pageTitle: "পাসওয়ার্ড পরিবর্তন",
		btnLabel: "জমা দিন",
		passwordLabel: "পাসওয়াার্ড",
		passwordPlaceholder: "পাসওয়ার্ড লিখুন",
		confirmPassLabel: "নিশ্চিত পাসওয়াার্ড",
		confirmPassPlaceholder: "নিশ্চিত পাসওয়ার্ড লিখুন",
		successLabel: "আপনার পাসওয়ার্ড আপডেট করা হয়েছে.",
		errorLabel: "আপনার পাসওয়ার্ড আপডেট ব্যর্থ হয়েছে",
		passNotMatchLabel: "আপনার পাসওয়ার্ড এবং নিশ্চিত পাসওয়ার্ড মেলে না।",
		changePassSuccessPara: "আপনার পাসওয়ার্ড সফলভাবে পরিবর্তন করা হয়েছে.",
		changePassContinueBtn: "পরবর্তী",
	},
	changeLanguages: {
		pageTitle: "ভাষা নির্বাচন করুন",
		btnLabel: "সংরক্ষণ করুন",
	},

	mysuppliers: {
		skusLabel: "এসকেইউ",
		productTitleLabel: "এই সরবরাহকারী পণ্য",
	},

	createOrder: {
		pageTitle: "অর্ডার তৈরী করুন",
		buyerDetailsTitle: "ক্রেতার বিবরণ",
		sellerDetailsTitle: "বিক্রেতার বিবরণ",
		deliveryDetailsTitle: "পণ্য সরবরাহ",
		productItemTitle: "পণ্য আইটেম",
		costSummaryTitle: "খরচ সারসংক্ষেপ",
		paymentDetailsTitle: "অর্থ প্রদানের বিবরণ",
		paymentTermsTitle: "অর্থ প্রদানের শর্তাবলী",
		paymentMethodeTitle: "অর্থ প্রদানের পদ্ধতি",
		moqLabel: "নূন্যতম অর্ডার পরিমাণ",
		pcsLabel: "পিছ",
		subtotal: "মোট",
		tax: "ট্যাক্স / ভ্যাট",
		discount: "মূল্যছাড়",
		deliveryFee: "ডেলিভারি ফি",
		creditFee: "সার্ভিস চার্জ ",
		total: " মোট",
		immediatePayment: "তাৎক্ষণিক পেমেন্ট",
		pickAndPay: "পিক অ্যান্ড পে",
		today: "ডেলিভারি দিনের রাতে",
		threeDays: "৩ দিনের মধ্যে অর্থ প্রদান করুন",
		sevenDays: "৭ দিনের মধ্যে অর্থ প্রদান করুন",
		immediatePaymentText: "এই অর্ডারের জন্য কোনও সার্ভিস চার্জ  প্রযোজ্য নয়",
		todayPaymentText1: "আজকের পেমেন্টের জন্য, অতিরিক্ত সার্ভিস চার্জ  ",
		todayPaymentText2: "এই অর্ডারে যোগ করা হবে",
		threePaymentText1: "৩ দিনের পেমেন্টের জন্য, অতিরিক্ত সার্ভিস চার্জ ",
		threePaymentText2: "এই অর্ডারে যোগ করা হবে.",
		sevenPaymentText1: "৭ দিনের পেমেন্টের জন্য, অতিরিক্ত সার্ভিস চার্জ  ",
		sevenPaymentText2: "এই অর্ডারে যোগ করা হবে",
		cashOnDelivery: "ক্যাশ অন ডেলিভারি",
		cashOnDeliveryText1: "নগদ অর্থ প্রদান করুন",
		cashOnDeliveryText2: "যখন পণ্য বিতরণ করা হয়",
		cash: "ক্যাশ",
		cashText: "নগদ অর্থ প্রদান করুন",
		bankTransfer: "ব্যাংক ট্রান্সফার",
		bankTransferText1: "ব্যাংক ট্রান্সফার - পরিশোধ করুন",
		bankTransferText2: "রেফারেন্স হিসাবে 'অর্ডার নম্বর' সহ ব্যাংক অ্যাকাউন্ট",
		bankTransferText3: "ব্যাংকের নাম",
		bankTransferText4: "অ্যাকাউন্টের নাম",
		bankTransferText5: "অ্যাকাউন্টের নম্বর",
		bankTransferText6: "রাউট নম্বর",
		bankTransferText7: "শাখা",
		btnLabel: "নিশ্চিত",
		addMoreProductLabel: "আরও পণ্য যোগ করুন",
		selectProductLabel: "জমা দিন",
		cosgressTitle: "অভিনন্দন!",
		cosgressPara: "আপনার অর্ডার সম্পূর্ণ হয়েছে।",
		congressCancelText:
			"অর্ডার নিয়ে কোনো সমস্যা হলে রাত ১২ টার আগে বাতিল করুন। আপনি ১২ টার পরে অর্ডার বাতিল করতে পারবেন না।",
		congressProductLabel: "পরবর্তী",
		orderNow: "অর্ডার করুন",
		alert: "সতর্কতা",
		importentMessage: "গুরুত্বপূর্ণ বার্তা",
		deliveryDateMessage: "অনুগ্রহ করে ডেলিভারির তারিখ নির্বাচন করুন",
		paymentTermsMessage: "অনুগ্রহ করে অর্থপ্রদানের মেয়াদ নির্বাচন করুন",
		paymentMethodMessage: "অনুগ্রহ করে পেমেন্ট পদ্ধতি নির্বাচন করুন",
		backButtonMessage:
			"আপনার কার্টে কিছু আইটেম আছে, আপনি যদি এই পৃষ্ঠাটি ছেড়ে যান তবে আপনার কার্ট খালি হয়ে যাবে।",
		okBtnLabel: "ঠিক আছে",
		loader: "অনুগ্রহপূর্বক অপেক্ষা করুন...",
		placeConformationMessage: "আপনি কি এই অর্ডার দেওয়ার বিষয়ে নিশ্চিত?",
		lessThenMoq: "ইনপুট করা আইটেম পরিমাণ সর্বনিম্ন অর্ডার পরিমাণের চেয়ে কম।",
		all: "সবগুলো",
		requestDeliveryDate: "ডেলিভারি অনুরোধের তারিখ",
	},

	selectProduct: {
		pageTitle: "পণ্য নির্বাচন করুন",
		totalLabel: "মোট",
		btnLabel: "জমা দিন",
	},
	orderList: {
		pageTitle: "অর্ডার তালিকা",
		tabMenuRecent: "সবগুলো",
		tabMenuPlaced: "স্থাপিত",
		tabMenuAccepted: "গ্রহণ",
		tabMenuScDelivered: "ডেলিভারির জন্য নির্ধারিত",
		tabMenuPartialDelivered: "আংশিক ডেলিভারি",
		tabMenuDelivered: "ফুল ডেলিভারি",
		tabMenuReturn: "ফেরত পণ্য",
		tabMenuPartialPaid: "আংশিক পরিশোধ",
		tabMenuPaid: "ফুল পরিশোধ",
		tabMenuCancel: "বাতিল",
		tabMenuDeliveryFailed: "ডেলিভারি ব্যর্থ হয়েছে",
		orderItem: "অর্ডারকৃত পণ্য",
		seller: "বিক্রেতা",
		buyer: "ক্রেতা",
		createdBy: "তৈরি করেছেন",
		loader: "অনুগ্রহপূর্বক অপেক্ষা করুন...",
		notFound: "কোন তালিকা পাওয়া যায়নি।",
	},
	customerList: {
		pageTitle: "ডিস্ট্রিবিউটর তালিকা",
		searchPlaceHolder: "আপনার ডিস্ট্রিবিউটরের নাম লিখুন",
		detailsPageTitle: "ডিস্ট্রিবিউটরের বিবরণ",
		customerDetailsLabel: "ডিস্ট্রিবিউটর তথ্য",
		businessTypeLabel: "ব্যবসার ধরণ",
		customerNameLabel: "নাম",
		addressLabel: "ঠিকানা",
		postalCodeLabel: "পোস্টাল কোড",
		cityLabel: "শহর",
		acceptedCustomerLabel: "ডিস্ট্রিবিউটর",
		requestedCustomerLabel: "অর্ডার তালিকা",
		accept: "গ্রহণ করুন",
		acceptConfirmation:
			"আপনি কি নিশ্চিত আপনি এই ডিস্ট্রিবিউটরকে গ্রহণ করতে চান?",
		km: "কি.মি.",
		createCustomer: "নতুন ডিস্ট্রিবিউটর তৈরি করুন",
		invitedDistributor: "ইনভাইটেড ডিস্ট্রিবিউটর তালিকা",
		customerNoHolder: "ডিস্ট্রিবিউটরের মোবাইল নম্বর",
		nextBtn: "পরবর্তী",
		deleteDistributor: "ডিস্ট্রিবিউটর মুছুন",
		addCustomPrice: "স্পেশাল মূল্যের জন্য পণ্য যোগ করুন",
		warningMsg: "আপনি কি নিশ্চিত যে আপনি এই ডিস্ট্রিবিউটরকে মুছতে চান?",
		yes: "হ্যাঁ",
		no: "না",
		successMsg: "ডিস্ট্রিবিউটরটি সফলভাবে মোছা হয়েছে!",
		customPriceMsg:
			"এই ডিস্ট্রিবিউটরের জন্য কাস্টম মূল্য সেট করতে কি আপনি নিশ্চিত?",
		inputPlaceholder: "স্পেশাল মূল্য লিখুন",
		priceEmptyError: "স্পেশাল মূল্য খালি রাখা যাবে না!",
		alreadySetPriceError:
			"এই পণ্যের মূল্যটি ইতিমধ্যে এই ডিস্ট্রিবিউটরের জন্য নির্ধারণ করা হয়েছে।",
		cancelBtn: "বাতিল",
		submit: "জমা দিন",
		modalTitle: "স্পেশাল মূল্য নির্ধারণ করুন",
		productsSectionTitle: "স্পেশাল মূল্যর পণ্য তালিকা",
		distributorCustomerList: "কাস্টমার তালিকা",
		updatePriceTitle: "স্পেশাল মূল্য পরিবর্তন করুন",
		deletePriceTitle: "স্পেশাল মূল্য থেকে পণ্য মুছুন",
		productDetailTitle: "পণ্যের বিবরণ",
		offerDetailsTitle: "অফার বিবরণ",
		description:
			"আপনি কি নিশ্চিত যে আপনি এই স্পেশাল মূল্যর পণ্যটি মুছতে চান?",
		distributorCode: "ডিস্ট্রিবিউটর কোড",
		notSet: "এখনো সেট করা হয়নি",
		distributorCodeAdd: "ডিস্ট্রিবিউটর কোড এড করুন",
		distributorCodeEdit: "ডিস্ট্রিবিউটর কোড আপডেইট করুন",
		writeDistributorCode: "ডিস্ট্রিবিউটর কোড লিখুন",
		successLabel: "ডিস্ট্রিবিউটর কোড এড সফল হয়েছে",
		editSuccessLabel: "ডিস্ট্রিবিউটর কোড আপডেইট সফল হয়েছে",
	},
	orderDetails: {
		createdBy: "তৈরি করেছেন",
		placedLabel: "অর্ডার হয়েছে ",
		deliveredLabel: "ডেলিভারী সম্পন্ন হয়েছে",
		paidLabel: "পরিশোধিত",
		orderItemLabel: "অর্ডারকৃত পণ্য",
		castItemLabel: "খরচের বিবরণ",
		timelineLabel: "সময়রেখা",
		subtotal: "মোট",
		tax: "ট্যাক্স / ভ্যাট",
		discount: "ডিসকাউন্ট",
		deliveryFee: "ডেলিভারি ফি",
		creditFee: "সার্ভিস চার্জ ",
		total: "মোট",
		paymentSettlementLabel: "পেমেন্ট সেটেলমেন্ট",
		date: "তারিখ",
		amount: "পরিমাণ",
		dueDate: "নির্দিষ্ট তারিখ",
		remark: "মন্তব্য",
		collectedBy: "দ্বারা সংগৃহীত",
		paymentDetailsLabel: "অর্থ প্রদানের বিবরণ",
		paymentMethod: "অর্থ প্রদানের পদ্ধতি",
		paymentTerms: "অর্থ প্রদানের শর্তাবলী",
		paymentDueDate: "অর্থ প্রদানের বাকি তারিখ",
		totalDue: "মোট বকেয়া",
		totalPaid: "মোট পরিশোধ",
		remaining: "অবশিষ্ট",
		deliveryDetailsLabel: "ডেলিভারির বিস্তারিত",
		selectTimeDate: "ডেলিভারির তারিখ ও সময় নির্ধারণ করুন",
		selectTime: "ডেলিভারির সময় নির্ধারণ করুন",
		selectDate: "ডেলিভারির তারিখ নির্ধারণ করুন",
		acceptOrder: "অর্ডার গ্রহণ করুন",
		cancelOrder: "অর্ডার বাতিল করুন",
		scheduleDelivery: "শিডিউল প্রস্তুত করুন",
		BuyerName: "ক্রেতার নাম",
		address: "ঠিকানা",
		phoneNumber: "মোবাইল নম্বর",
		email: "ইমেইল ",
		deliveryDate: "ডেলিভারি প্রদানের তারিখ",
		downloadInvoices: "চালান ডাউনলোড করুন",
		receiptDownloads: "রসিদ প্রিন্ট করুন",
		cancelConformationAsk: "আপনি কি নিশ্চিত আপনি এই অর্ডার বাতিল করতে চান?",
		cancelConformationParagraph:
			"আপনি এই অর্ডারটি বাতিল করতে পারবেন না, আপনি শুধুমাত্র একই দিনে আপনার অর্ডার বাতিল করতে পারবেন।",
		yesBtn: "হ্যাঁ",
		noBtn: "না",
		withoutSettelment: "পেমেন্ট সম্পূর্ণ তথ্য এখানে দেখানো হবে।",
		bankInfoLabel: "পেমেন্ট ট্রান্সফারের জন্য ব্যাঙ্ক অ্যাকাউন্টের তথ্য",
		assignDriver: "ড্রাইভার নিয়োগ করুন",
		assignCollector: "কালেক্টর নিয়োগ করুন",
		assignedDriver: "ড্রাইভার নিযুক্ত হয়েছেন",
		assignedCollector: "কালেক্টর নিযুক্ত হয়েছেন ",
		notAssigned: "এখনো নিযুক্ত করা হয়নি",
		addDeliveryFee: "ডেলিভারি ফি যুক্ত করুন",
		addDiscount: "ডিসকাউন্ট যুক্ত করুন",
		feePlaceHolder: "ডেলিভারি ফি এমাউন্ট লিখুন",
		discountPlaceHolder: "ডিসকাউন্ট এমাউন্ট লিখুন",
		requestDeliveryDate: "ডেলিভারি অনুরোধের তারিখ",
		scheduleDeliveryDate: "সিডিউল ডেলিভারি তারিখ",
		scheduleDeliveryTime: "সিডিউল ডেলিভারি সময়",
		deliveryFailMessage: "ডেলিভারি ফেল মেসেজ",
		selectCancelOrder: "অর্ডার বাতিল করার কারণ নির্বাচন করুন",
		multipleTime: "একাধিকবার ডেলিভারি ব্যর্থ হয়েছে",
		productNotAvailable: "পণ্য স্টকে নেই",
		cannotFindAddress: "ঠিকানা খুঁজে পাওয়া যাচ্ছে না",
		noShop: "প্রতিষ্ঠাতার ঠিকানা কিন্তু সেখানে কোনো দোকান নেই",
		buyerNotAccept: "ক্রেতা অর্ডার মূল্য গ্রহণ করবে না",
		supplierNotGive: "সরবরাহকারী পণ্য দেয়নি",
		other: "অন্যান্য",
		yes: "হ্যাঁ",
		no: "না",
		deliveryTimeWarning:
			"আপনাকে অবশ্যই ডেলিভারি একটি তারিখ নির্বাচন করতে হবে, অন্যথায়, আমরা ডেলিভারির জন্য সময়সূচী প্রক্রিয়া করতে পারি না",
		deliveryDateWarning:
			"আপনাকে অবশ্যই ডেলিভারি একটি সময় নির্বাচন করতে হবে, অন্যথায়, আমরা ডেলিভারির জন্য সময়সূচী প্রক্রিয়া করতে পারি না",
		deliveryDateTimeWarning:
			"আপনাকে অবশ্যই ডেলিভারি একটি সময় নির্বাচন এবং একটি তারিখ করতে হবে",

		orderSearchLabel: "আপনার অর্ডারটি খুঁজুন",
		close: "বন্ধ করুন",
		confirmDelivery: "ডেলিভারি সম্পুর্ন করুন",
		instantDelivery:
			"আপনি কি নিশ্চিত যে আপনি এই অর্ডারের জন্য ডেলিভারি সম্পূর্ণ করতে চান?",
		transactionType: "ট্রানজেকশনের ধরণ",
		gateway: "পেমেন্ট গেটওয়ে",
		accountName: "অ্যাকাউন্টের নাম",
		accountNumber: "অ্যাকাউন্ট নম্বর",
		transactionNumber: "ট্রানজেকশন নম্বর",
		receipt: "রশিদ",
		receiptNotProvided: "রশিদ প্রদান করা হয়নি",
		sellerPaymentDone: "সেলার পেমেন্ট সম্পূর্ন",
	},
	businessCreateName: {
		pageTitle: "ব্যবসার নাম",
		businessName: "ব্যবসার নাম",
		businessNamePlaceholder: "ব্যবসার নাম দিন",
		paragraph:
			"আপনার ব্যবসা তৈরি করতে ৩ ধাপ দূরে আছি আমরা। আপনার ব্যবসার নাম দিয়ে শুরু করা যাক।",
		btnLabel: "পরবর্তী",
		submit: "জমা দিন",
		numberOne: "১",
		numberTwo: "২",
		numberThree: "৩",
		errorLabel: "ব্যবসায়ের নাম লিখুন!",
		congratulation: "অভিনন্দন!",
		congratulationMessage: "আপনার ব্যবসা সফলভাবে তৈরি হয়েছে",
		kycSubmit: "কেওয়াইসি সাবমিট করুন",
		pending:
			"কেওয়াইসি সাবমিট করার জন্য ধন্যবাদ। আপনার কেওয়াইসি ভেরিফিকেশন চলছে, অনুগ্রহ করে অপেক্ষা করুন।",
		goBack: "ফিরে যাও",
	},
	businessType: {
		pageTitle: "ব্যবসার ধরণ",
		paragraph: "আপনার ব্যবসার ধরণ নির্বাচন করুন",
		fmcg: "এফএমসিজি",
		miniMart: "মিনি মার্ট",
		superMart: "সুপার মার্ট",
		pharmacy: "ফার্মেসি",
		cart: "কার্ট",
		bazaarStore: "বাজার দোকান",
		hawkerCenter: "হকার সেন্টার",
		restaurant: "রেস্টুরেন্ট",
		hotel: "হোটেল",
		cosmeticsStore: "প্রসাধনী দোকান",
		nonFmcg: "নন এফএমসিজি",
		office: "অফিস",
		NGO: "এনজিও",
		entertainmentPlace: "বিনোদনের স্থান",
		religiousOrganization: "ধর্মীয় সংগঠন",
		spa: "স্পা",
		salon: "সেলুন",
		gym: "জিম",
		electronicsStore: "ইলেক্ট্রনিক্স স্টোর",
		shoeStore: "জুতার দোকান",
		fashionStore: "ফ্যাশন স্টোর",
		laundryStore: "লন্ড্রি স্টোর",
		numberOne: "১",
		numberTwo: "২",
		numberThree: "৩",
		btnLabel: "পরবর্তী",
		validationLabel: "একটি ব্যবসার ধরন নির্বাচন করুন",
		producer: "প্রোডিউসার",
		reseller: "রিসেলার",
	},
	businessLocation: {
		pageTitle: "ব্যবসার অবস্থান",
		paragraph: "আপনার ব্যবসার যোগাযোগের বিস্তারিত লিখুন",
		address: "ঠিকানা",
		addressPlaceholder: "আপনার ঠিকানা লিখুন",
		city: "শহর",
		cityPlaceholder: "শহরের নাম লিখুন",
		postalCode: "পোস্টাল কোড",
		postalCodePlaceholder: "পোস্টাল কোড লিখুন",
		email: "ইমেইল",
		emailPlaceholder: "আপনার ইমেল লিখুন",
		userName: "ব্যবহারকারীর নাম",
		userNamePlaceholder: "ব্যবহারকারীর নাম লিখুন",
		numberOne: "১",
		numberTwo: "২",
		numberThree: "৩",
		btnLabel: "সম্পন্ন করুন",
		successLabel: "ব্যবসা তৈরি করার জন্য অভিনন্দন",
		errorLabel: "ব্যবসা তৈরি ব্যর্থ হয়েছে",
		validationLabel: "অনুগ্রহ করে ঠিকানাটি পূরণ করুন",
		loader: "দয়া করে অপেক্ষা করুন...",
		validationCityLabel: "অনুগ্রহ করে শহর পূরণ করুন.",
		validationPostCodeLabel: "অনুগ্রহ করে পোস্টাল কোড পূরণ করুন.",
	},
	locationPicker: {
		pageTitle: "দোকান অবস্থান অনুসন্ধান",
		btnLabel: "নিশ্চিত করুন",
		paragraph:
			"মেপ এর মার্কারটির অবস্থা পরিবর্তন করে আপনার ব্যবসার অবস্থান নির্বাচন করুন।",
	},
	location: {
		title: "আপনার অবস্থান সক্রিয় করুন",
		paragraph:
			"এই অ্যাপটির জন্য আপনার ডিভাইস এবং এই অ্যাপের জন্য লোকেশন পরিষেবা চালু থাকা প্রয়োজন। এই অ্যাপটি ব্যবহার করার আগে আপনাকে অবশ্যই সেগুলিকে সক্রিয় করতে হবে।",
		btnLabel: "শুধুমাত্র অ্যাপ ব্যবহার করার সময় অনুমতি দিন",
	},
	businessSelect: {
		pageTitle: "ব্যবসা নির্বাচন করুন",
	},
	pwaDownload: {
		header: "EkkBaz অ্যাপ ডাউনলোড করুন",
		paragraph:
			"অ্যাপটি ডাউনলোড করুন এবং এখন থেকে যেকোনো সময় আপনার সরবরাহকারীর কাছ থেকে বাল্ক অর্ডার করুন।",
		cancelBtn: "বাতিল করুন",
		downloadBtn: "ডাউনলোড করুন",
	},
	businessProfile: {
		pageTitle: "ব্যবসায়িক প্রোফাইল সেটিংস",
		businessDetailsLabel: "বিস্তারিত",
		registrationNo: "নিবন্ধনের নম্বর",
		taxNo: "টেক্স/ভ্যাট নম্বর",
		mobileNo: "মোবাইল নম্বর",
		officeNo: "অফিস নম্বর",
		email: "ইমেইল",
		website: "ওয়েবসাইট",
		branchLabel: "শাখা তালিকা",
		paymentDetailsLabel: "পেমেন্ট বিবরণ",
		bankInfoLabel: "ব্যাংক তথ্য",
		bankName: "ব্যাংকের নাম",
		branchName: "ব্যাংকের শাখা",
		branchAddress: "ব্যাংকের ঠিকানা",
		swiftCode: "ব্যাংক সুইফট কোড",
		acName: "ব্যাঙ্কের A/C নাম",
		acNumber: "ব্যাংক এ/সি নম্বর",
		bkashInfoLabel: "বিকাশ তথ্য",
		bkashAcName: "বিকাশ এ/সি নাম",
		bkashAcNo: "বিকাশ এ/সি নম্বর",
		nagadInfoLabel: "নগদ তথ্য",
		nagadAcName: "নাগদ এ/সি নাম",
		nagadAcNo: "নাগদ এ/সি নম্বর",
		upayInfoLabel: "উপাই তথ্য",
		upayAcName: "উপাই এ/সি নাম",
		upayAcNo: "উপাই এ/সি নম্বর",
		verified: "যাচাই",
		unVerified: "যাচাই করা হয়নি",
		editBusinessMenu: "ব্যবসা সম্পাদনা করুন",
		addPayMenu: "পেমেন্ট অপশন যোগ করুন ",
		setLocation: "ব্যবসার লোকেশন আপডেট করুন",
		addMenu: "শাখা যোগ করুন",
		verifyBusinessMenu: "ব্যবসা যাচাই করুন",
		bankInfoNotFound: "ব্যাংক তথ্য পাওয়া যায়নি",
		paymentTermsSettings: "পেমেন্ট শর্তাবলী সেটিং",
		switchBusiness: "ব্যবসা পরিবর্তন করুন",
		deleteBusiness: "ব্যবসা মুছে ফেলুন",
		deleteWarning:
			"আপনার সমস্ত অর্ডার, পণ্য, কর্মচারী এবং আপনার সম্পূর্ণ অ্যাকাউন্ট মুছে ফেলা হবে। আপনি কি এই ব্যবসা মুছে ফেলার বিষয়ে নিশ্চিত?",
		otpSubmit: "ওটিপি প্রদান",
		confirmDeleteWarning:
			"আবার চিন্তা করুন! আপনার সমস্ত অর্ডার, পণ্য, কর্মচারী এবং আপনার সম্পূর্ণ অ্যাকাউন্ট মুছে ফেলা হবে। আপনি কি এই ব্যবসা মুছে ফেলার বিষয়ে নিশ্চিত?",
		directSells: "সরাসরি বিক্রি",
		directSellsEnable: "আপনার ব্যবসায় সরাসরি বিক্রি চালু করতে চান?",
		directSellsDisable:
			"আপনার ব্যবসায় সরাসরি বিক্রি চালু রয়েছে। আপনি কি সরাসরি বিক্রি বন্ধ করতে চান?",
		yes: "হ্যাঁ",
		no: "না",
	},
	businessEdit: {
		pageTitle: "ব্যবসা সম্পাদনা করুন",
		uploadPhoto: "ছবি আপলোড",
		email: "ইমেইল",
		emailPlaceholder: "ইমেইল লিখুন",
		address: "ঠিকানা",
		addressPlaceholder: "আপনার ঠিকানা লিখুন",
		city: "শহর",
		cityPlaceholder: "শহরের নাম লিখুন",
		postalCode: "পোস্টাল কোড",
		postalCodePlaceholder: "পোস্টাল কোড লিখুন",
		resigter: "নিবন্ধনের নম্বর",
		resigterPlaceholder: "নিবন্ধনের নম্বর লিখুন",
		tex: "টেক্স/ভ্যাট নম্বর",
		texPlaceholder: "টেক্স/ভ্যাট নম্বর লিখুন",
		mobile: "মোবাইল নম্বর",
		mobilePlaceholder: "মোবাইল নম্বর লিখুন",
		office: "অফিস নম্বর",
		officePlaceholder: "অফিস নম্বর লিখুন",
		website: "ওয়েবসাইট",
		websitePlaceholder: "ওয়েবসাইট লিখুন",
		btnLabel: "সেভ করুন",
		successLabel: "আপনার প্রোফাইল সফলভাবে আপডেট করা হয়েছে.",
		currectEmail: "অনুগ্রহ করে সঠিক ইমেল ঠিকানা লিখুন",
	},
	bankDetails: {
		referenceNumber: "পরিচিত সংখ্যা",
		bankName: "ব্যাংকের নাম",
		accountName: "A/C নাম",
		accountNumber: "A/C নম্বর",
		routeNumber: "রুট নম্বর",
		branch: "শাখা",
	},
	invoice: {
		title: "চালান",
		receipt: "রসিদ",
		buyerDetails: "ক্রেতার বিবরণ",
		sellerDetails: "বিক্রেতার বিবরণ",
		description: "বর্ণনা",
		qty: "পরিমাণ",
		price: "মূল্য",
		subtotal: "মোট",
	},
	verifyBusiness: {
		numberOne: "১",
		numberTwo: "২",
		numberThree: "৩",
		numberFour: "৪",
		numberFive: "৫",
		numberSix: "৬",
	},
	receipt: {
		placed: "স্থাপিত",
		name: "নাম",
		qty: "পরিমাণ",
		price: "মূল্য",
		subtotal: "সাবটোটাল",
		tax: "ট্যাক্স",
		discount: "ছাড়",
		creditFee: "সার্ভিস চার্জ ",
		deliveryFee: "ডেলিভারি করার টাকা",
		total: "মোট",
		paid: "পরিশোধিত",
		totalDue: "মোট বাকি",
		paymentTerms: "পরিশোধের শর্ত",
		days: "দিন",
		paymentMethod: "মূল্যপরিশোধ পদ্ধতি",
		referenceNumber: "ব্যাঙ্ক রেফারেন্স নম্বর",
		deliveryDate: "ডেলিভারির তারিখ ",
		dueDate: "পরিশোধযোগ্য তারিখ",
		printTime: "মুদ্রণের সময়",
		conditionsCreditFee: "অর্থপ্রদানের শর্তাবলী এবং সার্ভিস চার্জ ",
		immediatePayment: "জরুরী টাকা প্রদান",
		cashOnDelivery: "প্রদানোত্তর পরিশোধ",
		atNight: "ডেলিভারি দিবসের রাতে",
		payInthreeDay: "3 দিনের মধ্যে পেমেন্ট করুন",
		payInSevenDay: "7 দিনের মধ্যে পেমেন্ট করুন",
		afterSeven: "৭ দিন পর (প্রতিদিনের জন্য অতিরিক্ত)",
	},

	landing: {
		header1: "আপনার ডিস্ট্রিবিউটর চ্যানেল এর বিক্রয়সমূহ",
		header2: "সহজেই পরিচালনা করুন",
		login: "প্রবেশ করুন",
		bangla: "বাংলা",
		english: "English",
		vietnamese: "Tiếng Việt",
		malaysia: "Malay",
		retail: "দোকানদার",
		manufacture: "সাপ্লাইয়ার",
		brand: "ব্র্যান্ড",
		hotline: "হট লাইন",
		phoneNumber: "ফোন নম্বর",
		phoneNumberPlaceHolder: "ফোন নম্বর লিখুন",
		brandHeader: " আমাদের সাথে কাজ করা ব্র্যান্ড গুলো",
		fAbout: "বিক্রির একমাত্র সমাধান, বিক্রি কে করুন আরো সহজ",
		location: "অবস্থান",
		singapore: "সিঙ্গাপুর অফিস",
		bangladesh: "বাংলাদেশ অফিস",
		bOfficeAddress: "১৩ তলা, রাজ্জাক প্লাজা, মগ বাজার মোড়, ঢাকা-১২০৫",
		otherInfo: "অন্যান্য তথ্য",
		news: "খবর",
		support: "সাপোর্ট",
		corporate: "কর্পোরেট",
		refundPolicy: "রিফান্ড পোলেসি",
		termsCondition: "ট্রার্ম & কন্ডিশন",
		privacyPolicy: "প্রাইভেসি পোলেসি",
		contactUs: "যোগাযোগ করুন",
		messageUs: "মেসেজ করুন",
		facebook: "ফেসবুক",
		sales: "বিক্রয়",
		media: "মিডিয়া",
		downloadBtn: "ডাউনলোড করুন",
		backers: "আমাদের ব্যাকার্স",
		benefits: "আপনার সুবিধা",
		discover: "সর্বশেষ একবাজ খবর",
		benefitsOne: "বৃদ্ধি বিক্রয়",
		partners: "আমাদের অংশীদার",
		benefitsDisOne: "",
		benefitsTwo: "সহজেই গ্রাহকের কাছে পৌঁছান",
		benefitsDisOneTwo: "",
		benefitsThree: "পণ্যের দৃশ্যমানতা বাড়ান",
		benefitsDisOneThree: "",
		benefitsFore: "আপনার বিক্রয় প্রক্রিয়া ডিজিটালাইজ করুন",
		benefitsDisOneFore: "",
		seeMore: "আরো দেখুন",
		downloadApp: "অ্যাপ ডাউনলোড করুন এখনই !",
	},
	employees: {
		pageTitle: "কর্মী তালিকা",
		createNewEmployees: "নতুন কর্মী তৈরী করুন",
		searchPlaceHolder: "আপনার কর্মী খুঁজুন",
	},
	employeesDetails: {
		pageTitle: "কর্মীর বিবরণ",
	},
	createEmployees: {
		pageTitle: "নতুন কর্মী তৈরী করুন",
		customerNoHolder: "মোবাইল নম্বর লিখুন",
		employeeNumber: "কর্মীর নম্বর",
		name: "নাম",
		namePlaceholder: "কর্মীর নাম লিখুন",
		confirmBtn: "নিশ্চিত করুন",
		selectEmployees: "কর্মী নির্বাচন করুন",
		nameError: "অনুগ্রহ করে কর্মীর নাম লিখুন",
		roleError: "অনুগ্রহ করে কর্মীর রোল নির্বাচন করুন",
		edit: "ইডিট করুন",
		delete: "ডিলিট করুন",
		editEmployee: "ইডিট কর্মী",
		deleteConformationAsk: "আপনি কি এই কর্মীকে মুছে দেওয়ার বিষয়ে নিশ্চিত?",
		updateEmployee: "কর্মী আপডেট",
	},
	productList: {
		pageTitle: "পণ্য তালিকা",
		offerPageTitle: "অফার তালিকা",
		searchYourProduct: "আপনার পণ্য অনুসন্ধান করুন",
		brandList: "ব্র্যান্ড তালিকা",
		createProduct: "পণ্য তৈরি করুন",
		createPack: "প্যাক তৈরি করুন",
		createOffer: "অফার তৈরি করুন",
		product: "পণ্য",
		pack: "প্যাক",
		Offer: "অফার",
		discount: "ছাড়",
		all: "সবগুলো",
	},
	productDetails: {
		pageTitle: "পণ্যের বিবরণ",
		offerStart: "অফার শুরুর তারিখ",
		offerEnd: "অফার শেষ হওয়ার তারিখ",
		moqLabel: "সর্বনিম্ন অর্ডার",
		consumerLabel: "ভোক্তা মূল্য (এমআরপি)",
		profitLabel: "লাভ",
		productDetailTitle: "পণ্যের বিবরণ",
		expireData: "মেয়াদ উত্তীর্ণের তারিখ",
		manufactureData: "উত্পাদন তারিখ",
		productWeight: "পণ্যের ওজন",
		packingType: "প্যাকিং টাইপ",
		importedBy: "দ্বারা আমদানীকৃত",
		manufacturedBy: "দ্বারা নির্মিত",
		country: "দেশ",
		descriptionLabel: "বর্ণনা",
		btnLabel: "অর্ডার করুন",
		inStock: "ইন স্টক",
		outStock: "স্টক আউট",
		description: "বর্ণনা",
		inventory: "স্টক",
		unpublished: "আনপাবলিশ",
		published: "পাবলিশ",
		edit: "এডিট",
		delete: "মুছে ফেলুন",
		deleteText: "আপনি কি এই পণ্যটি  মুছে দেওয়ার বিষয়ে নিশ্চিত?",
		myProfit: "আমার লাভ",
		buyerProfit: "ক্রেতার লাভ",
		skuId: "এসকেউ আইডি",
		searchTram: "অনুসন্ধানের ট্রাম",
	},
	brandList: {
		pageTitle: "আমার ব্র্যান্ড তালিকা",
		searchBrand: "নাম অনুসারে ব্র্যান্ড খুঁজুন",
		myBrand: "আমার ব্র্যান্ড",
		allBrand: "সব ব্র্যান্ড",
		createNewBrand: "নিজের ব্র্যান্ড তৈরি করুন",
		importBrand: "অন্যের ব্র্যান্ড ইমপোর্ট করুন",
		brandList: "ব্র্যান্ড তালিকা",
	},
	brandDetails: {
		pageTitle: "ব্র্যান্ডের বিবরণ",
		sku: "এসকেইউ",
		import: "ইম্পর্ট",
		edit: "এডিট",
		delete: "ডিলিট",
		skusLabel: "এসকেইউ",
		descriptionLabel: "বর্ণনা",
		productTitleLabel: "এই ব্র্যান্ড পণ্য",
		deleteConformationAsk:
			"আপনি কি নিশ্চিত আপনি এই ব্র্যান্ড মুছে ফেলতে চান?",
	},
	createNewBrand: {
		pageTitle: "নতুন ব্র্যান্ড তৈরি করুন",
		uploadNewLogo: "নতুন লোগো আপলোড করুন",
		brandName: "ব্র্যান্ড নাম",
		brandNamePlaceHolder: "আপনার ব্র্যান্ড নাম লিখুন",
		selectCountry: "দেশ নির্বাচন করুন",
		description: "বর্ণনা",
		descriptionPlaceHolder: "আপনার ব্র্যান্ড বিবরণ লিখুন দয়া করে",
		createdBy: "ক্রিয়েটেড বায়",
		complete: "সম্পূর্ণ",
		congratulation: "অভিনন্দন",
		congratulationParagraph: "নতুন ব্র্যান্ড তৈরি সফলভাবে হয়েছে",
		continue: "কন্টিনিউ",
		conformModal: "আপনি কি নিশ্চিত আপনি এই ব্র্যান্ড তৈরি করতে চান?",
		importBrandConformations:
			"আপনি কি নিশ্চিত আপনি এই ব্র্যান্ড আমদানি করতে চান?",
		errorMessage: "আপনি 1 MB এর বেশি ছবি আপলোড করতে পারবেন না",
		imageFormatError:
			"অবৈধ ছবি বিন্যাস। শুধুমাত্র JPEG, PNG এবং JPG অনুমোদিত।",
		ownerName: "ব্র্যান্ডের আইনি মালিক",
		ownerNamePlaceholder: "আইনি মালিকের নাম লিখুন",
		isLegalOwner: "আমার কোম্পানি আইনত এই ব্র্যান্ডের মালিক",
		ownerNumber: "ফোন নাম্বার",
		ownerNumberPlaceholder: "অনুগ্রহ করে আপনার ফোন নাম্বারটি প্রদান করুন",
	},
	updateBrand: {
		pageTitle: "ব্র্যান্ড আপডেট করুন",
		congratulationParagraph: "ব্র্যান্ড আপডেট সফলভাবে হয়েছে",
		conformModal: "আপনি কি নিশ্চিত আপনি এই ব্র্যান্ড আপডেট করতে চান?",
	},
	createProduct: {
		pageTitle: "পণ্য তৈরি করুন",
		productWarning: "পণ্যের নাম এখানে স্বয়ংক্রিয়ভাবে তৈরি হবে।",
		productPhoto: "পণ্যের ছবি",
		productPhotoUpload: "পণ্যের ছবি আপলোড করুন",
		uploadPhoto: "ছবি আপলোড",
		grossWeight: "মোট ওজন",
		grossWeightPlaceholder: "মোট ওজন লিখুন",
		uploadFromGallery: "গ্যালারি থেকে আপলোড করুন",
		takePhoto: "ছবি তোল",
		productName: "পণ্যের নাম",
		productNamePlaceHolder: "আপনার পণ্যের নাম লিখুন",
		description: "বর্ণনা",
		descriptionPlaceHolder: "আপনার পণ্য বিবরণ লিখুন দয়া করে",
		selectBrand: "ব্র্যান্ড নির্বাচন করুন",
		selectDepartment: "ডিপার্টমেন্ট নির্বাচন করুন",
		selectCategory: "ক্যাটাগরি নির্বাচন করুন",
		selectSubCategory: "সাব-ক্যাটাগরি নির্বাচন করুন",
		setPrice: "মূল্য নির্ধারণ করুন",
		sizeDetermine: "আকার নির্ধারণ করুন",
		inventoryDetermine: "স্টক নির্ধারণ",
		manufacturingDate: "প্রস্তুতকরণ তারিখ",
		expireData: "মেয়াদোত্তীর্ণ ডেটা",
		ManufacturerInformation: "প্রস্তুতকারকের তথ্য",
		save: "সেভ করুন",
		publish: "পাবলিশ করুন",
		purchasePrice: "কাস্টমার ক্রয় মূল্য",
		purchasePricePlaceHolder: "গ্রাহক ক্রয় মূল্য লিখুন",
		sellingPrice: "বিক্রয় মূল্য",
		sellingPricePlaceHolder: "বিক্রয় মূল্য লিখুন",
		priceWarning: "আপনার মূল্য খালি",
		customerPrice: "পুনর্বিক্রয়কারীর বিক্রয় মূল্য",
		customerPricePlaceHolder: "পুনর্বিক্রয়কারীর বিক্রয় মূল্য লিখুন",
		taxVat: "ট্যাক্স/ভ্যাট",
		taxVatPlaceHolder: "ট্যাক্স/ভ্যাট লিখুন",
		moqPlaceHolder: "ন্যূনতম অর্ডারের পরিমাণ লিখুন",
		moq: "ন্যূনতম অর্ডারের পরিমাণ (MOQ)",
		weight: "ওজন",
		weightPlaceholder: "পণ্যের ওজন লিখুন",
		weightWarning: "ওজন খালি",
		measurement: "মাপা",
		weightUnits: "ওজন ইউনিট",
		units: "ইউনিট",
		packingType: "প্যাকিং টাইপ",
		ok: "ঠিক আছে",
		manufacturedCountry: "উৎপাদিত দেশ",
		manufacturedBy: "উৎপাদকের নাম",
		manufacturedByPlaceHolder: "উৎপাদকের নাম লিখুন",
		importedBy: "আমদানিকারকের নাম",
		importedByPlaceHolder: "আমদানিকারকের নাম লিখুন",
		distributedBy: "বিতরণকারকের নাম",
		distributedByPlaceHolder: "বিতরণকারকের নাম লিখুন",
		initialInventory: "প্রাথমিক স্টক",
		initialInventoryPlaceHolder: "প্রাথমিক স্টক লিখুন",
		skuPlaceholder: "এসকেইউ লিখুন",
		sku: "এসকেইউ",
		empty: "খালি",
		barCode: "বার কোড",
		scan: "স্ক্যান",
		width: "প্রস্থ",
		widthPlaceholder: "প্রস্থ লিখুন",
		height: "উচ্চতা",
		heightPlaceholder: "উচ্চতা লিখুন",
		length: "দৈর্ঘ্য",
		lengthPlaceholder: "দৈর্ঘ্য লিখুন",
		searchDepartment: "নাম দিয়ে ডিপার্টমেন্ট সার্চ করুন",
		searchCategory: "নাম দিয়ে ক্যাটাগরি  সার্চ করুন",
		searchSubCategory: "নাম দিয়ে সাব-ক্যাটাগরি সার্চ করুন",
		conformModal: "আপনি কি নিশ্চিত আপনি এই পণ্য তৈরি করতে চান?",
		updateConformModal: "আপনি কি নিশ্চিত আপনি এই পণ্য আপডেট করতে চান?",
		saveConformModal: "আপনি কি নিশ্চিত আপনি এই পণ্য সেভ করতে চান?",
		updateBtn: "আপডেট করুন",
		updateMessage: "পাবলিশ করার জন্য প্রোডাক্টের নাম, দাম, স্টক থাকতেই হবে।",
		publishMessage: "আপনি কি নিশ্চিত আপনি এই পণ্য পাবলিশ করতে চান?",
		unPublishMessage: "আপনি কি নিশ্চিত আপনি এই পণ্য আনপাবলিশ করতে চান?",
		englishName: "অনুসন্ধানের জন্য ইংরেজি নাম",
		englishNamePlaceholder: "অনুসন্ধানের নাম লিখুন",
		productCreated: "পণ্য সফলভাবে তৈরি হয়েছে !",
		finalGrossWeight: "চূড়ান্ত ডেলিভারি ওজন",
		grossWeightUnit: "মোট ওজন ইউনিট",
		productUpdated: "পণ্য সফলভাবে আপডেট হয়েছে !",
	},
	updateProduct: {
		pageTitle: "পণ্য আপডেট করুন",
	},
	createPack: {
		pageTitle: "প্যাক তৈরি করুন",
		packWarning: "প্যাকের নাম এখানে স্বয়ংক্রিয়ভাবে তৈরি হবে।",
		packName: "প্যাকের নাম",
		packNamePlaceHolder: "অনুগ্রহ করে আপনার প্যাকের নাম লিখুন",
		description: "বর্ণনা",
		descriptionPlaceHolder: "আপনার প্যাকের বিবরণ লিখুন",
		selectProduct: "পণ্যে নির্বাচন করুন",
		qtyPack: "প্যাকে পরিমাণ",
		qtyPackPlaceholder: "প্যাকে পরিমাণ লিখুন",
		conformModal: "আপনি কি নিশ্চিত আপনি এই প্যাক তৈরি করতে চান?",
		saveConformModal: "আপনি কি নিশ্চিত আপনি এই প্যাক সেভ করতে চান?",
		productCreated: "প্যাক সফলভাবে তৈরি হয়েছে !",
		productUpdated: "প্যাক সফলভাবে আপডেট হয়েছে !",
	},
	createOffer: {
		pageTitle: "অফার তৈরি করুন",
		offerWarning: "অফারের নাম এখানে স্বয়ংক্রিয়ভাবে তৈরি হবে।",
		offerName: "অফারের নাম",
		offerNamePlaceHolder: "অনুগ্রহ করে আপনার অফারের নাম লিখুন",
		description: "বর্ণনা",
		descriptionPlaceHolder: "আপনার অফারের বিবরণ লিখুন",
		selectProduct: "অফার নির্বাচন করুন",
		qtyOffer: "অফারের পরিমাণ",
		qtyOfferPlaceholder: "অফারের পরিমাণ লিখুন",
		conformModal: "আপনি কি নিশ্চিত আপনি এই অফার তৈরি করতে চান?",
		saveConformModal: "আপনি কি নিশ্চিত আপনি এই অফার সেভ করতে চান?",
		offerStartDate: "অফার শুরুর তারিখ",
		offerEndDate: "অফার শেষ হওয়ার তারিখ",
		selectedItem: "নির্বাচিত পণ্য",
		totalProductCount: "মোট পণ্য",
		totalAmount: "সর্ব মোট",
		spWarnings: "আপনাকে একটি পণ্য নির্বাচন করতে হবে",
		closeButton: "বন্ধ করুন",
		offerCreated: "অফার সফলভাবে তৈরি হয়েছে !",
		offerUpdated: "অফার সফলভাবে আপডেট হয়েছে !",
	},
	updateOffer: {
		pageTitle: "অফার আপডেট করুন",
		conformModal: "আপনি কি নিশ্চিত আপনি এই অফার আপডেট করতে চান?",
	},
	dashboard: {
		statusLable: "অর্ডারের অবস্থা",
		today: "আজ",
		yesterday: "গতকাল",
		thisWeek: "এই সপ্তাহ",
		thisMonth: "এই মাস",
		thisYear: "এই বছর",
		lastWeek: "গত সপ্তাহে",
		lastMonth: "গত মাসে",
		lastYear: "গত বছর",
		custom: "কাস্টম",
		selectDay: "নির্দিষ্ট দিন",
		Order: "অর্ডার",
		totalOrder: "মোট অর্ডার",
		placed: "স্থাপিত",
		accepted: "গ্রহণ",
		scDelivered: "ডেলিভারির জন্য নির্ধা..",
		partialDelivered: "আংশিক ডেলিভারি",
		delivered: "ফুল ডেলিভারি",
		return: "ফেরত পণ্য",
		partialPaid: "আংশিক পরিশোধ",
		paid: "ফুল পরিশোধ",
		totalPaid: "মোট পরিশোধ",
		cancel: "বাতিল",
		deliveryFailed: "ডেলিভারি ব্যর্থ হয়েছে",
		orderAmount: "অর্ডার এমাউন্ট",
		totalAmount: "মোট এমাউন্ট",
		collectedAmount: "কলেক্টেড এমাউন্ট",
		dueAmount: "বাকি",
		totalDue: "মোট বাকি",
		directOrder: "ডাইরেক্ট অর্ডার",
		directOrderAmount: "ডাইরেক্ট অর্ডার এমাউন্ট",
		ekkHeroOrder: "EkkHero অর্ডার",
		ekkHeroOrderAmount: "EkkHero অর্ডার এমা..",
		cancelOrderAmount: "বাতিল অর্ডার এমাউন্ট",
		seeDetails: "বিস্তারিত দেখুন",
		period: "সময়কাল",
		perviousWeek: "আগের সপ্তাহ",
		nextWeek: "পরের সপ্তাহে",
		supplierOrder: "সাপ্লায়ারের অর্ডার",
		customerOrder: "কাষ্টমারের অর্ডার",
	},
	newCustomer: {
		pageTitle: "নতুন ডিস্ট্রিবিউটর তৈরি করুন",
		mobileNo: "মোবাইল নম্বর",
		customerNoHolder: "ডিস্ট্রিবিউটর মোবাইল নম্বর লিখুন",
		customerName: "ডিস্ট্রিবিউটর নাম",
		customerNameHolder: "ডিস্ট্রিবিউটরের পুরো নাম লিখুন",
		businessPhoto: "ব্যবসায়িক ছবি",
		uploadPhoto: "ব্যবসা/স্টোরের ছবি আপলোড করুন",
		uploadPhotoTitle: "ছবি আপলোড",
		uploadFromGallery: "গ্যালারি থেকে আপলোড করুন",
		takePhoto: "ছবি তোল",
		businessName: "ব্যবসার নাম",
		businessNameHolder: "ব্যবসা/স্টোরের নাম লিখুন",
		address: "ঠিকানা",
		addressHolder: "ব্যবসা/স্টোরের ঠিকানা লিখুন",
		city: "শহর",
		cityHolder: "শহরের নাম লিখুন",
		postalCode: "পোস্ট কোড",
		postalCodeHolder: "পোস্ট কোড লিখুন",
		selectBusiness: "ব্যবসার ধরন নির্বাচন করুন",
		fmcg: "এফএমসিজি",
		nonFmcg: "নন-এফএমসিজি",
		nextBtn: "পরবর্তী",
		completeBtn: "সম্পূর্ণ",
		customerFindText: `ডিস্ট্রিবিউটর ইতিমধ্যেই এখানে পাওয়া গেছে, আপনি চাইলে এটিকে "ডিস্ট্রিবিউটর তালিকা"-এ যোগ করতে পারেন। এবং যদি আপনি চান, আপনি সবুজ বোতামে ক্লিক করে একটি নতুন ডিস্ট্রিবিউটর তৈরি করতে পারেন।`,
		addBusiness: "ব্যবসা যোগ করুন",
		addBusinessInfo:
			"আপনি কি আমার ডিস্ট্রিবিউটর হিসাবে এই ব্যবসা যোগ করতে চান?",
		noBtn: "না",
		alreayExist: "ডিস্ট্রিবিউটর আগে থেকেই আছে!",
		reseller: "রিসেলার",
		businessNameWarning: "অনুগ্রহ করে ব্যবসার নাম যোগ করুন",
		distributorNameWarning: "অনুগ্রহ করে ডিস্ট্রিবিউটরের নাম যোগ করুন",
		businessCreationSuccess: "নতুন ব্যবসা সফলভাবে তৈরি করা হয়েছে",
		businessLocationWarning: "গুগল ম্যাপ থেকে ব্যবসার অবস্থান যোগ করুন",
	},
	kycVerification: {
		imageWarning: "আপনি 1 MB এর বেশি ছবি আপলোড করতে পারবেন না৷",
		imageUpload: "এখানে আপনার ছবি আপলোড করুন, অথবা ব্রাউজ করুন",
		nidFront: "NID - সামনের দিক",
		nidBack: "NID - পিছনের দিক",
		uploadNidFront: "NID আপলোড করুন “ফ্রন্ট সাইড”",
		uploadNidBack: "NID আপলোড করুন পিছনের সাইড”",
		complete: "আপলোড সম্পূর্ণ",
		pleaseCheck: "চেক করুন",
		next: "পরবর্তী",
		button: "বাটন",
		upload: "আপলোড করুন",
		nid: "NID",
		copy: "কপি",
		tradeLicense: "ট্রেড লাইসেন্স",
		uploadTradeLicense: "আপলোড “ট্রেড লাইসেন্স”",
		giveLater: "আমি এই তথ্য পরে দেব",
		propertyType: "সম্পত্তির প্রকার",
		rented: "ভাড়া",
		own: "নিজের",
		rentalAgreement: "মালিকের সাথে ভাড়া চুক্তি/ দলিল",
		ownAgreement: "	মালিকানা চুক্তি/ দলিল",
		rentalDocument: "ভাড়া চুক্তির নথি",
		ownDocument: "নিজস্ব সম্পত্তির কাগজ",
		startDate: "ভাড়া শুরুর তারিখ",
		rentPerMonth: "প্রতি মাসে ভাড়া",
		rentPerMonthPlaceholder: "প্রতি মাসে আপনার দোকান ভাড়া লিখুন",
		select: "অনুগ্রহ করে, আপনার নির্বাচন করুন",
		fillup: "এবং আপনার পূরণ করুন",
		amount: "পরিমাণ",
		click: "ক্লিক করুন",
		submit: "জমা দিন",
	},
	notification: {
		pageTitle: "নোটিফিকেশন",
		customer: "কাস্টমার",
		supplier: "সাপ্লায়ার",
		update: "আপডেট",
		supllierPageTitle: "সাপ্লায়ার থেকে বিজ্ঞপ্তি",
	},
	paymentSettings: {
		pageTitle: "পেমেন্ট শর্তাবলী সেটিং",
		mainTitle: "আপনার উপযুক্ত অর্থপ্রদান শর্তাবলী নির্বাচন করুন",
		subTitle:
			"আপনি এখানে যে অর্থপ্রদানের শর্তাবলী নির্বাচন করেছেন, গ্রাহক অর্ডার করার সময় শুধুমাত্র এই অর্থপ্রদান শর্তাবলী ব্যবহার করতে পারবেন",
		btnLabel: "সেভ করুন",
		payNow: "এখনই অর্থ প্রদান করুন",
		cashOnDelivery: "ক্যাশ অন ডেলিভারি",
		pickAndPay: "পিক অ্যান্ড পে",
		today: "ডেলিভারি দিনের রাতে",
		threeDays: "৩ দিনের মধ্যে অর্থ প্রদান করুন",
		sevenDays: "৭ দিনের মধ্যে অর্থ প্রদান করুন",
		fourteenDays: "১৪ দিনের মধ্যে অর্থ প্রদান করুন",
		thirtyDays: "৩০ দিনের মধ্যে অর্থ প্রদান করুন",
	},
	orderSummary: {
		orderProductPageTitle: "অর্ডার পণ্য সারাংশ",
		orderProductDetailsPageTitle: "অর্ডার পণ্য সারাংশ বিবরণ",
		driverProductPageTitle: "অর্ডার ড্রাইভার পণ্য সারাংশ",
		orderDriverProductDrilldown: "অর্ডার ড্রাইভার পণ্য সারাংশ বিবরণ",
		driverDeliveryPageTitle: "অর্ডার ড্রাইভার ডেলিভারি সারাংশ",
		deliveryAmountPageTitle: "অর্ডার ডেলিভারি পরিমাণ সারাংশ",
		orderDeliveryAmountDrilldown: "অর্ডার ডেলিভারি পরিমাণ বিবরণ",
		buyerSummary: "ক্রেতার সারাংশ",
		buyerSummaryDrillDown: "ক্রেতার সারাংশ বিবরণ",
		delayInPayment: "পেমেন্টে বিলম্ব",
		delayInPaymentDetails: "পেমেন্টে বিলম্ব বিবরণ",
		productTitle: "পণ্যের শিরোনাম",
		qty: "পরিমাণ",
		orderNo: "অর্ডার নম্বর",
		date: "তারিখ",
		status: "স্ট্যাটাস",
		product: "পণ্য",
		driver: "ড্রাইভার",
		searchPlaceholder: "ড্রাইভার অনুসন্ধান করুন",
		totalAmount: "সর্বমোট পরিমাণ",
		totalPaid: "সর্বমোট পরিশোধ",
		totalDue: "সর্বমোট বাকি",
		numberOfOrders: "মোট অর্ডার",
		deliveryFailed: "ডেলিভারি ব্যর্থ",
		gvmAmount: "GMV",
		numberOfFulfillment: "পূর্ণতা",
		orderCancel: "অর্ডার বাতিল",
		revenue: "আয়",
		dueAmount: "বাকি টাকা",
		dayPassed: "দিন অতিবাহিত",
		storeName: "দোকানের নাম",
		selectPlaceholder: "দিন নির্বাচন করুন",
		amount: "পরিমাণ",
		orderAmount: "অর্ডার পরিমাণ",
	},
	autoLogOut: {
		pageTitle: "স্বয়ংক্রিয় লগআউট",
		warningMsg:
			"স্বয়ংক্রিয় লগআউট যেহেতু এই নম্বরটি অন্য লগইনে ব্যবহৃত হয়েছিল৷",
		instrMsg: "আবার লগইন করতে নিচের সবুজ বোতামে ক্লিক করুন",
		btnLabel: "লগইন",
	},
	customerOrder: {
		customerOrderAmount: "ডিস্ট্রিবিউটর অর্ডারের পরমাণ",
	},
	addBankSettings: {
		bottomSheetTitle: "ব্যাংক অ্যাকাউন্ট যোগ করুন",
		pageTitle: "ব্যাংক অ্যাকাউন্ট যোগ করুন",
		accountName: "অ্যাকাউন্ট নাম",
		accountNamePlaceholder: "আপনার অ্যাকাউন্ট নাম লিখুন",
		accountNumber: "অ্যাকাউন্ট নম্বর",
		accountNumberPlaceholder: "আপনার অ্যাকাউন্ট নম্বর লিখুন",
		selectBank: "ব্যাংক নির্বাচন করুন",
		selectBankPlaceHolder: "আপনার ব্যাংক অ্যাকাউন্ট নির্বাচন করুন",
		branch: "শাখা",
		branchPlaceHolder: "আপনার ব্যাংক শাখা লিখুন",
		address: "ঠিকানা",
		addressPlaceholder: "ব্যাংক ঠিকানা লিখুন",
		selectCountry: "দেশ নির্বাচন করুন",
		selectCountryPlaceHolder: "আপনার ব্যাংক দেশ নির্বাচন করুন",
		selectCity: "শহর নির্বাচন করুন",
		selectCityPlaceHolder: "শহর নির্বাচন করুন",
		postalCode: "পোস্টাল কোড",
		postalCodePlaceHolder: "আপনার ব্যাংক পোস্টাল কোড লিখুন",
		buttonTitle: "সংরক্ষণ করুন",
		bankNameWarning: "আপনার ব্যাঙ্ক অ্যাকাউন্ট নাম লিখুন",
		bankNumberWarning: "আপনার ব্যাঙ্ক অ্যাকাউন্ট নম্বর লিখুন",
		selectBankWarning: "একটি ব্যাঙ্ক নির্বাচন করুন",
		branchWarning: "দয়া করে আপনার ব্যাংক শাখা লিখুন",
		addressWarning: "দয়া করে আপনার ঠিকানা লিখুন",
		cityWarning: "দয়া করে আপনার শহর লিখুন",
		searchBank: "আপনার ব্যাংক খুজুন",
	},
	addedBankList: {
		pageTitle: "ব্যাংক তালিকা",
		acName: "একাউন্ট নাম",
		acNumber: "একাউন্ট নাম্বার",
		branch: "শাখা",
		address: "ঠিকানা",
		city: "শহর",
		country: "দেশ",
		addBank: "আপনার ব্যাংক অ্যাকাউন্ট যোগ করুন",
	},
	defaultPayment: {
		menuTitle: "ডিফল্ট পেমেন্ট",
		pageTitle: "ডিফল্ট পেমেন্টস",
		pageHeadline:
			"এই ডিস্ট্রিবিউটরের জন্য Baz AI অর্ডারিং এর জন্য উপযুক্ত পেমেন্ট টার্ম এবং মেথড সেটিং নির্বাচন করুন",
		pageSubHeadline:
			"আপনি এখানে নির্বাচন করা পেমেন্ট টার্ম এবং মেথড, এই ডিস্ট্রিবিউটর কেবল এই পেমেন্ট টার্ম এবং মেথড ব্যবহার করতে পারবেন যখন Baz AI দিয়ে অর্ডার দেবেন",
		paymentTermLabel: "পেমেন্ট শর্তাবলী",
		paymentTermPlaceHolder: "একটি পেমেন্ট শর্তাবলী নির্বাচন করুন",
		paymentMethodLabel: "পেমেন্ট পদ্ধতি",
		paymentMethodPlaceHolder: "একটি ব্যাংক নির্বাচন করুন",
		successMessage: "ডিফল্ট পেমেন্ট সফলভাবে যোগ করা হয়েছে",
		successMessage2: "ডিফল্ট পেমেন্ট সফলভাবে আপডেট হয়েছে",
	},
	blackListed: {
		pageTitle: "ব্ল্যাকলিস্টেড",
		warningMsg: "আপনার ব্যবসা ব্ল্যাকলিস্টে রয়েছে",
		instrMsg:
			"আপনার ব্যবসার মালিককে অনুরোধ করুন EkkBaz এ যোগাযোগ করে প্রয়োজনীয় ব্যবস্থা নিয়ে পুনরায় আপনার ব্যবসা সক্রিয় করুন।",
		btnLabel: "লগ আউট",
	},
	payment: {
		pageTitle: "পে একবাজ",
		bank: "ব্যাংক",
		acName: "A/C নাম",
		acNumber: "A/C নম্বর",
		branch: "শাখা",
		address: "ঠিকানা",
		city: "শহর",
		postal: "পোস্টাল কোড",
		country: "দেশ",
		selectTimeDate: "সময় এবং তারিখ নির্বাচন করুন",
		transitionId: "ট্রানজেকশন আইডি",
		transitionIdPlaceHolder: "আপনার ট্রানজেকশন আইডি লিখুন",
		amount: "পরিমাণ",
		amountPlaceHolder: "আপনার পাঠানো পরিমাণ লিখুন",
		uploadReceipt: "রসিদ আপলোড করুন",
		senderInfo: "প্রেরকের তথ্য",
		receiverInfo: "প্রাপকের তথ্য",
		senderWarning: "দয়া করে আপনার ব্যাংক অ্যাকাউন্ট যোগ করুন",
		paymentConformationMessage:
			"আপনি কি নিশ্চিত যে আপনি এই পেমেন্টটি জমা দিতে চান?",
		inputWarning: "দয়া করে এই ফিল্ডটি পূর্ণ করুন",
		congressPayment: "অভিনন্দন! আপনার পেমেন্টটি সফলভাবে জমা হয়েছে।",
		paymentRecieptImage: "পেমেন্ট রশিদের ছবি",
		receiptOrBank: "রসিদ বা ব্যাংক স্লিপ এর ছবি আপলোড করুন",
		warning: "অর্থের পরিমাণ সঠিক নয়",
	},
	industryType: {
		pageTitle: "ওয়ার্কিং ইন্ডাস্ট্রি",
		title: "অনুগ্রহ করে আপনার ইন্ডাস্ট্রি নির্বাচন করুন",
	},
	driectSales: {
		pageTitle: "সরাসরি বিক্রি",
		customer: "কাষ্টমার তালিকা",
		orderList: "অর্ডার তালিকা",
		searchCustomer: "কাষ্টমার খুঁজুন",
		ownCustomer: "নিজের কাষ্টমার",
		allCustomer: "সকাল কাষ্টমার",
		ownCustomerSearch: "নিজের কাষ্টমার খুঁজুন",
		allCustomerSearch: "সকাল কাষ্টমার খুঁজুন",
	},
	invitedDistributor: {
		pageTitle: "ইনভাইটেড ডিস্টিবিউটর তালিকা",
		searchPlaceHolder: "ডিস্টিবিউটরের নাম লিখুন",
		invitedDistributor: "ডিস্টিবিউটর হিসেবে যোগ করতে চান?",
		invited: "ইনভাইটেড",
		inviteApprove: "ইনভাইট গ্রহণ করা হয়েছে",
	},
	addCard: {
		pageTitle: "এড পেমেন্ট কার্ড",
		addedList: "যোগ করা কার্ড তালিকা",
		addNewCard: "নতুন কার্ড যোগ করুন",
		continue: "চালিয়ে যান",
	},
	paymentModule: {
		pageTitle: "আপগ্রেড প্ল্যান নির্বাচন করুন",
		upgradeNow: "এখনই আপগ্রেড করুন",
		monthlyPlan: "মাসিক প্ল্যান",
		annualPlan: "বার্ষিক প্ল্যান",
		card: "কার্ড",
		paymentMethod: "পেমেন্ট পদ্ধতি",
		continue: "চালিয়ে যান",
		selectedMethod: "অনুগ্রহ করে একটি পেমেন্ট পদ্ধতি নির্বাচন করুন..!",
		checkOut: "চেক আউট",
		paymentCard: "পেমেন্ট কার্ড",
		changeCard: "কার্ড পরিবর্তন করুন",
		selectPackage: "প্যাকেজ নির্বাচন করুন",
		orderSummary: "অর্ডার সারাংশ",
		subtotal: "সাবটোটাল",
		total: "মোট",
		payNow: "এখন পরিশোধ করুন",
		successTitle: "পেমেন্ট সফল",
		successText: "আপনার পেমেন্টের জন্য ধন্যবাদ",
		yourPlan: "আপনার সাবস্ক্রিপশন প্ল্যান",
		transactionHistory: "লেনদেনের ইতিহাস",
		cardNumber: "কার্ড নম্বর",
		status: "স্ট্যাটাস",
		invoice: "ইনভয়েস",
		newCard: "নতুন কার্ড যোগ করুন",
		couponDiscount: "কুপন ডিসকাউন্ট",
		discount: "ডিসকাউন্ট",
		getDiscount: "ডিসকাউন্ট",
		noAvailableCard: "কোন কার্ড নেই",
		noTransaction: "আপনার কোনও পূর্ববর্তী ট্রাঞ্জেকশান নেই",
		enterCouponCode: "কুপন কোড লিখুন",
	},
};
export default bangla;
