import { IonApp, IonContent, IonPage } from "@ionic/react";
import LeftSidebar from "components/global-components/Navigation/LeftSidebar";
import BackButtonTitle from "components/mobile-components/Common/BackButtonTitle";
import Dashboard from "components/mobile-components/DashBoard";
import { getUserBusiness } from "config/user";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DashboardBottomSheet from "components/mobile-components/DashBoard/DashboardBottomSheet";
import {
  setCustomerUnreadCount,
  setSupplierUnreadCount,
} from "redux/container/notificationSlice";
import Notification from "services/Notification";
import { setWarning } from "redux/container/userSlice";
import { getLanguage } from "config/language";

export default function DashboardPage() {
  const [menuCollapse, setMenuCollapse] = useState(true);
  const ekkLanguage = useSelector((state) => state.lan.lan);
  const dispatch = useDispatch();
  const id = getUserBusiness()?.id;
  const localLanguage = getLanguage();

  const menuIconClick = () => {
    setMenuCollapse(!menuCollapse);
  };

  useEffect(() => {
    dispatch(setWarning(true));
    let getUnreadCount = async () => {
      //   dispatch(setDriverData(""))
      if (id) {
        let response1 = await Notification.getNewCustomerUnreadCount(
          id,
          localLanguage === "bn" ? "BD" : "EN"
        );
        if (response1.status === 200) {
          dispatch(setCustomerUnreadCount(response1?.unread_count));
        }
        // let response = await Notification.getSupplierUnreadCount(id);
        // if (response.status === 200) {
        //   dispatch(setSupplierUnreadCount(response?.unread_count));
        // }
      }
    };
    getUnreadCount();
  }, [id, dispatch]);

  return (
    <IonApp>
      <IonPage>
        <IonContent>
          <LeftSidebar
            isMobile={true}
            menuCollapse={menuCollapse}
            menuIconClick={menuIconClick}
          />
          <BackButtonTitle
            title={ekkLanguage.letNav.dashboardProfileMenuLabel}
            burgerMenu={true}
            menuCollapse={menuCollapse}
            menuIconClick={menuIconClick}
            from="dashboard"
          />
          <DashboardBottomSheet />

          <section className="pt-14 px-2">
            <Dashboard />
          </section>
        </IonContent>
      </IonPage>
    </IonApp>
  );
}
